<template>
  <f7-page>
    <navbar :text="$t('BareRoots_title')" />
    <DxBareRootTable />
  </f7-page>
</template>

<script>
import DxBareRootTable from '../../components/greenhouse/DxBareRootTable.vue';
import navbar from '../../components/NavBar.vue';

export default {
  name: 'BareRootsPage',
  components: {
    navbar,
    DxBareRootTable,
  },
  computed: {
  },
  methods: {
  },
};
</script>
