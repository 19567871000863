<template>
  <div
    v-if="loaded"
    class="content-devx content-dx-work-orders main-dx-work-orders"
  >
    <f7-row>
      <f7-col>
        <ExportDataToExcel
          :data-to-export="detailDailyTasksToExport"
          :column-titles="arrayExcelColumnTranslations"
          :name-file="nameFileToExport"
        />
      </f7-col>
      <f7-col>
        <CalendarInitEndDate :key-name="keyName" />
      </f7-col>
    </f7-row>
    <DxDataGrid
      id="gridContainer"
      :data-source="detailDailyTasks"
      :remote-operations="true"
      :column-min-width="70"
      :allow-column-reordering="true"
      :show-borders="true"
      :show-column-lines="true"
      :show-row-lines="false"
      :column-auto-width="true"
      :row-alternation-enabled="true"
      @exporting="onExporting"
    >
      <DxSelection
        mode="single"
      />
      <DxStateStoring
        :enabled="true"
        type="localStorage"
        :storage-key="`${keyName}`"
      />
      <DxHeaderFilter :visible="true" />
      <DxExport
        :enabled="true"
      />
      <DxSelection
        mode="single"
      />
      <DxPaging :page-size="10" />
      <DxPager
        :show-page-size-selector="true"
        :allowed-page-sizes="pageSizes"
        :show-info="true"
      />
      <DxSearchPanel
        :visible="true"
        :width="200"
        :highlight-case-sensitive="true"
        :placeholder="$t('Search_placeholder')"
      />
      <DxColumn
        :buttons="editButtons"
        type="buttons"
        width="80"
        data-field="Actions"
      />
      <DxColumn
        data-field="short_id"
        name="short_id"
        :caption="$t('DxWorkOrder_short_id')"
        :allow-header-filtering="false"
      />
      <DxColumn
        data-field="Date"
        name="date"
        :caption="$t('DxDailyPlanning_date_caption')"
        :allow-header-filtering="false"
      />
      <DxColumn
        data-field="Activity"
        name="Activity"
        :caption="$t('DxDailyPlanning_activity_caption')"
        :allow-header-filtering="true"
      />
      <DxColumn
        data-field="Task"
        name="task"
        :width="200"
        css-class="text-expand"
        :caption="$t('DxDailyPlanning_task_caption')"
        :allow-header-filtering="true"
      />
      <DxColumn
        v-if="checkFarmSerbia()"
        data-field="group"
        name="group"
        :width="200"
        :caption="$t('DxWorkOrders_ExportTranslation_Group')"
        :allow-header-filtering="true"
      />
      <DxColumn
        v-if="checkFarmSerbia()"
        data-field="shift"
        name="shift"
        :width="200"
        css-class="text-expand"
        :caption="$t('DxWorkOrders_ExportTranslation_Shift')"
        :allow-header-filtering="true"
      />
      <DxColumn
        data-field="locationLevel2"
        name="location"
        :caption="$t('DxDailyPlanning_location_caption')"
        :allow-header-filtering="false"
      />
      <DxColumn
        v-if="keyName === 'harvesting'"
        data-field="variety"
        name="variety"
        :caption="$t('DxWorkOrders_variety_caption')"
        :allow-header-filtering="true"
        alignment="center"
      />
      <DxColumn
        data-field="real_area_effective"
        name="real_area_effective"
        data-type="number"
        :caption="$t('DxDailyPlanning_workingArea_caption')"
        :allow-header-filtering="false"
        alignment="center"
        width="140"
      />
      <DxColumn
        v-if="(keyName !== 'harvesting')"
        data-field="goal_performance"
        name="goal_performance"
        :caption="$t('DxWorkOrders_productivity_planned_caption')"
        :allow-header-filtering="false"
        alignment="center"
        width="140"
      />
      <DxColumn
        v-else
        data-field="goal_performance"
        name="goal_performance"
        :caption="$t('DxMonthlyPlanning_goal_harvesting_caption')"
        :allow-header-filtering="false"
        alignment="center"
        width="140"
      />
      <DxColumn
        data-field="productivity"
        name="productivity"
        :caption="$t('DxWorkOrders_productivity_caption')"
        :allow-header-filtering="false"
        alignment="center"
        width="140"
      />
      <DxColumn
        data-field="Workers"
        name="workers"
        :caption="$t('DxDailyPlanning_workers_caption')"
        :allow-header-filtering="false"
        alignment="center"
      />
      <DxColumn
        data-field="Supervisor"
        name="supervisor"
        :caption="$t('DxDailyPlanning_supervisors_caption')"
        :allow-header-filtering="true"
        alignment="left"
      />
      <DxColumn
        v-if="reporting"
        data-field="creation"
        name="creation"
        :caption="$t('DxDailyPlanning_creation_caption')"
        :allow-header-filtering="true"
        alignment="left"
      />
      <DxColumn
        v-if="keyName === 'harvesting'"
        data-field="manualPerformance.total"
        name="manualPerformance"
        :caption="$t('DxWorkOrders_manualPerformance_caption')"
        :allow-header-filtering="false"
        alignment="center"
      />
      <DxColumn
        data-field="TaskStatus"
        name="task_status"
        :caption="$t('DxDailyPlanning_task_status_caption')"
        alignment="center"
        :allow-header-filtering="true"
        :header-filter="{ dataSource: dataTaskStatus }"
        cell-template="taskStatusTemplate"
      />
      <DxColumn
        data-field="WorkOrderStatus"
        name="work_order_status"
        width="160"
        alignment="center"
        :caption="$t('DxDailyPlanning_work_order_status_caption')"
        :allow-header-filtering="true"
        :header-filter="{ dataSource: dataWordTaskStatus }"
        cell-template="workOrderStatusTemplate"
      />
      <DxColumn
        data-field="additionalInfoWorkOrder"
        name="notes"
        :caption="$t('DxWorkOrders_notes_caption')"
        :allow-header-filtering="false"
        alignment="left"
        width="400"
        css-class="text-expand"
      />
      <template #taskStatusTemplate="{ data: detailDailyTasks }">
        <div :class="detailDailyTasks.data.taskStatusTemplateClass">
          {{ detailDailyTasks.data.taskStatusTranslated }}
        </div>
      </template>
      <template #workOrderStatusTemplate="{ data: detailDailyTasks }">
        <div :class="detailDailyTasks.data.workOrderStatusTemplateClass">
          {{ detailDailyTasks.data.workOrderStatusTranslated }}
        </div>
      </template>
      <DxMasterDetail
        :enabled="true"
        template="master-detail"
      />
      <template #master-detail="{ data: detailDailyTasks }">
        <DxDetailTabs :master-detail-data="detailDailyTasks" />
      </template>
    </DxDataGrid>
    <DxPopupWorkOrder
      v-if="visiblePopupWorkOrder"
      :machinery-popup="machineryPopup"
      :product-popup="productPopup"
      :supervisor="supervisorPopup"
      :supervisors-availables="supervisorsAvailables"
      :back="back"
    />
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxMasterDetail,
  DxSearchPanel,
  DxExport,
  DxHeaderFilter,
  DxStateStoring,
  DxPager,
  DxSelection,
} from 'devextreme-vue/data-grid';
import { exportDataGrid } from 'devextreme/excel_exporter';
import ExcelJS from 'exceljs';
import moment from 'moment';
import saveAs from 'file-saver';
import * as overlay from 'devextreme/ui/overlay';
import DxDetailTabs from './DxDetailTabs.vue';
import DxPopupWorkOrder from './DxPopupWorkOrder.vue';
import Api from '../../services/Api';
import CalendarInitEndDate from '../CalendarInitEndDate.vue';
import ExportDataToExcel from '../ExportDataToExcel.vue';

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxMasterDetail,
    DxDetailTabs,
    DxSearchPanel,
    DxExport,
    DxHeaderFilter,
    DxPopupWorkOrder,
    DxStateStoring,
    CalendarInitEndDate,
    DxPager,
    DxSelection,
    ExportDataToExcel,
  },
  props: {
    includeActivities: { type: Array, default: () => [] },
    excludeActivities: { type: Array, default: () => [] },
    back: { type: String, default: 'workOrders' },
    keyName: { type: String, default: '' },
    reporting: { type: Boolean, default: false },
  },
  data() {
    return {
      loaded: false,
      detailDailyTasksToExport: [],
      nameFileToExport: '',
      arrayExcelColumnTranslations: [],
      detailDailyTasks: [],
      finalLevelLocation: [],
      supervisorsAvailables: [],
      taskStatusTranslated: '',
      workOrderStatusTranslated: '',
      machineryPopup: [],
      productPopup: [],
      ocultarBotton: false,
      editButtons: [{
        icon: 'edit',
        hint: this.$t('DxWorkOrders_edit_hint'),
        visible: this.isEditIconVisible,
        onClick: this.editWorkOrder,
      },
      {
        icon: 'trash',
        hint: this.$t('DxDailyPlanning_delete_hint'),
        visible: this.isDeleteIconVisible,
        onClick: this.deleteTask,
      },
      {
        icon: 'find',
        hint: this.$t('DxWorkOrder_Button_View_hint'),
        visible: this.isViewconVisible,
        onClick: this.viewtask,
      }],
      dataTaskStatus: [{
        text: this.$t('Status_Open'),
        value: 'OPEN',
      }, {
        text: this.$t('Status_Completed'),
        value: 'COMPLETED',
      },
      {
        text: this.$t('Status_InProgress'),
        value: 'In_Progress',
      }],
      dataWordTaskStatus: [{
        text: this.$t('Status_reallyNot_Closed'),
        value: 'OPEN',
      }, {
        text: this.$t('Status_Closed'),
        value: 'COMPLETED',
      }],
      supervisorPopup: '',
      pageSizes: [5, 10, 20],
    };
  },
  computed: {
    ...mapState('PlanningManager', ['visiblePopupWorkOrder']),
    ...mapGetters('CalendarInitEndDate', ['actualFilter']),
    ...mapGetters('greenhouse', ['getVarietyNameByCode', 'getCategoryNameByCode', 'getClassificationNameByCode', 'getLocationNameLevelSelectedChildId']),
  },
  async beforeMount() {
    overlay.baseZIndex(9999);
    this.$f7.preloader.show();
    try {
      await this.fetchLocationTree();
      // this.locationsTree = await this.loadLocationsTree();
      this.supervisorsAvailables = await this.getSupervisors();
      await this.fetchBinsContentFamilies();
      const checkedInitEndDate = Api.checkInitEndDate(this.actualFilter[this.keyName]);
      const xhr = await Api.getDailyTasks(true, this.includeActivities, this.excludeActivities, checkedInitEndDate.init, checkedInitEndDate.end, this.reporting);
      const dailyTasks = JSON.parse(xhr.response);
      this.detailDailyTasks.length = 0;
      for (let i = 0; i < dailyTasks.detail.length; i += 1) {
        for (let j = 0; j < dailyTasks.detail[i].tasks.length; j += 1) {
          const items = this.getItems(dailyTasks.detail[i].tasks[j].items_drivers);
          // Establedemos los nombres de variedad/categoria/clasificacion a través del code
          if (dailyTasks.detail[i].tasks[j].nursery) {
            dailyTasks.detail[i].tasks[j].nursery.forEach((element) => {
              const varietyName = this.getVarietyNameByCode(element.variety);
              const categoryName = this.getCategoryNameByCode(element.category);
              const classificationName = this.getClassificationNameByCode(element.class_type);
              element.variety = varietyName !== '' ? varietyName : element.variety;
              element.category = varietyName !== '' ? categoryName : element.category;
              element.class_type = classificationName !== '' ? classificationName : element.class_type;
            });
          }
          if (dailyTasks.detail[i].tasks[j].planner != null) {
            if (this.reporting && (dailyTasks.detail[i].tasks[j].status !== 'COMPLETED' || dailyTasks.detail[i].tasks[j].worder_status !== 'COMPLETED')) {
              continue;
            }
            let manuallyOrPlanner = '';
            if (dailyTasks.detail[i].tasks[j].planner.planner_id !== null) {
              manuallyOrPlanner = this.$t('DxDailyPlanning_CreationPlanned_caption');
            } else {
              manuallyOrPlanner = this.$t('DxDailyPlanning_CreationManually_caption');
            }

            const newTask = {
              ID: dailyTasks.detail[i].tasks[j].id,
              Date: moment(dailyTasks.detail[i].tasks[j].date, 'YYYY-MM-DD').format('YYYY-MM-DD'),
              Task: dailyTasks.detail[i].tasks[j].task,
              taskCode: dailyTasks.detail[i].tasks[j].task_code,
              Activity: dailyTasks.detail[i].tasks[j].activity,
              activityCode: dailyTasks.detail[i].tasks[j].activity_code,
              TaskStatus: dailyTasks.detail[i].tasks[j].status,
              taskStatusTemplateClass: this.setClassByTaskStatus(dailyTasks.detail[i].tasks[j].status),
              taskStatusTranslated: this.taskStatusTranslated,
              WorkOrderStatus: dailyTasks.detail[i].tasks[j].worder_status,
              workOrderStatusTemplateClass: this.setClassByWorkOrderStatus(dailyTasks.detail[i].tasks[j].worder_status),
              workOrderStatusTranslated: this.workOrderStatusTranslated,
              Supervisor: this.getNameSupervisor(dailyTasks.detail[i].tasks[j].responsible),
              Workers: `${this.getTotalWorkers(dailyTasks.detail[i].tasks[j].workers)} / ${this.getTotalWorkersPlanner(dailyTasks.detail[i].tasks[j].planner.workers)}`,
              WorkersDetailTab: dailyTasks.detail[i].tasks[j].workers,
              ItemsDetailTab: items,
              Attachments: dailyTasks.detail[i].tasks[j].attachments_vehicles,
              AttachmentsDetailTab: dailyTasks.detail[i].tasks[j].attachments,
              Tools: dailyTasks.detail[i].tasks[j].planner.tools,
              ToolsDetailTab: dailyTasks.detail[i].tasks[j].tools,
              itemsTypes: dailyTasks.detail[i].tasks[j].planner.items,
              items,
              itemsDriversWorkOrder: dailyTasks.detail[i].tasks[j].items_drivers,
              locationLevel2: this.getNamesByBdIds(dailyTasks.detail[i].tasks[j].location, dailyTasks.detail[i].tasks[j].activity_code),
              area_effective: dailyTasks.detail[i].tasks[j].area_effective,
              real_area_effective: this.calculateRealAreaEfective(dailyTasks.detail[i].tasks[j].workers),
              goal_performance: this.getGoalPerformance(dailyTasks.detail[i].tasks[j].planner),
              location: dailyTasks.detail[i].tasks[j].location,
              Planner: dailyTasks.detail[i].tasks[j].planner,
              responsible: dailyTasks.detail[i].tasks[j].responsible,
              additionalInfoWorkOrder: dailyTasks.detail[i].tasks[j].comment,
              machineryPopup: dailyTasks.detail[i].tasks[j].machinery_performance,
              products: dailyTasks.detail[i].tasks[j].products,
              prescription: dailyTasks.detail[i].tasks[j].prescription,
              productPopup: dailyTasks.detail[i].tasks[j].product_performance,
              variety: dailyTasks.detail[i].tasks[j].variety,
              binsDetail: dailyTasks.detail[i].tasks[j].nursery,
              short_id: dailyTasks.detail[i].tasks[j].short_id,
              manualPerformance: dailyTasks.detail[i].tasks[j].performance,
              creation: manuallyOrPlanner,
              supervisorsLocation: dailyTasks.detail[i].tasks[j].responsible,
              group: dailyTasks.detail[i].tasks[j].planner.group,
              shift: dailyTasks.detail[i].tasks[j].planner.shift,
              shortId: dailyTasks.detail[i].tasks[j].short_id,
              productivity: this.calculateProductivity(dailyTasks.detail[i].tasks[j].workers, dailyTasks.detail[i].tasks[j].planner),
            };
            this.detailDailyTasks.push(newTask);
            this.addElementToExport(newTask);
          }
        }
      }
    } catch (e) {
      const message = this.$helpers.getFilteredErrorMessage(e);
      if (message.includes('default_') && !message.includes('404')) {
        this.$f7.dialog.alert(message.split('_').pop());
      } else if (!message.includes('404')) {
        this.$f7.dialog.alert(this.$t(message));
      }
    } finally {
      this.$f7.preloader.hide();
      this.loaded = true;
    }
  },
  methods: {
    calculateRealAreaEfective(workers) {
      let totalArea = 0;
      workers.forEach((w) => {
        totalArea += parseFloat(w.working_area);
      });
      return parseFloat(totalArea).toFixed(2);
    },
    checkFarmSerbia() {
      return Api.getAgriFarm() === 'serbia';
    },
    // MÉTODOS PARA AÑADIR ELEMENTOS FORMATEAçDOS PARA EXPORTAR TODO:
    addElementToExport(newTask) {
      let machinary = '';
      if (newTask.itemsTypes.length > 0) {
        try {
          machinary = this.formatMachinary(newTask.itemsTypes);
        } catch (error) {
          console.error(error);
        }
      }
      let attachments = '';
      if (newTask.Attachments.length > 0) {
        try {
          attachments = this.formatAttachments(newTask.Attachments);
        } catch (error) {
          console.error(error);
        }
      }
      let tools = '';
      if (newTask.Tools.length > 0) {
        try {
          tools = this.formatTools(newTask.Tools);
        } catch (error) {
          console.error(error);
        }
      }
      let products = [];
      if (newTask.products.length > 0) {
        try {
          products = this.formatProducts(newTask);
        } catch (error) {
          console.error(error);
        }
      }
      let locations = [];
      if (newTask.locationLevel2.length > 0) {
        try {
          locations = this.formatLocations(newTask.locationLevel2);
        } catch (error) {
          console.error(error);
        }
      }
      let workers = [];
      if (newTask.WorkersDetailTab.length > 0) {
        try {
          workers = this.formatWorkers(newTask.WorkersDetailTab);
        } catch (error) {
          console.error(error);
        }
      }

      let nursery = [];
      if (newTask.binsDetail.length > 0) {
        try {
          nursery = this.formatNursery(newTask.binsDetail);
        } catch (error) {
          console.error(error);
        }
      }

      let drivers = [];
      if (newTask.itemsDriversWorkOrder.length > 0) {
        try {
          drivers = this.formatDrivers(newTask.itemsDriversWorkOrder);
        } catch (error) {
          console.error(error);
        }
      }

      // Construir campos a exportar por orden
      const baseFields = {
        short_id: newTask.short_id,
        Date: newTask.Date,
        Activity: newTask.Activity,
        Task: newTask.Task,
        Location: '',
        area_effective: parseFloat(newTask.real_area_effective).toFixed(2),
      };
      const emptyFields = {
        short_id: newTask.short_id,
        Date: '',
        Activity: '',
        Task: '',
        Location: '',
        area_effective: '',
      };
      this.arrayExcelColumnTranslations = [this.$t('DxWorkOrder_short_id').toUpperCase(), this.$t('DxDailyPlanning_date_caption').toUpperCase(),
        this.$t('DxDailyPlanning_activity_caption').toUpperCase(), this.$t('DxDailyPlanning_task_caption').toUpperCase(),
        this.$t('DxDailyPlanning_location_caption').toUpperCase(), this.$t('DxDailyPlanning_workingArea_caption').toUpperCase()];

      // Campos si estamos en cosecha
      let goalCaption = this.$t('DxMonthlyPlanning_goal_caption').toUpperCase();
      baseFields.goal_performance = newTask.goal_performance;
      emptyFields.goal_performance = '';
      if (this.keyName === 'harvesting') {
        goalCaption = this.$t('DxMonthlyPlanning_goal_harvesting_caption').toUpperCase();
        this.arrayExcelColumnTranslations.push(goalCaption);
        // Variedad
        emptyFields.variety = '';
        baseFields.variety = newTask.variety;
        this.arrayExcelColumnTranslations.push(this.$t('DxWorkOrders_ExportTranslation_Variety').toUpperCase());
      } else {
        this.arrayExcelColumnTranslations.push(goalCaption);
      }

      // Workers
      baseFields.Workers = newTask.Workers;
      emptyFields.Workers = '';
      this.arrayExcelColumnTranslations.push(this.$t('DxDailyPlanning_workers_caption').toUpperCase());
      // Supervisor
      baseFields.Supervisor = newTask.Supervisor;
      emptyFields.Supervisor = '';
      this.arrayExcelColumnTranslations.push(this.$t('DxDailyPlanning_supervisors_caption').toUpperCase());
      // taskStatusTranslated
      baseFields.taskStatusTranslated = newTask.taskStatusTranslated;
      emptyFields.taskStatusTranslated = '';
      this.arrayExcelColumnTranslations.push(this.$t('DxDailyPlanning_task_status_caption').toUpperCase());
      // workOrderStatusTranslated
      baseFields.workOrderStatusTranslated = newTask.workOrderStatusTranslated;
      emptyFields.workOrderStatusTranslated = '';
      this.arrayExcelColumnTranslations.push(this.$t('DxDailyPlanning_work_order_status_caption').toUpperCase());
      // machinary
      baseFields.machinary = machinary;
      emptyFields.machinary = '';
      this.arrayExcelColumnTranslations.push(this.$t('DetailTabs_machinary_title').toUpperCase());
      // drivers
      baseFields.drivers = '';
      emptyFields.drivers = '';
      this.arrayExcelColumnTranslations.push(this.$t('DetailTabs_driver_title').toUpperCase());
      // attachments
      baseFields.attachments = attachments;
      emptyFields.attachments = '';
      this.arrayExcelColumnTranslations.push(this.$t('DetailTabs_attachment_title').toUpperCase());
      // tools
      baseFields.tools = tools;
      emptyFields.tools = '';
      this.arrayExcelColumnTranslations.push(this.$t('DetailTabs_tools_title').toUpperCase());
      // employes
      baseFields.employes = '';
      emptyFields.employes = '';
      this.arrayExcelColumnTranslations.push(this.$t('DxDailyPlanning_workers_caption').toUpperCase());
      // employes_type
      baseFields.employes_type = '';
      emptyFields.employes_type = '';
      this.arrayExcelColumnTranslations.push(this.$t('DxWorkOrders_ExportTranslation_WorkerType').toUpperCase());
      // employes_performance
      baseFields.employes_performance = '';
      emptyFields.employes_performance = '';
      this.arrayExcelColumnTranslations.push(this.$t('DxDailyPlanning_workers_performance_caption').toUpperCase());
      // employes_performance_unit
      baseFields.employes_performance_unit = '';
      emptyFields.employes_performance_unit = '';
      this.arrayExcelColumnTranslations.push(this.$t('DxDailyPlanning_workers_performance_unit_caption').toUpperCase());

      if (this.keyName !== 'harvesting') {
        // products
        baseFields.products = '';
        emptyFields.products = '';
        this.arrayExcelColumnTranslations.push(this.$t('DetailTabs_products_title').toUpperCase());
        // quantityPlanned
        baseFields.quantityPlanned = '';
        emptyFields.quantityPlanned = '';
        this.arrayExcelColumnTranslations.push(this.$t('DxDetailTabs_products_quantityPlanned').toUpperCase());
        // solutionPlanned
        baseFields.solutionPlanned = '';
        emptyFields.solutionPlanned = '';
        this.arrayExcelColumnTranslations.push(this.$t('DxDetailTabs_products_waterPlanned').toUpperCase());
        // nursery_code
        baseFields.nursery_code = '';
        emptyFields.nursery_code = '';
        this.arrayExcelColumnTranslations.push(this.$t('DxWorkOrders_ExportTranslation_NurseryCode').toUpperCase());
        // nursery_variety
        baseFields.nursery_variety = '';
        emptyFields.nursery_variety = '';
        this.arrayExcelColumnTranslations.push(this.$t('DxWorkOrders_ExportTranslation_NurseryVariety').toUpperCase());
        // nursery_category
        baseFields.nursery_category = '';
        emptyFields.nursery_category = '';
        this.arrayExcelColumnTranslations.push(this.$t('DxWorkOrders_ExportTranslation_NurseryCategory').toUpperCase());
        // nursery_class
        baseFields.nursery_class = '';
        emptyFields.nursery_class = '';
        this.arrayExcelColumnTranslations.push(this.$t('DxWorkOrders_ExportTranslation_NurseryClassification').toUpperCase());
        // nursery_numPlants
        baseFields.nursery_numPlants = '';
        emptyFields.nursery_numPlants = '';
        this.arrayExcelColumnTranslations.push(this.$t('DxWorkOrders_ExportTranslation_NurseryNumPlants').toUpperCase());
      }

      if (Api.getAgriFarm() === 'serbia') {
        emptyFields.group = '';
        emptyFields.shift = '';
        baseFields.group = newTask.group;
        baseFields.shift = newTask.shift;
        this.arrayExcelColumnTranslations.push(this.$t('DxWorkOrders_ExportTranslation_Group').toUpperCase());
        this.arrayExcelColumnTranslations.push(this.$t('DxWorkOrders_ExportTranslation_Shift').toUpperCase());
      }

      this.insertNewElementToExport(baseFields, emptyFields, drivers, products, workers, nursery, locations, false);
      this.nameFileToExport = `ReportWO_${moment().format('YYYY-MM-DD HH:mm:ss')}`;
    },
    formatMachinary(machinaryArray) {
      let machinaryFormated = '';
      machinaryArray.forEach((element) => {
        if (machinaryFormated !== '') {
          machinaryFormated += '\n';
        }
        machinaryFormated += ` ${element.type}: ${element.count} `;
      });
      return machinaryFormated;
    },
    formatAttachments(attachmentsArray) {
      let attachmentsFormated = '';
      attachmentsArray.forEach((element) => {
        if (attachmentsFormated !== '') {
          attachmentsFormated += '\n';
        }
        if (element.attachment_data) {
          attachmentsFormated += ` ${element.attachment_data.description}: ${element.vehicle_data.description} `;
        } else {
          attachmentsFormated += element.vehicle_data.description;
        }
      });
      return attachmentsFormated;
    },
    formatTools(toolsArray) {
      let toolsFormated = '';
      toolsArray.forEach((element) => {
        if (toolsFormated !== '') {
          toolsFormated += '\n';
        }
        toolsFormated += ` ${element.data.description}: ${element.count} `;
      });
      return toolsFormated;
    },
    formatProducts(task) {
      const listProducts = task.products;
      const newProducts = [];
      if (listProducts !== null) {
        for (let i = 0; i < listProducts.length; i += 1) {
          const productSelected = listProducts[i];
          const calcSolutionTotal = (productSelected.solution !== '0.00') ? (parseFloat(productSelected.solution) * parseFloat(task.Planner.area_effective)) : 0;
          const quantityPlanned = this.calulateQuantityPlanned(task, productSelected);
          const unitQuantityPlanned = this.calulateUnitQuantityPlanned(productSelected);
          newProducts.push({
            quantityPlanned: `${quantityPlanned} ${unitQuantityPlanned}`,
            solutionPlanned: `${calcSolutionTotal.toFixed(0).toString()} L`,
            productName: listProducts[i].product.description,
          });
        }
      }
      return newProducts;
    },
    calulateQuantityPlanned(selectedObject, seletedProduct) {
      let quantityPlanned = parseFloat(selectedObject.area_effective * seletedProduct.dosis);
      if (!this.isKilogrameOrliter(seletedProduct)) {
        quantityPlanned = parseFloat((quantityPlanned * seletedProduct.dilution_factor) / 1000).toFixed(2);
      }
      return quantityPlanned;
    },
    calulateUnitQuantityPlanned(seletedProduct) {
      const unitQuantityPlanned = (typeof (seletedProduct.dosisUt) === 'undefined' || seletedProduct.dosisUt.toLowerCase().includes('kg/') || seletedProduct.dosisUt.toLowerCase().includes('gr/'))
        ? 'Kg' : 'L';
      return unitQuantityPlanned;
    },
    isKilogrameOrliter(seletedProduct) {
      return typeof (seletedProduct.dosisUt) === 'undefined' || seletedProduct.dosisUt.toLowerCase().includes('kg/') || seletedProduct.dosisUt.toLowerCase().includes('l/');
    },

    formatWorkers(workersArray) {
      const newWorkesArray = [];
      workersArray.forEach((element) => {
        let unit;
        let performance;
        if (element.unit === '') {
          performance = element.working_area;
          unit = 'HA';
        } else {
          performance = element.performance;
          unit = element.unit;
        }
        newWorkesArray.push({
          fullName: `${element.name} ${element.surname}`, type: element.type, performance, unit,
        });
      });
      return newWorkesArray;
    },
    formatLocations(locationsArray) {
      const newLocationsArray = [];
      locationsArray.forEach((element) => {
        newLocationsArray.push({ locationName: `${element}` });
      });
      return newLocationsArray;
    },
    formatNursery(nurseryArray) {
      const newNurseryArray = [];
      nurseryArray.forEach((element) => {
        newNurseryArray.push({
          nursery_code: element.code,
          nursery_variety: element.variety,
          nursery_category: element.category,
          nursery_class: element.class_type,
          nursery_numPlants: element.count,
        });
      });
      return newNurseryArray;
    },
    formatDrivers(itemDriverArray) {
      const dataDrivers = [];
      for (let i = 0; i < itemDriverArray.length; i += 1) {
        dataDrivers.push({
          text: `${itemDriverArray[i].item_data.description}(${itemDriverArray[i].item_data.code}): ${itemDriverArray[i].driver_data.name} ${itemDriverArray[i].driver_data.surname}`,
        });
      }
      return dataDrivers;
    },
    insertNewElementToExport(baseFields, emptyFields, drivers, products, workers, nursery, locations, childRow) {
      let newObject = {};
      if (childRow) {
        newObject = JSON.parse(JSON.stringify(emptyFields));
      } else {
        newObject = JSON.parse(JSON.stringify(baseFields));
      }
      if (drivers.length > 0) {
        newObject.drivers = drivers[0].text;
        drivers.splice(0, 1);
      }
      if (nursery.length > 0) {
        newObject.nursery_code = nursery[0].nursery_code;
        newObject.nursery_variety = nursery[0].nursery_variety;
        newObject.nursery_category = nursery[0].nursery_category;
        newObject.nursery_class = nursery[0].nursery_class;
        newObject.nursery_numPlants = nursery[0].nursery_numPlants;
        nursery.splice(0, 1);
      }
      if (workers.length > 0) {
        newObject.employes = workers[0].fullName;
        newObject.employes_type = workers[0].type;
        newObject.employes_performance = workers[0].performance;
        newObject.employes_performance_unit = workers[0].unit;
        workers.splice(0, 1);
      }
      if (products.length > 0) {
        newObject.products = products[0].productName;
        newObject.quantityPlanned = products[0].quantityPlanned;
        newObject.solutionPlanned = products[0].solutionPlanned;
        products.splice(0, 1);
      }
      if (locations.length > 0) {
        newObject.Location = locations[0].locationName;
        locations.splice(0, 1);
      }
      this.detailDailyTasksToExport.push(newObject);
      if (workers.length > 0 || drivers.length > 0 || products.length > 0 || nursery.length > 0 || locations.length > 0) {
        this.insertNewElementToExport(baseFields, emptyFields, drivers, products, workers, nursery, locations, true);
      }
    },
    // FIN MÉTODOS PARA AÑADIR ELEMENTOS FORMATEADOS PARA EXPORTAR TODO:

    getItems(itemsDriver) {
      const items = [];
      for (const itemDriver of itemsDriver) {
        items.push(itemDriver.item);
      }
      return items;
    },
    // POR CADA FILA QUE SEA TASKSTATUS = COMPLETED Y WORKWORDERSTATUS = COMPLETED DESHABILITO LOS BOTONES EDITAR Y BORRAR
    isDeleteIconVisible(e) {
      if (e.row.data.TaskStatus === 'COMPLETED' || e.row.data.TaskStatus === 'IN_PROGRESS') {
        return false;
      }
      return true;
    },
    isEditIconVisible(e) {
      if (e.row.data.WorkOrderStatus === 'COMPLETED' && e.row.data.TaskStatus === 'COMPLETED') {
        return false;
      }
      if (e.row.data.TaskStatus === 'IN_PROGRESS') {
        return false;
      }
      return true;
    },
    isViewconVisible(e) {
      if (e.row.data.WorkOrderStatus === 'COMPLETED' && e.row.data.TaskStatus === 'COMPLETED') {
        return true;
      }
      if (e.row.data.TaskStatus === 'IN_PROGRESS' || e.row.data.TaskStatus === 'OPEN' || e.row.data.WorkOrderStatus !== 'COMPLETED') {
        return false;
      }
      return true;
    },
    getNamesByBdIds(ids, activityCode) {
      const names = [];
      const level = this.$helpers.getLevelLocationToDisplay(activityCode);
      for (const id of ids) {
        const newName = this.getLocationNameLevelSelectedChildId(id, level);
        if (names.indexOf(newName) === -1) {
          names.push(newName);
        }
      }
      return names;
    },
    getGoalPerformance(planner) {
      if (planner.goal_performance.unit === '') {
        return '';
      }
      return `${planner.goal_performance.count} ${planner.goal_performance.unit}`;
    },
    // GET WORKERS - SUPERVISORS
    async getSupervisors() {
      const xhr = await Api.getSupervisors();
      return JSON.parse(xhr.response).data;
    },
    getNameSupervisor(idSupervisor) {
      for (const supervisor of this.supervisorsAvailables) {
        if (supervisor.id === idSupervisor) {
          return `${supervisor.name} ${supervisor.surname}`;
        }
      }
      return '';
    },
    getTotalWorkers(workers) {
      return workers.length;
    },
    getTotalWorkersPlanner(workersPlanner) {
      let element = 0;
      for (let i = 0; i < workersPlanner.length; i += 1) {
        element += workersPlanner[i].count;
      }
      return element;
    },
    // EDIT, VIEW AND DELETE TASK
    editWorkOrder(rowSelected) {
      if (rowSelected.row.data.TaskStatus === 'OPEN') {
        // Comprobación para editar productos / prescripcion
        if (rowSelected.row.data.Planner.prescription) {
          this.setPrescription(rowSelected.row.data.Planner.prescription);
        } else {
          this.setNewPrescription();
        }
        // Comprobación para editar tools
        if (rowSelected.row.data.Planner) {
          rowSelected.row.data.ToolsDetailTab = rowSelected.row.data.Planner.tools;
        }
        const binsContentPlannedToEdit = rowSelected.row.data.binsDetail.map((content) => ({
          bin: content.bin.bin_id,
          category: content.category,
          classification: content.class_type,
          content: content.code,
          variety: content.variety,
          plants: content.count,
        }));
        this.setBinsContentPlannedToEdit(binsContentPlannedToEdit);
        this.setTaskSelectedByTaskCode(rowSelected.row.data.taskCode);
        this.setActualWorkOrder(rowSelected.row.data);
        this.setProducts(rowSelected.row.data.products);
        this.$f7.views.main.router.navigate(`/editOrder/${this.back}/`, { reloadCurrent: true });
      } else if (rowSelected.row.data.TaskStatus === 'COMPLETED' && rowSelected.row.data.WorkOrderStatus !== 'COMPLETED') {
        this.machineryPopup = rowSelected.row.data.machineryPopup;
        this.productPopup = rowSelected.row.data.productPopup;
        this.supervisorPopup = rowSelected.row.data.responsible;
        if (rowSelected.row.data.responsible === null) {
          this.supervisorPopup = '';
          rowSelected.row.data.responsible = '';
        }
        this.setActualWorkOrder(rowSelected.row.data);
        this.setVisiblePopupWorkOrder(true);
      } else {
        this.$f7.dialog.alert(this.$t('DxDailyWorkOrders_dialogUpdate_noOpen'));
      }
    },
    async deleteTask(rowSelected) {
      if (rowSelected.row.data.TaskStatus !== 'OPEN') {
        this.$f7.dialog.alert(this.$t('DxDailyWorkOrders_dialogDelete_noOpen'));
        return false;
      }
      const taskId = rowSelected.row.data.ID;
      const f7 = this.$f7;
      this.$f7.dialog.create({
        title: this.$t('DxDailyWorkOrders_dialogDelete_title'),
        text: this.$t('DxDailyWorkOrders_dialogDelete_text'),
        buttons: [
          {
            text: this.$t('DxDailyWorkOrders_dialogDelete_cancel'),
          },
          {
            text: this.$t('DxDailyWorkOrders_dialogDelete_confirm'),
            async onClick() {
              try {
                f7.preloader.show();
                await Api.deleteTask(taskId);
                f7.views.main.router.navigate('/workOrders/', { reloadCurrent: true });
              } catch (e) {
                f7.dialog.alert(e);
              } finally {
                f7.preloader.hide();
              }
            },
          },
        ],
      }).open();
      return false;
    },
    viewtask(rowSelected) {
      if (rowSelected.row.data.TaskStatus === 'COMPLETED') {
        // Comprobación para editar productos / prescripcion
        if (rowSelected.row.data.Planner.prescription) {
          this.setPrescription(rowSelected.row.data.Planner);
        }
        // Comprobación para editar tools
        if (rowSelected.row.data.Planner) {
          rowSelected.row.data.ToolsDetailTab = rowSelected.row.data.Planner.tools;
        }
        this.setActualWorkOrder(rowSelected.row.data);
        this.setProducts(rowSelected.row.data.products);
        this.$f7.views.main.router.navigate('/ViewPostWork/', { reloadCurrent: true });
      }
    },
    // SET CSS CLASSES TO CELLS
    setClassByTaskStatus(status) {
      let colorCell = '';
      switch (status) {
        case 'OPEN':
          colorCell = 'open';
          this.taskStatusTranslated = this.$t('Status_Open');
          break;
        case 'IN_PROGRESS':
          colorCell = 'in-progress';
          this.taskStatusTranslated = this.$t('Status_InProgress');
          break;
        case 'COMPLETED':
          colorCell = 'completed';
          this.taskStatusTranslated = this.$t('Status_Completed');
          break;
        case 'PENDING':
          colorCell = 'pending';
          this.taskStatusTranslated = this.$t('Status_Pending');
          break;
        case 'DELETED':
          colorCell = 'deleted';
          this.taskStatusTranslated = this.$t('Status_Deleted');
          break;
        default:
          break;
      }
      return colorCell;
    },
    setClassByWorkOrderStatus(status) {
      let colorCell = '';
      switch (status) {
        case 'COMPLETED':
          colorCell = 'work-order-closed';
          this.workOrderStatusTranslated = this.$t('Closed');
          break;
        case null:
        default:
          colorCell = 'work-order-not-closed';
          this.workOrderStatusTranslated = this.$t('Not Closed');
          break;
      }
      return colorCell;
    },
    // EXPORT EXCEL
    onExporting(e) {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('WorkOrders');
      exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
      }).then(() => {
        workbook.xlsx.writeBuffer()
          .then((buffer) => {
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'WorkOrders.xlsx');
          });
      });
      e.cancel = true;
    },
    getDriverName(workers) {
      if (workers.length === 0) {
        return '';
      }
      return `${workers[0].name} ${workers[0].surname}`;
    },
    calculateProductivity(workers, planner) {
      let productivityTotal = 0;
      for (const worker of workers) {
        productivityTotal += parseFloat((worker.unit === '') ? worker.working_area : worker.performance);
      }
      return `${productivityTotal} ${(planner.goal_performance.unit === '') ? 'HA' : planner.goal_performance.unit}`;
    },
    ...mapActions('PlanningManager', ['setActualWorkOrder', 'setVisiblePopupWorkOrder']),
    ...mapActions('Prescription', ['setProducts', 'setPrescription', 'setNewPrescription']),
    ...mapActions('greenhouse', [
      'fetchBinsContentFamilies',
      'setBinsContentPlannedToEdit',
      'setTaskSelectedByTaskCode',
      'fetchLocationTree',
    ]),
  },
};
</script>
<style scoped>
.content-status-cell {
  padding: 0;
}
.open, .in-progress, .completed, .pending, .deleted, .rejected, .work-order-closed, .work-order-not-closed{
  border-radius: 2px;
  font-size: 12px;
  padding: 4px 2px;
  font-weight: 600;
  text-transform: uppercase;
}

.open {
  color: var(--fr-open);
  background: var( --f7-checkbox-inactive-color);
}
.in-progress {
  background: var(--ha-yellow);
  color: var(--fr-inprogress-tint);
}
.completed {
  background: var(--fr-finished);
  color: var(--fr-finished-tint);
}
.pending {
  color: var(--fr-pending);
  background: var(--fr-pending-tint);
}
.deleted {
  color: var(--fr-deleted);;
  background: var(--fr-deleted-tint);
}
.work-order-closed{
  color: var(--ha-dark-green);
  background: var(--ha-light-green);
}
.work-order-not-closed{
  color: #ffffff;
  background: var(--fr-nutella);
}
</style>
