<template>
  <div
    class="content-devx content-dx-cost-form"
  >
    <DxForm
      id="costForm"
    >
      <DxGroupItem>
        <DxGroupItem
          css-class="content-combo"
        >
          <DxSimpleItem
            name="name"
            :data-field="$t('DxCostsDatabase_column_name')"
            :editor-options="{
              value: actualName,
              onValueChanged: onChangeName,
              readOnly: true,
            }"
          >
            <DxRequiredRule :message="$t('DxCostForm_name_required')" />
          </DxSimpleItem>
          <DxSimpleItem
            name="code"
            :data-field="$t('DxCostsDatabase_column_code')"
            :editor-options="{
              value: actualCode,
              onValueChanged: onChangeCode,
              readOnly: true,
            }"
          >
            <DxRequiredRule :message="$t('DxCostForm_code_required')" />
          </DxSimpleItem>
          <DxSimpleItem
            name="currency"
            :data-field="$t('DxCostsDatabase_column_currency')"
            editor-type="dxSelectBox"
            :editor-options="{
              items: listCurrency,
              value: actualCurrency,
              searchEnabled: true,
              searchTimeout: searchTimeoutOption,
              minSearchLength: minSearchLengthOption,
              showDataBeforeSearch: showDataBeforeSearchOption,
              onItemClick: onChangeCurrency,
              onValueChanged: onChangeCurrency,
              readOnly: true,
            }"
          >
            <DxRequiredRule :message="$t('DxCostForm_Currency_required')" />
          </DxSimpleItem>
          <DxSimpleItem
            name="Currency Code"
            :data-field="$t('DxCostsDatabase_column_currency_code')"
            :editor-options="{
              value: currencyCodeValue,
              readOnly: true,
            }"
          />
          <DxSimpleItem
            v-if="editForm"
            name="Id"
            :data-field="$t('DxCostsDatabase_column_id')"
            :editor-options="{
              value: actualId,
              onValueChanged: onChangeId,
              readOnly: true,
            }"
          >
            <DxRequiredRule :message="$t('DxCostForm_Id_required')" />
          </DxSimpleItem>
          <DxSimpleItem
            name="Price"
            :data-field="$t('DxCostsDatabase_column_price')"
            :editor-options="{
              value: actualPrice,
              onValueChanged: onChangePrice,
            }"
          >
            <DxRequiredRule :message="$t('DxCostForm_price_required')" />
          </DxSimpleItem>
          <DxSimpleItem
            name="Unit"
            :data-field="$t('DxCostsDatabase_column_unit')"
            editor-type="dxSelectBox"
            :editor-options="{
              items: listUnit,
              value: actualUnit,
              searchEnabled: true,
              searchTimeout: searchTimeoutOption,
              minSearchLength: minSearchLengthOption,
              showDataBeforeSearch: showDataBeforeSearchOption,
              onItemClick: onChangeUnit,
              onValueChanged: onChangeUnit,
              readOnly: true,
            }"
          >
            <DxRequiredRule :message="$t('DxMachineryForm_family_required')" />
          </DxSimpleItem>
        </DxGroupItem>
      </Dxgroupitem>
    </DxForm>
  </div>
</template>
<script>

import { mapState, mapActions } from 'vuex';
import {
  DxForm,
  DxSimpleItem,
  DxGroupItem,
  DxRequiredRule,
} from 'devextreme-vue/form';
import * as overlay from 'devextreme/ui/overlay';

export default {
  name: 'DxCostForm',
  components: {
    DxForm,
    DxSimpleItem,
    DxGroupItem,
    DxRequiredRule,
  },
  props: {
    editForm: { type: Boolean, default: false },
  },
  data() {
    return {
      // UTILITIES
      searchTimeoutOption: 200,
      minSearchLengthOption: 0,
      showDataBeforeSearchOption: false,
      // FORM VALUES
      actualCode: '',
      actualName: '',
      actualCurrency: 'EURO',
      actualPrice: '',
      actualCurrencyCode: '€',
      actualUnit: '',
      actualId: '',
    };
  },
  computed: {
    ...mapState('costManager', ['listCurrency', 'costFormated', 'listUnit', 'actualCost']),
    currencyCodeValue() {
      return this.editForm === true ? this.actualCost.currency_code : this.actualCurrencyCode;
    },
  },
  async beforeMount() {
    this.$f7.preloader.show();
    overlay.baseZIndex(9999);
    try {
      if (this.editForm) {
        this.setValuesActualCost();
      } else {
        this.setValuesNewCost();
      }
    } catch (e) {
      const message = this.$helpers.getFilteredErrorMessage(e);
      if (message.includes('default_') && !message.includes('404')) {
        this.$f7.dialog.alert(message.split('_').pop());
      } else if (!message.includes('404')) {
        this.$f7.dialog.alert(this.$t(message));
      }
    } finally {
      this.$f7.preloader.hide();
    }
  },
  methods: {
    // SET VALUES NEW COST
    setValuesNewCost() {
      this.setCode('');
      this.setCurrency(this.actualCurrency);
      this.setCurrencyCode(this.actualCurrencyCode);
      this.setId('');
      this.setName('');
      this.setPrice('');
      this.setUnit('');
    },
    // SET VALUES ACTUAL COST
    setValuesActualCost() {
      this.actualCode = this.actualCost.code;
      this.actualName = this.actualCost.name;
      this.actualCurrency = this.actualCost.currency;
      this.actualPrice = this.actualCost.price;
      this.actualCurrencyCode = this.actualCost.currencyCode;
      this.actualUnit = this.actualCost.unit;
      this.actualId = this.actualCost.id;
    },
    // EVENTS HANDLER ITEMS FORM
    onChangeCode(e) {
      this.actualCode = e.value;
      this.setCode(e.value);
    },
    onChangeName(e) {
      this.actualName = e.value;
      this.setName(e.value);
    },
    // Cuando cambie el valor, comprobamos que moneda ha elegido y se asigna su currency_code.
    onChangeCurrency(e) {
      this.actualCurrency = e.itemData;
      this.setCurrency(e.itemData);
      for (let i = 0; i < this.costFormated.length; i += 1) {
        if (this.actualCurrency === this.costFormated[i].currency) {
          this.actualCurrencyCode = this.costFormated[i].currency_code;
          this.setCurrencyCode(this.actualCurrencyCode);
          break;
        }
      }
    },
    onChangePrice(e) {
      this.actualPrice = e.value;
      this.setPrice(e.value);
    },
    onChangeUnit(e) {
      this.actualUnit = e.value;
      this.setUnit(e.value);
    },
    onChangeId(e) {
      this.actualId = e.value;
      this.setId(e.value);
    },
    ...mapActions('costManager', ['setActualCost', 'setCode', 'setName', 'setCurrency', 'setPrice', 'setCurrencyCode', 'setUnit', 'setId']),
  },
};
</script>

<style lang="scss">

  .content-combo{
    .dx-item{
      .dx-item-content{
        label{
          min-width: 160px;
        }
      }
    }
  }
</style>
