var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"content-devx content-dx-daily-planning no-shadow no-margin"},[_c('DxForm',{attrs:{"id":"form","col-count":1}},[_c('DxSimpleItem',{attrs:{"editor-options":{
          items: _vm.supervisorsAvailables,
          searchEnabled: true,
          searchTimeout: _vm.searchTimeoutOption,
          minSearchLength: _vm.minSearchLengthOption,
          showDataBeforeSearch: _vm.showDataBeforeSearchOption,
          onValueChanged: _vm.onSupervisorChange,
          readOnly: _vm.readOnly,
          displayExpr: 'fullname',
          valueExpr:'id',
        },"editor-type":"dxSelectBox","caption":_vm.$t('SelectSupervisor_supervisor_caption')}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('DxSelectSupervisor')}})],1),_vm._v(" "),_c('DxSimpleItem',{attrs:{"editor-type":"dxTextArea","editor-options":{
          placeholder: _vm.$t('DxInformationPrescription_notes_caption'),
          value: _vm.additionalInfo,
          onValueChanged: _vm.additionalInfoEvent,
          maxLength: 256,
        }}})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }