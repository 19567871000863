<template>
  <div class="block">
    <DxDataGrid
      id="detailProductsGrid"
      :data-source="productsFormated"
      :column-min-width="70"
      :allow-column-reordering="true"
      :show-borders="true"
      :show-column-lines="true"
      :show-row-lines="false"
      :row-alternation-enabled="true"
      @exporting="onExporting"
    >
      <DxExport
        :enabled="false"
      />
      <DxPaging :page-size="18" />
      <DxSearchPanel
        :visible="true"
        :width="200"
        :placeholder="$t('Search_placeholder')"
      />
      <DxColumn
        data-field="productFamily"
        name="productFamily"
        :caption="$t('DxProducts_dxColumn_productFamily')"
      />
      <DxColumn
        data-field="productGroup"
        name="product.productGroup"
        :caption="$t('DxProducts_dxColumn_productGroup')"
      />
      <DxColumn
        data-field="component"
        name="product.component"
        :caption="$t('DxProducts_dxColumn_activeIngredients')"
      />
      <DxColumn
        data-field="description"
        name="product.description"
        :caption="$t('DxProducts_dxColumn_productName')"
      />
      <DxColumn
        data-field="dosis"
        :width="120"
        name="dosis"
        :caption="$t('DxProducts_dxColumn_dosis')"
      />
      <DxColumn
        data-field="solution"
        :width="120"
        name="solution"
        :caption="$t('DxProducts_dxColumn_solution')"
      />
      <DxColumn
        data-field="dilution_factor"
        :width="120"
        name="dilution_factor"
        :caption="$t('DxProducts_dxColumn_dilutionFactor')"
      />
    </DxDataGrid>
  </div>
</template>

<script>

import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxSearchPanel,
  DxExport,
} from 'devextreme-vue/data-grid';
import { mapState } from 'vuex';
import { exportDataGrid } from 'devextreme/excel_exporter';
import ExcelJS from 'exceljs';
import saveAs from 'file-saver';

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxSearchPanel,
    DxExport,
  },
  props: {
    products: { type: Array, default: () => [] },
  },
  data() {
    return {
      hideColumn: false,
      productsFormated: [],
      text: '',
    };
  },
  computed: {
  },
  beforeMount() {
    for (let i = 0; i < this.products.length; i += 1) {
      this.productsFormated.push({
        productFamily: this.getTypePrescription(this.products[i].product.properties),
        productGroup: this.getProductGroup(this.products[i].product.properties),
        component: this.products[i].product.component,
        description: this.products[i].product.description,
        dosis: `${this.products[i].dosis} ${this.products[i].dosisUt}`,
        // 08/12/2020: Se quiere siempre L/ha
        solution: (this.products[i].solution === '0.00') ? '' : `${this.products[i].solution} L/ha`,
        dilution_factor: (this.isKilogrameOrliter(this.products[i])) ? '' : this.products[i].solution / 100,
      });
    }
  },
  methods: {
    isKilogrameOrliter(rowData) {
      return typeof (rowData.dosisUt) === 'undefined' || rowData.dosisUt.toLowerCase().includes('kg/') || rowData.dosisUt.toLowerCase().includes('l/');
    },
    getTypePrescription(properties) {
      for (const property of properties) {
        if (property.key === 'family') {
          return property.value;
        }
      }
      return '';
    },
    getProductGroup(properties) {
      for (const property of properties) {
        if (property.key === 'subfamily') {
          return property.value;
        }
      }
      return '';
    },
    // EXPORT EXCEL
    onExporting(e) {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('DetailProducts');
      exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
      }).then(() => {
        workbook.xlsx.writeBuffer()
          .then((buffer) => {
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'DetailProducts.xlsx');
          });
      });
      e.cancel = true;
    },
  },
};
</script>
