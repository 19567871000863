<template>
  <f7-page>
    <navbar :text="$t('LeftPanel_subtitle_territorialUnitcaps')" />
    <DxTerritorialUnit />
  </f7-page>
</template>

<script>
import DxTerritorialUnit from '../../components/territorialUnit/DxTerritorialUnit.vue';
import navbar from '../../components/NavBar.vue';

export default {
  name: 'TerritorialUnit',
  components: {
    DxTerritorialUnit,
    navbar,
  },
};
</script>
