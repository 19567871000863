<template>
  <div>
    <div
      v-if="loaded"
      class="content-devx content-dx-daily-planning"
      style="margin: 0px 0px 15px 0;"
    >
      <DxForm
        id="formPrecription"
      >
        <DxSimpleItem
          name="subfamily"
          :data-field="$t('DxPlanningProducts_typePrescription')"
          editor-type="dxSelectBox"
          :editor-options="{
            readOnly: readOnly,
            items: typePrescription,
            value: actualSubFamily,
            displayExpr: 'name',
            valueExpr:'id',
            onValueChanged: onChangeSubFamily,
            searchEnabled: true,
            searchTimeout: searchTimeoutOption,
            minSearchLength: minSearchLengthOption,
            showDataBeforeSearch: showDataBeforeSearchOption,
          }"
        >
          <DxRequiredRule :message="$t('DxPlanningProducts_typePrescription_required')" />
        </DxSimpleItem>
        <DxSimpleItem
          name="component"
          :data-field="$t('DxPlanningProducts_prescription')"
          editor-type="dxSelectBox"
          :editor-options="{
            readOnly: readOnly,
            items: listComponents,
            value: actualComponent,
            displayExpr: 'name',
            valueExpr:'id',
            onValueChanged: onChangeComponent,
            searchEnabled: true,
            searchTimeout: searchTimeoutOption,
            minSearchLength: minSearchLengthOption,
            showDataBeforeSearch: showDataBeforeSearchOption,
          }"
        >
          <DxRequiredRule :message="$t('DxPlanningProducts_prescription_required')" />
        </DxSimpleItem>
      </DxForm>

      <DxDataGrid
        id="productsGrid"
        ref="productsGrid"
        :data-source="prescription.products"
        :column-min-width="70"
        :allow-column-reordering="true"
        :show-borders="true"
        :show-column-lines="true"
        :show-row-lines="false"
        :row-alternation-enabled="true"
        :column-auto-width="true"
        @row-updated="onChangeRow"
      >
        <DxEditing
          :allow-updating="true"
          :allow-deleting="false"
          :allow-adding="false"
          mode="row"
        />
        <DxColumn
          data-field="product.description"
          name="product.description"
          :caption="$t('DxProducts_dxColumn_productName')"
          :allow-editing="false"
          alignment="left"
          :width="120"
        >
          <DxRequiredRule />
        </DxColumn>
        <DxColumn
          data-field="dosis"
          name="dosis"
          data-type="number"
          :caption="$t('DxProducts_dxColumn_dosis')"
          alignment="right"
        >
          <DxRequiredRule />
        </DxColumn>
        <DxColumn
          data-field="dosisUt"
          name="dosisUt"
          :caption="$t('DxProducts_dxColumn_Unit')"
          :allow-editing="false"
          alignment="right"
        />
        <!-- Quantity Planned -->
        <DxColumn
          data-field="dosis_total"
          name="dosis_total"
          :allow-editing="false"
          :caption="$t('DxProducts_dxColumn_dosisTotal')"
          :calculate-cell-value="calculateDosisTotal"
          alignment="right"
        />
      </DxDataGrid>

      <DxDataGrid
        id="dxSolutionGrid"
        ref="dxSolutionGrid"
        :data-source="computedSolution"
        :column-min-width="70"
        :allow-column-reordering="true"
        :show-borders="true"
        :show-column-lines="true"
        :column-auto-width="true"
        :show-row-lines="false"
        :row-alternation-enabled="true"
        style="margin-top: 20px;"
        @row-updated="updatedSolutionVuex"
      >
        <DxEditing
          :allow-updating="true"
          :allow-deleting="false"
          :allow-adding="false"
          mode="row"
        />
        <DxColumn
          data-field="solution"
          name="solution"
          data-type="number"
          alignment="right"
          :caption="$t('DxProducts_dxColumn_solution_total')"
          :format="dosisColumnFormat"
          :editor-options="dosisEditorFormat"
        >
          <DxRequiredRule />
        </DxColumn>
        <DxColumn
          data-field="solutionUt"
          name="solutionUt"
          alignment="left"
          data-type="string"
          :caption="$t('DxProducts_dxColumn_solutionUt')"
          :allow-editing="false"
        />
        <DxColumn
          data-field="dilution_factor"
          name="dilution_factor"
          data-type="number"
          alignment="right"
          :allow-editing="false"
          :caption="$t('DxProducts_dxColumn_dilutionFactor')"
          :format="dosisColumnFormat"
          :editor-options="dosisEditorFormat"
          :calculate-cell-value="calculateDilutionFactor"
        />
        <!-- Water Planned (L) -->
        <DxColumn
          data-field="solution_total"
          name="solution_total"
          :allow-editing="false"
          alignment="right"
          :format="dosisColumnFormat"
          :editor-options="dosisEditorFormat"
          :calculate-cell-value="calculateTotalSolution"
          :caption="$t('DxProducts_dxColumn_solutionTotal')"
          :width="100"
        />
      </DxDataGrid>
    </div>
  </div>
</template>
<script>

import { mapState, mapActions } from 'vuex';
import {
  DxForm,
  DxSimpleItem,
} from 'devextreme-vue/form';
import {
  DxDataGrid,
  DxColumn,
  DxEditing,
  DxRequiredRule,
} from 'devextreme-vue/data-grid';
import * as overlay from 'devextreme/ui/overlay';
import Api from '../../services/Api';
import EventBus from '../../js/event-bus';

export default {
  name: 'DxProducts',
  components: {
    DxDataGrid,
    DxColumn,
    DxEditing,
    DxRequiredRule,
    DxForm,
    DxSimpleItem,
  },
  props: {
    readOnly: { type: Boolean, default: () => false },
  },
  data() {
    return {
      family: [],
      subfamily: [],
      product: [],
      listPrescriptions: [],
      actualSubFamily: '',
      actualComponent: '',
      minSearchLengthOption: 0,
      showDataBeforeSearchOption: false,
      searchTimeoutOption: 200,
      dosisColumnFormat: { type: 'fixedPoint', precision: 2 },
      dosisEditorFormat: { format: { type: 'fixedPoint', precision: 2 } },
      loaded: false,
    };
  },
  computed: {
    ...mapState('TaskManager', ['totalAreaSelected']),
    ...mapState('Prescription', ['prescription', 'listFamily', 'typePrescription', 'listComponents']),
    ...mapState('PlanningManager', ['workingArea', 'actualDailyPlanning']),
    computedSolution() {
      if (this.prescription.products.length > 0) {
        return [this.prescription.products[0]];
      }
      return [];
    },
  },
  destroyed() {
    EventBus.$off('changeTotalAreaSelected');
  },
  async beforeMount() {
    overlay.baseZIndex(9999);
    this.$f7.preloader.show();
    try {
      const xhr = await Api.getDataSubfamilyByType('product');
      this.setData(JSON.parse(xhr.response));
      const xhr2 = await Api.getPrescriptions();
      this.listPrescriptions = JSON.parse(xhr2.response);
      EventBus.$on('changeTotalAreaSelected', this.calculateDoseSolution);
      if (this.prescription.name !== '') {
      // Modo edición

        // Seteo subfamilia, filtrando por la familia seleccionada
        this.actualSubFamily = this.prescription.type;
        this.setlistSubfamily(this.subfamily);

        // Seteo las prescripciones filtradas:
        this.actualComponent = this.prescription.id;
        const arrayComponent = this.listPrescriptions.filter(
          (elemento) => elemento.type === this.prescription.type && elemento.active === 'Yes',
        );
        this.setlistComponents(arrayComponent);
      }
    } catch (e) {
      const message = this.$helpers.getFilteredErrorMessage(e);
      if (message.includes('default_') && !message.includes('404')) {
        this.$f7.dialog.alert(message.split('_').pop());
      } else if (!message.includes('404')) {
        this.$f7.dialog.alert(this.$t(message));
      }
    } finally {
      this.$f7.preloader.hide();
      this.loaded = true;
    }
  },
  beforeDestroy() {
    this.setNewPrescription();
  },
  methods: {
    calculateSolution(rowData) {
      const value = (typeof (rowData.solution) === 'undefined' || (rowData.solution === '0.00')) ? '' : `${rowData.solution}`;
      return value;
    },
    calculateUnit(rowData) {
      const value = (rowData.solution === '0.00') ? '' : 'L/Ha';
      return value;
    },
    isKilogrameOrliter(rowData) {
      return typeof (rowData.dosisUt) === 'undefined' || rowData.dosisUt.toLowerCase().includes('kg/') || rowData.dosisUt.toLowerCase().includes('l/');
    },
    calculateDilutionFactor(rowData) {
      const value = typeof (rowData.solution) === 'undefined' ? '' : `${rowData.solution / 100}`;
      return value;
    },
    calculateDosisTotal(rowData) {
      let calcDosisTotal = (parseFloat(rowData.dosis) * parseFloat(this.workingArea)).toFixed(2);
      const dilutionFactor = parseFloat(rowData.solution) / 100;
      const unit = this.calculateUnitDosisTotal(rowData);
      calcDosisTotal = (!this.isKilogrameOrliter(rowData)) ? ((calcDosisTotal * dilutionFactor) / 1000).toFixed(2) : calcDosisTotal;
      return `${calcDosisTotal} ${unit}`;
    },
    calculateUnitDosisTotal(rowData) {
      if (rowData.dosisUt.toLowerCase().includes('gr/')
        || rowData.dosisUt.toLowerCase().includes('kg/')) return 'Kg';
      return 'L';
    },
    calculateTotalSolution(rowData) {
      return (rowData.solution * this.workingArea).toFixed(2);
    },
    setData(dataArray) {
      this.family.length = 0;
      this.subfamily.length = 0;
      this.product.length = 0;
      for (let i = 0; i < dataArray.length; i += 1) {
        this.family.push({
          id: dataArray[i].id,
          name: dataArray[i].id,
        }); // PHITOSANITARY / SUBSTRATE / FERTILIZERS
        const subfami = dataArray[i].items;
        for (let j = 0; j < subfami.length; j += 1) {
          this.subfamily.push({
            id: subfami[j].id,
            name: subfami[j].id,
            family_id: dataArray[i].id,
          }); // <-- subfamily

          const component = subfami[j].items;
          for (let k = 0; k < component.length; k += 1) {
            if (dataArray[i].id === 'PHITOSANITARY') {
              // En caso de fitosanitario, los productos estan un nivel mas adentro
              const prod = component[k].items;
              for (let n = 0; n < prod.length; n += 1) {
                this.product.push({
                  id: prod[n].id,
                  name: prod[n].description,
                  subfamily_id: subfami[j].id,
                });
              }
            } else {
              // En caso contrario, los productos son directamente el componente
              this.product.push({
                id: component[k].id,
                name: component[k].description,
                subfamily_id: subfami[j].id,
              });
            }
          }
        }
      }
      this.setlistFamily(this.family);
    },
    onChangeSubFamily(e) {
      this.$f7.preloader.show();
      this.actualSubFamily = e.value;
      const arrayFiltrado = this.listPrescriptions.filter(
        (elemento) => elemento.type === e.value && elemento.active === 'Yes',
      );
      this.setlistComponents(arrayFiltrado);
      this.actualComponent = '';
      this.setProducts([]);
      this.$f7.preloader.hide();
    },
    onChangeComponent(e) {
      this.$f7.preloader.show();
      this.actualComponent = e.value;
      const arrayFiltrado = this.listPrescriptions.filter(
        (elemento) => elemento.id === e.value,
      );
      if (this.prescription.id === e.value) {
        this.setProducts(this.actualDailyPlanning.products);
      } else {
        this.calculateDoseSolution();
        this.setPrescription(arrayFiltrado[0]);
      }
      this.$f7.preloader.hide();
    },
    // SET DATA SOURCE TO PRODUCTS VUEX
    updatedProductsVuex() {
      this.products = this.prescription.products;
      for (let i = 0; i < this.products.length; i += 1) {
        const item = this.products[i];
        if (item.dosisUt.includes('Ha')) {
          this.products[i].solutionUt = 'L/Ha';
          this.products[i].dilution_factor = '0.00';
        } else {
          this.products[i].solutionUt = 'L/Ha';
          this.products[i].dilution_factor = parseFloat(item.solution) / 100;
        }
      }
      this.setProducts(this.products);
    },
    onChangeRow() {
      this.updatedProductsVuex();
      this.calculateDoseSolution();
    },
    calculateDoseSolution() {
      if (this.prescription.name === '') {
        this.setNewPrescription(); // Controlo que no tenga productos una prescripción vacía
      }
      if (this.prescription.products.length > 0) {
        for (let i = 0; i < this.prescription.products.length; i += 1) {
          const producto = this.prescription.products[i];
          let dosisTotal = 0.00;
          let solutionTotal = 0.00;
          const dilutionFactor = producto.solution / 100;
          if (producto.dosisUt.toLowerCase().includes('gr/')
          || producto.dosisUt.toLowerCase().includes('cc/')) {
            solutionTotal = parseFloat(dilutionFactor) * parseFloat(this.workingArea);
          } else {
            solutionTotal = 0;
          }

          if (producto.dosisUt.toLowerCase().includes('gr/')
          || producto.dosisUt.toLowerCase().includes('cc/')) {
            dosisTotal = (parseFloat(producto.dosis) * parseFloat(solutionTotal)
             * parseFloat(this.workingArea)) / 1000;
          } else {
            dosisTotal = parseFloat(producto.dosis) * parseFloat(this.workingArea);
          }

          this.prescription.products[i].dosis_total = dosisTotal.toFixed(2);
          this.prescription.products[i].solution_total = solutionTotal.toFixed(2);
          this.prescription.products[i].dilution_factor = dilutionFactor.toFixed(2).toString();
        }
        this.setProducts(this.prescription.products);
      }
    },
    updatedSolutionVuex(e) {
      this.products = this.prescription.products;
      for (let i = 0; i < this.products.length; i += 1) {
        const item = this.products[i];
        if (item.dosisUt.includes('Ha')) {
          this.products[i].solution = 0;
          this.products[i].dilution_factor = 0.00;
          this.products[i].solutionUt = 'L/Ha';
        } else {
          this.products[i].solution = e.data.solution;
          this.products[i].solutionUt = 'L/Ha';
          this.products[i].dilution_factor = parseFloat(e.data.solution) / 100;
        }
      }
      this.setProducts(this.products);
      this.calculateDoseSolution();
    },
    ...mapActions('Prescription', ['setPrescription', 'setNewPrescription', 'setProducts', 'setlistFamily', 'setlistSubfamily', 'setlistComponents']),
  },
};
</script>
