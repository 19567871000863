// Import Vue & Framework7
import Vue from 'vue';
import Framework7 from 'framework7/framework7-lite.esm.bundle';
import Framework7Vue from 'framework7-vue/framework7-vue.esm.bundle';

// Importamos la librería de traducción
import i18next from 'i18next';
import VueI18Next from '@panter/vue-i18next';

// Styles
import 'framework7/css/framework7.bundle.css';
import '../css/icons.css';
import '../css/app.scss';

// Import App Component
import VCalendar from 'v-calendar';
import App from '../components/app.vue';
import store from '../store/index';

// Import DevExtreme
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';

// Importamos librerías de apoyo
import Helpers from './helpers';

// Importamos los lenguajes
import en from './languageEN';
import es from './languageES';
import sr from './languageSR';
import Api from '../services/Api';

// Init Framework7-Vue Plugin
Framework7.use(Framework7Vue);

// Inicializamos las librerías de apoyo
Vue.use(VueI18Next);
Vue.use(VCalendar);

// const api = new Api();
const dictionaries = { // TODO: Estos diccionarios deberían de llegar desde el back
  en: en.en,
  es: es.es,
  sr: sr.sr,
};
let lng;
switch (Api.getAgriFarm()) {
  case 'serbia':
    lng = 'sr';
    break;
  case 'chile':
    lng = 'es';
    break;
  default:
    lng = 'en';
    break;
}
i18next.init({
  lng: 'es', // Para la demo siempre en español
  fallbackLng: 'en',
  whitelist: ['en', 'es', 'sr'],
  resources: dictionaries,
});
const i18n = new VueI18Next(i18next);

// Init App
Vue.prototype.$helpers = Helpers;
// eslint-disable-next-line no-new
new Vue({
  el: '#app',

  // Register App Component
  components: {
    app: App,
  },
  render: (h) => h(App),
  i18n,
  store,
});
