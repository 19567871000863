import { render, staticRenderFns } from "./DxViewPlanningWorkOrder.vue?vue&type=template&id=35f8aada&scoped=true&"
import script from "./DxViewPlanningWorkOrder.vue?vue&type=script&lang=js&"
export * from "./DxViewPlanningWorkOrder.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "35f8aada",
  null
  
)

export default component.exports