<template>
  <div>
    <div
      class="content-devx content-dx-daily-planning"
    >
      <DxDataGrid
        id="machineryMonthlyTable"
        ref="machineryMonthlyTable"
        :data-source="actualTaskBadget.machinery"
        :column-min-width="70"
        :allow-column-reordering="true"
        :show-borders="true"
        :show-column-lines="true"
        :column-auto-width="true"
        :show-row-lines="false"
        :row-alternation-enabled="true"
        @row-inserted="onInsertedRow"
        @row-updated="onChangeRow"
        @row-removed="onRemovedRow"
      >
        <DxStateStoring
          :enabled="true"
          type="localStorage"
          storage-key="DxMachineryMonthlyTable"
        />
        <DxExport
          :enabled="false"
        />
        <DxPaging :page-size="18" />
        <DxEditing
          :allow-updating="true"
          :allow-deleting="true"
          :allow-adding="true"
          mode="form"
        >
          <DxForm>
            <DxItem
              :col-count="1"
              :col-span="2"
              item-type="group"
            >
              <DxItem
                :col-span="20"
                data-field="type"
              />
              <DxItem
                :editor-options="{ showSpinButtons: false,
                                   mode: 'number',
                                   placeholder: 0,
                                   showClearButton: true,
                                   min: 0,
                                   step: 1,
                }"
                editor-type="dxNumberBox"
                data-field="performance"
                :caption="$t('MonthlyComponent_performance_caption')"
              />
              <DxItem
                data-field="performance_unit"
                :caption="$t('MonthlyComponent_performanceunit_caption')"
              />
              <DxItem
                :editor-options="{ showSpinButtons: false,
                                   mode: 'number',
                                   placeholder: '0',
                                   showClearButton: true,
                                   min: '0',
                                   step: '1',
                }"
                editor-type="dxNumberBox"
                data-field="consumption"
                :caption="$t('MonthlyComponent_consumption_caption')"
              />
              <DxItem
                data-field="consumption_unit"
                :caption="$t('MonthlyComponent_consumptionunit_caption')"
              />
              <DxItem
                data-field="consumption_type"
                :caption="$t('MonthlyComponent_typeconsumption_caption')"
              />
            </DxItem>
          </DxForm>
        </DxEditing>
        <DxSearchPanel
          :visible="true"
          :width="200"
          :placeholder="$t('Search_placeholder')"
        />
        <DxColumn
          data-field="type"
          name="type"
          alignment="left"
          :caption="$t('DxMachinery_dxColumn_TypeMachinery')"
        >
          <DxLookup
            :data-source="itemsAvailables2"
            value-expr="subfamily"
            display-expr="subfamily"
          />
          <DxRequiredRule />
        </DxColumn>
        <DxColumn
          data-field="consumption"
          name="consumption"
          data-type="number"
          alignment="right"
          :caption="$t('MonthlyComponent_consumption_caption')"
        >
          <DxRequiredRule />
        </DxColumn>
        <DxColumn
          data-field="consumption_unit"
          name="consumptionUnit"
          alignment="left"
          :caption="$t('MonthlyComponent_consumptionunit_caption')"
        >
          <DxLookup
            :data-source="consumptionUnit"
          />
          <DxRequiredRule />
        </DxColumn>
        <DxColumn
          data-field="consumption_type"
          name="consumptionType"
          alignment="left"
          :caption="$t('MonthlyComponent_typeconsumption_caption')"
        >
          <DxLookup
            :data-source="typeCosumptionAvailable"
            :display-expr="dysplayConsumptionType"
          />
          <DxRequiredRule />
        </DxColumn>
        <DxColumn
          data-field="performance"
          alignment="right"
          name="performance"
          data-type="number"
          :caption="$t('MonthlyComponent_performance_caption')"
        >
          <DxRequiredRule />
        </DxColumn>
        <DxColumn
          data-field="performance_unit"
          name="performanceUnit"
          alignment="left"
          :caption="$t('MonthlyComponent_performanceunit_caption')"
        >
          <DxLookup
            :data-source="unitPerformanceAvailable"
          />
          <DxRequiredRule />
        </DxColumn>
      </DxDataGrid>
    </div>
  </div>
</template>
<script>

import { mapState, mapActions } from 'vuex';
import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxEditing,
  DxSearchPanel,
  DxExport,
  DxRequiredRule,
  DxLookup,
  DxForm,
  DxStateStoring,
} from 'devextreme-vue/data-grid';
import { DxItem } from 'devextreme-vue/form';
import * as overlay from 'devextreme/ui/overlay';
import Api from '../../services/Api';
import EventBus from '../../js/event-bus';

export default {
  name: 'DxMachineryMonthyTable',
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxEditing,
    DxSearchPanel,
    DxExport,
    DxForm,
    DxItem,
    DxRequiredRule,
    DxLookup,
    DxStateStoring,
  },
  props: {
    machinary: { type: Array, default: () => [] },
    planning: { type: Object, default: null },
  },
  data() {
    return {
      itemsAvailables: [],
      itemsAvailables2: [],
      unitPerformanceAvailable: [this.$t('DxMachineryMonthlyTableHADAY'), this.$t('DxMachineryMonthlyTableUNDIA')],
      typeCosumptionAvailable: ['DIESEL', 'GASOLINE'],
      consumptionUnit: [`L/${this.$t('DxWorker_workingDay_caption')}`],
    };
  },
  computed: {
    ...mapState('TaskBadget', ['actualTaskBadget']),
  },
  async beforeMount() {
    overlay.baseZIndex(9999);
    this.$f7.preloader.show();
    try {
      this.itemsAvailables = await this.getItems('MACHINERY', 'VEHICLE');
      this.displayExpression(this.itemsAvailables);
      this.loaded = true;
    } catch (e) {
      const message = this.$helpers.getFilteredErrorMessage(e);
      if (message.includes('default_') && !message.includes('404')) {
        this.$f7.dialog.alert(message.split('_').pop());
      } else if (!message.includes('404')) {
        this.$f7.dialog.alert(this.$t(message));
      }
    } finally {
      this.$f7.preloader.hide();
    }
  },
  methods: {
    // GET ITEMS AND DRIVERS
    async getItems(type, family) {
      const xhr = await Api.getItems(type, family);
      return JSON.parse(xhr.response);
    },
    dysplayConsumptionType(e) {
      if (e === 'DIESEL') {
        return this.$t('DIESEL');
      }
      return this.$t('GASOLINE');
    },
    displayExpression(data) {
      this.itemsAvailables2 = [];
      for (const current of data) {
        if (this.itemsAvailables2.length === 0) this.itemsAvailables2.push(current);
        if (this.itemsAvailables2[this.itemsAvailables2.length - 1].subfamily !== current.subfamily) {
          this.itemsAvailables2.push(current);
        }
      }
    },
    // SET DATA SOURCE TO PRODUCTS VUEX
    onChangeRow() {
      const arrayItems = this.actualTaskBadget.machinery;
      this.setMachinery(arrayItems);
      EventBus.$emit('updateCosts');
    },
    onInsertedRow() {
      const arrayItems = this.actualTaskBadget.machinery;
      this.setMachinery(arrayItems);
      EventBus.$emit('updateCosts');
    },
    onRemovedRow() {
      const arrayItems = this.actualTaskBadget.machinery;
      this.setMachinery(arrayItems);
      EventBus.$emit('updateCosts');
    },
    ...mapActions('TaskBadget', ['setMachinery']),
  },
};
</script>
