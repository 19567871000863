<template>
  <div
    class="main-selectWarehouse"
  >
    <DxForm
      id="form"
      :col-count="1"
    >
      <DxSimpleItem
        :editor-options="{
          items: availableWarehouses,
          displayExpr:'name',
          valueExpr:'code',
          searchEnabled: true,
          onValueChanged: changeWarehouse,
          width: 400,
          value: actualWarehouse,
        }"
        editor-type="dxSelectBox"
      />
    </DxForm>
  </div>
</template>
<script>
import {
  DxForm,
  DxSimpleItem,
} from 'devextreme-vue/form';
import { mapActions } from 'vuex';
import Api from '../../services/Api';

export default {
  name: 'DxSelectWarehouse',
  components: {
    DxForm,
    DxSimpleItem,
  },
  props: {
    availableWarehouses: { type: Array, default: () => [] },
  },
  data() {
    return {
      warehouses: [],
      actualWarehouse: '',
    };
  },
  computed: {
  },
  async beforeMount() {
    if (this.availableWarehouses.length > 0) {
      this.actualWarehouse = this.availableWarehouses[0].code;
    }
  },
  methods: {
    async changeWarehouse(e) {
      this.$f7.preloader.show();
      try {
        const xhr = await Api.getStockByWarehouseCode(e.value);
        if (JSON.parse(xhr.response).data.length > 0) {
          this.setWarehouseProductStock(JSON.parse(xhr.response).data[0].products);
        }
      } catch (error) {
        const message = this.$helpers.getFilteredErrorMessage(e);
        if (message.includes('default_') && !message.includes('404')) {
          this.$f7.dialog.alert(message.split('_').pop());
        } else if (message.includes('You do not have permission')) {
          this.$f7.dialog.alert(this.$t(message));
        } else if (!message.includes('404')) {
          this.$f7.dialog.alert(this.$t(message));
        }
      } finally {
        this.$f7.preloader.hide();
      }
    },
    ...mapActions('Prescription', ['setWarehouseProductStock']),
  },
};
</script>
