<template>
  <div class="content-devx main-dx-batch-info-form no-shadow">
    <f7-block-title
      class="no-padding-top"
    >
      {{ editing ? $t('BatchesForm_edit_batches_info') : $t('BatchesForm_create_batches_info') }}
    </f7-block-title>
    <DxForm>
      <DxSimpleItem
        v-if="editing"
        :data-field="$t('DxBatchesInfoForm_Farm')"
        name="farm"
        :validation-rules="validationRules.farm"
        :editor-options="{
          disabled: true,
          items: locationTree,
          displayExpr:'name',
          valueExpr:'bd_id',
          searchEnabled: true,
          onValueChanged: changeLocation,
          value: currentLocation,
        }"
        editor-type="dxSelectBox"
      />
      <DxSimpleItem
        v-else
        :data-field="$t('DxBatchesInfoForm_Farm')"
        name="farm"
        :validation-rules="validationRules.farm"
        :editor-options="{
          items: locationTree,
          displayExpr:'name',
          valueExpr:'bd_id',
          searchEnabled: true,
          onValueChanged: changeLocation,
          value: currentLocation,
        }"
        editor-type="dxSelectBox"
      />
      <DxSimpleItem
        v-if="editing"
        :validation-rules="validationRules.name"
        :data-field="$t('BatchesInfoForm_name')"
        :editor-options="{
          disabled: true,
          value: actualNameBatch,
          mode: 'text',
          onValueChanged: changeNameBatch,
        }"
      />
      <DxSimpleItem
        v-else
        :validation-rules="validationRules.name"
        :data-field="$t('BatchesInfoForm_name')"
        :editor-options="{
          disabled: false,
          value: actualNameBatch,
          mode: 'text',
          onValueChanged: changeNameBatch,
        }"
      />
      <DxSimpleItem
        :data-field="$t('PlanningComponent_date_caption')"
        name="date"
      >
        <template #default>
          <f7-input
            id="datepicker-multiple"
            type="datepicker"
            class="form-calendar"
            :placeholder="$t('PlanningComponent_datepicker_multiple_placeholder')"
            disabled
            :calendar-params="{
              openIn: 'customModal',
              header: false,
              footer: true,
              dateFormat: 'yyyy-mm-dd'
            }"
            :value="currentDate"
          />
        </template>
      </DxSimpleItem>
    </DxForm>
  </div>
</template>
<script>
import moment from 'moment';
import { mapState, mapActions } from 'vuex';
import {
  DxForm,
  DxSimpleItem,
} from 'devextreme-vue/form';

export default {
  name: 'BinsInfoForm',
  components: {
    DxForm,
    DxSimpleItem,
  },
  props: {
    editing: {
      type: Boolean,
      default: false,
    },
    binToEdit: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      actualNameBatch: '',
      validationRules: {
        name: [
          {
            type: 'required',
            message: this.$t('DxBatchesInfoForm_Name_Rule'),
          },
        ],
        farm: [
          {
            type: 'required',
            message: this.$t('DxBatchesInfoForm_Farm_Rule'),
          },
        ],
      },
      currentDate: [moment().format('MM/DD/YYYY')],
      currentLocation: '',
    };
  },
  computed: {
    ...mapState('greenhouse', ['nameBatch', 'dateBatch', 'locationTree', 'locationBatch']),
  },
  beforeDestroy() {
    this.setNameBatch('');
  },
  async beforeMount() {
    try {
      await this.fetchLocationTree();
      if (this.nameBatch !== '' && this.editing === true) { // Modo editar
        this.actualNameBatch = this.nameBatch;
        this.currentDate = [this.dateBatch];
        this.currentLocation = this.locationBatch;
      }
    } catch (e) {
      const message = this.$helpers.getFilteredErrorMessage(e);
      if (message.includes('default_') && !message.includes('404')) {
        this.$f7.dialog.alert(message.split('_').pop());
      } else if (!message.includes('404')) {
        this.$f7.dialog.alert(this.$t(message));
      }
    }
  },
  methods: {
    changeNameBatch(e) {
      this.setNameBatch(e.value);
      this.setDateBatch(this.currentDate[0]);
    },
    changeLocation(e) {
      this.currentLocation = e.value;
      this.setLocationBatch(e.value);
      this.fetchBinsFromLocationSelected(e.value);
    },
    ...mapActions('greenhouse',
      [
        'setNameBatch',
        'setDateBatch',
        'fetchLocationTree',
        'setLocationBatch',
        'fetchBinsFromLocationSelected',
      ]),
  },
};
</script>
<style lang="scss" scoped>
</style>
