import {
  f7,
} from 'framework7-vue';
import moment from 'moment-timezone';
import Request from './Request';
import EventBus from '../js/event-bus';

let username = '';
let token = '';
let headerAuth = {};

const agriFarm = 'serbia';
const baseUrl = 'https://fmis.demo.hispatecanalytics.com'; // Endpoint estable desarrollo
// const baseUrl = 'https://mobile.development.hispatecanalytics.com'; // Endpoint beta
// const baseUrl = 'https://ferrero.pre.privatecloud.hispatecanalytics.com'; // Endpoint preproducción
// const baseUrl = 'https://ferrero.privatecloud.hispatecanalytics.com'; // Endpoint producción

EventBus.$on('newLoggedUser', (payload) => {
  const data = JSON.parse(payload);
  username = data.username;
  token = data.token;
  headerAuth = `Bearer ${token}`;
});

export default {

  errors: {
    default: {
      name: 'error',
      message: 'error',
      callback() {},
    },

    E002: {
      name: 'invalid_token',
      message: 'Su sesión ha expirado',

      callback() {
        if (!username) return;
        username = null;
        EventBus.$emit('invalidTokenDetected');
        f7.dialog.alert(this.message);
      },
    },
  },
  getToken() {
    return token;
  },
  getHeaderAuth() {
    return headerAuth;
  },
  getAgriFarm() {
    return agriFarm;
  },
  getTimeZone() {
    if (agriFarm === 'chile') return 'America/Santiago';
    if (agriFarm === 'serbia') return 'Europe/Madrid';
    return 'UTC';
  },
  getBaseUrl() {
    return baseUrl;
  },
  // Login
  login(credentials) {
    const url = `${baseUrl}/api/user/authorization`;
    const payload = {
      data: JSON.stringify({
        username: credentials.username,
        password: credentials.password,
      }),
    };
    return Request.async(url, payload, 'POST', false, 'application/json')
      .then(this.handleLoginResult);
  },

  async handleLoginResult(result) {
    if (result.response !== '') {
      EventBus.$emit('newLoggedUser', result.response);
    }
  },

  //  Auxiliary methods
  async request(url, payload, verb, processData, contentType) {
    const response = await Request.async(url, payload, verb, processData, contentType);
    return response;
  },

  getException(errorcode) {
    return errorcode in this.errors ? this.errors[errorcode] : this.errors.default;
  },

  // List employess
  getEmployees(limit = 0, page = 0, fullname = '') {
    let url = `${baseUrl}/${agriFarm}/employees?limit=${limit}&offset=${page}`;
    if (fullname !== '') {
      url += `&fullname=${fullname}`;
    }
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth);
  },

  getEmployeesAllFullName(limit = 0, page = 0, fullname = '', status = 'all') {
    let url = `${baseUrl}/${agriFarm}/employees?limit=${limit}&offset=${page}`;
    if (fullname !== '') {
      url += `&fullname=${fullname}`;
    }
    if (status !== '') {
      url += `&status=${status}`;
    }
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth);
  },

  // List employess
  getEmployeesAll(limit = 0, page = 0, status = 'all') {
    let url = `${baseUrl}/${agriFarm}/employees?limit=${limit}&offset=${page}`;
    if (status !== '') {
      url += `&status=${status}`;
    }
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth);
  },
  getTask(taskId) {
    const url = `${baseUrl}/${agriFarm}/tasks/${taskId}/`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth);
  },

  getPostWork(init, end) {
    let url = `${baseUrl}/${agriFarm}/tasks/postwork?`;
    const queryParams = new URLSearchParams();
    if (init !== '') {
      queryParams.set('init_date', init);
    }
    if (end !== '') {
      queryParams.set('end_date', end);
    }
    url += queryParams.toString();

    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth);
  },

  getTaskByCode(code) {
    const url = `${baseUrl}/${agriFarm}/tasks/search?code=${code}`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth);
  },

  // Devuele un json con los tipos de tareas,
  // junto con las tareas en progreso y acabadas de la forma:
  /*
    {
      "new_task": [{...},{...}],
      "current_task": [{...},{...}],
      "completed_task": [{...},{...}]
    }
  */
  getTasks(farmId, activityId) {
    const url = `${baseUrl}/${agriFarm}/tasks/all?farm=${farmId}&activity=${activityId}`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth);
  },

  getActivities() {
    const url = `${baseUrl}/${agriFarm}/tasks/activities`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth);
  },

  createTask(newTaskId, newTaskLocationId) {
    const url = `${baseUrl}/${agriFarm}/tasks`;
    const payload = {
      data: JSON.stringify({
        task_type: newTaskId,
        location: newTaskLocationId,
      }),
    };
    return Request.async(url, payload, 'POST', false, 'application/json', headerAuth);
  },

  checkIn(taskId, type, code) {
    const url = `${baseUrl}/${agriFarm}/tasks/${taskId}/checkin`;
    const payload = {
      data: JSON.stringify({
        worker: {
          type,
          code,
        },
      }),
    };
    return Request.async(url, payload, 'POST', false, 'application/json', headerAuth);
  },

  checkOut(taskId, type, code, performance, unit) {
    const url = `${baseUrl}/${agriFarm}/tasks/${taskId}/checkout`;
    const payload = {
      data: JSON.stringify({
        worker: {
          type,
          code,
        },
        performance,
        unit,
      }),
    };
    return Request.async(url, payload, 'POST', false, 'application/json', headerAuth);
  },
  deleteTask(taskId) {
    const url = `${baseUrl}/${agriFarm}/tasks/${taskId}`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'DELETE', false, 'application/json', headerAuth);
  },
  // Arbol del sistema parcelario
  getLocationsTree() {
    const url = `${baseUrl}/${agriFarm}/locations/tree`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth);
  },
  // Obtención de la definición (tipo) de tareas
  getTasksDefinition() {
    const url = `${baseUrl}/${agriFarm}/tasks/definition`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth);
  },
  // Obtención de los tipos de item por familia
  getItemsType() {
    const url = `${baseUrl}/${agriFarm}/keyvalues`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth);
  },
  // Retorna las planificaciones
  getDailyPlan(init, end) {
    let url = `${baseUrl}/${agriFarm}/tasks/planning?`;
    const queryParams = new URLSearchParams();
    if (init !== '') {
      queryParams.set('date_init', init);
    }
    if (end !== '') {
      queryParams.set('date_end', end);
    }
    url += queryParams.toString();

    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth);
  },

  EditTaskMonthlyPlanning(newTaskMonthlyPlanning, badgetId, versionId, taskId) {
    const url = `${baseUrl}/${agriFarm}/badget/${badgetId}/version/${versionId}/task/${taskId}`;
    const payload = {
      data: JSON.stringify(newTaskMonthlyPlanning),
    };
    return Request.async(url, payload, 'PUT', false, 'application/json', headerAuth);
  },
  addTaskMonthlyPlanning(newTaskMonthlyPlanning, badgetId, versionId) {
    const url = `${baseUrl}/${agriFarm}/badget/${badgetId}/version/${versionId}/task`;
    const payload = {
      data: JSON.stringify(newTaskMonthlyPlanning),
    };
    return Request.async(url, payload, 'POST', false, 'application/json', headerAuth);
  },

  createPlanning(newPlanning) {
    const url = `${baseUrl}/${agriFarm}/tasks/planning`;
    const payload = {
      data: JSON.stringify({
        task_code: newPlanning.task_code,
        activity_code: newPlanning.activity_code,
        date: newPlanning.date,
        shift: newPlanning.shift,
        group: newPlanning.group,
        grouped: newPlanning.grouped,
        workers: newPlanning.workers,
        items: newPlanning.items,
        locations: newPlanning.locations,
        attachments: newPlanning.attachments,
        tools: newPlanning.tools,
        products: newPlanning.products,
        prescription: newPlanning.prescription,
        area_effective: newPlanning.area_effective,
        distributed: newPlanning.distributed,
        goal_performance: newPlanning.goal_performance,
        variety: newPlanning.variety,
        nursery: newPlanning.nursery,
      }),
    };
    return Request.async(url, payload, 'POST', false, 'application/json', headerAuth);
  },
  editEmployee(newEmployee, employeeId) {
    const url = `${baseUrl}/${agriFarm}/employees/${employeeId}`;
    const payload = {
      data: JSON.stringify(newEmployee),
    };
    return Request.async(url, payload, 'PUT', false, 'application/json', headerAuth);
  },
  createEmployee(newEmployee) {
    const url = `${baseUrl}/${agriFarm}/employees`;
    const payload = {
      data: JSON.stringify(newEmployee),
    };
    return Request.async(url, payload, 'POST', false, 'application/json', headerAuth);
  },

  updatePlanning(ID, newPlanning) {
    const url = `${baseUrl}/${agriFarm}/tasks/planning/${ID}`;
    const payload = {
      data: JSON.stringify({
        task_code: newPlanning.task_code,
        activity_code: newPlanning.activity_code,
        date: newPlanning.date,
        shift: newPlanning.shift,
        group: newPlanning.group,
        grouped: newPlanning.grouped,
        workers: newPlanning.workers,
        items: newPlanning.items,
        locations: newPlanning.locations,
        attachments: newPlanning.attachments,
        tools: newPlanning.tools,
        products: newPlanning.products,
        prescription: newPlanning.prescription,
        area_effective: newPlanning.area_effective,
        distributed: newPlanning.distributed,
        goal_performance: newPlanning.goal_performance,
        variety: newPlanning.variety,
        nursery: newPlanning.nursery,
      }),
    };
    return Request.async(url, payload, 'POST', false, 'application/json', headerAuth);
  },
  getMonthlyPlanning(badgetId, versionId, init = '', end = '') {
    let url = `${baseUrl}/${agriFarm}/badget/${badgetId}/version/${versionId}?`;
    const queryParams = new URLSearchParams();
    if (init !== '') {
      queryParams.set('init_date', init);
    }
    if (end !== '') {
      queryParams.set('end_date', end);
    }
    url += queryParams.toString();
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth);
  },
  getMonthlyTaskPlanningId(badgetId, versionId, taskId) {
    const url = `${baseUrl}/${agriFarm}/badget/${badgetId}/version/${versionId}/task/${taskId}`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth);
  },
  // Monthly Plan
  getMonthlyTasks(init = '', end = '') {
    let url = `${baseUrl}/${agriFarm}/badget?`;
    const queryParams = new URLSearchParams();
    if (init !== '') {
      queryParams.set('init_date', init);
    }
    if (end !== '') {
      queryParams.set('end_date', end);
    }
    url += `&${queryParams.toString()}`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth);
  },
  // Daily Plan
  getDailyTasks(all = false, includeActivities = [], excludeActivities = [], init = '', end = '', unplanned = false, warehouse = false, machinery = false) {
    if (init !== '') {
      all = false;
    }
    let url = `${baseUrl}/${agriFarm}/tasks/daily?all=${all}`;
    const queryParams = new URLSearchParams();
    for (const activities of excludeActivities) {
      url += `&exclude_activities=${activities}`;
    }
    for (const activities of includeActivities) {
      url += `&activities=${activities}`;
    }
    if (init !== '') {
      queryParams.set('date_init', init);
    }
    if (end !== '') {
      queryParams.set('date_end', end);
    }
    if (unplanned) {
      queryParams.set('unplanned', 'true');
    }
    if (warehouse) {
      queryParams.set('warehouse', 'true');
    }
    if (machinery) {
      queryParams.set('machinery', 'true');
    }
    url += `&${queryParams.toString()}`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth);
  },
  // List supervisors
  getSupervisors(limit = 0, page = 0) {
    const url = `${baseUrl}/${agriFarm}/employees?role=supervisor&limit=${limit}&offset=${page}`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth);
  },
  // Listado de items
  // family: Obligatorio. Familia de Item a devolver. Los posibles valores corresponden al key-value ITEM_FAMILY
  // subfamily: (Opcional) Subfamilia de items. Se pueden consultar en key-value concatenando ITEM_SUBFAMILY_<CODIGO_FAMILY>
  // textsearch: (Opcional) Buscador de texto. Buscará la cadena de texto tanto en el campo code como en el name.
  //
  // {
  //  "_id" : <IDENTIFICADOR ITEM>,
  //  "company" : <IDENTIFICADOR COMPANY>,
  //  "family" : <CODIGO FAMILIA>,
  //  "subfamily" : <CODIGO SUBFAMILIA>,
  //  "code" : <CODIGO ITERNO>,
  //  "name" : <DESCRIPCION>,
  //  "properties" : [{
  //   "key" : <CLAVE PROPIEDAD>,
  //   "value" : <VALOR PROPIEDAD>
  //  },
  //    ...
  // ]}
  getItems(type, family, subfamily = '', textsearch = '') {
    let url = `${baseUrl}/${agriFarm}/items?type=${type}&family=${family}`;
    if (subfamily !== '') {
      url += `&subfamily=${subfamily}`;
    }
    if (textsearch !== '') {
      url += `&textsearch=${textsearch}`;
    }
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth);
  },
  createOrders(newOrders) {
    const url = `${baseUrl}/${agriFarm}/tasks/planning/${newOrders.planner}/generate`;
    const payload = {
      data: JSON.stringify({
        tasks: newOrders.tasks,
      }),
    };
    return Request.async(url, payload, 'POST', false, 'application/json', headerAuth);
  },
  deletePlanning(planningId) {
    const url = `${baseUrl}/${agriFarm}/tasks/planning/${planningId}`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'DELETE', false, 'application/json', headerAuth);
  },
  updateOrder(orderId, updateOrder) {
    const url = `${baseUrl}/${agriFarm}/tasks/${orderId}`;
    const payload = {
      data: JSON.stringify(updateOrder),
    };
    return Request.async(url, payload, 'POST', false, 'application/json', headerAuth);
  },
  // Dashboard - Reports
  getEffort(locations = [], init = '', end = '', grouped = '', activity = '', task = '', responsible = '') {
    let url = `${baseUrl}/${agriFarm}/report/effort?`;
    for (let i = 0; i < locations.length; i += 1) {
      url += `locations=${locations[i]}&`;
    }
    url += `init=${init}&`;
    url += `end=${end}`;
    if (activity !== '') {
      url += `&activity=${activity}`;
    }
    if (task !== '') {
      url += `&task=${task}`;
    }
    if (responsible !== '') {
      url += `&responsible=${responsible}`;
    }
    if (grouped !== '') {
      url += `&grouped=${grouped}`;
    }
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth);
  },

  getClimate(locations, category, init = '', end = '', parameters = [], enabledCache = false) {
    let url = `${baseUrl}/${agriFarm}/report/climate?`;
    for (let i = 0; i < locations.length; i += 1) {
      url += `locations=${locations[i]}&`;
    }
    url += `category=${category}&init=${init}&end=${end}`;
    if (parameters.length > 0) {
      url += `&parameters=${parameters.join('&parameters=')}`;
    }
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth, enabledCache);
  },

  getEffortByLocations(locations = [], init = '', end = '') {
    let url = `${baseUrl}/${agriFarm}/report/effort?`;
    for (let i = 0; i < locations.length; i += 1) {
      url += `locations=${locations[i]}&`;
    }
    url += `init=${init}&`;
    url += `end=${end}`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth);
  },

  getEffortByGrouped(locations = [], init = '', end = '', grouped = '') {
    let url = `${baseUrl}/${agriFarm}/report/effort?`;
    for (let i = 0; i < locations.length; i += 1) {
      url += `locations=${locations[i]}&`;
    }
    url += `init=${init}&`;
    url += `end=${end}`;
    if (grouped !== '') {
      url += `&grouped=${grouped}`;
    }
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth);
  },

  getDataSubfamilyByType(typeData) {
    const url = `${baseUrl}/${agriFarm}/items/getitemsubfamily?&type=${typeData}`; // &family=FERTILIZERS
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth);
  },
  getWorkersSummary(init, end) {
    let url = `${baseUrl}/${agriFarm}/report/workers/summary?`;
    const queryParams = new URLSearchParams();

    if (init !== '') {
      queryParams.set('date_init', init);
    }
    if (end !== '') {
      queryParams.set('date_end', end);
    }
    url += queryParams.toString();

    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth);
  },
  getClockInOut(init, end) {
    let url = `${baseUrl}/${agriFarm}/employees/list/access?`;
    const queryParams = new URLSearchParams();
    if (init !== '') {
      queryParams.set('init_date', init);
    }
    if (end !== '') {
      queryParams.set('end_date', end);
    }
    url += queryParams.toString();
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth);
  },
  getPrescriptions(familia) {
    let url = `${baseUrl}/${agriFarm}/prescription`;
    if (typeof familia === 'string') {
      url += `?family=${familia}`;
    }
    // url = `${baseUrl}/${agriFarm}/prescription`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth);
  },
  sendPrescriptions(datos) {
    const url = `${baseUrl}/${agriFarm}/prescription`;
    const payload = {
      data: JSON.stringify(datos),
    };
    return Request.async(url, payload, 'POST', false, 'application/json', headerAuth);
  },

  getVademecum() {
    const url = `${baseUrl}/${agriFarm}/vademecum`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth);
  },

  updatePrescriptions(id, datos) {
    const url = `${baseUrl}/${agriFarm}/prescription/${id}`;
    const payload = {
      data: JSON.stringify(datos),
    };
    return Request.async(url, payload, 'POST', false, 'application/json', headerAuth);
  },

  daletePrescriptions(id) {
    const url = `${baseUrl}/${agriFarm}/prescription/${id}`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'DELETE', false, 'application/json', headerAuth);
  },
  sendPostWork(datos) {
    const url = `${baseUrl}/${agriFarm}/tasks/postwork`;
    const payload = {
      data: JSON.stringify(datos),
    };
    return Request.async(url, payload, 'POST', false, 'application/json', headerAuth);
  },
  createMachinery(newMachinery) {
    const url = `${baseUrl}/${agriFarm}/machinery`;
    const payload = {
      data: JSON.stringify(newMachinery),
    };
    return Request.async(url, payload, 'POST', false, 'application/json', headerAuth);
  },
  updateMachinery(ID, newMachinery) {
    const url = `${baseUrl}/${agriFarm}/machinery/${ID}`;
    const payload = {
      data: JSON.stringify(newMachinery),
    };
    return Request.async(url, payload, 'PUT', false, 'application/json', headerAuth);
  },

  updateProduct(product) {
    const verb = (product.id === '') ? 'POST' : 'PUT';
    let url = `${baseUrl}/${agriFarm}/product`;
    if (product.id !== '') url += `/${product.id}`;
    const payload = {
      data: JSON.stringify(product),
    };
    return Request.async(url, payload, verb, false, 'application/json', headerAuth);
  },
  // Obtener costes
  getAllCosts() {
    const url = `${baseUrl}/${agriFarm}/cost`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth);
  },
  getCost(costId) {
    const url = `${baseUrl}/${agriFarm}/cost/${costId}`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth);
  },
  createCost(newCost) {
    const url = `${baseUrl}/${agriFarm}/cost`;
    const payload = {
      data: JSON.stringify(newCost),
    };
    return Request.async(url, payload, 'POST', false, 'application/json', headerAuth);
  },
  updateCost(ID, newCost) {
    const url = `${baseUrl}/${agriFarm}/cost/${ID}`;
    const payload = {
      data: JSON.stringify(newCost),
    };
    return Request.async(url, payload, 'PUT', false, 'application/json', headerAuth);
  },
  deleteCost(costId) {
    const url = `${baseUrl}/${agriFarm}/cost/${costId}`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'DELETE', false, 'application/json', headerAuth);
  },
  getBPASSummary(init, end) {
    let url = `${baseUrl}/${agriFarm}/report/bpas/summary?`;
    const queryParams = new URLSearchParams();
    if (init !== '') {
      queryParams.set('date_init', init);
    }
    if (end !== '') {
      queryParams.set('date_end', end);
    }
    url += queryParams.toString();

    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth);
  },
  getMachinerySummary(init, end) {
    let url = `${baseUrl}/${agriFarm}/report/machinery/summary?`;
    const queryParams = new URLSearchParams();
    if (init !== '') {
      queryParams.set('date_init', init);
    }
    if (end !== '') {
      queryParams.set('date_end', end);
    }
    url += queryParams.toString();

    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth);
  },
  uploadHarvestingPlan(file) {
    const url = `${baseUrl}/crop/file/up`;
    const payload = {
      data: JSON.stringify({ file }),
    };
    return Request.async(url, payload, 'POST', false, 'application/json', headerAuth);
  },
  uploadFile(file, urlToSend) {
    const url = `${baseUrl}/${agriFarm}/${urlToSend}`;
    const payload = {
      data: JSON.stringify({ file }),
    };
    return Request.async(url, payload, 'POST', false, 'application/json', headerAuth);
  },
  getIrrigation(initDate, endDate) {
    let url = `${baseUrl}/${agriFarm}/irrigation?`;
    const queryParams = new URLSearchParams();
    if (initDate !== '') {
      queryParams.set('init_date', initDate);
    }
    if (endDate !== '') {
      queryParams.set('end_date', endDate);
    }
    url += queryParams.toString();
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth);
  },
  getErrorLogs(init, end) {
    let url = `${baseUrl}/${agriFarm}/log?`;
    const queryParams = new URLSearchParams();
    if (init !== '') {
      queryParams.set('init_date', init);
    }
    if (end !== '') {
      queryParams.set('end_date', end);
    }
    url += queryParams.toString();

    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth);
  },
  checkInitEndDate(actualFilter) {
    let init = '';
    let end = '';
    if (typeof actualFilter !== 'undefined' && actualFilter.init !== 'Invalid date' && actualFilter.end !== 'Invalid date') {
      init = actualFilter.init;
      end = actualFilter.end;
    }
    // TODO: Dejarlo como estaba cuando se arregle el problema de rendimiento
    if (init === '') {
      init = moment().format('YYYY-MM-DD');
      end = moment().add(3, 'days').format('YYYY-MM-DD');
    }
    return { init, end };
  },
  getAllVariety() {
    const url = `${baseUrl}/${agriFarm}/variety`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth);
  },
  getSummaryTools() {
    const url = `${baseUrl}/${agriFarm}/tool`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth);
  },
  updateTool(toolId, newData) {
    const url = `${baseUrl}/${agriFarm}/tool/${toolId}`;
    const payload = {
      data: JSON.stringify(newData),
    };
    return Request.async(url, payload, 'PUT', false, 'application/json', headerAuth);
  },
  createTool(newData) {
    const url = `${baseUrl}/${agriFarm}/tool`;
    const payload = {
      data: JSON.stringify(newData),
    };
    return Request.async(url, payload, 'POST', false, 'application/json', headerAuth);
  },
  updateProductWarehouse(ID, updatedProduct) {
    const url = `${baseUrl}/${agriFarm}/task/warehouse/${ID}`;
    const payload = {
      data: JSON.stringify(updatedProduct),
    };
    return Request.async(url, payload, 'PUT', false, 'application/json', headerAuth);
  },
  getCostCenters() {
    const url = `${baseUrl}/${agriFarm}/cost_center`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth);
  },
  getWarehouses() {
    const url = `${baseUrl}/${agriFarm}/warehouse`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth);
  },
  // Greenhouse
  getBinsCreateLote() {
    const url = `${baseUrl}/${agriFarm}/bins?create_lote`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth);
  },
  getBinsCreateLoteByIdLocation(ID) {
    const url = `${baseUrl}/${agriFarm}/bins?create_lote&farm=${ID}`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth);
  },
  getBatches() {
    const url = `${baseUrl}/${agriFarm}/lote`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth);
  },
  getBinsContentFamilies() {
    const url = `${baseUrl}/${agriFarm}/keyvalues?family=BINS_CONTENT_CATEGORY&family=BINS_CONTENT_VARIETY&family=BINS_CONTENT_CLASS`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth);
  },
  getKeysToxicity() {
    const url = `${baseUrl}/${agriFarm}/keyvalues?family=TOXICITY`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth);
  },
  createBatch(newBatch) {
    const url = `${baseUrl}/${agriFarm}/lote`;
    const payload = {
      data: JSON.stringify(newBatch),
    };
    return Request.async(url, payload, 'POST', false, 'application/json', headerAuth);
  },
  updateBatch(ID, batch) {
    const url = `${baseUrl}/${agriFarm}/lote/${ID}`;
    const payload = {
      data: JSON.stringify(batch),
    };
    return Request.async(url, payload, 'POST', false, 'application/json', headerAuth);
  },
  createBin(newBin) {
    const url = `${baseUrl}/${agriFarm}/bin`;
    const payload = {
      data: JSON.stringify(newBin),
    };
    return Request.async(url, payload, 'POST', false, 'application/json', headerAuth);
  },
  createBareRoot(newBareRoot) {
    const url = `${baseUrl}/${agriFarm}/nursery/stock`;
    const payload = {
      data: JSON.stringify(newBareRoot),
    };
    return Request.async(url, payload, 'POST', false, 'application/json', headerAuth);
  },
  getBareRootsWithoutBinsByFarm(selectedFarm, idBin = '') {
    let url = `${baseUrl}/${agriFarm}/nursery/stock?farm=${selectedFarm}&bin=false`;
    if (idBin !== '') {
      url += `&editing=${idBin}`;
    }
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth);
  },
  getBins() {
    const url = `${baseUrl}/${agriFarm}/bins`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth);
  },
  changeBinState(ID, data) {
    const url = `${baseUrl}/${agriFarm}/bin/${ID}/status`;
    const payload = {
      data: JSON.stringify(data),
    };
    return Request.async(url, payload, 'POST', false, 'application/json', headerAuth);
  },
  deleteBin(ID) {
    const url = `${baseUrl}/${agriFarm}/bin/${ID}`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'DELETE', false, 'application/json', headerAuth);
  },
  sendBin(ID, data) {
    const url = `${baseUrl}/${agriFarm}/bin/${ID}/shipment`;
    const payload = {
      data: JSON.stringify(data),
    };
    return Request.async(url, payload, 'POST', false, 'application/json', headerAuth);
  },
  sendBatch(ID, data) {
    const url = `${baseUrl}/${agriFarm}/lote/${ID}/shipment`;
    const payload = {
      data: JSON.stringify(data),
    };
    return Request.async(url, payload, 'POST', false, 'application/json', headerAuth);
  },
  editBin(ID, editedBinToSave) {
    const url = `${baseUrl}/${agriFarm}/bin/${ID}`;
    const payload = {
      data: JSON.stringify(editedBinToSave),
    };
    return Request.async(url, payload, 'POST', false, 'application/json', headerAuth);
  },
  reclassifyBin(ID, reclassifiedBinToSave) {
    const url = `${baseUrl}/${agriFarm}/bin/${ID}/reclassification`;
    const payload = {
      data: JSON.stringify(reclassifiedBinToSave),
    };
    return Request.async(url, payload, 'POST', false, 'application/json', headerAuth);
  },
  moveBinsContent(ID, binsContentMovesToSave) {
    const url = `${baseUrl}/${agriFarm}/bin/${ID}/movement`;
    const payload = {
      data: JSON.stringify(binsContentMovesToSave),
    };
    return Request.async(url, payload, 'POST', false, 'application/json', headerAuth);
  },
  deleteBatch(ID) {
    const url = `${baseUrl}/${agriFarm}/lote/${ID}`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'DELETE', false, 'application/json', headerAuth);
  },
  binsToReclassify() {
    const url = `${baseUrl}/${agriFarm}/bins?status=REGISTERED&status=RECEIVED`;
    const payload = {
      // eslint-disable-next-line no-dupe-keys
      data: { limit: 10 },
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth);
  },
  getFarmsToSend() {
    const url = `${baseUrl}/${agriFarm}/locations/farms`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth);
  },
  getBinsContentVarieties() {
    const url = `${baseUrl}/${agriFarm}/nursery/variety`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth);
  },
  getBareRootCategories() {
    const url = `${baseUrl}/${agriFarm}/nursery/stock_category`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth);
  },
  getBinsEgress() {
    const url = `${baseUrl}/${agriFarm}/nursery/egreso`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth);
  },
  getBinsContentCategories() {
    const url = `${baseUrl}/${agriFarm}/nursery/category`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth);
  },
  getBinsContentClassifications() {
    const url = `${baseUrl}/${agriFarm}/nursery/classtype`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth);
  },
  getBinsContentsForPlanning(locations) {
    const url = `${baseUrl}/${agriFarm}/bins/planning`;
    const payload = {
      data: JSON.stringify({ location: locations }),
    };
    return Request.async(url, payload, 'POST', false, 'application/json', headerAuth);
  },
  closeBadget(id) {
    const url = `${baseUrl}/${agriFarm}/badget/${id}/closed`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'PUT', false, 'application/json', headerAuth);
  },
  createAnnualBudget(newAnnualBudget) {
    const url = `${baseUrl}/${agriFarm}/badget`;
    const payload = {
      data: JSON.stringify(newAnnualBudget),
    };
    return Request.async(url, payload, 'POST', false, 'application/json', headerAuth);
  },
  cloneAnnualBudgetVersion(newAnnualBudgetVersion) {
    const url = `${baseUrl}/${agriFarm}/badget`;
    const payload = {
      data: JSON.stringify(newAnnualBudgetVersion),
    };
    return Request.async(url, payload, 'POST', false, 'application/json', headerAuth);
  },
  getScoutingMasterData(types = []) {
    let url = `${baseUrl}/${agriFarm}/plagues_and_disease?`;
    for (let i = 0; i < types.length; i += 1) {
      url += `type=${types[i]}&`;
    }
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth);
  },
  getPhenologies() {
    const url = `${baseUrl}/${agriFarm}/phenological_phase`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth);
  },
  getPoints() {
    const url = `${baseUrl}/${agriFarm}/scouting_point`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth);
  },
  createPoint(newPoint) {
    const url = `${baseUrl}/${agriFarm}/scouting_point`;
    const payload = {
      data: JSON.stringify(newPoint),
    };
    return Request.async(url, payload, 'POST', false, 'application/json', headerAuth);
  },
  updatePoint(ID, updatedPoint) {
    const url = `${baseUrl}/${agriFarm}/scouting_point/${ID}`;
    const payload = {
      data: JSON.stringify(updatedPoint),
    };
    return Request.async(url, payload, 'PUT', false, 'application/json', headerAuth);
  },
  deletePoint(ID) {
    const url = `${baseUrl}/${agriFarm}/scouting_point/${ID}`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'DELETE', false, 'application/json', headerAuth);
  },
  getPlaguesDisease() {
    const url = `${baseUrl}/${agriFarm}/plagues_and_disease`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth);
  },
  getAllScouting(init, end) {
    let url = `${baseUrl}/${agriFarm}/scouting?`;
    const queryParams = new URLSearchParams();
    if (init !== '') {
      queryParams.set('init_date', init);
    }
    if (end !== '') {
      queryParams.set('end_date', end);
    }
    url += queryParams.toString();

    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth);
  },
  getWarehouseTasks(init = '', end = '') {
    let url = `${baseUrl}/${agriFarm}/task/warehouse`;
    const queryParams = new URLSearchParams();
    if (init !== '') {
      queryParams.set('date_init', init);
    }
    if (end !== '') {
      queryParams.set('date_end', end);
    }
    url += `?${queryParams.toString()}`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth);
  },
  getAllDataBudget(badgetId, versionId) {
    const url = `${baseUrl}/${agriFarm}/badget/${badgetId}/version/${versionId}/export`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth);
  },
  reSendProduct(taskId, warehouseId) {
    const url = `${baseUrl}/${agriFarm}/task/${taskId}/warehouse/${warehouseId}`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'POST', false, 'application/json', headerAuth);
  },
  getStockByWarehouseCode(warehouseCode) {
    const url = `${baseUrl}/${agriFarm}/warehouse_stock?warehouse=${warehouseCode}`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth);
  },
  getTerritorialUnit() {
    const url = `${baseUrl}/${agriFarm}/locations`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth);
  },
  getBareRoots() {
    const url = `${baseUrl}/${agriFarm}/nursery/stock`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth);
  },
  deleteBareRoot(ID) {
    const url = `${baseUrl}/${agriFarm}/nursery/stock/${ID}`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'DELETE', false, 'application/json', headerAuth);
  },
  editBareRoot(ID, editedBareRootToSave) {
    const url = `${baseUrl}/${agriFarm}/nursery/stock/${ID}`;
    const payload = {
      data: JSON.stringify(editedBareRootToSave),
    };
    return Request.async(url, payload, 'POST', false, 'application/json', headerAuth);
  },
  closeBatch(ID) {
    const url = `${baseUrl}/${agriFarm}/lote/${ID}/close`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'POST', false, 'application/json', headerAuth);
  },
  getNurseryClassificationReport(init = '', end = '') {
    let url = `${baseUrl}/${agriFarm}/nursery/reports/classification`;
    const queryParams = new URLSearchParams();
    if (init !== '') {
      queryParams.set('init', init);
    }
    if (end !== '') {
      queryParams.set('end', end);
    }
    url += `?${queryParams.toString()}`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth);
  },
  getNurseryEgresosReport(init = '', end = '') {
    let url = `${baseUrl}/${agriFarm}/nursery/reports/egresos`;
    const queryParams = new URLSearchParams();
    if (init !== '') {
      queryParams.set('init', init);
    }
    if (end !== '') {
      queryParams.set('end', end);
    }
    url += `?${queryParams.toString()}`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth);
  },
  getNurseryStockReport(init = '', end = '') {
    let url = `${baseUrl}/${agriFarm}/nursery/reports/stock`;
    const queryParams = new URLSearchParams();
    if (init !== '') {
      queryParams.set('init', init);
    }
    if (end !== '') {
      queryParams.set('end', end);
    }
    url += `?${queryParams.toString()}`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth);
  },
  getStackedBarPlanned(init = '', end = '') {
    let url = `${baseUrl}/${agriFarm}/current_workers`;
    const queryParams = new URLSearchParams();
    if (init !== '') {
      queryParams.set('date_init', init);
    }
    if (end !== '') {
      queryParams.set('date_end', end);
    }
    url += `?${queryParams.toString()}`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth);
  },
  getRealPerformance(init = '', end = '') {
    let url = `${baseUrl}/${agriFarm}/real_performance`;
    const queryParams = new URLSearchParams();
    if (init !== '') {
      queryParams.set('date_init', init);
    }
    if (end !== '') {
      queryParams.set('date_end', end);
    }
    url += `?${queryParams.toString()}`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth);
  },
  getFuelRegister(init = '', end = '') {
    let url = `${baseUrl}/${agriFarm}/fuel_register`;
    const queryParams = new URLSearchParams();
    if (init !== '') {
      queryParams.set('date_init', init);
    }
    if (end !== '') {
      queryParams.set('date_end', end);
    }
    url += `?${queryParams.toString()}`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth);
  },
  getReportHarvestByLocations(locations = [], dateInit = '', dateEnd = '') {
    let url = `${baseUrl}/${agriFarm}/report/harvest/workers?`;
    for (let i = 0; i < locations.length; i += 1) {
      url += `locations=${locations[i]}&`;
    }
    const queryParams = new URLSearchParams();
    if (dateInit !== '') {
      queryParams.set('date_init', dateInit);
    }
    if (dateEnd !== '') {
      queryParams.set('date_end', dateEnd);
    }
    url += `${queryParams.toString()}`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth);
  },
  getReportHarvest(init = '', end = '') {
    let url = `${baseUrl}/${agriFarm}/report/harvest`;
    const queryParams = new URLSearchParams();
    if (init !== '') {
      queryParams.set('date_init', init);
    }
    if (end !== '') {
      queryParams.set('date_end', end);
    }
    url += `?${queryParams.toString()}`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth);
  },
  getReportHarvestByDays(init = '', end = '') {
    let url = `${baseUrl}/${agriFarm}/report/harvest/days`;
    const queryParams = new URLSearchParams();
    if (init !== '') {
      queryParams.set('date_init', init);
    }
    if (end !== '') {
      queryParams.set('date_end', end);
    }
    url += `?${queryParams.toString()}`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth);
  },
  getDailyRealPerformance(init = '', end = '') {
    let url = `${baseUrl}/${agriFarm}/real_performance`;
    const queryParams = new URLSearchParams();
    if (init !== '') {
      queryParams.set('date_init', init);
    }
    if (end !== '') {
      queryParams.set('date_end', end);
    }
    url += `?${queryParams.toString()}`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth);
  },
  getDailyRealPerformance(init = '', end = '') {
    let url = `${baseUrl}/${agriFarm}/real_performance`;
    const queryParams = new URLSearchParams();
    if (init !== '') {
      queryParams.set('date_init', init);
    }
    if (end !== '') {
      queryParams.set('date_end', end);
    }
    url += `?${queryParams.toString()}`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth);
  },
};
