<template>
  <f7-page>
    <div v-if="loaded">
      <navbar :text="$t('CreatePostWork_Navbar_Title')" />
      <DxPlanningComponent
        class="content-planning"
        :show-tables="false"
        :calendar-multiple="false"
        :max-date="actualDate"
        :distributed-visible="false"
      />
      <div class="content-devx main-create-post-work">
        <f7-row>
          <f7-col width="100">
            <f7-block-title name="select_supervisor">
              {{ $t("WorkOrderDetail_select_supervisor") }}:
            </f7-block-title>
            <DxPostWorkSelectSupervisor
              :supervisors-availables="supervisorsAvailables"
            />
          </f7-col>
          <f7-col width="100">
            <f7-block-title>
              {{ $t('DxEmployeeTable_Title') }}:
            </f7-block-title>
            <DxPostWorkEmployeeTable
              :employees-array="employeesArray.data"
            />
          </f7-col>
          <f7-col width="100">
            <div v-if="showProducts">
              <f7-block-title>
                {{ $t('DxProducts_Title') }}:
              </f7-block-title>
              <DxPostWorkProducts />
            </div>
            <!-- <DxPostWorkEmployeeTable
              :employees-array="employeesArray.data"
            /> -->
            <f7-block-title>
              {{ $t('DxMachinery_Title') }}:
            </f7-block-title>
            <DxPostWorkMachinaryDriverTable
              :items-availables="itemsAvailables"
              :drivers-availables="driversAvailables"
              :types="typesMachinary"
              :updating="true"
              :deleting="true"
              :adding="true"
            />
          </f7-col>
          <f7-col width="100">
            <f7-block-title>
              {{ $t('DxAttachment_Title') }}:
            </f7-block-title>
            <DxPostWorkAttachmentMachinaryTable
              :types="typesAttachmentList"
              :attachment-list="attachmentList"
              :machinary-availables="itemsAvailables"
              :updating="true"
              :deleting="true"
              :adding="true"
            />
          </f7-col>
          <f7-col width="100">
            <f7-block-title>
              {{ $t('DxTools_Title') }}:
            </f7-block-title>
            <DxToolsTable
              :tools-types-availables="toolList"
              :tools-availables="typesTool"
              :allow-updating="true"
              :allow-deleting="true"
              :allow-adding="true"
              :is-post-work="true"
            />
          </f7-col>
          <f7-col
            v-if="showBinsPlanningForm"
            width="100"
          >
            <f7-block-title>
              {{ $t('DxNursery_Title') }}
            </f7-block-title>
            <DxBinsContentGridPlanning />
          </f7-col>
        </f7-row>
      </div>
      <f7-block-footer
        class="content-action-botton"
      >
        <f7-button
          class="dx-btn-cancel margin-right"
          raised
          type="default"
          text-color="grey"
          styling-mode="outlined"
          @click="goToPostWork"
        >
          {{ $t("CreatePlanning_ButtonCancel_caption") }}
        </f7-button>
        <f7-button
          class="dx-btn-success"
          raised
          type="success"
          styling-mode="contained"
          name="CreatePlanning_ButtonSave_caption"
          @click="createPlanning"
        >
          {{ $t('CreatePlanning_ButtonSave_caption') }}
        </f7-button>
      </f7-block-footer>
    </div>
  </f7-page>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import moment from 'moment';
import DxPlanningComponent from '../components/Planning/DxPlanningComponent.vue';
import DxPostWorkAttachmentMachinaryTable from '../components/postWork/DxPostWorkAttachmentMachinaryTable.vue';
import DxPostWorkMachinaryDriverTable from '../components/postWork/DxPostWorkMachinaryDriverTable.vue';
import DxToolsTable from '../components/Planning/DxToolsTable.vue';
import DxPostWorkSelectSupervisor from '../components/postWork/DxPostWorkSelectSupervisor.vue';
import DxPostWorkProducts from '../components/postWork/DxPostWorkProducts.vue';
import DxPostWorkEmployeeTable from '../components/postWork/DxPostWorkEmployeeTable.vue';
import DxBinsContentGridPlanning from '../components/greenhouse/DxBinsContentGridPlanning.vue';

import navbar from '../components/NavBar.vue';
import Api from '../services/Api';

export default {
  name: 'CreatePostWork',
  components: {
    DxPlanningComponent,
    DxPostWorkAttachmentMachinaryTable,
    DxPostWorkMachinaryDriverTable,
    DxPostWorkSelectSupervisor,
    DxToolsTable,
    DxPostWorkProducts,
    DxPostWorkEmployeeTable,
    navbar,
    DxBinsContentGridPlanning,
  },
  props: {
    fullNameFilter: { type: String, default: '' },
    limitFilter: { type: Number, default: 9999 },
    pageFilter: { type: Number, default: 0 },
  },
  data() {
    return {
      loaded: false,
      itemsAvailables: [],
      driversAvailables: [],
      attachmentList: [],
      toolList: [],
      typesAttachmentList: [],
      typesMachinary: [],
      supervisorsAvailables: [],
      employeesArray: [],
      actualDate: moment().format('MM/DD/YYYY'),
      typesTool: [],
    };
  },
  computed: {
    ...mapState('PlanningManager', ['activitySelected', 'taskSelected', 'dateSelected', 'workingArea',
      'actualLocationsSelected', 'performanceUnit', 'performanceCount', 'actualWorkOrder', 'employees',
      'workers', 'items', 'attachments', 'tools', 'groupedSelected', 'shiftValue', 'groupName',
      'supervisorsLocation', 'showProducts', 'variety', 'kgCollected']),
    ...mapState('TaskManager', ['totalAreaSelected']),
    ...mapState('Prescription', ['prescription']),
    ...mapState('greenhouse', [
      'binsContentPlanned',
      'binsContentsForPlanning',
    ]),
    ...mapGetters('greenhouse', [
      'filteredContentsForPlanning',
      'getCategoryCodeByName',
      'getVarietyCodeByName',
      'getClassificationCodeByName',
      'showBinsPlanningForm',
    ]),
  },
  beforeDestroy() {
    this.setTotalAreaSelected(0);
    this.setNewPrescription();
  },
  async beforeMount() {
    try {
      this.$f7.preloader.show();
      this.setNewPrescription();
      this.setActualDailyPlanning(null);
      this.initStore();
      // Cargamos los supervisores y el arbol de localizaciones que necesitan los supervisores
      this.supervisorsAvailables = await this.getSupervisors();
      // Cargamos los conductores
      this.driversAvailables = await this.getEmployeesDriver();
      // Cargamos los empleados
      this.employeesArray = await this.getEmployees();
      // Cargamos attachment
      let xhr = await Api.getDataSubfamilyByType('machinery');
      const machineryAttachment = JSON.parse(xhr.response);
      this.setDataAttachment(machineryAttachment);
      // Cargamos tools
      xhr = await Api.getDataSubfamilyByType('tool');
      const dataTools = JSON.parse(xhr.response);
      this.setDataTool(dataTools);
    } catch (error) {
      const message = this.$helpers.getFilteredErrorMessage(error);
      if (message.includes('default_') && !message.includes('404')) {
        this.$f7.dialog.alert(message.split('_').pop());
      } else if (!message.includes('404')) {
        this.$f7.dialog.alert(this.$t(message));
      }
    } finally {
      this.$f7.preloader.hide();
      this.loaded = true;
    }
  },
  methods: {
    async getEmployees() {
      const xhr = await Api.getEmployeesAllFullName(
        this.limitFilter,
        this.pageFilter,
        this.fullNameFilter,
      );
      return JSON.parse(xhr.response);
    },
    async getEmployeesDriver() {
      const xhr = await Api.getEmployees();
      return JSON.parse(xhr.response).data.filter((employee) => employee.driver === true);
    },
    async loadLocationsTree() {
      const xhr = await Api.getLocationsTree();
      return JSON.parse(xhr.response);
    },
    async getSupervisors() {
      const xhr = await Api.getSupervisors();
      return JSON.parse(xhr.response).data;
    },
    isDriver(empleado) {
      for (let i = 0; i < empleado.properties.length; i += 1) {
        if (empleado.properties[i].key === 'drive') {
          return empleado.properties[i].value;
        }
      }
      return 'False';
    },
    setDataAttachment(dataMachinaryAttachments) {
      this.itemsAvailables.length = 0;
      this.attachmentList.length = 0;
      this.typesAttachmentList.length = 0;
      this.typesMachinary.length = 0;
      this.typesTool.length = 0;
      for (let i = 0; i < dataMachinaryAttachments.length; i += 1) {
        if (dataMachinaryAttachments[i].id === 'VEHICLE') {
          for (let j = 0; j < dataMachinaryAttachments[i].items.length; j += 1) {
            this.itemsAvailables.push(dataMachinaryAttachments[i].items[j]);
            this.typesMachinary.push({ id: dataMachinaryAttachments[i].items[j].id, name: dataMachinaryAttachments[i].items[j].id });
          }
        } else if (dataMachinaryAttachments[i].id === 'ATTACHMENT') {
          for (let j = 0; j < dataMachinaryAttachments[i].items.length; j += 1) {
            this.attachmentList.push(dataMachinaryAttachments[i].items[j]);
            this.typesAttachmentList.push({ id: dataMachinaryAttachments[i].items[j].id, name: dataMachinaryAttachments[i].items[j].id });
          }
        }
      }
    },
    setDataTool(tools) {
      this.toolList.length = 0;
      for (let i = 0; i < tools.length; i += 1) {
        if (tools[i].id === 'TOOL') {
          for (let j = 0; j < tools[i].items.length; j += 1) {
            if (tools[i].items[j].items.length > 0) {
              this.toolList.push({
                name: tools[i].items[j].id,
                id: tools[i].items[j].items[0].item,
              });
            }
            for (let k = 0; k < tools[i].items[j].items.length; k += 1) {
              this.typesTool.push({
                id: tools[i].items[j].items[k].id,
                description: tools[i].items[j].items[k].description,
                item: tools[i].items[j].items[k].item,
              });
            }
          }
          break;
        }
      }
    },
    goToPostWork() {
      this.$f7.views.main.router.navigate('/postWork/', { reloadCurrent: true });
    },
    async createPlanning() {
      if (this.dateSelected === '') {
        this.$f7.dialog.alert(this.$t('CreatePlanning_ValidationDate'));
      } else if (Array.isArray(this.activitySelected)) {
        this.$f7.dialog.alert(this.$t('CreatePlanning_ValidationActivityName'));
      } else if (Array.isArray(this.taskSelected)) {
        this.$f7.dialog.alert(this.$t('CreatePlanning_ValidationTaskName'));
      } else if (this.actualLocationsSelected.length === 0) {
        this.$f7.dialog.alert(this.$t('CreatePlanning_ValidationActualLocationsSelected'));
      } else if (typeof (this.employees) === 'undefined' || this.employees.length === 0) {
        this.$f7.dialog.alert(this.$t('CreatePlanning_ValidationNumberOfWorker'));
      } else if (typeof (this.actualWorkOrder.supervisorsLocation) === 'undefined') {
        this.$f7.dialog.alert(this.$t('DxSelectSupervisor'));
      } else if (!this.checkCheckInWorkers) {
        this.$f7.dialog.alert(this.$t('DxPostWorkEmployeeTable_dialogCreate_wrongHour'));
      } else {
        this.$f7.preloader.show();
        try {
          // Se preparan los empleados para su envío a back
          const newEmployees = [];
          const duplicated = [];
          const previousEmployees = JSON.parse(JSON.stringify(this.employees));
          const idEmployees = JSON.parse(JSON.stringify(this.employees[0].employees_ids));
          previousEmployees.forEach((element) => {
            element.fullname.forEach((name) => {
              const newWorker = JSON.parse(JSON.stringify(element));
              newWorker.fullname = name;
              newWorker.employee_id = idEmployees[0].id;
              idEmployees.splice(0, 1);
              if (newEmployees.filter((e) => e.employee_id === newWorker.employee_id).length > 0) {
                duplicated.push(newWorker.fullname);
              }
              newEmployees.push(newWorker);
            });
          });
          if (duplicated.length > 0) {
            this.$f7.dialog.alert(this.$t('CreatePlanning_DuplicatedEmployees') + duplicated);
          } else {
            const vehicles = [];
            for (let i = 0; i < this.items.length; i += 1) {
              vehicles.push(this.items[i].item);
            }
            const nurseryContent = this.binsContentPlanned.map((element) => {
              const filteredContentAvailable = this.filteredContentsForPlanning(
                element.bin,
                element.variety,
                element.category,
                element.classification,
              );
              if (filteredContentAvailable.length > 0) {
                const binContentForPlanning = this.binsContentsForPlanning.filter((content) => content.bin.bin_id === element.bin);
                return {
                  bin: binContentForPlanning[0].bin,
                  category: this.getCategoryCodeByName(element.category),
                  class_type: this.getClassificationCodeByName(element.classification),
                  code: element.content,
                  count: filteredContentAvailable[0].plants_count,
                  variety: this.getVarietyCodeByName(element.variety),
                };
              }
              return [];
            });
            const itemsPlanning = this.calculateItems(this.items.values);
            const attachmentsPlanning = this.calculateAttachments(this.attachments.values);
            const toolsPlanning = this.calculateTools(this.tools.values);
            const newPlanning = {
              task_code: this.taskSelected.code,
              activity_code: this.activitySelected.code,
              date: this.dateSelected,
              performanceUnit: this.performanceUnit,
              performanceCount: this.performanceCount,
              shift: this.shiftValue,
              group: this.groupName,
              grouped: this.groupedSelected,
              workers: this.workers.values,
              items: itemsPlanning,
              attachments: attachmentsPlanning,
              tools: toolsPlanning,
              locations: this.actualLocationsSelected,
              totalAreaSelected: this.totalAreaSelected,
              products: this.prescription.products,
              prescription: this.prescription.id,
              goal_performance: { unit: this.performanceUnit, count: this.performanceCount, working_area: this.workingArea },
              area_effective: this.workingArea,
              variety: this.variety,
            };

            const newOrder = {
              date: this.dateSelected[0],
              responsible: this.actualWorkOrder.supervisorsLocation,
              workers: newEmployees,
              machinery_performance: this.items.values,
              product_performance: this.prescription.products,
              items_drivers: this.items.values,
              attachments_vehicles: this.attachments.values,
              products: this.prescription.products,
              prescription: this.prescription.id,
              tools: toolsPlanning,
              area_effective: this.workingArea,
              location: this.actualLocationsSelected,
              task: this.taskSelected.id,
              comment: this.actualWorkOrder.additionalInfoWorkOrder,
              planner: newPlanning,
              nursery: nurseryContent,
              performance: { total: parseFloat(this.kgCollected).toFixed(2), price: 0, unit: 'KGS' },
            };
            if ((this.showProducts && this.prescription.id !== undefined)
            || (!this.showProducts)) {
              await Api.sendPostWork(newOrder);
              this.goToPostWork(); // TODO: Habría que mostrar alguna notificación de que se creó correctamente
            } else {
              this.$f7.dialog.alert(this.$t('CreatePostWork_Validation_PrescriptionNeeded'));
            }
          }
        } catch (reason) {
          const message = this.$helpers.getFilteredErrorMessage(reason);
          if (message.includes('TASK_WORDER_PRODUCT_PERFORMANCE_REQUIRED')) {
            this.$f7.dialog.alert(this.$t(message));
          }
          if (message.includes('TASK_WORDER_MACHINERY_PERFORMANCE_INCOMPLETE')) {
            this.$f7.dialog.alert(this.$t(message));
          }
          if (message.includes('TASK_WORDER_PRODUCT_PERFORMANCE_REQUIRED')) {
            this.$f7.dialog.alert(this.$t(message));
          }
          if (message.includes('TASK_WORDER_PRODUCT_PERFORMANCE_TOTAL_REAL_PRESCRIPTION_ZERO')) {
            this.$f7.dialog.alert(this.$t(message));
          }
          if (message.includes('default_') && !message.includes('404')) {
            this.$f7.dialog.alert(message.split('_').pop());
          } else if (!message.includes('404')) {
            this.$f7.dialog.alert(this.$t(message));
          }
        } finally {
          this.$f7.preloader.hide();
        }
      }
    },
    calculateItems(items) {
      const arrayItems = [];
      const arrayNames = [];
      if (items.length > 0) {
        for (let i = 0; i < items.length; i += 1) {
          if (arrayNames.indexOf(items[i].type) >= 0) {
            arrayItems[arrayNames.indexOf(items[i].type)].count += 1;
          } else {
            arrayNames.push(items[i].type);
            arrayItems.push({ type: items[i].type, count: 1 });
          }
        }
      }
      return arrayItems;
    },
    calculateAttachments(attachments) {
      const arrayAttachments = [];
      const arrayNames = [];
      if (attachments.length > 0) {
        for (let i = 0; i < attachments.length; i += 1) {
          if (arrayNames.indexOf(attachments[i].type) >= 0) {
            arrayAttachments[arrayNames.indexOf(attachments[i].type)].count += 1;
          } else {
            arrayNames.push(attachments[i].item);
            // item se ha cambiado por type por la tarea SFR-17
            arrayAttachments.push({ type: attachments[i].type, count: 1 });
          }
        }
      }
      return arrayAttachments;
    },
    calculateTools(tools) {
      tools.forEach((element) => {
        element.count = 1;
        const aux = element.type;
        element.type = element.item;
        element.item = aux;
      });
      return tools;
    },
    checkCheckInWorkers() {
      for (const worker of this.employees) {
        if (moment(worker.init_date).format('YYYY-MM-DD') < this.dateSelected[0]) {
          return false;
        }
      }
      return true;
    },
    ...mapActions('TaskManager', ['setTotalAreaSelected']),
    ...mapActions('Prescription', ['setNewPrescription']),
    ...mapActions('PlanningManager', ['setActualDailyPlanning', 'initStore']),
  },
};
</script>
