<template>
  <div
    v-if="loaded"
    class="content-devx main-dx-monitoring-others"
  >
    <CalendarInitEndDate :key-name="keyName" />
    <DxDataGrid
      :id="`${keyName}Grid`"
      :data-source="monitoringFormated"
      :column-min-width="90"
      :column-auto-width="true"
      :allow-column-reordering="true"
      :show-borders="true"
      :show-column-lines="true"
      :show-row-lines="true"
      :row-alternation-enabled="true"
      @exporting="onExporting"
    >
      <DxSelection
        mode="single"
      />
      <DxStateStoring
        :enabled="true"
        type="localStorage"
        :storage-key="`${keyName}`"
      />
      <DxExport
        :enabled="true"
      />
      <DxHeaderFilter :visible="true" />
      <DxPaging :page-size="10" />
      <DxPager
        :show-page-size-selector="true"
        :allowed-page-sizes="pageSizes"
        :show-info="true"
      />
      <DxSearchPanel
        :visible="true"
        :width="200"
        :placeholder="$t('Search_placeholder')"
      />
      <DxColumn
        data-field="date"
        name="date"
        :caption="$t('Monitoring_column_date')"
        :allow-header-filtering="false"
      />
      <DxColumn
        data-field="point_location"
        name="point_location"
        :caption="$t('Monitoring_point_location')"
        :allow-header-filtering="false"
      />
      <DxColumn
        data-field="point_name"
        name="point_name"
        :caption="$t('Monitoring_point_name')"
        :allow-header-filtering="false"
      />
      <DxColumn
        data-field="point_longitude"
        name="point_longitude"
        :caption="$t('Monitoring_point_longitude')"
        :allow-header-filtering="false"
      />
      <DxColumn
        data-field="point_latitude"
        name="point_latitude"
        :caption="$t('Monitoring_point_latitude')"
        :allow-header-filtering="false"
      />
      <DxColumn
        data-field="point_number_plants"
        name="point_number_plants"
        :caption="$t('Monitoring_point_number_plants')"
        :allow-header-filtering="false"
      />
      <DxColumn
        data-field="point_variety"
        name="point_variety"
        :caption="$t('Monitoring_point_variety')"
        :allow-header-filtering="true"
      />
      <DxColumn
        data-field="point_variety_code"
        name="point_variety"
        :caption="$t('Monitoring_point_variety_code')"
        :allow-header-filtering="true"
      />
      <DxColumn
        data-field="point_planting_year"
        name="point_planting_year"
        :caption="$t('Monitoring_point_planting_year')"
        :allow-header-filtering="false"
      />
      <DxColumn
        data-field="point_note"
        name="point_note"
        :caption="$t('Monitoring_point_note')"
        :allow-header-filtering="false"
      />
      <DxColumn
        data-field="plagues_and_diseases"
        name="plagues_and_diseases"
        :caption="$t('Monitoring_plagues_and_diseases')"
        :allow-header-filtering="true"
      />
      <DxColumn
        data-field="state"
        name="state"
        :caption="$t('Monitoring_state')"
        :allow-header-filtering="true"
      />
      <DxColumn
        data-field="phenological_phase"
        name="phenological_phase"
        :caption="$t('Monitoring_phenological_phase')"
        :allow-header-filtering="true"
      />
      <DxColumn
        data-field="comments"
        name="comments"
        :caption="$t('Monitoring_comments')"
        :allow-header-filtering="false"
      />
      <DxColumn
        data-field="fullName"
        name="fullName"
        :caption="$t('DxScouting_fullName')"
        :allow-header-filtering="false"
      />
      <DxColumn
        :width="100"
        data-field="photos"
        name="photos"
        :caption="$t('Monitoring_photos')"
        :allow-header-filtering="false"
        cell-template="cellTemplate"
      />
      <template #cellTemplate="{ data }">
        <DxImageScoutingPopup :images="data.value" />
      </template>
    </DxDataGrid>
  </div>
</template>

<script>

import { mapState, mapGetters } from 'vuex';
import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxSearchPanel,
  DxExport,
  DxHeaderFilter,
  DxStateStoring,
  DxPager,
  DxSelection,
} from 'devextreme-vue/data-grid';
import { exportDataGrid } from 'devextreme/excel_exporter';
import ExcelJS from 'exceljs';
import saveAs from 'file-saver';
import moment from 'moment';
import Api from '../../services/Api';
import CalendarInitEndDate from '../CalendarInitEndDate.vue';
import DxImageScoutingPopup from './DxImageScoutingPopup.vue';

export default {
  name: 'DxProductWarehouse',
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxSearchPanel,
    DxExport,
    DxHeaderFilter,
    DxStateStoring,
    DxPager,
    DxSelection,
    CalendarInitEndDate,
    DxImageScoutingPopup,
  },
  props: {
    keyName: { type: String, default: '' },
  },
  data() {
    return {
      loaded: false,
      pageSizes: [5, 10, 20],
      monitoringFormated: [],
      locationsAvailables: [],
      phenologiesAvailables: [],
      scoutingMasterData: [],
    };
  },
  computed: {
    ...mapGetters('CalendarInitEndDate', ['actualFilter']),
    ...mapState('Reporting', ['locationsTree']),
  },
  async beforeMount() {
    this.$f7.preloader.show();
    try {
      let xhr = await Api.getPhenologies();
      this.phenologiesAvailables = JSON.parse(xhr.response).data;
      await this.getLocationsAvailables();
      xhr = await Api.getScoutingMasterData();
      this.scoutingMasterData = JSON.parse(xhr.response).data;
      await this.getLocationsAvailables();
      const checkedInitEndDate = Api.checkInitEndDate(this.actualFilter[this.keyName]);
      xhr = await Api.getAllScouting(checkedInitEndDate.init, checkedInitEndDate.end);
      const allMonitoring = JSON.parse(xhr.response).data;
      for (const monitoring of allMonitoring) {
        const scoutingPoint = monitoring.scouting_point;
        const point = {
          date: moment(monitoring.date, 'YYYY-MM-DD').format('YYYY-MM-DD'),
          point_id: scoutingPoint.scouting_point_id,
          point_type: scoutingPoint.type,
          point_species: scoutingPoint.species,
          point_location: this.getLocationNameById(scoutingPoint.location),
          point_longitude: scoutingPoint.coordinates.longitude,
          point_latitude: scoutingPoint.coordinates.latitude,
          point_number_plants: scoutingPoint.number_plants,
          point_variety: scoutingPoint.variety,
          point_planting_year: scoutingPoint.planting_year,
          point_note: scoutingPoint.note,
          point_name: scoutingPoint.name,
          point_variety_code: scoutingPoint.variety_code,
        };
        for (const information of monitoring.disease) {
          this.monitoringFormated.push({
            date: moment(monitoring.date, 'YYYY-MM-DD').format('YYYY-MM-DD'),
            point_id: point.point_id,
            point_type: point.point_type,
            point_location: this.getLocationNameById(point.point_location),
            point_longitude: point.point_longitude,
            point_latitude: point.point_latitude,
            point_number_plants: point.point_number_plants,
            point_variety: point.point_variety,
            point_planting_year: point.point_planting_year,
            point_note: point.point_note,
            plagues_and_diseases: this.getScoutingMasterDataNameById(information.plagues_and_diseases),
            state: information.state,
            number: information.number,
            phenological_phase: this.getPhenologicalPhaseNameById(information.phenological_phase),
            comments: information.comments,
            photos: information.photos,
            point_name: point.point_name,
            point_variety_code: point.point_variety_code,
            fullName: monitoring.responsible_name,
          });
        }
      }
    } catch (e) {
      const message = this.$helpers.getFilteredErrorMessage(e);
      if (message.includes('default_') && !message.includes('404')) {
        this.$f7.dialog.alert(message.split('_').pop());
      } else if (!message.includes('404')) {
        this.$f7.dialog.alert(this.$t(message));
      }
    } finally {
      this.$f7.preloader.hide();
      this.loaded = true;
    }
  },
  methods: {
    // ---- OBTENCIÓN Y FORMATEO DE LOCATIONS ----
    async getLocationsAvailables() {
      for (const item of this.locationsTree) {
        this.setLocationTreeFormat(item);
        this.recursiveAddChildrens(item.children);
      }
    },
    recursiveAddChildrens(childrens) {
      for (let i = 0; i < childrens.length; i += 1) {
        this.setLocationTreeFormat(childrens[i]);
        this.recursiveAddChildrens(childrens[i].children);
      }
    },
    setLocationTreeFormat(item) {
      this.locationsAvailables.push({
        name: item.name,
        bd_id: item.bd_id,
        area: item.area,
        ID: item.ID,
        HEAD_ID: item.HEAD_ID,
        children: item.children,
      });
    },
    getLocationNameById(id) {
      for (const location of this.locationsAvailables) {
        if (location.bd_id === id) {
          return location.name;
        }
      }
      return '';
    },
    getPhenologicalPhaseNameById(id) {
      for (const phenology of this.phenologiesAvailables) {
        if (phenology.id === id) {
          return phenology.phase;
        }
      }
      return '';
    },
    getScoutingMasterDataNameById(id) {
      for (const scouting of this.scoutingMasterData) {
        if (scouting.id === id) {
          return scouting.name;
        }
      }
      return '';
    },
    // EXPORT EXCEL
    onExporting(e) {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet(`${this.keyName}`);
      exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
      }).then(() => {
        workbook.xlsx.writeBuffer()
          .then((buffer) => {
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `${this.keyName}.xlsx`);
          });
      });
      e.cancel = true;
    },
  },
};
</script>
