<template>
  <f7-page>
    <navbar
      :text="`${currentFarmCode} - ${$t('activitySelection_navbar_text')}`"
      :path-back="pathBack"
    />

    <div class="container">
      <f7-block>
        <div class="row no-gap">
          <!-- Each "cell" has col-[width in percents] class -->
          <div class="col-100">
            <div class="item-title-row title-block">
              <div class="item-title item-title-block text-color-black">
                {{ $t('ActivitySelection_txtSearchCodeTask') }}
              </div>
            </div>
          </div>
        </div>
        <f7-list
          inline-labels
          no-hairlines-md
        >
          <f7-list-input
            outline
            type="text"
            name="listInput"
            :placeholder="$t('ActivitySelection_navbarTask_text')"
            clear-button
            @input="changeInput($event.target.value)"
          />
        </f7-list>
        <f7-block>
          <f7-button
            name="btnContinueTaskCode"
            class="botonPrimary"
            col
            button
            button-large
            button-raised
            fill
            :disabled="(codeTask == '')"
            @click="startTask()"
          >
            {{ $t('NfcManually_navbarpresence_btnContinue') }}
          </f7-button>
        </f7-block>
      </f7-block>
      <f7-block>
        <div class="col-100">
          <div class="item-title-row title-block">
            <div class="item-title item-title-block text-color-black">
              {{ $t('ActivitySelection_txtSearchActivity') }}
            </div>
          </div>
        </div>
        <f7-searchbar
          id="searcherActivity"
          search-container="#search-activity-list"
          search-item="li"
          search-in=".item-title"
          :placeholder="$t('Search_placeholder')"
        />

        <f7-list class="searchbar-not-found">
          <f7-list-item title="Nothing found" />
        </f7-list>

        <f7-list
          id="search-activity-list"
          class="searchbar-found"
          virtual-list
          :virtual-list-params="vlParams2"
        >
          <ul>
            <f7-list-item
              v-for="(item, index) in vlData.items"
              :key="index"
              radio
              :title="item.name"
              :value="item.name"
              name="demo-radio"
              @click="changeStatebtnDisable(item)"
            />
          </ul>
        </f7-list>
      </f7-block>
      <f7-block>
        <f7-button
          name="btnContinue"
          class="botonPrimary"
          col
          button
          button-large
          button-raised
          fill
          :disabled="btnDisable"
          @click="goToTasks()"
        >
          {{ $t("Nfc_navbarpresence_btnContinue") }}
        </f7-button>
      </f7-block>
      <SheetNFC />
    </div>
  </f7-page>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import Api from '../services/Api';
import navbar from '../components/NavBar.vue';
import taskActivity from '../json/tasklist.json';
import SheetNFC from '../components/SheetNFC.vue';

export default {
  components: {
    navbar,
    SheetNFC,
  },
  props: {
    employees: { type: Array, default: () => [] },
    callbackSelected: { type: Function, default: () => {} },
  },
  data() {
    return {
      taskActivity,
      vlData: {
        items: [],
      },
      vlParams2: {
        items: this.employees,
        searchAll: this.searchAll,
        renderExternal: this.renderExternal,
        // height: this.$theme.ios ? 63 : (this.$theme.md ? 73 : 46)
      },
      btnDisable: true,
      pathBack: '/farmSelection/',
      codeTask: '',
      activities: [],
    };
  },
  computed: {
    ...mapState('AccessControl', ['currentFarmCode']),
  },
  async created() {
    this.$f7.preloader.show();
    try {
      const xhr = await Api.getActivities();
      const xhrFormat = JSON.parse(xhr.response);
      this.activities = xhrFormat;
      for (let i = 0; i < xhrFormat.length; i += 1) {
        this.employees.push({
          name: `(${xhrFormat[i].code}) ${xhrFormat[i].name}`,
          code: xhrFormat[i].code,
        });
      }
      this.vlData.items = this.employees;
      this.$f7.virtualList.get('#search-activity-list').replaceAllItems(this.vlData.items);
    } catch (error) {
      const message = this.$helpers.getFilteredErrorMessage(error);
      if (message.includes('default_') && !message.includes('404')) {
        this.$f7.dialog.alert(message.split('_').pop());
      } else if (!message.includes('404')) {
        this.$f7.dialog.alert(this.$t(message));
      }
    } finally {
      this.$f7.preloader.hide();
    }
  },
  methods: {
    changeInput(inputValue) {
      this.codeTask = inputValue;
    },
    getActivityName(activityId) {
      for (let i = 0; i < this.activities.length; i += 1) {
        if (this.activities[i].code === activityId) {
          return this.activities[i].name;
        }
      }
      return '';
    },
    async startTask() {
      this.$f7.preloader.show();
      try {
        const xhr = await Api.getTaskByCode(this.codeTask);
        const response = JSON.parse(xhr.response);
        // TODO: Pedir al back que devuelva el codigo y nombre de la actividad a la que pertenece la tarea
        // para no tener que recurrir al split y la búsqueda siguientes:
        const activityId = response.code.substr(0, 2);
        this.setActivityId(activityId);
        this.setActivity(this.getActivityName(activityId));
        this.setLocations(response.locations);
        this.setNewTask(response.id);
        this.setTaskName(response.name);
        this.setTaskCode(response.code);
        this.setTaskUnit(response.unit);
        this.setTaskPrice(response.price);
        this.$f7.views.main.router.navigate('/locationSelect/', { reloadCurrent: true });
      } catch (error) {
        // TODO: Se está cambiando el tipo de error por uno custom para poder aprovechar
        // el sheetNFC. Esto es de manera rapido y furioso será cambiado como debe
        // cuando Alejandro Lopez integre su sistema de popups
        const event = { type: 'ERROR', status: 469, error: `${this.$t('ActivitySelection_taskError')}: ${this.codeTask}` };
        this.setEvent(event);
        this.$f7.sheet.open('.demo-sheet');
      } finally {
        this.$f7.preloader.hide();
      }
    },
    searchAll(query, items) {
      const result = [];
      const lowerQuery = query.toLowerCase();
      items.forEach((item, i) => {
        if (item.name.toLowerCase().includes(lowerQuery)) {
          result.push(i);
        }
      });
      return result;
    },
    renderExternal(vl, vlData) {
      this.vlData = vlData;
    },
    changeStatebtnDisable(item) {
      this.btnDisable = false;
      this.setActivityId(item.code);
      this.setActivity(item.name);
    },
    goToTasks() {
      this.$f7.views.main.router.navigate('/tasks/list/', { reloadCurrent: true });
    },
    ...mapActions('worker', ['setEvent']),
    ...mapActions('TaskManager', [
      'setLocations',
      'setId',
      'setTaskName',
      'setWorkers',
      'setTaskUnit',
      'setTaskPrice',
      'setNewTask',
      'setLocationName',
      'setTaskCode',
      'setActivityId',
      'setActivity',
    ]),
  },
};
</script>
<style lang="scss" scoped>
// .block {
//   position: absolute;
//   bottom: 100px;
//   width: 100%;
// }
// .container {
//   background: #f4f5f7;
//   padding-top: 10px;
// }
// .searchbar-input-wrap {
//   border: 1px solid #dddddd;
//   box-sizing: border-box;
//   border-radius: 4px;
// }
.searchbar {
  height: 40px;
}
.searchbar-not-found {
  text-align: center;
}
// .list .item-subtitle {
//   font-weight: 500;
//   font-size: 20px;
//   line-height: 23px;
//   color: #999;
//   mix-blend-mode: normal;
//   opacity: 0.8;
// }
.searchbar-found {
  height: 338px !important;
  overflow: scroll;
  background: white;
}
.item-title {
  padding: 10px 5px;
  margin-right: 10px;
  font-size: 18px;
  letter-spacing: -0.04em;
}
</style>
