<template>
  <div
    v-if="loaded"
    class="content-devx content-dx-costs-database"
  >
    <CalendarInitEndDate :key-name="keyName" />
    <DxDataGrid
      id="costsGrid"
      :data-source="costsFormated"
      :column-min-width="70"
      :column-auto-width="true"
      :allow-column-reordering="true"
      :show-borders="true"
      :show-column-lines="true"
      :show-row-lines="true"
      :row-alternation-enabled="true"
      @exporting="onExporting"
    >
      <!-- Dejo comentado el método y el toolbar-preparing por si
    mas adelante quieren volver a crear costes.
    @toolbar-preparing="onToolbarPreparing($event)" -->

      <DxStateStoring
        :enabled="true"
        type="localStorage"
        storage-key="DxCostsDatabase"
      />
      <DxExport
        :enabled="true"
      />
      <DxHeaderFilter :visible="true" />
      <DxPaging :page-size="18" />
      <DxSearchPanel
        :visible="true"
        :width="200"
        :placeholder="$t('Search_placeholder')"
      />
      <DxColumn
        v-if="$helpers.havePermission(getPermissions.company_update_master_data)"
        type="buttons"
        data-field="Actions"
      >
        <DxButton
          icon="edit"
          :hint="$t('DxDailyPlanning_edit_hint')"
          :on-click="editCost"
          alignment="right"
        />
        <DxButton
          icon="trash"
          :hint="$t('DxDailyPlanning_edit_hint')"
          :on-click="deleteCost"
          alignment="right"
        />
      </DxColumn>
      <DxColumn
        data-field="name"
        name="name"
        :caption="$t('DxCostsDatabase_column_name')"
        :allow-header-filtering="false"
        alignment="left"
      />
      <DxColumn
        data-field="code"
        name="code"
        :caption="$t('DxCostsDatabase_column_code')"
        :allow-header-filtering="true"
        alignment="left"
      />
      <DxColumn
        data-field="unit"
        name="unit"
        :caption="$t('DxCostsDatabase_column_unit')"
        :allow-header-filtering="true"
        alignment="left"
      />
      <DxColumn
        data-field="currency"
        name="currency"
        :caption="$t('DxCostsDatabase_column_currency')"
        :allow-header-filtering="true"
        alignment="left"
      />
      <DxColumn
        data-field="price"
        name="price"
        :caption="$t('DxCostsDatabase_column_price')"
        :allow-header-filtering="false"
        alignment="right"
      />
    </DxDataGrid>
  </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex';
import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxSearchPanel,
  DxButton,
  DxExport,
  DxHeaderFilter,
  DxStateStoring,
} from 'devextreme-vue/data-grid';
import { exportDataGrid } from 'devextreme/excel_exporter';
import ExcelJS from 'exceljs';
import saveAs from 'file-saver';
import CalendarInitEndDate from '../CalendarInitEndDate.vue';
import Api from '../../services/Api';

export default {
  name: 'DxPrescriptionManager',
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxSearchPanel,
    DxButton,
    DxExport,
    DxHeaderFilter,
    DxStateStoring,
    CalendarInitEndDate,
  },
  data() {
    return {
      loaded: false,
      costsFormated: [],
      totalCount: 0,
      keyName: 'costsDatabase',
    };
  },
  computed: {
    ...mapGetters('authentication', ['getPermissions']),
  },
  async beforeMount() {
    this.$f7.preloader.show();
    try {
      const xhr = await Api.getAllCosts();
      const costsAvailables = JSON.parse(xhr.response).data;
      for (const cost of costsAvailables) {
        this.costsFormated.push({
          code: cost.code,
          currency: cost.currency,
          id: cost.id,
          name: cost.name,
          price: `${parseFloat(cost.price).toFixed(2)} ${cost.currency_code}`,
          currency_code: cost.currency_code,
          unit: cost.unit,
        });
      }
    } catch (e) {
      const message = this.$helpers.getFilteredErrorMessage(e);
      if (message.includes('default_') && !message.includes('404')) {
        this.$f7.dialog.alert(message.split('_').pop());
      } else if (!message.includes('404')) {
        this.$f7.dialog.alert(this.$t(message));
      }
    } finally {
      this.$f7.preloader.hide();
      this.loaded = true;
    }
  },
  methods: {
    createCost() {
      this.$f7.views.main.router.navigate('/createCosts/', { reloadCurrent: true });
      this.setCostFormated(this.costsFormated);
    },
    editCost(rowSelected) {
      this.setActualCost(rowSelected.row.data);
      this.$f7.views.main.router.navigate('/editCostS/', { reloadCurrent: true });
    },
    deleteCost(rowSelected) {
      const costId = rowSelected.row.data.id;
      const f7 = this.$f7;
      this.$f7.dialog.create({
        title: this.$t('DxDailyWorkOrders_dialogDelete_title'),
        text: this.$t('DeleteCost_dialogDelete_text'),
        buttons: [
          {
            text: this.$t('DxDailyWorkOrders_dialogDelete_cancel'),
          },
          {
            text: this.$t('DxDailyWorkOrders_dialogDelete_confirm'),
            async onClick() {
              try {
                f7.preloader.show();
                await Api.deleteCost(costId);
                f7.views.main.router.navigate('/costsDatabase/', { reloadCurrent: true });
              } catch (e) {
                f7.dialog.alert(e);
              } finally {
                f7.preloader.hide();
              }
            },
          },
        ],
      }).open();
      return false;
    },
    // CREACION BOTON CREAR COSTES
    // onToolbarPreparing(e) {
    //   if (!this.$helpers.havePermission(this.getPermissions.company_update_master_data)) return;
    //   e.toolbarOptions.items.unshift({
    //     location: 'before',
    //     widget: 'dxButton',
    //     options: {
    //       icon: 'add',
    //       elementAttr: {
    //         class: 'add-task',
    //       },
    //       text: this.$t('DxCost_button_text'),
    //       onClick: () => {
    //         this.createCost();
    //       },
    //     },
    //   });
    // },
    // EXPORT EXCEL
    onExporting(e) {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('CostDatabase');
      exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
      }).then(() => {
        workbook.xlsx.writeBuffer()
          .then((buffer) => {
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'CostDatabase.xlsx');
          });
      });
      e.cancel = true;
    },
    ...mapActions('costManager', ['setCostFormated', 'setActualCost']),
  },
};
</script>
