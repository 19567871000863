<template>
  <f7-page>
    <navbar
      :new-icon-enable="true"
      :text="`${currentFarmCode} - ${$t('Nfc_navbarTask_checkout')}`"
      :path-back="`/${pathBack}/`"
    />
    <f7-block
      class="header"
      align="center"
    >
      <img
        class="avatar"
        width="60"
        height="60"
        :src="photoSrc()"
        alt="ImageCheckout"
      >
      <label></br> {{ selectedWorker.name }} {{ selectedWorker.surname }} </label>
    </f7-block>
    <f7-block>
      <div class="item-content">
        <f7-list no-hairlines-md>
          <div class="item-label smart-title">
            {{ $t('checkOut_Task') }}
          </div>
          <f7-list-input
            v-model="task_name"
            name="Task"
            outline
            floating-label
            type="text"
            readonly
          />
        </f7-list>
        <div class="item-label smart-title">
          {{ $t('checkOut_Productivity') }}
        </div>
        <f7-list
          no-hairlines-md
          style="margin: 0px !important;"
        >
          <f7-list-input
            name="Productivity"
            outline
            floating-label
            type="text"
            :placeholder="$t('checkOut_Productivity_placeholder')"
            class="input-field"
            @input="changeInput($event.target.value)"
          />
          <f7-list-item
            :title="`unit`"
            smart-select
            :smart-select-params="{openIn: 'popover'}"
            class="color"
          >
            <select
              name="unit"
              @change="selectUnit"
            >
              <option
                v-for="(item, index) in units"
                :key="index"
                :value="item.name"
              >
                {{ item.name }}
              </option>
            </select>
          </f7-list-item>
        </f7-list>
      </div>
    </f7-block>
    <f7-block-title center>
      Time: {{ formattedHour(workers[0].init_date) }}
    </f7-block-title>
    <f7-block>
      <f7-button
        name="btnContinue"
        class="botonPrimary"
        col
        button
        button-large
        button-raised
        fill
        :disabled="btnDisable"
        @click="setFullNameFilter"
      >
        {{ $t("Nfc_navbarpresence_btnContinue") }}
      </f7-button>
    </f7-block>
  </f7-page>
</template>

<script>
// import ImageCheckOut from "../../static/img/imgCheckOut.png";
import moment from 'moment';
import { mapActions, mapState } from 'vuex';
import navbar from '../../components/NavBar.vue';
import img from '../../static/img/imgCheckOut.png';
import Api from '../../services/Api';

export default {
  components: {
    navbar,
  },
  props: {
    pathBack: { type: String, default: '/nfc/' },
  },
  data() {
    return {
      btnDisable: true,
      fullNameFilter: 0,
      selectUnits: '',
      units: [
        { name: 'man/day' },
        { name: 'ha' },
        { name: 'm' },
        { name: 'plants' },
        { name: 'hours' },
        { name: 'm2' },
        { name: 'm3' },
        { name: 'kg' },
      ],
    };
  },
  computed: {
    ...mapState('TaskManager', ['id', 'workers', 'task_name']),
    ...mapState('worker', ['type', 'code', 'selectedWorker']),
    ...mapState('AccessControl', ['currentFarmCode']),
  },
  methods: {
    selectUnit() {
      this.$f7.smartSelect.get().close();
    },
    async setFullNameFilter() {
      this.$f7.preloader.show();
      this.showManualList = true;
      this.selectUnits = this.$f7.smartSelect.get().getValue();
      this.setTaskUnit(this.fullNameFilter);
      try {
        const xhr = await Api.checkOut(this.id, this.type, this.code, this.fullNameFilter, this.selectUnits);
        const response = JSON.parse(xhr.response);
        this.setWorkers(response.workers);
        this.setEvent({ type: 'OUT' });
        this.changeUpSheet();
        this.$f7.views.main.router.navigate('/nfc/', { reloadCurrent: true });
      } catch (error) {
        const message = this.$helpers.getFilteredErrorMessage(error);
        if (message.includes('default_') && !message.includes('404')) {
          this.$f7.dialog.alert(message.split('_').pop());
        } else if (!message.includes('404')) {
          this.$f7.dialog.alert(this.$t(message));
        }
      } finally {
        this.$f7.preloader.hide();
      }
    },
    formattedHour(dt) {
      const ms = moment().diff(moment(dt));
      const d = moment.duration(ms);
      return `${d.hours()}:${d.minutes()}`;
    },

    changeInput(inputValue) {
      this.fullNameFilter = inputValue;
      this.btnDisable = (this.fullNameFilter === '');
    },
    photoSrc() {
      return img;
    },
    ...mapActions('TaskManager', ['setTaskUnit', 'setWorkers']),
    ...mapActions('worker', ['changeUpSheet', 'setEvent', 'changeUpSheet']),
  },
};
</script>
<style scoped>
div.input.input-with-value{
  padding-top: 4%;
  padding-left: 8%;
}
div.item-label.smart-title{
  margin-left: 5%;
  margin-right: 5%;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 19px;
  color: #000000;
  height: 19px;
  padding-bottom: 3%;
}
div.input.input-with-value{
  height: 35px;
  margin-left: 5%;
  margin-right: 5%;
  background: #F7F8FA;
  border: 1px solid #D4DCE1;
  font-size: 18px;
  border-radius: 8px;
}
div.block-title{
  line-height: 34px;
  text-align: center;
  font-size: 30px;
  color: darkslateblue;
}
.color{
  background: #F7F8FA;
  border: 1px solid #D4DCE1;
  border-radius: 8px;
  height: 59px;
  width: 50%;
  left: 16px;
}
.list.item-input-outline.item-floating-label {
  background: #f7f8fa !important;
  width: 70% !important;
}

.header {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
}

</style>
