<template>
  <div class="content-devx content-dx-daily-planning main-dx-products">
    <DxDataGrid
      id="dxProductsGrid"
      ref="dxProductsGrid"
      :data-source="productsFormated"
      :column-min-width="70"
      :allow-column-reordering="true"
      :show-borders="true"
      :show-column-lines="true"
      :column-auto-width="true"
      :show-row-lines="false"
      :row-alternation-enabled="true"
      @row-inserted="updatedProductsVuex"
      @row-updated="updatedProductsVuex"
      @row-removed="updatedProductsVuex"
      @exporting="onExporting"
      @editor-preparing="onEditorPreparing"
    >
      <DxExport
        :enabled="false"
      />
      <DxPaging :page-size="18" />
      <DxEditing
        :allow-updating="true"
        :allow-deleting="true"
        :allow-adding="true"
        mode="row"
      />
      <DxSearchPanel
        :visible="true"
        :width="200"
        :placeholder="$t('Search_placeholder')"
      />
      <DxColumn
        data-field="productFamily"
        name="productFamily"
        alignment="left"
        :caption="$t('DxProducts_dxColumn_productFamily')"
        :set-cell-value="actualProductFamily"
      >
        <DxLookup
          :data-source="productFamily"
        />
        <DxRequiredRule />
      </DxColumn>
      <DxColumn
        data-field="productGroup"
        name="productGroup"
        alignment="left"
        :caption="$t('DxProducts_dxColumn_productGroup')"
        :set-cell-value="actualProductGroup"
      >
        <DxLookup
          :data-source="filterProductGroup"
          display-expr="id"
          value-expr="id"
        />
        <DxRequiredRule />
      </DxColumn>
      <DxColumn
        data-field="activeIngredients"
        name="activeIngredients"
        alignment="left"
        :caption="$t('DxProducts_dxColumn_activeIngredients')"
        :set-cell-value="actualActiveIngredient"
      >
        <DxLookup
          :data-source="filterActiveIngredients"
          display-expr="id"
          value-expr="id"
        />
      </DxColumn>
      <DxColumn
        data-field="productName"
        name="productName"
        alignment="left"
        :caption="$t('DxProducts_dxColumn_productName')"
        :set-cell-value="actualActiveProduct"
      >
        <DxLookup
          :data-source="filterProduct"
          display-expr="name"
          value-expr="id"
        />
        <DxRequiredRule />
      </DxColumn>
      <DxColumn
        data-field="dosis"
        name="dosis"
        data-type="number"
        alignment="right"
        :caption="$t('DxProducts_dxColumn_dosis')"
        :format="dosisColumnFormat"
        :editor-options="dosisEditorFormat"
      >
        <DxRequiredRule
          :trim="false"
          :message="$t('DxProduts_rule_dosis_required')"
        />
        <DxRangeRule
          min="0"
          :message="$t('DxProduts_rule_range')"
        />
      </DxColumn>
      <DxColumn
        data-field="dosisUt"
        name="dosisUt"
        alignment="left"
        :caption="$t('DxProducts_dxColumn_dosisUt')"
      >
        <DxLookup
          :data-source="listDosisUT"
        />
        <DxRequiredRule />
      </DxColumn>
    </DxDataGrid>

    <DxDataGrid
      id="dxSolutionGrid"
      ref="dxSolutionGrid"
      :data-source="solutionsFormated"
      :column-min-width="70"
      :allow-column-reordering="true"
      :show-borders="true"
      :show-column-lines="true"
      :column-auto-width="true"
      :show-row-lines="false"
      :row-alternation-enabled="true"
      style="margin-top: 20px;"
      @row-inserted="updatedSolutionVuex"
      @row-updated="updatedSolutionVuex"
      @row-removed="updatedSolutionVuex"
    >
      <DxExport
        :enabled="false"
      />
      <DxPaging :page-size="18" />
      <DxEditing
        :allow-updating="true"
        :allow-deleting="true"
        :allow-adding="allowAddTotalSolution"
        mode="row"
      />
      <DxSearchPanel
        :visible="true"
        :width="200"
        :placeholder="$t('Search_placeholder')"
      />
      <DxColumn
        data-field="solution"
        name="solution"
        data-type="number"
        alignment="right"
        :caption="$t('DxProducts_dxColumn_solution_total')"
        :format="dosisColumnFormat"
        :editor-options="dosisEditorFormat"
      >
        <DxRequiredRule />
        <DxRangeRule
          min="0"
          :message="$t('DxProduts_rule_range')"
        />
      </DxColumn>
      <DxColumn
        data-field="solutionUt"
        name="solutionUt"
        alignment="left"
        data-type="string"
        :caption="$t('DxProducts_dxColumn_solutionUt')"
        :allow-editing="false"
      />
      <DxColumn
        data-field="dilution_factor"
        name="dilution_factor"
        data-type="number"
        alignment="right"
        :allow-editing="false"
        :caption="$t('DxProducts_dxColumn_dilutionFactor')"
        :format="dosisColumnFormat"
        :editor-options="dosisEditorFormat"
        :calculate-cell-value="calculateDilutionFactor"
      />
    </DxDataGrid>
  </div>
</template>
<script>

import { mapState, mapActions } from 'vuex';
import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxEditing,
  DxSearchPanel,
  DxExport,
  DxRequiredRule,
  DxLookup,
} from 'devextreme-vue/data-grid';
import {
  DxRangeRule,
} from 'devextreme-vue/validator';

import * as overlay from 'devextreme/ui/overlay';
import { exportDataGrid } from 'devextreme/excel_exporter';
import ExcelJS from 'exceljs';
import saveAs from 'file-saver';

export default {
  name: 'DxProducts',
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxEditing,
    DxSearchPanel,
    DxExport,
    DxRequiredRule,
    DxLookup,
    DxRangeRule,
  },
  data() {
    return {
      actualProductFamily(rowData, value) {
        rowData.productGroup = null;
        rowData.activeIngredients = null;
        rowData.productName = null;
        this.defaultSetCellValue(rowData, value);
      },
      actualProductGroup(rowData, value) {
        rowData.activeIngredients = null;
        rowData.productName = null;
        this.defaultSetCellValue(rowData, value);
      },
      actualActiveIngredient(rowData, value) {
        rowData.productName = null;
        this.defaultSetCellValue(rowData, value);
      },
      actualActiveProduct(rowData, value) {
        rowData.productName = null;
        this.defaultSetCellValue(rowData, value);
      },
      dosisColumnFormat: { type: 'fixedPoint', precision: 2 },
      dosisEditorFormat: { format: { type: 'fixedPoint', precision: 2 } },
      productsFormated: [],
      solutionsFormated: [],
      productFamily: ['PHITOSANITARY', 'FERTILIZERS', 'SUBSTRATE'],
      isEditable: true,
    };
  },
  computed: {
    allowAddTotalSolution() {
      return this.solutionsFormated.length === 0;
    },
    ...mapState('Prescription', ['prescription', 'listActiveIngredients', 'listProducts', 'listDosisUT', 'listSolutionUT', 'listTypes']),
  },
  beforeMount() {
    overlay.baseZIndex(9999);
    for (let i = 0; i < this.prescription.products.length; i += 1) {
      this.productsFormated.push({
        productFamily: this.getTypePrescription(this.prescription.products[i].product.properties),
        productGroup: this.getProductGroup(this.prescription.products[i].product.properties),
        activeIngredients: this.prescription.products[i].activeIngredients,
        productName: this.prescription.products[i].productName,
        dosis: this.prescription.products[i].dosis,
        dosisUt: this.prescription.products[i].dosisUt,
        solution: this.prescription.products[i].solution,
        solutionUt: this.prescription.products[i].solutionUt,
        dilution_factor: this.prescription.products[i].dilution_factor,
      });
    }
    const filterProduct = this.prescription.products.filter((element) => element.solution !== '0.00');
    if (filterProduct.length > 0) {
      this.solutionsFormated.push({
        solution: filterProduct[0].solution,
        dilution_factor: parseFloat(filterProduct[0].solution) / 100,
        solutionUt: filterProduct[0].solutionUt,
      });
    }
  },
  methods: {
    isKilogrameOrliter(rowData) {
      return typeof (rowData.dosisUt) === 'undefined' || rowData.dosisUt.toLowerCase().includes('kg/') || rowData.dosisUt.toLowerCase().includes('l/');
    },
    calculateDilutionFactor(rowData) {
      const value = typeof (rowData.solution) === 'undefined' ? '' : `${rowData.solution / 100}`;
      return value;
    },
    calculateUnit(rowData) {
      const value = (this.isKilogrameOrliter(rowData)) ? '' : 'L/Ha';
      return value;
    },
    setCellValue(rowData, value) {
      rowData.caution = value;
    },
    filterProductGroup(e) {
      try {
        if (e.values !== undefined) {
          // Pintamos los grupos de producto filtrados por los tipos de prescripcion
          const listaFiltrado = this.listTypes.filter(
            (elemento) => elemento.subfamily === e.values[0],
          );
          return listaFiltrado;
        }
        // Permite mostrar los datos de cada row sin darle a la opcion de editar row
        const listaFiltrado = this.listTypes.filter(
          (type) => this.prescription.products.filter(
            (prescripcion) => prescripcion.productGroup === type.id,
          ),
        );
        return listaFiltrado;
      } catch (error) {
        return [{ id: '', name: '' }];
      }
    },
    filterActiveIngredients(e) {
      try {
        if (e.values !== undefined) {
          // Pintamos los ingredientes activos filtrados por los product group
          const listaFiltrado = this.listActiveIngredients.filter(
            (elemento) => elemento.typePrescription_id === e.values[1],
          );
          return listaFiltrado;
        }
        // Pintamos los ingredientes activos una vez guardados, comparandolos con product group
        const listaFiltrado = this.listActiveIngredients.filter(
          (activeIngredient) => this.prescription.products.filter(
            (prescripcion) => prescripcion.activeIngredients === activeIngredient.id,
          ),
        );
        return listaFiltrado;
      } catch (error) {
        return [{ id: '', name: '' }];
      }
    },
    filterProduct(e) {
      try {
        if (e.values !== undefined) {
          let dataPosition = 0;
          if (e.values[0] === 'FERTILIZERS') {
            dataPosition = 1;
          } else if (e.values[0] === 'PHITOSANITARY') {
            dataPosition = 2;
          } else {
            const listaFiltrado = this.listProducts.filter(
              (elemento) => elemento.family === e.values[0]
              && elemento.activeIngredients_id === e.values[1],
            );
            return listaFiltrado;
          }
          // Pintamos los productos filtrados por los ingredientes activos
          const listaFiltrado = this.listProducts.filter(
            (elemento) => elemento.activeIngredients_id === e.values[dataPosition],
          );
          return listaFiltrado;
        }
        // Pintamos los productos una vez guardados, comparandolos con la prescripcion
        const listaFiltrado = this.listProducts.filter(
          (producto) => this.prescription.products.filter(
            (prescripcion) => prescripcion.productName === producto.name,
          ),
        );
        return listaFiltrado;
      } catch (error) {
        return [{ id: '', name: '' }];
      }
    },
    getProductGroup(properties) {
      for (const property of properties) {
        if (property.key === 'subfamily') {
          return property.value;
        }
      }
      return '';
    },
    getTypePrescription(properties) {
      for (const property of properties) {
        if (property.key === 'family') {
          return property.value;
        }
      }
      return '';
    },
    updatedProductsVuex(e) {
      this.products = this.productsFormated;
      for (let i = 0; i < this.products.length; i += 1) {
        const item = this.products[i];
        let solutionSelected = 0;
        if (this.solutionsFormated.length > 0) {
          solutionSelected = this.solutionsFormated[0].solution;
        }
        if (item.dosisUt.includes('Ha') && typeof (e.data) === 'undefined') {
          this.products[i].solution = 0;
          this.products[i].solutionUt = '';
          this.products[i].dilution_factor = 0.00;
          this.products[i].solutionUt = 'L/Ha';
        } else if (typeof (e.newData) === 'undefined') {
          this.products[i].solution = solutionSelected;
          this.products[i].solutionUt = 'L/Ha';
          this.products[i].dilution_factor = parseFloat(solutionSelected) / 100;
        } else {
          this.products[i].solution = solutionSelected;
          this.products[i].solutionUt = 'L/Ha';
          this.products[i].dilution_factor = parseFloat(solutionSelected) / 100;
        }
      }
      this.setProducts(this.products);
    },
    // SET DATA SOURCE TO PRODUCTS VUEX
    updatedSolutionVuex(e) {
      this.solutions = this.solutionsFormated;
      this.solutions[0].solution = e.data.solution;
      this.solutions[0].solutionUt = 'L/Ha';
      this.solutions[0].dilution_factor = parseFloat(e.data.solution) / 100;

      this.products = this.productsFormated;
      for (let i = 0; i < this.products.length; i += 1) {
        const item = this.products[i];
        if (!item.dosisUt.includes('Ha')) {
          this.products[i].solution = 0;
          this.products[i].dilution_factor = 0.00;
          this.products[i].solutionUt = 'L/Ha';
        } else {
          this.products[i].solution = e.data.solution;
          this.products[i].solutionUt = 'L/Ha';
          this.products[i].dilution_factor = parseFloat(e.data.solution) / 100;
        }
      }
      this.setProducts(this.products);
    },
    onEditorPreparing(e) {
      if (e.dataField === 'activeIngredients' && typeof (e.row.data.productFamily) !== 'undefined' && e.row.data.productFamily !== 'PHITOSANITARY') {
        e.editorOptions.readOnly = true;
      }
    },
    // EXPORT EXCEL
    onExporting(e) {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('PrescriptionProducts');
      exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
      }).then(() => {
        workbook.xlsx.writeBuffer()
          .then((buffer) => {
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'PrescriptionProducts.xlsx');
          });
      });
      e.cancel = true;
    },
    ...mapActions('Prescription', ['setProducts']),
  },
};
</script>
