<template>
  <div>
    <div
      class="content-devx content-dx-daily-planning no-shadow no-margin"
    >
      <DxDataGrid
        id="toolsTable"
        ref="toolsTable"
        :data-source="selectedToolsFormated"
        :column-min-width="70"
        :allow-column-reordering="true"
        :show-borders="true"
        :show-column-lines="true"
        :show-row-lines="false"
        :row-alternation-enabled="true"
      >
        <DxStateStoring
          :enabled="true"
          type="localStorage"
          storage-key="DxViewWorkOrderToolsTable"
        />
        <DxExport
          :enabled="true"
        />
        <DxPaging :page-size="18" />
        <DxSearchPanel
          :visible="true"
          :width="200"
          :placeholder="$t('Search_placeholder')"
        />
        <DxColumn
          data-field="itemName"
          name="itemName"
          :caption="$t('DxTools_dxColumn_TypeTool')"
        />
        <DxColumn
          data-field="ToolName"
          name="ToolName"
          alignment="left"
          :caption="$t('DxTools_dxColumn_TypeTool')"
        />
        <DxColumn
          data-field="fuelConsumption"
          alignment="right"
          name="fuelConsumption"
          data-type="number"
          :caption="$t('MonthlyComponent_consumption_caption')"
        />
        <DxColumn
          data-field="fuelType"
          name="fuel_type"
          alignment="left"
          :caption="$t('MonthlyComponent_typeconsumption_caption')"
        />
      </DxDataGrid>
    </div>
  </div>
</template>
<script>

import { mapState, mapActions } from 'vuex';
import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxEditing,
  DxSearchPanel,
  DxExport,
  DxRequiredRule,
  DxLookup,
  DxStateStoring,
} from 'devextreme-vue/data-grid';
import * as overlay from 'devextreme/ui/overlay';

export default {
  name: 'DxProducts',
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxEditing,
    DxSearchPanel,
    DxExport,
    DxRequiredRule,
    DxLookup,
    DxStateStoring,
  },
  props: {
    selectTool: { type: Array, default: () => [] },
    planning: { type: Object, default: null },
    allowUpdating: { type: Boolean, default: true },
    allowDeleting: { type: Boolean, default: true },
    allowAdding: { type: Boolean, default: true },
    isPostWork: { type: Boolean, default: true },
  },
  data() {
    return {
      selectedToolsFormated: [],
    };
  },
  computed: {
    ...mapState('PlanningManager', ['tools']),
  },
  beforeMount() {
    overlay.baseZIndex(9999);
    if (this.planning == null) {
      this.setTools([]);
    } else {
      this.actualItems = this.planning.Tools;
      this.setTools(this.actualItems);
    }
    this.getSelectedToolFormated();
  },
  destroyed() {
    this.setTools([]);
  },
  methods: {
    getSelectedToolFormated() {
      for (const tool of this.selectTool) {
        this.selectedToolsFormated.push({
          count: tool.count,
          ToolName: tool.data.description,
          toolId: tool.data.id,
          itemName: tool.data.item.subfamily,
          fuelConsumption: tool.fuel_consumption,
          fuelType: tool.fuel_type,
        });
      }
      return '';
    },
    // SET DATA SOURCE TO PRODUCTS VUEX
    onChangeRow() {
      const arrayItems = this.$refs.toolsTable.instance.getDataSource().items();
      const newItems = this.tools;
      newItems.values = [];
      for (let i = 0; i < arrayItems.length; i += 1) {
        newItems.values.push({ item: arrayItems[i].item, count: arrayItems[i].count });
      }
      this.setTools(newItems.values);
    },
    onInsertedRow(e) {
      const newItems = this.tools;
      this.setTools(newItems.values);
    },
    ...mapActions('PlanningManager', ['setTools']),
  },
};
</script>
