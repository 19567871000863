<template>
  <div class="content__giseffort">
    <div class="label">
      <span v-text="$t('GisMap_title')" />
    </div>
    <div>
      <iframe
        id="mapGIS"
        title="GIS MAP"
        :src="url"
        style="min-height: 594px;"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Api from '../../services/Api';

export default {
  name: 'GisReporting',
  props: {
    locationSelected: {
      type: Array,
      default: () => [],
    },
    dateInit: {
      type: String,
      default: '',
    },
    dateEnd: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      efforts: {},
      token: encodeURIComponent(`${Api.getToken()}`),
      baseUrl: encodeURIComponent(`${Api.getBaseUrl()}`),
      agriFarm: encodeURIComponent(`${Api.getAgriFarm()}`),
      event: 'updateGIS',
    };
  },
  computed: {
    init() {
      return encodeURIComponent(this.dateInit);
    },
    end() {
      return encodeURIComponent(this.dateEnd);
    },
    locations() {
      return encodeURIComponent(JSON.stringify(this.locationSelected));
    },
    url() {
      return `./static/ferrero-gis/gis/level_map.html?baseurl=${this.baseUrl}&agrifarm=${this.agriFarm}&init=${this.init}&end=${this.end}&token=${this.token}&locations=${this.locations}`;
    },
    ...mapState('Reporting', ['selectedFarmList', 'currentLocation', 'currentLocationName']),
  },
};
</script>

<style lang="scss">
#mapGIS{
  width:100%;
  height:100%;
  border: none;
  margin-bottom: 15px;
}
.content__giseffort .label {
  font-size: 11px;
  line-height: 1.2;
  padding: 4px;
  text-transform: uppercase;
  font-weight: 600;
  color: var(--fr-chocolat);
  margin: 15px 0 3px;
}
</style>
