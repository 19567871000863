<template>
  <div
    v-if="loaded"
    class="main-bareRootTable"
  >
    <div
      class="content-devx"
    >
      <DxDataGrid
        id="bareRootTable"
        ref="bareRootTable"
        :data-source="bareRootData"
        :column-min-width="70"
        :allow-column-reordering="true"
        :show-borders="true"
        :show-column-lines="true"
        :show-row-lines="false"
        :row-alternation-enabled="true"
        :column-auto-width="true"
      >
        <DxSelection
          mode="single"
        />
        <DxStateStoring
          :enabled="true"
          type="localStorage"
          :storage-key="'DxBareRootTable'"
        />
        <DxHeaderFilter :visible="true" />
        <DxExport
          :enabled="true"
        />
        <DxPaging :page-size="18" />
        <DxSearchPanel
          :visible="true"
          :width="200"
          :placeholder="$t('Search_placeholder')"
        />
        <DxColumn
          type="buttons"
          data-field=""
          :allow-header-filtering="false"
          cell-template="buttonsTemplate"
        />
        <DxColumn
          data-field="creationDate"
          name="creationDate"
          alignment="left"
          :allow-header-filtering="false"
          :caption="$t('DxBareRootTable_dxColumn_CreationDate')"
        />
        <DxColumn
          data-field="name"
          name="name"
          alignment="left"
          :allow-header-filtering="false"
          :caption="$t('DxBareRootTable_dxColumn_Name')"
        />
        <DxColumn
          data-field="origin"
          name="origin"
          alignment="left"
          :allow-header-filtering="false"
          :caption="$t('DxBareRootTable_dxColumn_Origin')"
        />
        <DxColumn
          data-field="plant_year"
          name="plant_year"
          alignment="left"
          :allow-header-filtering="false"
          :caption="$t('DxBareRootTable_dxColumn_PlantYear')"
        />
        <DxColumn
          data-field="variety"
          name="variety"
          alignment="left"
          :allow-header-filtering="false"
          :caption="$t('DxBareRootTable_dxColumn_Variety')"
        />
        <DxColumn
          data-field="category"
          name="category"
          alignment="left"
          :allow-header-filtering="false"
          :caption="$t('DxBareRootTable_dxColumn_Type')"
        />
        <DxColumn
          data-field="class_type"
          name="class_type"
          alignment="left"
          :allow-header-filtering="false"
          :caption="$t('DxBareRootTable_dxColumn_Category')"
        />
        <DxColumn
          data-field="numPlants"
          name="numPlants"
          alignment="left"
          :allow-header-filtering="false"
          :caption="$t('DxBareRootTable_dxColumn_NumPlants')"
        />
        <DxColumn
          data-field="binCode"
          name="binCode"
          alignment="left"
          :allow-header-filtering="false"
          :caption="$t('DxBareRootTable_dxColumn_BinCode')"
        />
        <template #buttonsTemplate="{ data: bareRootData }">
          <!-- CREADO -->
          <div
            v-if="!bareRootData.data.binCode"
            class="templateButtons"
          >
            <!-- editar -->
            <button
              class="dx-icon-edit boton-editar"
              @click="editBareRoot(bareRootData.data)"
            />
            <!-- borrar -->
            <button
              class="dx-icon-trash boton-borrar"
              @click="deleteBareRoot(bareRootData.data.id)"
            />
          </div>
        </template>
      </DxDataGrid>
    </div>
  </div>
</template>
<script>

import { mapActions, mapGetters } from 'vuex';
import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxSearchPanel,
  DxExport,
  DxStateStoring,
  DxHeaderFilter,
  DxSelection,
} from 'devextreme-vue/data-grid';
import * as overlay from 'devextreme/ui/overlay';
import Api from '../../services/Api';

export default {
  name: 'DxBareRootTable',
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxSearchPanel,
    DxExport,
    DxStateStoring,
    DxHeaderFilter,
    DxSelection,
  },
  props: {
    isReclassifying: { type: Boolean, default: false },
  },
  data() {
    return {
      dataGrid: this.$refs.bareRootTable,
      bareRootData: [],
      loaded: false,
      searchTimeoutOption: 200,
      minSearchLengthOption: 0,
      showDataBeforeSearchOption: false,
    };
  },
  computed: {
    ...mapGetters('greenhouse', [
      'getVarietyNameByCode',
      'getCategoryNameByCode',
      'getLocationNameByLocationId',
      'getClassificationNameByCode',
    ]),
  },
  async beforeMount() {
    overlay.baseZIndex(9999);
    this.$f7.preloader.show();
    try {
      await this.fetchBinsContentFamilies();
      await this.fetchLocationTree();
      const xhr = await Api.getBareRoots();
      const bareRoots = JSON.parse(xhr.response);
      for (let i = 0; i < bareRoots.data.length; i += 1) {
        this.bareRootData.push({
          id: bareRoots.data[i].id,
          creationDate: bareRoots.data[i].creation,
          name: bareRoots.data[i].name,
          origin: this.getLocationNameByLocationId(bareRoots.data[i].location),
          farm: this.getLocationNameByLocationId(bareRoots.data[i].farm),
          location: bareRoots.data[i].location,
          plant_year: bareRoots.data[i].plant_year,
          variety: this.getVarietyNameByCode(bareRoots.data[i].variety),
          varietyCode: bareRoots.data[i].variety,
          category: this.getCategoryNameByCode(bareRoots.data[i].category),
          categoryCode: bareRoots.data[i].category,
          class_type: this.getClassificationNameByCode(bareRoots.data[i].class_type),
          numPlants: bareRoots.data[i].count,
          binCode: bareRoots.data[i].bin_code,
          comments: bareRoots.data[i].comments,
        });
      }
    } catch (e) {
      const message = this.$helpers.getFilteredErrorMessage(e);
      if (message.includes('default_') && !message.includes('404')) {
        this.$f7.dialog.alert(message.split('_').pop());
      } else if (!message.includes('404')) {
        this.$f7.dialog.alert(this.$t(message));
      }
    } finally {
      this.$f7.preloader.hide();
      this.loaded = true;
    }
  },
  methods: {
    async deleteBareRoot(bdId) {
      try {
        const response = await this.fetchDeleteBareRoot(bdId);
        if (response.status === 204) {
          this.setActualMenuSelected('/bare-roots/');
          this.$f7.views.main.router.navigate('/bare-roots/', { reloadCurrent: true });
        } else {
          const message = this.$helpers.getFilteredErrorMessage(response);
          if (message.includes('default_') && !message.includes('404')) {
            this.$f7.dialog.alert(message.split('_').pop());
          } else if (!message.includes('404')) {
            this.$f7.dialog.alert(this.$t(message));
          }
        }
      } catch (e) {
        const message = this.$helpers.getFilteredErrorMessage(e);
        if (message.includes('default_') && !message.includes('404')) {
          this.$f7.dialog.alert(message.split('_').pop());
        } else if (!message.includes('404')) {
          this.$f7.dialog.alert(this.$t(message));
        }
      }
    },
    editBareRoot(bareRootData) {
      this.setBareRootToEdit(bareRootData);
      this.$f7.views.main.router.navigate('/bare-roots/edit/', { reloadCurrent: true });
    },
    ...mapActions('greenhouse', [
      'fetchBinsContentFamilies',
      'fetchLocationTree',
      'fetchDeleteBareRoot',
      'setBareRootToEdit',
    ]),
    ...mapActions('authentication', ['setActualMenuSelected']),
  },
};
</script>
<style lang="scss">
</style>
