var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('DxForm',{attrs:{"id":"selectLevelZero"}},[_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('DxSelectLevel0Location_farm'),"editor-options":{
      items: _vm.levelZeroLocations,
      searchEnabled: true,
      searchTimeout: _vm.searchTimeoutOption,
      minSearchLength: _vm.minSearchLengthOption,
      showDataBeforeSearch: _vm.showDataBeforeSearchOption,
      onValueChanged: _vm.onLevelZeroChange,
      value: _vm.selectedFarmToSend,
    },"editor-type":"dxSelectBox"}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('DxSelectLevel0Location_requiredRule_farm')}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }