export default {
  namespaced: true,
  state: {
    actualTaskBadget: {},
    badgedId: '',
    versionId: '',
    taskId: '',
    taskBadgetEditable: false,
  },

  getters: {
    actualTaskBadget: (state) => state.actualTaskBadget,
    activityName: (state) => state.actualTaskBadget.activityName,
    activityCode: (state) => state.actualTaskBadget.activityCode,
    taskName: (state) => state.actualTaskBadget.taskName,
    taskCode: (state) => state.actualTaskBadget.taskCode,
    goal: (state) => state.actualTaskBadget.goal,
    goalUnit: (state) => state.actualTaskBadget.goalUnit,
    workers: (state) => state.actualTaskBadget.workers,
    performanceWorker: (state) => state.actualTaskBadget.performanceWorker,
    dateProgrammed: (state) => state.actualTaskBadget.dateProgrammed,
    machinery: (state) => state.actualTaskBadget.machinery,
    attachments: (state) => state.actualTaskBadget.attachments,
    tools: (state) => state.actualTaskBadget.tools,
    prescription: (state) => state.actualTaskBadget.prescription,
    products: (state) => state.actualTaskBadget.products,
    badgedId: (state) => state.badgedId,
    versionId: (state) => state.versionId,
    totalWorkingDays: (state) => state.actualTaskBadget.totalWorkingDays,
    taskId: (state) => state.taskId,
    numberMachineryDays: (state) => state.actualTaskBadget.numberMachineryDays,
    variety: (state) => state.actualTaskBadget.variety,
    comments: (state) => state.actualTaskBadget.comments,
  },

  actions: {
    setActualTaskBadget(context, value) {
      context.commit('setActualTaskBadget', value);
    },
    setActivityName(context, value) {
      context.commit('setActivityName', value);
    },
    setActivityCode(context, value) {
      context.commit('setActivityCode', value);
    },
    setTaskName(context, value) {
      context.commit('setTaskName', value);
    },
    setTaskCode(context, value) {
      context.commit('setTaskCode', value);
    },
    setGoal(context, value) {
      context.commit('setGoal', value);
    },
    setGoalUnit(context, value) {
      context.commit('setGoalUnit', value);
    },
    setWorkers(context, value) {
      context.commit('setWorkers', value);
    },
    setPerformanceWorker(context, value) {
      context.commit('setPerformanceWorker', value);
    },
    setDateProgrammed(context, value) {
      context.commit('setDateProgrammed', value);
    },
    setMachinery(context, value) {
      context.commit('setMachinery', value);
    },
    setAttachments(context, value) {
      context.commit('setAttachments', value);
    },
    setTools(context, value) {
      context.commit('setTools', value);
    },
    setProducts(context, value) {
      context.commit('setProducts', value);
    },
    setPrescription(context, value) {
      context.commit('setPrescription', value);
    },
    setBadgedId(context, value) {
      context.commit('setBadgedId', value);
    },
    setVersionId(context, value) {
      context.commit('setVersionId', value);
    },
    setTotalWorkingDays(context, value) {
      context.commit('setTotalWorkingDays', value);
    },
    setTaskId(context, value) {
      context.commit('setTaskId', value);
    },
    setNewPrescription(context) {
      context.commit('setNewPrescription');
    },
    setNumberMachineryDays(context, value) {
      context.commit('setNumberMachineryDays', value);
    },
    setVariety(context, value) {
      context.commit('setVariety', value);
    },
    setTaskBadgetEditable(context, value) {
      context.commit('SET_TASKBADGET_EDITABLE', value);
    },
    setComments(context, value) {
      context.commit('SET_COMMENTS', value);
    },
  },
  mutations: {
    setActualTaskBadget(state, value) {
      state.actualTaskBadget = value;
    },
    setActivityName(state, value) {
      state.actualTaskBadget.activityName = value;
    },
    setActivityCode(state, value) {
      state.actualTaskBadget.activityCode = value;
    },
    setTaskName(state, value) {
      state.actualTaskBadget.taskName = value;
    },
    setTaskCode(state, value) {
      state.actualTaskBadget.taskCode = value;
    },
    setGoal(state, value) {
      state.actualTaskBadget.goal = value;
    },
    setGoalUnit(state, value) {
      state.actualTaskBadget.goalUnit = value;
    },
    setWorkers(state, value) {
      state.actualTaskBadget.workers = value;
    },
    setPerformanceWorker(state, value) {
      state.actualTaskBadget.performanceWorker = value;
    },
    setDateProgrammed(state, value) {
      state.actualTaskBadget.dateProgrammed = value;
    },
    setMachinery(state, value) {
      state.actualTaskBadget.machinery = value;
    },
    setAttachments(state, value) {
      state.actualTaskBadget.attachments = value;
    },
    setTools(state, value) {
      state.actualTaskBadget.tools = value;
    },
    setProducts(state, value) {
      state.actualTaskBadget.products = value;
    },
    setPrescription(state, value) {
      state.actualTaskBadget.prescription = value;
    },
    setBadgedId(state, value) {
      state.badgedId = value;
    },
    setVersionId(state, value) {
      state.versionId = value;
    },
    setTotalWorkingDays(state, value) {
      state.actualTaskBadget.totalWorkingDays = value;
    },
    setTaskId(state, value) {
      state.taskId = value;
    },
    setNewPrescription(state) {
      state.prescription = {
        active: 'Yes',
        name: '',
        type: '',
        method: '',
        disease: '',
        dateActivation: '',
        notes: '',
        products: [],
      };
    },
    setNumberMachineryDays(state, value) {
      state.actualTaskBadget.numberMachineryDays = value;
    },
    setVariety(state, value) {
      state.actualTaskBadget.variety = value;
    },
    SET_TASKBADGET_EDITABLE(state, value) {
      state.taskBadgetEditable = value;
    },
    SET_COMMENTS(state, value) {
      state.actualTaskBadget.comments = value;
    },
  },
};
