var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('DxForm',{attrs:{"id":"form","col-count":1}},[_c('DxSimpleItem',{attrs:{"editor-options":{
      items: _vm.supervisorsAvailablesFiltered,
      searchEnabled: true,
      searchTimeout: _vm.searchTimeoutOption,
      minSearchLength: _vm.minSearchLengthOption,
      showDataBeforeSearch: _vm.showDataBeforeSearchOption,
      onValueChanged: _vm.onSupervisorChange,
      value: _vm.actualSupervisor,
      displayExpr: 'fullname',
      valueExpr:'id',
    },"editor-type":"dxSelectBox","caption":_vm.$t('SelectSupervisor_supervisor_caption')}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }