<template>
  <DxForm
    v-if="loaded"
    id="form"
    :col-count="1"
  >
    <DxSimpleItem
      v-for="(item, index) in locationsFormated"
      :key="index"
      :data-field="`${index+1} ${item.name[0]}`"
      :editor-options="{
        items: getSupervisorsAvailablesByLocation(item),
        searchEnabled: true,
        searchTimeout: searchTimeoutOption,
        minSearchLength: minSearchLengthOption,
        showDataBeforeSearch: showDataBeforeSearchOption,
        onValueChanged: onSupervisorChange,
        value: supervisorSelected(item),
        readOnly: readOnly,
        displayExpr: 'fullname',
        valueExpr:'id',
      }"
      editor-type="dxSelectBox"
      :caption="$t('SelectSupervisor_supervisor_caption')"
    >
      <!--:message="$t('DxSelectSupervisor')"message="At least one supervisor is needed" -->
      <DxRequiredRule message="At least one supervisor is needed" />
    </DxSimpleItem>
  </DxForm>
</template>
<script>
import {
  DxForm,
  DxSimpleItem,
  DxRequiredRule,
} from 'devextreme-vue/form';
import * as overlay from 'devextreme/ui/overlay';
import { mapActions } from 'vuex';

export default {
  name: 'DxSelectSupervisor',
  components: {
    DxForm,
    DxSimpleItem,
    DxRequiredRule,
  },
  props: {
    locations: { type: Array, default: () => [] },
    locationsTree: { type: Array, default: () => [] },
    supervisorsAvailables: { type: Array, default: () => [] },
    supervisorsLocation: { type: Array, default: () => [] },
    readOnly: { type: Boolean, default: false },
  },
  data() {
    return {
      loaded: false,
      searchTimeoutOption: 200,
      minSearchLengthOption: 0,
      showDataBeforeSearchOption: false,
      locationsFormated: [],
      finalLevelLocation: [],
      supervisorsLocationAux: [],
      locationsAvailables: [],
    };
  },
  async beforeMount() {
    overlay.baseZIndex(9999);
    this.$f7.preloader.show();
    try {
      await this.getLocationsAvailables();
      for (const location of this.locations) {
        this.locationsFormated.push({ bd_id: location, name: this.getFinalLevelLocation(location) });
      }
      for (const locationFormated of this.locationsFormated) {
        this.supervisorsLocationAux.push({ supervisor: this.getSupervisorsAvailablesByLocation(locationFormated)[0].id, location: locationFormated.bd_id });
      }
      this.setSupervisorsLocation(this.supervisorsLocationAux);
    } catch (e) {
      const message = this.$helpers.getFilteredErrorMessage(e);
      if (message.includes('default_') && !message.includes('404')) {
        this.$f7.dialog.alert(message.split('_').pop());
      } else if (!message.includes('404')) {
        this.$f7.dialog.alert(this.$t(message));
      }
    } finally {
      this.$f7.preloader.hide();
      this.loaded = true;
    }
  },
  methods: {
    getFinalLevelLocation(locations) {
      this.finalLevelLocation = [];
      for (const item of this.locationsTree) {
        this.viewChildren(item, locations);
      }
      return this.finalLevelLocation;
    },
    viewChildren(item, locations) {
      for (let i = 0; i < item.children.length; i += 1) {
        this.viewChildren(item.children[i], locations);
      }
      if (item.children.length === 0) {
        if (typeof locations === 'string') {
          if (locations === item.bd_id) {
            this.finalLevelLocation.push(item.name);
          }
        } else {
          for (const location of locations) {
            if (location === item.bd_id) {
              this.finalLevelLocation.push(item.name);
            }
          }
        }
      }
    },
    onSupervisorChange(e) {
      const posChange = e.element.getElementsByTagName('input')[0].getAttribute('name').split(' ')[0] - 1;
      const idSupervisorUpdated = e.value;
      this.supervisorsLocationAux[posChange].supervisor = idSupervisorUpdated;
      this.setSupervisorsLocation(this.supervisorsLocationAux);
    },
    getCodeLocation(items, name) {
      for (const item of items) {
        if (item.name[0] === name) {
          return item.bd_id;
        }
      }
      return '';
    },
    supervisorSelected(item) {
      return this.getSupervisorsAvailablesByLocation(item)[0].id;
    },
    getSupervisorsAvailablesByLocation(location) {
      const supervisorsFiltered = [];
      for (const supervisorAvailable of this.supervisorsAvailables) {
        if (supervisorAvailable.farm_id.includes(this.$helpers.getLocationLevel0ById(location.bd_id, this.locationsTree))) {
          supervisorsFiltered.push(supervisorAvailable);
        }
      }
      return supervisorsFiltered;
    },
    // ---- OBTENCIÓN Y FORMATEO DE LOCATIONS ----
    async getLocationsAvailables() {
      for (const item of this.locationsTree) {
        this.recursiveAddChildrens(item.children);
      }
    },
    recursiveAddChildrens(childrens) {
      for (let i = 0; i < childrens.length; i += 1) {
        this.setLocationTreeFormat(childrens[i]);
        this.recursiveAddChildrens(childrens[i].children);
      }
    },
    setLocationTreeFormat(item) {
      this.locationsAvailables.push({
        name: item.name,
        bd_id: item.bd_id,
        area: item.area,
        ID: item.ID,
        HEAD_ID: item.HEAD_ID,
        children: item.children,
      });
    },
    ...mapActions('PlanningManager', ['setSupervisorsLocation']),
  },
};
</script>
<style>
.status-icon {
  height: 16px;
  width: 16px;
  display: inline-block;
  margin-right: 8px;
}
.middle {
  vertical-align: middle;
}
</style>
