<template>
  <div
    v-if="loaded"
    class="content-devx content-dx-worker main-dx-nursery-logs"
  >
    <CalendarInitEndDate :key-name="keyName" />
    <f7-block-title>
      <span>{{ $t('DxNurseryReport_classification') }}</span>
    </f7-block-title>
    <DxNurseryClasificationReport :classifications="classifications" />
    <f7-block-title>
      <span>{{ $t('DxNurseryReport_egresos') }}</span>
    </f7-block-title>
    <DxNurseryEgresosReport :egresos="egresos" />
    <f7-block-title>
      <span>{{ $t('DxNurseryReport_stock') }}</span>
    </f7-block-title>
    <DxNurseryStockReport :stocks="stocks" />
  </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex';
import Api from '../../services/Api';
import CalendarInitEndDate from '../CalendarInitEndDate.vue';
import DxNurseryClasificationReport from './DxNurseryClassificationReport.vue';
import DxNurseryStockReport from './DxNurseryStockReport.vue';
import DxNurseryEgresosReport from './DxNurseryEgresosReport.vue';

export default {
  name: 'DxNurseryReport',
  components: {
    CalendarInitEndDate,
    DxNurseryClasificationReport,
    DxNurseryStockReport,
    DxNurseryEgresosReport,
  },
  data() {
    return {
      loaded: false,
      classifications: [],
      egresos: [],
      stocks: [],
      keyName: 'nurseryReport',
      pageSizes: [5, 10, 20],
    };
  },
  computed: {
    ...mapGetters('CalendarInitEndDate', ['actualFilter']),
  },
  async beforeMount() {
    this.$f7.preloader.show();
    try {
      await this.fetchLocationTree();
      const checkedInitEndDate = Api.checkInitEndDate(this.actualFilter[this.keyName]);
      let xhr = await Api.getNurseryClassificationReport(checkedInitEndDate.init, checkedInitEndDate.end);
      this.classifications = JSON.parse(xhr.response);
      xhr = await Api.getNurseryEgresosReport(checkedInitEndDate.init, checkedInitEndDate.end);
      this.egresos = JSON.parse(xhr.response);
      xhr = await Api.getNurseryStockReport(checkedInitEndDate.init, checkedInitEndDate.end);
      this.stocks = JSON.parse(xhr.response);
    } catch (e) {
      const message = this.$helpers.getFilteredErrorMessage(e);
      if (message.includes('default_') && !message.includes('404')) {
        this.$f7.dialog.alert(message.split('_').pop());
      } else if (!message.includes('404')) {
        this.$f7.dialog.alert(this.$t(message));
      }
    } finally {
      this.$f7.preloader.hide();
      this.loaded = true;
    }
  },
  methods: {
    ...mapActions('greenhouse', ['fetchLocationTree']),
  },
};
</script>
