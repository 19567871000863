<template>
  <DxChart
    id="chart"
    :data-source="workerFormated"
    :title="this.$t('dxSideBySideBarDayWorked_totalWorkerByActivity')"
    @pointClick="onPointClick"
    @legend-click="onLegendClick"
  >
    <DxCommonSeriesSettings
      argument-field="activity"
      type="bar"
      hover-mode="allArgumentPoints"
      selection-mode="allArgumentPoints"
    >
      <DxLabel :visible="true">
        <DxFormat
          :precision="0"
          type="fixedPoint"
        />
      </DxLabel>
    </DxCommonSeriesSettings>
    <DxValueAxis>
      <DxTitle :text="this.$t('dxSideBySideBarDayWorked_totalWorkerByActivity_Worked')" />
    </DxValueAxis>
    <DxSeries
      value-field="permanent"
      :name="this.$t('DxSeries_Permanent_name')"
    >
      <DxAggregation
        :enabled="true"
        method="sum"
      />
    </DxSeries>
    <DxSeries
      value-field="temporary"
      :name="this.$t('DxSeries_Temporary_name')"
    >
      <DxAggregation
        :enabled="true"
        method="sum"
      />
    </DxSeries>
    <DxSeries
      value-field="contratist"
      :name="this.$t('DxSeries_Contratist_name')"
    >
      <DxAggregation
        :enabled="true"
        method="sum"
      />
    </DxSeries>
    <DxLegend
      vertical-alignment="bottom"
      horizontal-alignment="center"
    />
    <DxArgumentAxis :aggregate-by-category="true" />
    <DxExport :enabled="true" />
    <DxScrollBar :visible="true" />
    <DxZoomAndPan argument-axis="both" />
  </DxChart>
</template>
<script>
import {
  DxChart,
  DxSeries,
  DxCommonSeriesSettings,
  DxLabel,
  DxFormat,
  DxLegend,
  DxExport,
  DxScrollBar,
  DxZoomAndPan,
  DxArgumentAxis,
  DxAggregation,
  DxValueAxis,
  DxTitle,
} from 'devextreme-vue/chart';
import moment from 'moment';
import { mapGetters } from 'vuex';
import Api from '../../services/Api';

export default {
  components: {
    DxChart,
    DxSeries,
    DxCommonSeriesSettings,
    DxLabel,
    DxFormat,
    DxLegend,
    DxExport,
    DxScrollBar,
    DxZoomAndPan,
    DxArgumentAxis,
    DxAggregation,
    DxValueAxis,
    DxTitle,
  },
  props: {
    reportingWorker: { type: Array, default: () => [] },
  },
  data() {
    return {
      workerFormated: [],
    };
  },
  computed: {
    ...mapGetters('CalendarInitEndDate', ['actualFilter']),
  },
  async beforeMount() {
    this.$f7.preloader.show();
    try {
      for (const worker of this.reportingWorker) {
        let typeWorker = '';
        let permanent = 0;
        let contratist = 0;
        let temporary = 0;
        for (let j = 0; j < worker.workers.length; j += 1) {
          typeWorker = worker.workers[j];
          switch (typeWorker.type) {
            case this.$t('PERMANENT'):
              permanent += moment.duration(worker.workers[j].working_time).asSeconds();
              break;
            case this.$t('TEMPORARY'):
              temporary += moment.duration(worker.workers[j].working_time).asSeconds();
              break;
            case this.$t('CONTRATIST'):
              contratist += moment.duration(worker.workers[j].working_time).asSeconds();
              break;
            default:
              break;
          }
          this.workerFormated.push({
            activity: worker.activity,
            permanent: (permanent / 3600) / 8,
            contratist: (contratist / 3600) / 8,
            temporary: (temporary / 3600) / 8,
          });
        }
      }
    } catch (e) {
      const message = this.$helpers.getFilteredErrorMessage(e);
      if (message.includes('default_') && !message.includes('404')) {
        this.$f7.dialog.alert(message.split('_').pop());
      } else if (!message.includes('404')) {
        this.$f7.dialog.alert(this.$t(message));
      }
    } finally {
      this.$f7.preloader.hide();
      this.loaded = true;
    }
  },
  methods: {
    onPointClick({ target }) {
      target.select();
    },
    onLegendClick({ target: series }) {
      if (series.isVisible()) {
        series.hide();
      } else {
        series.show();
      }
    },
  },
};
</script>
<style>
#chart {
    height: 440px;
}
</style>
