var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.loaded)?_c('div',{staticClass:"content-devx content-dx-machinery-form"},[_c('DxForm',{attrs:{"id":"machineryForm"}},[_c('DxGroupItem',{attrs:{"col-count":6}},[_c('DxGroupItem',{attrs:{"css-class":"content-combo","col-span":3}},[_c('DxSimpleItem',{attrs:{"name":"code","data-field":_vm.$t('DxMachineryForm_code_caption'),"editor-options":{
            value: _vm.actualCode,
            onValueChanged: _vm.onChangeCode,
          }}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('DxMachineryForm_code_required')}})],1),_vm._v(" "),_c('DxSimpleItem',{attrs:{"name":"description","data-field":_vm.$t('DxMachineryForm_description_caption'),"editor-options":{
            value: _vm.actualDescription,
            onValueChanged: _vm.onChangeDescription,
          }}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('DxMachineryForm_description_required')}})],1),_vm._v(" "),_c('DxSimpleItem',{attrs:{"name":"brand","data-field":_vm.$t('DxMachineryForm_brand_caption'),"editor-options":{
            value: _vm.actualBrand,
            onValueChanged: _vm.onChangeBrand,
          }}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('DxMachineryForm_brand_required')}})],1),_vm._v(" "),_c('DxSimpleItem',{attrs:{"name":"model","data-field":_vm.$t('DxMachineryForm_model_caption'),"editor-options":{
            value: _vm.actualModel,
            onValueChanged: _vm.onChangeModel,
          }}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('DxMachineryForm_model_required')}})],1),_vm._v(" "),_c('DxSimpleItem',{attrs:{"name":"serial_number","data-field":_vm.$t('DxMachineryForm_serial_number_caption'),"editor-options":{
            value: _vm.actualSerialNumber,
            onValueChanged: _vm.onChangeSerialNumber,
          }}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('DxMachineryForm_serial_number_required')}})],1),_vm._v(" "),_c('DxSimpleItem',{attrs:{"name":"plate","data-field":_vm.$t('DxMachineryForm_plate_caption'),"editor-options":{
            value: _vm.actualPlate,
            onValueChanged: _vm.onChangePlate,
          }}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('DxMachineryForm_plate_required')}})],1),_vm._v(" "),_c('DxSimpleItem',{attrs:{"name":"internal_code","data-field":_vm.$t('DxMachineryForm_internal_code_caption'),"editor-options":{
            value: _vm.actualInternalCode,
            onValueChanged: _vm.onChangeInternalCode,
          }}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('DxMachineryForm_internal_code_required')}})],1)],1),_vm._v(" "),_c('DxGroupItem',{attrs:{"col-span":3,"css-class":"content-combo"}},[_c('DxSimpleItem',{attrs:{"name":"family","data-field":_vm.$t('DxMachineryForm_family_caption'),"editor-type":"dxSelectBox","editor-options":{
            items: _vm.familyAvailables,
            value: _vm.actualFamily,
            onValueChanged: _vm.onChangeFamily,
            searchEnabled: true,
            searchTimeout: _vm.searchTimeoutOption,
            minSearchLength: _vm.minSearchLengthOption,
            showDataBeforeSearch: _vm.showDataBeforeSearchOption,
          }}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('DxMachineryForm_family_required')}})],1),_vm._v(" "),_c('DxSimpleItem',{attrs:{"name":"subfamily","data-field":_vm.$t('DxMachineryForm_subfamily_caption'),"editor-type":"dxSelectBox","editor-options":{
            items: _vm.subfamilyAvailables,
            value: _vm.actualSubfamily,
            onValueChanged: _vm.onChangeSubfamily,
            searchEnabled: true,
            searchTimeout: _vm.searchTimeoutOption,
            minSearchLength: _vm.minSearchLengthOption,
            showDataBeforeSearch: _vm.showDataBeforeSearchOption,
          }}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('DxMachineryForm_subfamily_required')}})],1),_vm._v(" "),_c('DxSimpleItem',{attrs:{"name":"purchase","data-field":_vm.$t('DxMachineryForm_purchase_caption'),"editor-type":"dxDateBox","editor-options":{
            value: _vm.actualPurchase,
            onValueChanged: _vm.onChangePurchase,
            displayFormat: 'yyyy/MM/dd',
            disabledDates:_vm.disableSundays,
          }}}),_vm._v(" "),_c('DxSimpleItem',{attrs:{"name":"attachment","data-field":_vm.$t('DxMachineryForm_attachment_caption'),"editor-type":"dxCheckBox","editor-options":{
            value: _vm.actualAttachment,
            onValueChanged: _vm.onChangeAttachment,
          }}}),_vm._v(" "),_c('DxSimpleItem',{attrs:{"name":"status","data-field":_vm.$t('DxMachineryForm_status_caption'),"editor-type":"dxCheckBox","editor-options":{
            value: _vm.actualStatus,
            onValueChanged: _vm.onChangeStatus,
          }}})],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }