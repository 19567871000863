var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.loaded)?_c('div',{staticClass:"content-devx content-dx-point-form"},[_c('div',{attrs:{"id":"form-container"}},[_c('f7-row',[_c('f7-col',{attrs:{"width":"25","medium":"30"}},[_c('DxTreePlanning',{attrs:{"locations-tree":_vm.locationFormatted,"planning":{locations: [_vm.location]},"show-grouped":false,"recursive":false,"monitoring-points":true}})],1),_vm._v(" "),_c('f7-col',{attrs:{"width":"75","medium":"70"}},[_c('DxForm',{attrs:{"id":"pointForm"}},[_c('DxGroupItem',{attrs:{"col-count":6}},[_c('DxGroupItem',{attrs:{"css-class":"content-combo","col-span":3}},[_c('DxSimpleItem',{attrs:{"name":"name","data-field":_vm.$t('DxPointForm_name_caption'),"editor-options":{
                  value: _vm.name,
                  onValueChanged: _vm.onChangeName,
                }}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('DxPointForm_name_required')}})],1),_vm._v(" "),_c('DxSimpleItem',{attrs:{"name":"latitude","data-field":_vm.$t('DxPointForm_latitude_caption'),"editor-options":{
                  value: _vm.latitude,
                  onValueChanged: _vm.onChangeLatitude,
                }}}),_vm._v(" "),_c('DxSimpleItem',{attrs:{"name":"longitude","data-field":_vm.$t('DxPointForm_longitude_caption'),"editor-options":{
                  value: _vm.longitude,
                  onValueChanged: _vm.onChangeLongitude,
                }}}),_vm._v(" "),_c('DxSimpleItem',{attrs:{"name":"numberPlants","data-field":_vm.$t('DxPointForm_numberPlants_caption'),"editor-type":"dxNumberBox","editor-options":{
                  mode: 'number',
                  min: '0',
                  value: _vm.numberPlants,
                  onValueChanged: _vm.onChangeNumberPlants,
                }}})],1),_vm._v(" "),_c('DxGroupItem',{attrs:{"col-span":3,"css-class":"content-combo"}},[_c('DxSimpleItem',{attrs:{"name":"variety","data-field":_vm.$t('DxPointForm_variety_caption'),"editor-type":"dxSelectBox","editor-options":{
                  items: _vm.varietyAvailables,
                  value: _vm.variety,
                  onValueChanged: _vm.onChangeVariety,
                  searchEnabled: true,
                  searchTimeout: _vm.searchTimeoutOption,
                  minSearchLength: _vm.minSearchLengthOption,
                  showDataBeforeSearch: _vm.showDataBeforeSearchOption,
                  displayExpr:'name',
                  valueExpr:'name',
                }}}),_vm._v(" "),_c('DxSimpleItem',{attrs:{"name":"type","data-field":_vm.$t('DxPointForm_type_caption'),"editor-type":"dxSelectBox","editor-options":{
                  items: _vm.typeAvailables,
                  value: _vm.type,
                  onValueChanged: _vm.onChangeType,
                  searchEnabled: true,
                  searchTimeout: _vm.searchTimeoutOption,
                  minSearchLength: _vm.minSearchLengthOption,
                  showDataBeforeSearch: _vm.showDataBeforeSearchOption,
                }}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('Point_type_validation')}})],1),_vm._v(" "),_c('DxSimpleItem',{attrs:{"name":"yearPlanting","data-field":_vm.$t('DxPointForm_yearPlanting_caption'),"editor-type":"dxDateBox","editor-options":{
                  value: _vm.yearPlanting,
                  onValueChanged: _vm.onChangeYearPlanting,
                  maxZoomLevel:'decade',
                  minZoomLevel:'decade',
                  zoomLevel: 'decade',
                  displayFormat: 'yyyy',
                }}}),_vm._v(" "),_c('DxSimpleItem',{attrs:{"name":"note","data-field":_vm.$t('DxPointForm_note_caption'),"editor-type":"dxTextArea","editor-options":{
                  value: _vm.note,
                  onValueChanged: _vm.onChangeNote,
                  maxLength: 110,
                }}})],1)],1)],1)],1)],1)],1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }