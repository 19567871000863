var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"content-devx content-dx-daily-planning no-margin no-shadow"},[_c('DxForm',{attrs:{"id":"form","col-count":1}},[_c('DxSimpleItem',{attrs:{"data-field":"Name","editor-options":{
          readOnly: _vm.readOnly,
          disabled: false,
          value: _vm.supervisorsAvailables,
        },"caption":_vm.$t('SelectSupervisor_supervisor_caption')}}),_vm._v(" "),_c('DxSimpleItem',{attrs:{"editor-type":"dxTextArea","editor-options":{
          placeholder: _vm.$t('DxInformationPrescription_notes_caption'),
          value: _vm.additionalInfoWorkOrder,
          readOnly: _vm.readOnly,
          maxLength: 256,
        }}})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }