<template>
  <div
    v-if="loaded"
    class="content-devx content-dx-variety-database"
  >
    <DxDataGrid
      id="varietyGrid"
      :data-source="varietyAvailables"
      :column-min-width="70"
      :column-auto-width="true"
      :allow-column-reordering="true"
      :show-borders="true"
      :show-column-lines="true"
      :show-row-lines="true"
      :row-alternation-enabled="true"
      @exporting="onExporting"
    >
      <DxStateStoring
        :enabled="true"
        type="localStorage"
        storage-key="DxVarietyDatabase"
      />
      <DxExport
        :enabled="true"
      />
      <DxHeaderFilter :visible="true" />
      <DxPaging :page-size="10" />
      <DxPager
        :show-page-size-selector="true"
        :allowed-page-sizes="pageSizes"
        :show-info="true"
      />
      <DxSearchPanel
        :visible="true"
        :width="400"
        :placeholder="$t('Search_placeholder')"
      />
      <DxColumn
        data-field="code"
        name="code"
        :caption="$t('DxVarietyDatabase_column_code')"
        :allow-header-filtering="false"
      />
      <DxColumn
        data-field="name"
        name="name"
        :caption="$t('DxVarietyDatabase_column_name')"
        :allow-header-filtering="false"
      />
    </DxDataGrid>
  </div>
</template>

<script>

import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxSearchPanel,
  DxExport,
  DxHeaderFilter,
  DxStateStoring,
  DxPager,
} from 'devextreme-vue/data-grid';
import { exportDataGrid } from 'devextreme/excel_exporter';
import ExcelJS from 'exceljs';
import saveAs from 'file-saver';
import Api from '../../services/Api';

export default {
  name: 'DxVarietyDatabase',
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxSearchPanel,
    DxExport,
    DxHeaderFilter,
    DxStateStoring,
    DxPager,
  },
  data() {
    return {
      loaded: false,
      pageSizes: [5, 10, 20],
      varietyAvailables: [],
    };
  },
  async beforeMount() {
    this.$f7.preloader.show();
    try {
      const xhr = await Api.getAllVariety();
      this.varietyAvailables = JSON.parse(xhr.response);
    } catch (e) {
      const message = this.$helpers.getFilteredErrorMessage(e);
      if (message.includes('default_') && !message.includes('404')) {
        this.$f7.dialog.alert(message.split('_').pop());
      } else if (!message.includes('404')) {
        this.$f7.dialog.alert(this.$t(message));
      }
    } finally {
      this.$f7.preloader.hide();
      this.loaded = true;
    }
  },
  methods: {
    // EXPORT EXCEL
    onExporting(e) {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('VarietyDatabase');
      exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
      }).then(() => {
        workbook.xlsx.writeBuffer()
          .then((buffer) => {
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'VarietyDatabase.xlsx');
          });
      });
      e.cancel = true;
    },
  },
};
</script>
