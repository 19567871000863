<template>
  <div>
    <div
      class="content-devx content-dx-daily-planning no-shadow no-margin"
    >
      <DxPostWorkEmployeeManualHarvesting
        v-if="showManualHarvesting"
        :start-value="1"
        :disable-field="true"
      />
      <DxDataGrid
        id="employeeTable"
        ref="employeeTable"
        :data-source="employeesArray"
        :column-min-width="70"
        :allow-column-reordering="true"
        :show-borders="true"
        :show-column-lines="true"
        :show-row-lines="false"
        :row-alternation-enabled="true"
      >
        <DxStateStoring
          :enabled="true"
          type="localStorage"
          storage-key="DxViewWorkOrderEmployeeTable"
        />
        <DxExport
          :enabled="true"
        />
        <DxPaging :page-size="18" />
        <DxSearchPanel
          :visible="true"
          :width="200"
          :placeholder="$t('Search_placeholder')"
        />
        <DxColumn
          data-field="type"
          name="type"
          caption="Type"
        />
        <DxColumn
          data-field="name"
          name="name"
          :caption="$t('DxPostWorkEmployeeTable_Name')"
        />
        <DxColumn
          data-field="surname"
          name="surname"
          :caption="$t('DxPostWorkEmployeeTable_Surname')"
        />
        <DxColumn
          data-field="init_date"
          name="init_date"
          :caption="$t('DxPostWorkEmployeeTable_Checkin')"
          data-type="datetime"
          :calculate-display-value="initDateFormatting"
        />
        <DxColumn
          data-field="end_date"
          name="end_date"
          :caption="$t('DxPostWorkEmployeeTable_Checkout')"
          data-type="datetime"
          :calculate-display-value="endDateFormatting"
        />
        <DxColumn
          data-field="performance"
          name="performance"
          :caption="$t('DxPostWorkEmployeeTable_Productivity')"
          data-type="number"
          :calculate-cell-value="checkPerformance"
        />
        <DxColumn
          data-field="unit"
          name="unit"
          :caption="$t('DxPostWorkEmployeeTable_Unit')"
          :calculate-cell-value="checkPerformanceUnit"
        />
        <DxColumn
          data-field=""
          :customize-text="getDuration"
          name="taskduration"
          caption="Duration"
          data-type="datetime"
          format="HH:mm:ss"
        />
        <DxColumn
          data-field="comment"
          name="comment"
          :caption="$t('DxPostWorkEmployeeTable_Notes')"
        />
      </DxDataGrid>
    </div>
  </div>
</template>
<script>

import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxSearchPanel,
  DxExport,
  DxStateStoring,
} from 'devextreme-vue/data-grid';
import * as overlay from 'devextreme/ui/overlay';
import moment from 'moment';
import { mapState } from 'vuex';
import DxPostWorkEmployeeManualHarvesting from '../postWork/DxPostWorkEmployeeManualHarvesting.vue';

export default {
  name: 'DxViewWorkOrderEmployeeTable',
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxSearchPanel,
    DxExport,
    DxStateStoring,
    DxPostWorkEmployeeManualHarvesting,
  },
  props: {
    employeesArray: { type: Array, default: () => [] },
  },
  data() {
    return {
      init_date: '',
      end_date: '',
    };
  },
  computed: {
    showManualHarvesting() {
      if (this.actualWorkOrder && (this.actualWorkOrder.taskCode === '0801' || this.actualWorkOrder.taskCode === '0802' || this.actualWorkOrder.taskCode === '0803')) {
        return true;
      }
      return false;
    },
    ...mapState('PlanningManager', ['actualWorkOrder']),
  },
  beforeMount() {
    overlay.baseZIndex(9999);
  },
  methods: {
    checkPerformance(rowData) {
      return (rowData.unit === '') ? rowData.working_area : rowData.performance;
    },
    checkPerformanceUnit(rowData) {
      return (rowData.unit === '') ? 'HA' : rowData.unit;
    },
    getDuration() {
      for (let i = 0; i < this.employeesArray.length; i += 1) {
        this.init_date = this.employeesArray[i].init_date;
        this.end_date = this.employeesArray[i].end_date;
      }
      const ms = moment(this.end_date).diff(moment(this.init_date));
      const d = moment.duration(ms);
      const s = Math.floor(d.asHours()) + moment.utc(ms).format(':mm:ss');
      return s;
    },
    initDateFormatting(e) {
      return moment.tz(e.init_date, 'Europe/Belgrade').format('YYYY/MM/DD HH:mm');
    },
    endDateFormatting(e) {
      return moment.tz(e.end_date, 'Europe/Belgrade').format('YYYY/MM/DD HH:mm');
    },
  },
};
</script>
