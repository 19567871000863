<template>
  <DxForm
    id="form"
    :col-count="1"
  >
    <DxSimpleItem
      :editor-options="{
        items: supervisorsAvailablesFiltered,
        searchEnabled: true,
        searchTimeout: searchTimeoutOption,
        minSearchLength: minSearchLengthOption,
        showDataBeforeSearch: showDataBeforeSearchOption,
        onValueChanged: onSupervisorChange,
        value: actualSupervisor,
        displayExpr: 'fullname',
        valueExpr:'id',
      }"
      editor-type="dxSelectBox"
      :caption="$t('SelectSupervisor_supervisor_caption')"
    />
  </DxForm>
</template>
<script>
import {
  DxForm,
  DxSimpleItem,
} from 'devextreme-vue/form';
import * as overlay from 'devextreme/ui/overlay';
import { mapActions } from 'vuex';

export default {
  name: 'DxSelectSupervisorGrouped',
  components: {
    DxForm,
    DxSimpleItem,
  },
  props: {
    locations: { type: Array, default: () => [] },
    locationsTree: { type: Array, default: () => [] },
    supervisorsAvailables: { type: Array, default: () => [] },
    supervisorsLocation: { type: Array, default: () => [] },
  },
  data() {
    return {
      locationsFormated: [],
      finalLevelLocation: [],
      searchTimeoutOption: 200,
      minSearchLengthOption: 0,
      showDataBeforeSearchOption: false,
      nameSupervisors: [],
      create: false,
      currentRoot: [],
      nameRoot: '',
      supervisorsAvailablesFiltered: [],
      locationsLevel0Selected: [],
      actualSupervisor: '',

    };
  },
  async beforeMount() {
    overlay.baseZIndex(9999);
    for (const location of this.locations) {
      this.locationsLevel0Selected.push(this.$helpers.getLocationLevel0ById(location, this.locationsTree));
    }
    this.locationsLevel0Selected = ([...new Set(this.locationsLevel0Selected)]);
    for (const supervisorAvailable of this.supervisorsAvailables) {
      for (const farm of supervisorAvailable.farm_id) {
        if (this.locationsLevel0Selected.includes(farm)) {
          this.supervisorsAvailablesFiltered.push(supervisorAvailable);
        }
      }
    }
    this.supervisorsAvailablesFiltered = ([...new Set(this.supervisorsAvailablesFiltered)]);
    if (this.supervisorsLocation.length !== 0) {
      this.actualSupervisor = this.supervisorsLocation[0].supervisor;
    }
  },
  methods: {
    getFinalLevelLocation(locations) {
      this.finalLevelLocation = [];
      for (const item of this.locationsTree) {
        this.nameRoot = item.name;
        this.viewChildren(item, locations);
      }
      return this.finalLevelLocation;
    },
    viewChildren(item, locations) {
      for (let i = 0; i < item.children.length; i += 1) {
        this.viewChildren(item.children[i], locations);
      }
      if (item.children.length === 0) {
        if (typeof locations === 'string') {
          if (locations === item.bd_id) {
            if (this.currentRoot.indexOf(this.nameRoot) < 0) {
              this.currentRoot.push(this.nameRoot);
            }
            this.finalLevelLocation.push(item.name);
          }
        } else {
          for (const location of locations) {
            if (location === item.bd_id) {
              if (this.currentRoot.indexOf(this.nameRoot) < 0) {
                this.currentRoot.push(this.nameRoot);
              }
              this.finalLevelLocation.push(item.name);
            }
          }
        }
      }
    },
    onSupervisorChange(e) {
      this.setSupervisorsLocation(e.value);
    },
    getCodeSupervisor(items, fullname) {
      for (const item of items) {
        if (`${item.name} ${item.surname}` === fullname) {
          return item.id;
        }
      }
      return '';
    },
    ...mapActions('PlanningManager', ['setSupervisorsLocation']),
  },
};
</script>
<style>
.status-icon {
  height: 16px;
  width: 16px;
  display: inline-block;
  margin-right: 8px;
}
.middle {
  vertical-align: middle;
}
</style>
