<template>
  <div v-if="loaded">
    <f7-row>
      <f7-col>
        <DxForm>
          <DxGroupItem
            css-class="content-activity"
          >
            <DxSimpleItem
              css-class="content-activity__area"
              :data-field="$t('totalArea_field')"
              editor-type="dxNumberBox"
              :editor-options="{
                value: totalAreaSelected,
                mode: 'number',
                disabled: true,
              }"
            />
          </DxGroupItem>
        </DxForm>
        <DxForm
          id="form"

          caption="Activity Task"
        >
          <DxGroupItem>
            <DxGroupItem
              :caption="$t('PlanningComponent_caption_text')"
            >
              <DxSimpleItem
                :data-field="$t('PlanningComponent_activityName_caption')"
                :editor-options="{
                  items: activitiesName,
                  searchEnabled: true,
                  searchTimeout: searchTimeoutOption,
                  minSearchLength: minSearchLengthOption,
                  showDataBeforeSearch: showDataBeforeSearchOption,
                  onValueChanged: activitySelectedEvent,
                  value: actualActivityName,
                }"
                editor-type="dxSelectBox"
                name="activityName"
              >
                <DxRequiredRule :message="$t('DxPlanningForm_activity_name')" />
              </DxSimpleItem>

              <DxSimpleItem
                :data-field="$t('PlanningComponent_activitycode_caption')"
                name="activityCode"
                :caption="$t('PlanningComponent_activitycode_caption')"
                :editor-options="{ disabled: true, value: actualActivityCode }"
              />
              <DxSimpleItem
                :data-field="$t('PlanningComponent_taskName_caption')"
                :editor-options="{
                  dataSource: tasksAvailableName,
                  searchEnabled: true,
                  searchTimeout: searchTimeoutOption,
                  minSearchLength: minSearchLengthOption,
                  showDataBeforeSearch: showDataBeforeSearchOption,
                  onValueChanged: taskSelectedEvent,
                  onValueChanged: taskSelectedEvent,
                  value: actualTaskName
                }"
                editor-type="dxSelectBox"
                name="taskName"
                :caption="$t('PlanningComponent_taskName_caption')"
              >
                <DxRequiredRule :message="$t('DxPlanningForm_Task_name')" />
              </DxSimpleItem>
              <DxSimpleItem
                :data-field="$t('PlanningComponent_taskCode_caption')"
                name="taskCode"
                :caption="$t('PlanningComponent_taskCode_caption')"
                :editor-options="{ disabled: true, value: actualTaskCode }"
              />
              <DxSimpleItem
                :data-field="$t('PlanningComponent_variety_caption')"
                :editor-options="{
                  items: varietyAvailables,
                  searchEnabled: true,
                  searchTimeout: searchTimeoutOption,
                  minSearchLength: minSearchLengthOption,
                  showDataBeforeSearch: showDataBeforeSearchOption,
                  onValueChanged: varietySelectedEvent,
                  displayExpr: 'name',
                  valueExpr: 'name',
                  value: actualVariety,
                }"
                editor-type="dxSelectBox"
                name="variety"
                :visible="actualActivityCode === '08'"
              >
                <DxRequiredRule :message="$t('DxPlanningForm_variety_required')" />
              </DxSimpleItem>
              <DxSimpleItem
                :data-field="$t('PlanningComponent_date_caption')"
                name="date"
              >
                <template #default>
                  <f7-input
                    id="datepicker-multiple"
                    class="form-calendar"
                    type="datepicker"
                    :placeholder="$t('PlanningComponent_datepicker_multiple_placeholder')"
                    readonly
                    clear-button
                    :calendar-params="{openIn: 'customModal', header: false, footer: true, dateFormat: 'dd/mm/yyyy', multiple: calendarMultiple, minDate: minDate, maxDate: maxDate, disabled:sundays}"
                    :value="actualDate"
                    @calendar:change="dateSelectedEvent"
                  />
                </template>
                <DxRequiredRule :message="$t('CreatePlanning_ValidationDate')" />
              </DxSimpleItem>
              <DxSimpleItem
                :data-field="`${$t('PlanningComponent_workingarea_caption')} (HA)`"
                name="workingArea"
                :caption="$t('PlanningComponent_workingarea_caption')"
                :editor-options="{
                  disabled:workingAreaDisabled,
                  value: workingArea,
                  showSpinButtons: false,
                  mode: 'number',
                  placeholder: '0',
                  showClearButton: true,
                  min: '0',
                  max: totalAreaSelected,
                  step: '1',
                  onValueChanged: workingAreaChanged,
                }"
              />
              <DxSimpleItem
                :data-field="$t('PlanningComponent_distributed_caption')"
                name="distributed"
                :visible="distributedVisible"
                :caption="$t('PlanningComponent_distributed_caption')"
                editor-type="dxCheckBox"
                :editor-options="{
                  value: distributed,
                  onValueChanged: distributedChanged,
                }"
              />
              <DxSimpleItem
                :data-field="`${$t('PlanningComponent_performanceCount_caption')} (${actualPerformanceUnit})`"
                name="PerformanceCount"
                editor-type="dxNumberBox"
                :editor-options="{
                  value: actualPerformanceCount,
                  showSpinButtons: false,
                  mode: 'number',
                  placeholder: '0',
                  showClearButton: true,
                  min: '0',
                  step: '1',
                  onValueChanged: performanceUnitSelectEvent,
                }"
                :visible="visiblePerformance"
              />
            </DxGroupItem>
          </DxGroupItem>
          <DxGroupItem v-if="showTables">
            <DxGroupItem
              :caption="$t('PlanningComponent_numberWorkers_caption')"
            >
              <DxSimpleItem
                :data-field="$t('TEMPORARY')"
                editor-type="dxNumberBox"
                :editor-options="{
                  value: getWorkerTypeValue('TEMPORARY'),
                  onValueChanged: temporarySelectEvent,
                  showSpinButtons: false,
                  mode: 'number',
                  placeholder: '0',
                  showClearButton: true,
                  min: '0'
                }"
              />
              <DxSimpleItem
                :data-field="$t('PERMANENT')"
                editor-type="dxNumberBox"
                :editor-options="{
                  value: getWorkerTypeValue('PERMANENT'),
                  onValueChanged: permanentSelectEvent,
                  showSpinButtons: false,
                  mode: 'number',
                  placeholder: '0',
                  showClearButton: true,
                  min: '0'
                }"
              />
              <DxSimpleItem
                :data-field="$t('CONTRATIST')"
                editor-type="dxNumberBox"
                :editor-options="{
                  value: getWorkerTypeValue('CONTRATIST'),
                  onValueChanged: contratistSelectEvent,
                  showSpinButtons: false,
                  mode: 'number',
                  placeholder: '0',
                  showClearButton: true,
                  min: '0'
                }"
              />
              <DxSimpleItem
                :data-field="$t('PlanningComponent_shift_caption')"
                :editor-options="{
                  dataSource: ['Shift 1', 'Shift 2', 'Shift 3' ],
                  searchEnabled: true,
                  searchTimeout: searchTimeoutOption,
                  minSearchLength: minSearchLengthOption,
                  showDataBeforeSearch: showDataBeforeSearchOption,
                  onValueChanged: shiftSelectedEvent,
                  value: actualShift,
                }"
                editor-type="dxSelectBox"
                name="Shift"
                :caption="$t('PlanningComponent_shift_caption')"
              />
              <DxSimpleItem
                :data-field="$t('PlanningComponent_groupName_caption')"
                name="groupName"
                :caption="$t('PlanningComponent_groupName_caption')"
                :editor-options="{
                  value: groupName,
                  onValueChanged: groupNameEvent,
                  maxLength: 10,
                }"
              />
            </DxGroupItem>
          </DxGroupItem>
        </DxForm>
      </f7-col>
    </f7-row>
    <f7-row class="block">
      <f7-col
        v-if="showTables"
        class="content-machinery"
      >
        <f7-block-title>
          {{ $t('DxMachinery_Title') }}
        </f7-block-title>
        <DxMachineryTable
          :machinary="machinaryList"
          :planning="planning"
        />
        <f7-block-title>
          {{ $t('DxAttachment_Title') }}
        </f7-block-title>
        <DxAttachmentTable
          :attachment="attachmentList"
          :planning="planning"
        />
        <f7-block-title>
          {{ $t('DxTools_Title') }}
        </f7-block-title>
        <DxToolsTable
          :tools-types-availables="toolList"
          :planning="planning"
          :allow-updating="true"
          :allow-deleting="true"
          :allow-adding="true"
          :is-post-work="false"
        />
      </f7-col>
    </f7-row>
  </div>
</template>
<script>
import {
  DxForm,
  DxSimpleItem,
  DxGroupItem,
  DxRequiredRule,
} from 'devextreme-vue/form';
import { mapState, mapActions } from 'vuex';
import * as overlay from 'devextreme/ui/overlay';
import moment from 'moment';
import DxMachineryTable from './DxMachineryTable.vue';
import DxAttachmentTable from './DxAttachmentTable.vue';
import DxToolsTable from './DxToolsTable.vue';
import Api from '../../services/Api';
import EventBus from '../../js/event-bus';

export default {
  name: 'DxPlanningForm',
  components: {
    DxForm,
    DxSimpleItem,
    DxGroupItem,
    DxRequiredRule,
    DxMachineryTable,
    DxAttachmentTable,
    DxToolsTable,
  },
  props: {
    dataForm: { type: Object, default: () => {} },
    planning: { type: Object, default: null },
    calendarMultiple: { type: Boolean, default: true },
    maxDate: { type: String, default: '' },
    showTables: { type: Boolean, default: true },
    distributedVisible: { type: Boolean, default: true },
    workingAreaDisabled: { type: Boolean, default: false },
  },

  data() {
    return {
      loaded: false,
      searchTimeoutOption: 200,
      minSearchLengthOption: 0,
      showDataBeforeSearchOption: false,
      actualActivityName: '',
      actualActivityCode: '',
      workingArea: 0,
      distributed: false,
      actualTaskName: '',
      actualTaskCode: '',
      actualWorkers: [],
      actualDate: [moment().add(1, 'days').format('YYYY-MM-DD')],
      actualPerformanceUnit: '',
      actualPerformanceCount: 0,
      actualShift: 'Shift 1',
      groupName: '',
      minDate: moment().subtract(1, 'days').format('YYYY-MM-DD'),
      actualGrouped: true,
      tasksAvailable: [],
      tasksAvailableName: [],
      activitiesName: [],
      machinaryList: [],
      attachmentList: [],
      toolList: [],
      dateAux: [],
      visiblePerformance: false,
      varietyAvailables: [],
      actualVariety: '',
    };
  },
  computed: {
    ...mapState('TaskManager', ['totalAreaSelected']),
    ...mapState('PlanningManager', ['workers']),
  },
  destroyed() {
    EventBus.$off('changeTotalAreaSelected');
  },
  async beforeMount() {
    overlay.baseZIndex(9999);
    if (this.planning == null) {
      this.setWorkers([]);
      this.setActivitySelected([]);
      this.setTasksAvailableName();
      this.setTaskSelected([]);
      this.setPerformanceUnit('');
      this.setPerformanceCount(0);
      this.setShift(1);
      this.setGroupName('');
      this.setGroupedSelected(true);
      this.setTotalAreaSelected(0);
      this.setDateSelected([moment().format('MM/DD/YYYY')]);
      this.setWorkingArea(0);
      this.setDistributed(false);
      this.setVariety(this.actualVariety);
    } else {
      this.actualWorkers = this.planning.WorkersDetailTab;
      this.setWorkers(this.actualWorkers);
      this.actualActivityName = this.planning.activity.name;
      this.actualActivityCode = this.planning.activity.code;
      this.setActivitySelected(this.planning.activity);
      this.setTasksAvailableName();
      this.setTaskSelected(this.getTask(this.tasksAvailable, this.planning.task));
      this.actualTaskName = this.planning.task.name;
      this.actualTaskCode = this.planning.task.code;
      this.setTaskSelected(this.planning.task);
      this.actualPerformanceUnit = this.planning.performanceUnit;
      this.setPerformanceUnit(this.actualPerformanceUnit);
      this.actualShift = this.getValueShift(this.planning.shift);
      this.setShift(this.planning.shift);
      this.setDateSelected(this.formattingDates(this.planning.Date, 'YYYY-MM-DD', 'YYYY-MM-DD'));
      this.actualDate = this.formattingDates(this.planning.Date, 'YYYY-MM-DD', 'MM/DD/YYYY');
      this.groupName = this.planning.groupName;
      this.setGroupName(this.planning.groupName);
      this.actualPerformanceCount = this.planning.performanceCount;
      this.setPerformanceCount(this.actualPerformanceCount);
      this.actualGrouped = this.planning.Grouped;
      this.setGroupedSelected(this.planning.Grouped);
      this.distributed = this.planning.distributed;
      this.setDistributed(this.distributed);
      this.workingArea = this.planning.area_effective;
      this.setWorkingArea(this.workingArea);
      this.visiblePerformance = this.setVisiblePerformance(this.planning.task.id);
      this.actualVariety = this.planning.variety;
      this.setVariety(this.planning.variety);
    }
    await this.fetchBinsContentFamilies();
    this.setActivitiesName();
    let xhr = await Api.getDataSubfamilyByType('machinery');
    const machineryAttachment = JSON.parse(xhr.response);
    this.setDataMachinaryAttachmentTool(machineryAttachment);
    xhr = await Api.getDataSubfamilyByType('tool');
    const dataTools = JSON.parse(xhr.response);
    this.setDataTool(dataTools);
    xhr = await Api.getAllVariety();
    this.varietyAvailables = JSON.parse(xhr.response);
    this.loaded = true;
    if (this.planning !== null) {
      this.setShowProducts((this.planning.products.length > 0));
    } else {
      this.setShowProducts(false);
    }
    // SI ESTAMOS EN POSTWORK PONEMOS UN CALENDARIO CON MAXIMO DE DIA EL ACTUAL
    // FECHA TOPE EN EL DAILY 2 AÑOS EN EL FUTURO
    // PostWork
    if (this.maxDate !== '') {
      this.minDate = null;
      this.actualDate = [moment().format('YYYY-MM-DD')];
    } else { // Daily
      this.maxDate = moment().add(2, 'Y').format('MM/DD/YYYY');
    }
    EventBus.$on('changeTotalAreaSelected', this.updateWorkingArea);
  },
  methods: {
    dateSelectedEvent(e) {
      this.actualDate = this.formattingDates(e, 'MM/DD/YYYY', 'MM/DD/YYYY');
      this.setDateSelected(this.formattingDates(e, 'MM/DD/YYYY', 'YYYY-MM-DD'));
    },
    performanceUnitSelectEvent(e) {
      const newValue = (e.value) ?? 0;
      this.setPerformanceCount(newValue);
      this.actualPerformanceCount = newValue;
    },
    shiftSelectedEvent(e) {
      this.actualShift = e.value;
      const realValue = this.getValueShift(e.value);
      this.setShift(realValue);
    },
    groupNameEvent(e) {
      this.groupName = e.value;
      this.setGroupName(e.value);
    },
    groupedSelectedEvent(e) {
      this.setGroupedSelected(e.value);
      this.actualGrouped = e.value;
    },
    setActivitiesName() {
      for (const activity of this.dataForm.activityName) {
        this.activitiesName.push(activity.name);
      }
    },
    workingAreaChanged(e) {
      if (e.value === null || e.value < 0) {
        e.value = 0;
      }
      if (e.value > this.totalAreaSelected) {
        e.value = this.totalAreaSelected;
      }
      this.workingArea = e.value;
      this.setWorkingArea(e.value);
    },
    distributedChanged(e) {
      if (e.value === null) {
        e.value = false;
      }
      this.distributed = e.value;
      this.setDistributed(e.value);
    },
    setTasksAvailableName() {
      this.tasksAvailableName = [];
      this.setTaskSelected([]);
      this.tasksAvailable = this.dataForm.taskName.filter(
        (task) => task.activity_code === this.actualActivityCode,
      );
      for (const task of this.tasksAvailable) {
        this.tasksAvailableName.push(task.name);
      }
    },
    getTask(items, name) {
      for (const item of items) {
        if (item.name === name) {
          return item;
        }
      }
      return '';
    },
    activitySelectedEvent(e) {
      this.updateSelectedTask({});
      this.actualActivityName = e.value;
      this.actualActivityCode = this.getTask(this.dataForm.activityName, e.value).code;
      this.setActivitySelected({ name: this.actualActivityName, code: this.actualActivityCode });
      this.actualTaskName = '';
      this.actualTaskCode = '';
      this.setTasksAvailableName();
      this.visiblePerformance = false;
      if (this.actualActivityCode !== '08') {
        this.actualVariety = '';
        this.setVariety('');
      }
    },
    taskSelectedEvent(e) {
      const selectTask = this.getTask(this.tasksAvailable, e.value);
      this.updateSelectedTask(selectTask);
      this.actualTaskName = e.value;
      this.actualTaskCode = selectTask.code;
      this.setTaskSelected({ name: this.actualTaskName, code: this.actualTaskCode, id: selectTask.id });
      this.showProductTable(selectTask);
      this.visiblePerformance = this.setVisiblePerformance(selectTask.id);
      this.setPerformanceCount(0);
      this.actualPerformanceCount = 0;
    },
    showProductTable(selectTask) {
      let valueToShowTable = false;
      if (typeof selectTask.properties !== 'undefined') {
        selectTask.properties.forEach((element) => {
          if (element.key === 'prescription') {
            if (element.value !== 'False') {
              valueToShowTable = true;
            }
          }
        });
      }
      if (!valueToShowTable) {
        this.setNewPrescription();
      }
      this.setShowProducts(valueToShowTable);
    },
    temporarySelectEvent(e) {
      this.updateValueWorkers('TEMPORARY', e.value);
    },
    permanentSelectEvent(e) {
      this.updateValueWorkers('PERMANENT', e.value);
    },
    contratistSelectEvent(e) {
      this.updateValueWorkers('CONTRATIST', e.value);
    },
    updateValueWorkers(workerType, value) {
      const newWorkers = this.workers;
      let updated = false;
      for (let i = 0; i < this.workers.values.length; i += 1) {
        if (this.workers.values[i].type === workerType) {
          newWorkers.values[i].count = (value) ?? 0;
          updated = true;
          break;
        }
      }
      if (!updated) {
        newWorkers.values.push({ type: workerType, count: value });
      }
      for (let j = 0; j < newWorkers.values.length; j += 1) {
        if (newWorkers.values[j].count === 0) {
          newWorkers.values.splice(j, 1);
        }
      }
      this.setWorkers(newWorkers.values);
      this.actualWorkers = this.workers.values;
    },
    getWorkerTypeValue(workerType) {
      for (const workerDetail of this.actualWorkers) {
        if (workerDetail.type === workerType) {
          return workerDetail.count;
        }
      }
      return 0;
    },
    getValueShift(seletedShift) {
      switch (seletedShift) {
        case 'Shift 1':
          return 1;
        case 'Shift 2':
          return 2;
        case 'Shift 3':
          return 3;
        case 1:
          return 'Shift 1';
        case 2:
          return 'Shift 2';
        case 3:
          return 'Shift 3';
        default:
          break;
      }
      return 0;
    },
    setDataMachinaryAttachmentTool(machinery_attachment) {
      this.machinaryList.length = 0;
      this.attachmentList.length = 0;
      for (let i = 0; i < machinery_attachment.length; i += 1) {
        if (machinery_attachment[i].id === 'VEHICLE') {
          for (let j = 0; j < machinery_attachment[i].items.length; j += 1) {
            this.machinaryList.push({
              name: machinery_attachment[i].items[j].id,
              id: machinery_attachment[i].items[j].id,
            });
          }
        } else if (machinery_attachment[i].id === 'ATTACHMENT') {
          for (let j = 0; j < machinery_attachment[i].items.length; j += 1) {
            this.attachmentList.push({
              name: machinery_attachment[i].items[j].id,
              id: machinery_attachment[i].items[j].id,
            });
          }
        }
      }
    },
    setDataTool(tools) {
      this.toolList.length = 0;
      for (let i = 0; i < tools.length; i += 1) {
        if (tools[i].id === 'TOOL') {
          for (let j = 0; j < tools[i].items.length; j += 1) {
            if (tools[i].items[j].items.length > 0) {
              this.toolList.push({
                name: tools[i].items[j].id,
                id: tools[i].items[j].items[0].id,
              });
            }
          }
          break;
        }
      }
    },
    formattingDates(dates, formatOrigin, format = 'DD-MM-YYYY') {
      const formatedDates = [];
      for (const date of dates) {
        formatedDates.push(moment(date, formatOrigin).format(format));
      }
      return formatedDates;
    },
    setVisiblePerformance(taskId) {
      for (const task of this.dataForm.taskName) {
        if (task.id === taskId) {
          if (task.performance_unit !== '') {
            this.setPerformanceUnit(task.performance_unit);
            this.actualPerformanceUnit = task.performance_unit;
            return true;
          }
        }
      }
      this.setPerformanceUnit('');
      return false;
    },
    updateWorkingArea() {
      this.setWorkingArea(this.totalAreaSelected);
      this.workingArea = this.totalAreaSelected;
    },
    varietySelectedEvent(e) {
      this.actualVariety = e.value;
      this.setVariety(e.value);
    },
    sundays(date) {
      return [0].indexOf(date.getDay()) >= 0;
    },
    ...mapActions('PlanningManager', ['setWorkers', 'setActivitySelected', 'setTaskSelected', 'setDateSelected', 'setPerformanceUnit', 'setPerformanceCount', 'setGroupedSelected', 'setShift', 'setGroupName', 'setItems', 'setShowProducts', 'setWorkingArea', 'setDistributed', 'setVariety']),
    ...mapActions('Prescription', ['setNewPrescription']),
    ...mapActions('TaskManager', ['setTotalAreaSelected']),
    ...mapActions('greenhouse', [
      'updateSelectedTask',
      'fetchBinsContentFamilies',
    ]),
  },
};
</script>

<style lang="scss">
#form-container {
  margin: 10px 10px 30px;
}

.long-title h3 {
  font-weight: 200;
  font-size: 28px;
  text-align: center;
  margin-bottom: 20px;
}

.content-activity{
  .dx-box-flex.dx-box.dx-widget.dx-visibility-change-handler.dx-collection{
    padding-top: 0;
    margin-top: -5px;
  }
  &__area{
    background-color: #EAE3D8;
    border-radius: 3px;
    padding: 8px 10px;
    margin: 15px 0;
    span, input{
      color: #6F4343;
      opacity: 1;
      text-transform: none;
      font-size: 14px;
      line-height: 14px;
      font-weight: bold;
    }
    input{
      font-weight: normal;
    }
  }
}
.content-machinery{
  .content-devx{
    margin: 0 0 15px 0!important;
  }
  .dx-form-group-caption{
    padding: 0 0 10px 0!important;
    display: block;
  }
}
</style>
