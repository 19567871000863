<template>
  <f7-page style="overflow: hidden;">
    <navbar :text="$t('PrecisionFarming_navbar_title')" />
    <iframe
      v-if="loaded"
      id="inlineFrameExample"
      title="GIS MAP"
      width="100%"
      height="100%"
      :src="`${url}static/ferrero-gis/${farm}/main/index.html`"
    />
  </f7-page>
</template>

<script>

import navbar from '../../components/NavBar.vue';
import Api from '../../services/Api';

export default {
  components: {
    navbar,
  },
  data() {
    return {
      loaded: false,
      url: '/',
      farm: Api.getAgriFarm(),
    };
  },
  async beforeMount() {
    this.$f7.preloader.show();
    this.loaded = false;
    try {
      const xhr = await Api.getPoints();
      const points = JSON.parse(xhr.response).data;
      localStorage.setItem('currentPoints', JSON.stringify(points));
      localStorage.setItem('currentCountry', Api.getAgriFarm());
      this.loaded = true;
    } catch (e) {
      const message = this.$helpers.getFilteredErrorMessage(e);
      if (message.includes('default_') && !message.includes('404')) {
        this.$f7.dialog.alert(message.split('_').pop());
      } else if (!message.includes('404')) {
        this.$f7.dialog.alert(this.$t(message));
      }
    } finally {
      this.$f7.preloader.hide();
    }
  },
  mounted() {
    this.url = this.$f7.views.main.router.params.url;
  },
};
</script>

<style>

</style>
