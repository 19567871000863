<template>
  <div class="content-devx main-dx-binsinfoform no-shadow">
    <f7-block-title class="no-padding">
      {{ $t('BinsForm_bins_info') }}
    </f7-block-title>
    <DxForm>
      <template v-if="creating">
        <DxSimpleItem
          v-model="currentBinsName"
          :validation-rules="validationRules.name"
          :editor-options="{
            disabled: isBinsNameDisabled,
            mode: 'text',
            onValueChanged: binNameChanged
          }"
          :data-field="$t('DxBinsInfoForm_name_caption')"
        />
      </template>
      <template v-else>
        <DxSimpleItem
          :validation-rules="validationRules.name"
          :editor-options="{
            disabled: isBinsNameDisabled,
            value: currentBinsName,
            mode: 'text',
            onValueChanged: binNameChanged
          }"
          :data-field="$t('DxBinsInfoForm_name_caption')"
        />
      </template>
      <DxSimpleItem
        :data-field="$t('DxBinsInfoForm_date_caption')"
        name="date"
      >
        <template #default>
          <f7-input
            id="datepicker-multiple"
            class="form-calendar"
            type="datepicker"
            disabled
            readonly
            :calendar-params="{
              openIn: 'customModal',
              header: false,
              footer: true,
              dateFormat: 'yyyy-mm-dd'
            }"
            :value="currentDate"
          />
        </template>
      </DxSimpleItem>
      <DxSimpleItem
        name="levelZeroLocation"
        editor-type="dxSelectBox"
        :data-field="$t('DxBinsInfoForm_bins_location_caption')"
        :validation-rules="validationRules.levelZeroLocation"
        :editor-options="{
          dataSource: usersLevelZeroNames,
          searchEnabled: true,
          searchTimeout: searchTimeoutOption,
          minSearchLength: minSearchLengthOption,
          showDataBeforeSearch: showDataBeforeSearchOption,
          onValueChanged: updateSelectedLevelZeroLocation,
          value: binsLocationName,
          disabled: isFarmSelectEnabled
        }"
      />
      <DxSimpleItem
        :validation-rules="validationRules.guide_number"
        :editor-options="{
          disabled: isBinsNameDisabled,
          value: currentBinsGuideNumber,
          mode: 'text',
          onValueChanged: binGuideNumberChanged
        }"
        :data-field="$t('DxBinsInfoForm_guide_number_caption')"
      />
    </DxForm>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';

import moment from 'moment';
import {
  DxForm,
  DxSimpleItem,
} from 'devextreme-vue/form';

import EventBus from '../../js/event-bus';

export default {
  name: 'BinsInfoForm',
  components: {
    DxForm,
    DxSimpleItem,

  },
  props: {
    editing: {
      type: Boolean,
      default: false,
    },
    reclassifying: {
      type: Boolean,
      default: false,
    },
    moving: {
      type: Boolean,
      default: false,
    },
    binsInfo: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      currentBinsName: '',
      currentBinsGuideNumber: '',
      selectedLevelZeroLocation: '',
      searchTimeoutOption: 200,
      minSearchLengthOption: 0,
      showDataBeforeSearchOption: false,
      validationRules: {
        name: [
          {
            type: 'required',
            message: 'Name is required',
          },
        ],
        levelZeroLocation: [
          {
            type: 'required',
            message: 'Farm is required',
          },
        ],
      },
      currentDate: [moment().format('MM/DD/YYYY')],
    };
  },
  computed: {
    ...mapState('greenhouse', ['isLocationDisabled', 'binsLocationName']),
    ...mapGetters('greenhouse',
      [
        'usersLevelZeroNames',
        'locationTreeByName',
      ]),
    isFarmSelectEnabled() {
      return this.editing || this.reclassifying || this.moving;
    },
    creating() {
      return !this.editing && !this.reclassifying && !this.moving;
    },
    isBinsNameDisabled() {
      return this.reclassifying || this.moving;
    },
  },
  beforeMount() {
    if (this.binsInfo && (this.editing || this.reclassifying || this.moving)) {
      this.currentBinsName = this.binsInfo.name;
      this.currentDate = [this.binsInfo.date];
      this.currentBinsGuideNumber = this.binsInfo.guideNumber;
      this.updateBinsGuideNumber(this.binsInfo.guideNumber);
      this.updateBinsLocationName(this.binsInfo.farm);
      this.updateBinsName(this.binsInfo.name);
    }
  },
  methods: {
    ...mapActions('greenhouse',
      [
        'updateBinsLocationName',
        'updateBinsName',
        'updateBinsGuideNumber',
        'fetchBareRootsWithoutBinBySelectedFarm',
      ]),
    async updateSelectedLevelZeroLocation(e) {
      if (e.itemData !== undefined) {
        this.updateBinsLocationName(e.itemData);
      } else if (e.value !== undefined) {
        this.updateBinsLocationName(e.value);
      }
      await this.fetchBareRootsWithoutBinBySelectedFarm();
      EventBus.$emit('level-zero-location-changed');
    },
    binNameChanged(e) {
      this.updateBinsName(e.value);
    },
    binGuideNumberChanged(e) {
      this.updateBinsGuideNumber(e.value);
    },

  },

};
</script>

<style lang="scss" scoped>
.ha-card {
    padding: 20px;
    margin-top: 30px;
}

</style>
