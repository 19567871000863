<template>
  <div
    v-if="loaded"
    class="content-devx main-dx-view-planning-work-order"
  >
    <div id="form-container">
      <f7-row>
        <f7-col
          width="25"
          medium="30"
        >
          <DxViewTreePlanning
            :locations-tree="locationsTree"
            :planning="actualWorkOrder"
            :show-grouped="false"
          />
        </f7-col>
        <f7-col
          width="75"
          medium="70"
        >
          <DxViewWorkOrderForm
            :planning="actualWorkOrder"
            :show-tables="showTables"
            :calendar-multiple="calendarMultiple"
            :max-date="maxDate"
            :distributed-visible="distributedVisible"
            :working-area-disabled="workingAreaDisabled"
          />
        </f7-col>
      </f7-row>
    </div>
  </div>
</template>
<script>

import { mapState, mapActions } from 'vuex';
import * as overlay from 'devextreme/ui/overlay';
import Api from '../../services/Api';
import DxViewTreePlanning from './DxViewTreePlanning.vue';
import DxViewWorkOrderForm from './DxViewWorkOrderForm.vue';

export default {
  name: 'DxPlanningComponent',
  components: {
    DxViewTreePlanning,
    DxViewWorkOrderForm,
  },
  props: {
    showTables: { type: Boolean, default: true },
    calendarMultiple: { type: Boolean, default: true },
    maxDate: { type: String, default: '' },
    distributedVisible: { type: Boolean, default: true },
    workingAreaDisabled: { type: Boolean, default: false },
  },
  data() {
    return {
      loaded: false,
      locationsTree: [],
      activitiesAvailables: [],
      tasksAvailables: [],
      itemTypes: [],
      performanceTypes: [],
    };
  },
  computed: {
    ...mapState('PlanningManager', ['actualWorkOrder']),
  },
  async beforeMount() {
    this.setShowProducts(false);
    overlay.baseZIndex(9999);
    this.$f7.preloader.show();
    try {
      this.locationsTree = await this.loadLocationsTree();
      this.activitiesAvailables = await this.loadActivitiesAvailables();
      this.tasksAvailables = await this.loadTasksAvailables();
    } catch (error) {
      const message = this.$helpers.getFilteredErrorMessage(error);
      if (message.includes('default_') && !message.includes('404')) {
        this.$f7.dialog.alert(message.split('_').pop());
      } else if (!message.includes('404')) {
        this.$f7.dialog.alert(this.$t(message));
      }
    } finally {
      this.$f7.preloader.hide();
    }
    this.loaded = true;
  },
  methods: {
    async loadLocationsTree() {
      const xhr = await Api.getLocationsTree();
      return JSON.parse(xhr.response);
    },
    async loadActivitiesAvailables() {
      const xhr = await Api.getActivities();
      return JSON.parse(xhr.response);
    },
    async loadTasksAvailables() {
      const xhr = await Api.getTasksDefinition();
      return JSON.parse(xhr.response);
    },
    ...mapActions('PlanningManager', ['setShowProducts']),
  },
};
</script>

<style scoped>

</style>
