<template>
  <div
    v-if="loaded"
    class="content-devx content-dx-worker main-dx-machinery"
  >
    <CalendarInitEndDate :key-name="keyName" />
    <DxDataGrid
      id="worker-grid"
      :data-source="machineryFormated"
      :remote-operations="true"
      :column-min-width="90"
      :column-auto-width="true"
      :allow-column-reordering="true"
      :allow-column-resizing="true"
      :show-borders="true"
      :show-column-lines="true"
      :show-row-lines="true"
      :row-alternation-enabled="true"
      @exporting="onExporting"
    >
      <DxSelection
        mode="single"
      />
      <DxStateStoring
        :enabled="true"
        type="localStorage"
        storage-key="DxMachinery"
      />
      <DxExport
        :enabled="true"
      />
      <DxPaging :page-size="10" />
      <DxPager
        :show-page-size-selector="true"
        :allowed-page-sizes="pageSizes"
        :show-info="true"
      />
      <DxHeaderFilter :visible="true" />
      <DxSearchPanel
        :visible="true"
        :width="200"
        :placeholder="$t('Search_placeholder')"
      />
      <DxColumnFixing :enabled="true" />
      <DxColumn
        data-field="date"
        :caption="$t('DxMachinery_Date_caption')"
        :allow-header-filtering="false"
        alignment="left"
      />
      <DxColumn
        data-field="activityCode"
        :caption="$t('DxMachinery_ActivityCode_caption')"
        :allow-header-filtering="true"
        alignment="right"
        width="110"
      />
      <DxColumn
        data-field="activity"
        :caption="$t('DxMachinery_Activity_caption')"
        :allow-header-filtering="true"
        alignment="left"
        css-class="bold"
      />
      <DxColumn
        data-field="taskCode"
        :caption="$t('DxMachinery_CodeTask_caption')"
        :allow-header-filtering="true"
        alignment="right"
        width="90"
      />
      <DxColumn
        data-field="task"
        :caption="$t('DxMachinery_Task_caption')"
        :allow-header-filtering="true"
        alignment="left"
        css-class="bold"
      />
      <DxColumn
        data-field="name"
        :caption="$t('DxMachinery_Name_caption')"
        :allow-header-filtering="true"
        alignment="left"
      />
      <DxColumn
        data-field="internalCode"
        :caption="$t('DxMachinery_InternalCode_caption')"
        :allow-header-filtering="true"
        alignment="left"
      />
      <DxColumn
        data-field="attachment"
        :caption="$t('DxMachinery_Attachment_caption')"
        :allow-header-filtering="true"
        alignment="left"
      />
      <DxColumn
        data-field="internalCodeAttachment"
        :caption="$t('DxMachinery_AttachmentInternalCode_caption')"
        :allow-header-filtering="true"
        alignment="left"
      />
      <DxColumn
        data-field="variety"
        :caption="$t('DxMachinery_Variety_caption')"
        :allow-header-filtering="true"
        alignment="center"
      />
      <DxColumn
        data-field="worker"
        :caption="$t('DxMachinery_Worker_caption')"
        :allow-header-filtering="false"
        alignment="left"
      />
      <DxColumn
        data-field="workingArea"
        :caption="$t('DxMachinery_workingArea_caption')"
        :allow-header-filtering="true"
        alignment="right"
      />
      <DxColumn
        data-field="location"
        :caption="$t('DxWorker_territorialUnit_caption')"
        :allow-header-filtering="true"
        alignment="left"
      />
      <DxColumn
        data-field="kmStart"
        :caption="$t('DxMachinery_init_caption')"
        :allow-header-filtering="false"
        alignment="center"
      />
      <DxColumn
        data-field="kmFinish"
        :caption="$t('DxMachinery_end_caption')"
        :allow-header-filtering="false"
        alignment="center"
      />
      <DxColumn
        data-field="total"
        :caption="$t('DxMachinery_total_caption')"
        :allow-header-filtering="false"
        alignment="center"
      />
      <DxColumn
        data-field="horometerStart"
        :caption="$t('DxMachinery_horometer_start_caption')"
        :allow-header-filtering="false"
        alignment="center"
      />
      <DxColumn
        data-field="horometerEnd"
        :caption="$t('DxMachinery_horometer_end_caption')"
        :allow-header-filtering="false"
        alignment="center"
      />
      <DxColumn
        data-field="horometerTotal"
        :caption="$t('DxMachinery_horometer_total_caption')"
        :allow-header-filtering="false"
        alignment="center"
      />
      <DxColumn
        data-field="fuelType"
        :caption="$t('DxMachinery_typeFuel_caption')"
        :allow-header-filtering="false"
        alignment="center"
      />
      <DxColumn
        data-field="fuelConsumption"
        :caption="$t('DxMachinery_quantityFuel_caption')"
        :allow-header-filtering="false"
        alignment="center"
      />
      <DxColumn
        data-field="performance"
        :caption="$t('DxMachinery_performance_caption')"
        :allow-header-filtering="false"
        alignment="center"
      />
      <DxColumn
        data-field="unit"
        :caption="$t('DxMachinery_unit_caption')"
        :allow-header-filtering="true"
        alignment="center"
      />
      <DxColumn
        data-field="comment"
        :caption="$t('DxMachinery_comment_caption')"
        :allow-header-filtering="false"
        alignment="left"
        :width="300"
      />
      <DxColumn
        data-field="shortId"
        :caption="$t('DxWorkOrder_short_id')"
        :allow-header-filtering="false"
        alignment="left"
        css-class="bold"
      />
      <DxColumn
        data-field="status"
        name="status"
        :caption="$t('DxDailyPlanning_task_status_caption')"
        alignment="center"
        :allow-header-filtering="true"
        :header-filter="{ dataSource: dataTaskStatus }"
        cell-template="taskStatusTemplate"
      />
      <DxColumn
        data-field="worder_status"
        name="worder_status"
        width="160"
        alignment="center"
        :caption="$t('DxDailyPlanning_work_order_status_caption')"
        :allow-header-filtering="true"
        :header-filter="{ dataSource: dataWordTaskStatus }"
        cell-template="workOrderStatusTemplate"
      />
      <template #taskStatusTemplate="{ data: machineryFormated }">
        <div :class="machineryFormated.data.taskStatusTemplateClass">
          {{ machineryFormated.data.taskStatusTranslated }}
        </div>
      </template>
      <template #workOrderStatusTemplate="{ data: machineryFormated }">
        <div :class="machineryFormated.data.workOrderStatusTemplateClass">
          {{ machineryFormated.data.workOrderStatusTranslated }}
        </div>
      </template>
      <DxScrolling column-rendering-mode="virtual" />
    </DxDataGrid>
  </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex';
import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxSearchPanel,
  DxExport,
  DxScrolling,
  DxColumnFixing,
  DxHeaderFilter,
  DxStateStoring,
  DxPager,
  DxSelection,
} from 'devextreme-vue/data-grid';
import { exportDataGrid } from 'devextreme/excel_exporter';
import ExcelJS from 'exceljs';
import saveAs from 'file-saver';
import Api from '../../services/Api';
import CalendarInitEndDate from '../CalendarInitEndDate.vue';

export default {
  name: 'DxFertilizersDataBase',
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxSearchPanel,
    DxExport,
    DxScrolling,
    DxColumnFixing,
    DxHeaderFilter,
    DxStateStoring,
    CalendarInitEndDate,
    DxPager,
    DxSelection,
  },
  data() {
    return {
      loaded: false,
      machineryFormated: [],
      keyName: 'reportingMachinery',
      pageSizes: [5, 10, 20],
      machineryFormatedTwo: [],
      dataTaskStatus: [{
        text: this.$t('Status_Open'),
        value: 'OPEN',
      }, {
        text: this.$t('Status_Completed'),
        value: 'COMPLETED',
      },
      {
        text: this.$t('Status_InProgress'),
        value: 'In_Progress',
      }],
      dataWordTaskStatus: [{
        text: this.$t('Status_reallyNot_Closed'),
        value: 'OPEN',
      }, {
        text: this.$t('Status_Closed'),
        value: 'COMPLETED',
      }],
      workOrderStatusTranslated: '',
      taskStatusTranslated: '',
    };
  },
  computed: {
    ...mapGetters('CalendarInitEndDate', ['actualFilter']),
    ...mapGetters('greenhouse', ['getLocationNameLevelSelectedChildId']),
  },
  async beforeMount() {
    this.$f7.preloader.show();
    try {
      await this.fetchLocationTree();
      let xhr = await Api.getDataSubfamilyByType('MACHINERY');
      const machineryAvailables = JSON.parse(xhr.response);
      for (let i = 0; i < machineryAvailables.length; i += 1) {
        if (machineryAvailables[i].id === 'ATTACHMENT') {
          for (let j = 0; j < machineryAvailables[i].items.length; j += 1) {
            for (let x = 0; x < machineryAvailables[i].items[j].items.length; x += 1) {
              this.machineryFormatedTwo.push({
                description: machineryAvailables[i].items[j].items[x].description,
                internalCodeAttachment: this.$helpers.getProperty(machineryAvailables[i].items[j].items[x].properties, 'internal_code'),
              });
            }
          }
        }
      }
      const checkedInitEndDate = Api.checkInitEndDate(this.actualFilter[this.keyName]);
      xhr = await Api.getMachinerySummary(checkedInitEndDate.init, checkedInitEndDate.end);
      const reportingMachinery = JSON.parse(xhr.response).data;
      // eslint-disable-next-line guard-for-in
      for (let i = 0; i < reportingMachinery.length; i += 1) {
        let internalCodeAttachment = '';
        let worker = '';
        let workerType = '';
        let name = '';
        let attachment = '';
        let internalCode = '';
        let variety = '';
        let workingArea = '';
        let unit = '';
        let kmStart = '';
        let kmFinish = '';
        let total = '';
        let fuelType = '';
        let fuelConsumption = '';
        let performance = '';
        let horometerStart = '';
        let horometerEnd = '';
        let horometerTotal = '';
        for (let j = 0; j < reportingMachinery[i].machinery.length; j += 1) {
          // Recorro machineryFormatedTwo para obtener el internal code de attachment.
          for (let k = 0; k < this.machineryFormatedTwo.length; k += 1) {
            if (reportingMachinery[i].machinery[j].attachment === this.machineryFormatedTwo[k].description) {
              internalCodeAttachment = this.machineryFormatedTwo[k].internalCodeAttachment;
            }
          }
          worker = reportingMachinery[i].machinery[j].worker;
          workerType = reportingMachinery[i].machinery[j].workerType;
          name = reportingMachinery[i].machinery[j].name;
          attachment = reportingMachinery[i].machinery[j].attachment;
          internalCode = reportingMachinery[i].machinery[j].internal_code;
          variety = reportingMachinery[i].machinery[j].variety;
          workingArea = reportingMachinery[i].machinery[j].working_area !== null ? `${reportingMachinery[i].machinery[j].working_area} ha` : '';
          unit = reportingMachinery[i].machinery[j].unit;
          kmStart = reportingMachinery[i].machinery[j].km_start !== null ? `${reportingMachinery[i].machinery[j].km_start} km` : '';
          kmFinish = reportingMachinery[i].machinery[j].km_finish !== null ? `${reportingMachinery[i].machinery[j].km_finish} km` : '';
          total = this.getTotal(reportingMachinery[i].machinery[j].km_start, reportingMachinery[i].machinery[j].km_finish) !== 0 ? `${this.getTotal(reportingMachinery[i].machinery[j].km_start, reportingMachinery[i].machinery[j].km_finish)} km` : '';
          fuelType = reportingMachinery[i].machinery[j].fuel_type;
          fuelConsumption = reportingMachinery[i].machinery[j].fuel_consumption !== null ? `${reportingMachinery[i].machinery[j].fuel_consumption} L` : 'L';
          performance = reportingMachinery[i].machinery[j].performance;
          horometerStart = reportingMachinery[i].machinery[j].horometer_start !== null ? `${reportingMachinery[i].machinery[j].horometer_start} ${this.$t('HOURS')}` : '';
          horometerEnd = reportingMachinery[i].machinery[j].horometer_finish !== null ? `${reportingMachinery[i].machinery[j].horometer_finish} ${this.$t('HOURS')}` : '';
          horometerTotal = this.getTotal(reportingMachinery[i].machinery[j].horometer_start, reportingMachinery[i].machinery[j].horometer_finish) !== 0 ? `${this.getTotal(reportingMachinery[i].machinery[j].horometer_start, reportingMachinery[i].machinery[j].horometer_finish)} ${this.$t('HOURS')}` : '';

          this.machineryFormated.push({
            date: reportingMachinery[i].date,
            task: reportingMachinery[i].task,
            taskType: reportingMachinery[i].task_type,
            taskCode: reportingMachinery[i].task_code,
            activity: reportingMachinery[i].activity,
            activityCode: reportingMachinery[i].task_code.substr(0, 2),
            worker,
            workerType,
            name,
            attachment,
            internalCode,
            workingArea,
            variety,
            unit,
            kmStart,
            kmFinish,
            total,
            fuelType,
            performance,
            fuelConsumption,
            location: this.getNamesByBdIds(reportingMachinery[i].locations),
            comment: reportingMachinery[i].comment,
            internalCodeAttachment,
            shortId: reportingMachinery[i].short_id,
            horometerStart,
            horometerEnd,
            horometerTotal,
            worder_status: reportingMachinery[i].worder_status,
            status: reportingMachinery[i].status,
            taskStatusTemplateClass: this.setClassByTaskStatus(reportingMachinery[i].status),
            workOrderStatusTemplateClass: this.setClassByWorkOrderStatus(reportingMachinery[i].worder_status),
            workOrderStatusTranslated: this.workOrderStatusTranslated,
            taskStatusTranslated: this.taskStatusTranslated,
          });
        }
      }
    } catch (e) {
      const message = this.$helpers.getFilteredErrorMessage(e);
      if (message.includes('default_') && !message.includes('404')) {
        this.$f7.dialog.alert(message.split('_').pop());
      } else if (!message.includes('404')) {
        this.$f7.dialog.alert(this.$t(message));
      }
    } finally {
      this.$f7.preloader.hide();
      this.loaded = true;
    }
  },
  methods: {
    ...mapActions('greenhouse', ['fetchLocationTree']),
    getTotal(init, end) {
      const total = end - init;
      return total.toFixed(2);
    },
    onExporting(e) {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('reportingMachinery');
      exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
      }).then(() => {
        workbook.xlsx.writeBuffer()
          .then((buffer) => {
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'reportingMachinery.xlsx');
          });
      });
      e.cancel = true;
    },
    getNamesByBdIds(ids) {
      const names = [];
      const level = 1;
      for (const id of ids) {
        const newName = this.getLocationNameLevelSelectedChildId(id, level);
        if (names.indexOf(newName) === -1) {
          names.push(newName);
        }
      }
      return names;
    },
    setClassByTaskStatus(status) {
      let colorCell = '';
      switch (status) {
        case 'OPEN':
          colorCell = 'open';
          this.taskStatusTranslated = this.$t('Status_Open');
          break;
        case 'IN_PROGRESS':
          colorCell = 'in-progress';
          this.taskStatusTranslated = this.$t('Status_InProgress');
          break;
        case 'COMPLETED':
          colorCell = 'completed';
          this.taskStatusTranslated = this.$t('Status_Completed');
          break;
        case 'PENDING':
          colorCell = 'pending';
          this.taskStatusTranslated = this.$t('Status_Pending');
          break;
        case 'DELETED':
          colorCell = 'deleted';
          this.taskStatusTranslated = this.$t('Status_Deleted');
          break;
        default:
          break;
      }
      return colorCell;
    },
    setClassByWorkOrderStatus(status) {
      let colorCell = '';
      switch (status) {
        case 'COMPLETED':
          colorCell = 'work-order-closed';
          this.workOrderStatusTranslated = this.$t('Closed');
          break;
        case null:
        default:
          colorCell = 'work-order-not-closed';
          this.workOrderStatusTranslated = this.$t('Not Closed');
          break;
      }
      return colorCell;
    },
  },
};
</script>
<style scoped>
.content-status-cell {
  padding: 0;
}
.open, .in-progress, .completed, .pending, .deleted, .rejected, .work-order-closed, .work-order-not-closed{
  border-radius: 2px;
  font-size: 12px;
  padding: 4px 2px;
  font-weight: 600;
  text-transform: uppercase;
}

.open {
  color: var(--fr-open);
  background: var( --f7-checkbox-inactive-color);
}
.in-progress {
  background: var(--ha-yellow);
  color: var(--fr-inprogress-tint);
}
.completed {
  background: var(--fr-finished);
  color: var(--fr-finished-tint);
}
.pending {
  color: var(--fr-pending);
  background: var(--fr-pending-tint);
}
.deleted {
  color: var(--fr-deleted);;
  background: var(--fr-deleted-tint);
}
.work-order-closed{
  color: var(--ha-dark-green);
  background: var(--ha-light-green);
}
.work-order-not-closed{
  color: #ffffff;
  background: var(--fr-nutella);
}
</style>
