var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main-selectWarehouse"},[_c('DxForm',{attrs:{"id":"form","col-count":1}},[_c('DxSimpleItem',{attrs:{"editor-options":{
        items: _vm.availableWarehouses,
        displayExpr:'name',
        valueExpr:'code',
        searchEnabled: true,
        onValueChanged: _vm.changeWarehouse,
        width: 400,
        value: _vm.actualWarehouse,
      },"editor-type":"dxSelectBox"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }