<template>
  <f7-block v-if="!loaded">
    <div class="loadingFalse">
      <div class="preloader" />
    </div>
  </f7-block>
  <f7-block v-else>
    <DxDataGrid
      if="effortGroupedGrid"
      :data-source="effortFormated"
      :show-borders="true"
      :remote-operations="true"
      :allow-column-reordering="true"
      :column-auto-width="true"
      :on-content-ready="dataFiltered"
      @exporting="onExporting"
    >
      <DxStateStoring
        :enabled="true"
        type="localStorage"
        storage-key="DxEffortActivity"
      />
      <DxHeaderFilter :visible="true" />
      <DxGroupPanel :visible="false" />
      <DxGrouping
        :auto-expand-all="false"
      />
      <DxPaging :page-size="10" />
      <DxExport
        :enabled="true"
      />
      <DxSearchPanel
        :visible="true"
        :width="200"
        :placeholder="$t('Search_placeholder')"
      />
      <DxColumn
        data-field="level"
        :caption="$t('DxEffort_level_caption')"
        :allow-header-filtering="true"
      />
      <DxColumn
        data-field="activity"
        :group-index="0"
        :caption="$t('DxEffort_activity_caption')"
        :allow-header-filtering="false"
      />
      <DxColumn
        data-field="activity"
        :caption="$t('DxEffort_activity_caption')"
        :allow-header-filtering="true"
      />
      <DxColumn
        data-field="totalHours"
        :caption="$t('DxEffortActivity_totalHours_caption')"
        alignment="right"
        :allow-header-filtering="false"
      />
      <DxColumn
        data-field="numberWorker"
        :caption="$t('DxEffort_activity_numberWorker_caption')"
        alignment="right"
        :allow-header-filtering="false"
      />
      <DxColumn
        data-field="totalHoursHa"
        :caption="$t('DxEffortActivity_totalHoursHa_caption')"
        alignment="right"
        :allow-header-filtering="false"
      />
      <DxColumn
        data-field="temporary"
        alignment="right"
        :allow-header-filtering="false"
        :visible="false"
      />
      <DxColumn
        data-field="permanent"
        alignment="right"
        :allow-header-filtering="false"
        :visible="false"
      />
      <DxColumn
        data-field="contratist"
        alignment="right"
        :allow-header-filtering="false"
        :visible="false"
      />
    </DxDataGrid>
  </f7-block>
</template>
<script>

import {
  DxDataGrid,
  DxColumn,
  DxSearchPanel,
  DxExport,
  DxGrouping,
  DxGroupPanel,
  DxPaging,
  DxHeaderFilter,
  DxStateStoring,
} from 'devextreme-vue/data-grid';
import { exportDataGrid } from 'devextreme/excel_exporter';
import ExcelJS from 'exceljs';
import saveAs from 'file-saver';
import * as overlay from 'devextreme/ui/overlay';
import { mapState, mapActions, mapGetters } from 'vuex';
import moment from 'moment';
import Api from '../../services/Api';
import EventBus from '../../js/event-bus';

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxSearchPanel,
    DxExport,
    DxGrouping,
    DxGroupPanel,
    DxPaging,
    DxHeaderFilter,
    DxStateStoring,
  },
  props: {
    init: {
      type: String,
      default: moment.utc().startOf('year').format('YYYY-MM-DD'),
    },
    end: {
      type: String,
      default: moment.utc().add(1, 'd').format('YYYY-MM-DD'),
    },
  },
  data() {
    return {
      loaded: false,
      effort: [],
      effortFormated: [],
      locationsAvailables: [],
      activityAvailables: [],
      event: 'updateEffort',
    };
  },
  computed: {
    ...mapState('Reporting', ['selectedFarmList', 'locationsTree']),
  },
  mounted() {
    overlay.baseZIndex(9999);
    EventBus.$on(this.event, this.updateLocations);
  },
  beforeDestroy() {
    EventBus.$off(this.event, this.updateLocations);
  },
  methods: {
    // ---- OBTENCIÓN Y FORMATEO DE LOCATIONS ----
    async getLocationsAvailables() {
      for (const item of this.locationsTree) {
        this.setLocationTreeFormat(item);
        this.recursiveAddChildrens(item.children);
      }
    },
    recursiveAddChildrens(childrens) {
      for (let i = 0; i < childrens.length; i += 1) {
        this.setLocationTreeFormat(childrens[i]);
        this.recursiveAddChildrens(childrens[i].children);
      }
    },
    setLocationTreeFormat(item) {
      this.locationsAvailables.push({
        name: item.name,
        bd_id: item.bd_id,
        area: item.area,
        ID: item.ID,
        HEAD_ID: item.HEAD_ID,
        children: item.children,
      });
    },
    // ---- OBTENCIÓN LOCATIONS ----
    async getActivityAvailables() {
      const xhr = await Api.getActivities();
      for (const activity of JSON.parse(xhr.response)) {
        this.activityAvailables.push(activity);
      }
      return JSON.parse(xhr.response);
    },
    // ---- OBTENCIóN Y FORMATEO DE ACTIVITIES Y TASKS ----
    async getEffort() {
      const xhr = await Api.getEffortByGrouped(this.selectedFarmList, this.init, this.end, 'activity');
      return JSON.parse(xhr.response);
    },
    formattingEffort() {
      this.effortFormated.length = 0;
      const totalHoursLocations = this.getTotalHoursLocations();
      for (const item of this.effort) {
        this.effortFormated.push({
          level: this.getLocationNameById(item.location),
          activity: this.getActivityNameById(item.activity),
          hrHours: (item.parameters.hours).toFixed(2).toString(),
          totalHours: (item.parameters.hours).toFixed(2).toString(),
          ha: (this.getAreaLocationById(item.location)).toFixed(2).toString(),
          totalHoursHa: ((item.parameters.hours / this.getAreaLocationById(item.location))).toFixed(2).toString(),
          totalHoursTotalHa: `${((item.parameters.hours * 100) / totalHoursLocations).toFixed(2)}%`,
          numberWorker: item.parameters.workers,
          permanent: typeof item.parameters.type_hours.PERMANENT === 'undefined' ? 0 : item.parameters.type_hours.PERMANENT,
          contratist: typeof item.parameters.type_hours.CONTRATIST === 'undefined' ? 0 : item.parameters.type_hours.CONTRATIST,
          temporary: typeof item.parameters.type_hours.TEMPORARY === 'undefined' ? 0 : item.parameters.type_hours.TEMPORARY,
        });
      }
    },
    getTotalHoursLocations() {
      let totalHours = 0;
      for (const item of this.effort) {
        totalHours += item.parameters.hours;
      }
      return totalHours;
    },
    getLocationNameById(id) {
      for (const location of this.locationsAvailables) {
        if (location.bd_id === id) {
          return location.name;
        }
      }
      return '';
    },
    getActivityNameById(id) {
      for (const activity of this.activityAvailables) {
        if (activity.code === id) {
          return activity.name;
        }
      }
      return '';
    },
    getAreaLocationById(id) {
      for (const location of this.locationsAvailables) {
        if (location.bd_id === id) {
          return location.area;
        }
      }
      return 0;
    },
    onExporting(e) {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('CurrentActivityEffort');
      exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
      }).then(() => {
        workbook.xlsx.writeBuffer()
          .then((buffer) => {
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'CurrentActivityEffort.xlsx');
          });
      });
      e.cancel = true;
    },
    async updateLocations() {
      this.loaded = false;
      try {
        await this.getLocationsAvailables();
        await this.getActivityAvailables();
        this.effort = await this.getEffort();
        this.formattingEffort();
        this.loaded = true;
      } catch (error) {
        const message = this.$helpers.getFilteredErrorMessage(error);
        if (message.includes('default_') && !message.includes('404')) {
          this.$f7.dialog.alert(message.split('_').pop());
        } else if (!message.includes('404')) {
          this.$f7.dialog.alert(this.$t(message));
        }
      } finally {
        this.loaded = true;
      }
    },
    dataFiltered(e) {
      this.setEfforFiltered(e.component.getDataSource().items());
    },
    ...mapActions('Reporting', ['setEfforFiltered']),
  },

};
</script>
