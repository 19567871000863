<template>
  <div class="container">
    <f7-searchbar
      search-container=".accordion-list"
      search-item="li"
      search-in=".item-title"
      :disable-button="!$theme.aurora"
    />
    <f7-list class="searchbar-not-found">
      <f7-list-item title="Nothing found" />
    </f7-list>
    <f7-list
      class="searchbar-found"
      accordion-list
      :virtual-list-params="vlParams">
      <f7-list-item
        style="background-color: #D6B35A;"
        name="lstNewTasks"
        accordion-item
        :title="$t('TasksList_lstNewTasks_title')"
        :badge="`${newTasks.length}`"
      >
        <strong>
          <f7-accordion-content>
            <f7-list>
              <f7-list-item
                v-for="(item, index) in newTasks"
                :key="index"
                accordion-list
                :title="'('+item.code+') ' + item.name"
                overflow-hiden
              >
                <f7-row>
                  <f7-col
                    class="right"
                    style="padding-top:0px;"
                    @click="irLocationSelect(item)"
                  >
                    <f7-icon :class="`icon-menu icon-play`" />
                  </f7-col>
                </f7-row>
              </f7-list-item>
            </f7-list>
          </f7-accordion-content>
        </strong>
      </f7-list-item>
      <f7-list-item
        style="background-color: #D6B35A;"
        name="lstInProgressTasks"
        accordion-item
        :title="$t('TasksList_lstInProgressTasks_title')"
        :badge="`${currentTasks.length}`"
      >
        <f7-accordion-content>
          <f7-list>
            <f7-list-item-row
              v-for="(item, index) in currentTasks"
              :key="index"
            >
              <f7-col
                style="width:60%"
                @click="navigateTo('/tasks/workersSelection/', item)"
              >
                <f7-block>
                  {{ item.name }}
                </f7-block>
                <f7-block style="color:rgb(80, 80, 80)">
                  <p>{{ $t("WorkersInTask") }}: {{ item.workers.length }}</p>
                </f7-block>
              </f7-col>
              <f7-col
                style="width:30%"
                @click="navigateTo('/tasks/workersSelection/', item)">
                {{ item.location }}
              </f7-col>
              <f7-col
                v-if="item.workers.length !== 0"
                style="width:10%"
                @click="navigateTo('/nfc/', item)"
              >
                <f7-icon
                  :class="`icon-menu icon-stop`"
                  style="width: 100%; height: 100%;"
                />
              </f7-col>
              <f7-col
                v-else
                style="width:10%"
                @click="deleteTask(item)"
              >
                <f7-icon
                  :class="`icon-menu icon-delete`"
                  style="width: 100%; height: 100%;"
                />
              </f7-col>
            </f7-list-item-row>
          </f7-list>
        </f7-accordion-content>
      </f7-list-item>
      <f7-list-item
        style="background-color: #D6B35A;"
        name="lstCompletedTasks"
        accordion-item
        :title="$t('TasksList_lstCompletedTasks_title')"
        :badge="`${completedTasks.length}`"
      >
        <f7-accordion-content>
          <f7-list>
            <f7-list-item
              v-for="item in completedTasks"
              :key="item.id"
              :title="item.name"
              @click="navigateTo('/tasks/workersSelection/', item)"
            >
              <p>{{ $t("WorkersInTask") }}: {{ item.workers.length }}</p>
              <f7-row>
                <f7-col class="right">
                  {{ item.location }}
                </f7-col>
              </f7-row>
            </f7-list-item>
          </f7-list>
        </f7-accordion-content>
      </f7-list-item>
    </f7-list>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import Api from '../../services/Api';

export default {
  name: 'AccordionList',
  data() {
    return {
      vlParams: {
        items: this.tasklist,
        searchAll: this.searchAll,
        renderExternal: this.renderExternal,
        // height: this.$theme.ios ? 63 : (this.$theme.md ? 73 : 46)
      },
      newTasks: [],
      currentTasks: [],
      completedTasks: [],
    };
  },
  computed: {
    ...mapState('TaskManager', ['farm', 'activityId']),
  },
  async beforeMount() {
    this.updateList();
  },
  methods: {
    searchAll(query, items) {
      const result = [];
      const lowerQuery = query.toLowerCase();
      items.forEach((item, i) => {
        if (
          item.name.toLowerCase().includes(lowerQuery)
        ) {
          result.push(i);
        }
      });
      return result;
    },
    renderExternal(vl, vlData) {
      this.vlData = vlData;
    },
    itemsCount(array) {
      const count = array.length;
      return count === 1
        ? `${count} ${this.$t('AccordionList_worker')}`
        : `${count} ${this.$t('AccordionList_workers')}`;
    },
    navigateTo(path, item) {
      this.setLocations(item.location);
      this.setLocationName(item.location);
      this.setNewTask(item.task);
      this.setId(item.id);
      this.setTaskName(item.name);
      this.setTaskCode(item.code);
      this.setWorkers(item.workers);
      this.$f7.views.main.router.navigate(path);
    },
    irLocationSelect(item) {
      const self = this;
      const router = self.$f7router;
      this.setLocations(item.locations);
      this.setNewTask(item.id);
      this.setTaskName(item.name);
      this.setTaskCode(item.code);
      this.setTaskUnit(item.unit);
      this.setTaskPrice(item.price);
      router.navigate('/locationSelect/', { reloadCurrent: true });
    },
    createSheet() {
      const self = this;
      const $ = self.$$;
      // Create sheet modal
      if (!self.sheet) {
        self.sheet = self.$f7.sheet.create({
          content: `
            <div class="sheet-modal" style="position: absolute;height: 147px;left: 0px;top: 493px;border-radius: 8px;background: #F7F8FA;box-shadow: 0px -2px 21px rgba(0, 0, 0, 0.301602);border-radius: 4px;">
              
              <div class="toolbar" style="position:absolute;bottom:0px;border-top: 1px solid #D4DCE1;">
                <div class="toolbar-inner justify-content-flex-end">
                  <a href="#" class="link sheet-close center" style="font-family: Roboto;font-style: normal;font-weight: 500;font-size: 20px;line-height: 23px;color: #24A436;right:30px;">Confirmar</a>
                </div>
                <div class="toolbar-inner ">
                  <a href="#" class="link sheet-close center" style="font-family: Roboto;font-style: normal;font-weight: 500;font-size: 18px;line-height: 20px;color: #9B9B9B;left:30px;">Cancel</a>
                </div>
              </div>
              <div class="sheet-modal-inner">
                <div class="page-content">
                  <div class="row">
                    <div class="col-30" style="position: absolute;width: 56px;height: 56px;left: 26.97px;top: 23.52px;"> 
                      <img src="../../static/img/fin_manifiesto.png" alt="Fin actividad" >
                    </div>
                    <div class="col-70" style="position: absolute;height: 50px;left: 29%; right: 0%; top: calc(50% - 75px/2 + 0.5px);font-family: Roboto;font-style: normal;font-weight: normal;font-size: 19px;line-height: 22px;color: #434E6F;">
                      <p>¿Finalizar activdades de todos los trabajadores?</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          `.trim(),
        });
      }
      if ($('.demo-sheet.modal-in').length > 0) self.$f7.sheet.close('.demo-sheet');
      // Open it
      self.sheet.open();
    },
    async deleteTask(item) {
      this.$f7.preloader.show();
      try {
        await Api.deleteTask(item.id);
        // TODO Mostrar ventana de que se borro la tarea.
        this.updateList();
        if (this.$f7.device.cordova) {
          this.$f7.views.main.router.navigate('/tasks/list/', { reloadCurrent: true });
        }
      } catch (error) {
        const message = this.$helpers.getFilteredErrorMessage(error);
        if (message.includes('default_') && !message.includes('404')) {
          this.$f7.dialog.alert(message.split('_').pop());
        } else if (!message.includes('404')) {
          this.$f7.dialog.alert(this.$t(message));
        }
      } finally {
        this.$f7.preloader.hide();
      }
    },
    async updateList() {
      this.$f7.preloader.show();
      try {
        this.newTasks.length = 0;
        this.currentTasks.length = 0;
        this.completedTasks.length = 0;
        const xhr = await Api.getTasks(this.farm, this.activityId);
        const xhrFormat = JSON.parse(xhr.response);
        for (let i = 0; i < xhrFormat.new_task.length; i += 1) {
          this.newTasks.push({
            id: xhrFormat.new_task[i].id,
            locations: xhrFormat.new_task[i].locations,
            name: xhrFormat.new_task[i].name,
            price: xhrFormat.new_task[i].price,
            unit: xhrFormat.new_task[i].unit,
            code: xhrFormat.new_task[i].code,
          });
        }
        for (let i = 0; i < xhrFormat.current_task.length; i += 1) {
          this.currentTasks.push({
            id: xhrFormat.current_task[i].id,
            task: xhrFormat.current_task[i].task,
            name: xhrFormat.current_task[i].name,
            location: xhrFormat.current_task[i].location,
            workers: xhrFormat.current_task[i].workers,
            status: xhrFormat.current_task[i].status,
            code: xhrFormat.current_task[i].task_code,
          });
        }
        for (let i = 0; i < xhrFormat.completed_task.length; i += 1) {
          this.completedTasks.push({
            id: xhrFormat.completed_task[i].id,
            name: xhrFormat.completed_task[i].name,
            location: xhrFormat.completed_task[i].location,
            status: xhrFormat.completed_task[i].status,
            workers: xhrFormat.completed_task[i].workers,
            code: xhrFormat.completed_task[i].task_code,
          });
        }
        this.vlData = this.newTasks + this.currentTask + this.completedTasks;
      } catch (e) {
        const message = this.$helpers.getFilteredErrorMessage(e);
        if (message.includes('default_') && !message.includes('404')) {
          this.$f7.dialog.alert(message.split('_').pop());
        } else if (!message.includes('404')) {
          this.$f7.dialog.alert(this.$t(message));
        }
      } finally {
        this.$f7.preloader.hide();
      }
    },
    ...mapActions('TaskManager', [
      'setLocations',
      'setId',
      'setTaskName',
      'setWorkers',
      'setTaskUnit',
      'setTaskPrice',
      'setNewTask',
      'setLocationName',
      'setTaskCode',
    ]),
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/styles/tasks/accordionList.scss";
.row {
  position: absolute;
  right: 20px;
  top: 5px;
}

ul {
  color: white;
}
.accordion-item-content {
  color: black !important;
}
li.accordion-item {
  color: white;
}
.badge::before {
  position: absolute !important;
  right: 52px !important;
  top: 13px !important;
  width: 44px !important;
}
</style>
