<template>
  <div
    v-if="loaded"
    class="content-devx main-dx-machinery-database"
  >
    <DxDataGrid
      id="toolsGrid"
      :data-source="toolsFormated"
      :column-min-width="90"
      :column-auto-width="true"
      :allow-column-reordering="true"
      :show-borders="true"
      :show-column-lines="true"
      :show-row-lines="true"
      :row-alternation-enabled="true"
      @exporting="onExporting"
      @toolbar-preparing="onToolbarPreparing($event)"
    >
      <DxSelection
        mode="single"
      />
      <DxStateStoring
        :enabled="true"
        type="localStorage"
        storage-key="DxMachineryDatabase"
      />
      <DxExport
        :enabled="true"
      />
      <DxHeaderFilter :visible="true" />
      <DxPaging :page-size="10" />
      <DxPager
        :show-page-size-selector="true"
        :allowed-page-sizes="pageSizes"
        :show-info="true"
      />
      <DxSearchPanel
        :visible="true"
        :width="200"
        :placeholder="$t('Search_placeholder')"
      />
      <DxColumn
        v-if="$helpers.havePermission(getPermissions.company_update_master_data)"
        type="buttons"
        data-field="Actions"
      >
        <DxButton
          icon="edit"
          :hint="$t('DxDailyPlanning_edit_hint')"
          :on-click="editTools"
        />
      </DxColumn>
      <DxColumn
        data-field="statusColumn"
        name="status"
        :caption="$t('DxToolsDatabase_column_status')"
        :allow-header-filtering="true"
      />
      <DxColumn
        data-field="code"
        name="code"
        :caption="$t('DxToolsDatabase_column_code')"
        :allow-header-filtering="false"
      />
      <DxColumn
        data-field="description"
        name="description"
        :caption="$t('DxToolsDatabase_column_description')"
        :allow-header-filtering="false"
      />
      <DxColumn
        data-field="family"
        name="family"
        :caption="$t('DxToolsDatabase_column_family')"
        :allow-header-filtering="true"
      />
      <DxColumn
        data-field="subfamily"
        name="subfamily"
        :caption="$t('DxToolsDatabase_column_subfamily')"
        :allow-header-filtering="true"
      />
      <DxColumn
        data-field="id"
        name="id"
        :caption="$t('DxToolsDatabase_column_id')"
        :allow-header-filtering="true"
      />
      <DxColumn
        data-field="itemId"
        name="itemId"
        :caption="$t('DxToolsDatabase_column_itemId')"
        :allow-header-filtering="true"
      />
      <DxColumn
        data-field="fuel"
        name="fuel"
        :caption="$t('DxToolsDatabase_column_fuel')"
        :allow-header-filtering="true"
      />
    </DxDataGrid>
  </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex';
import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxSearchPanel,
  DxButton,
  DxExport,
  DxHeaderFilter,
  DxStateStoring,
  DxPager,
  DxSelection,
} from 'devextreme-vue/data-grid';
import { exportDataGrid } from 'devextreme/excel_exporter';
import ExcelJS from 'exceljs';
import saveAs from 'file-saver';
import Api from '../../services/Api';

export default {
  name: 'DxPrescriptionManager',
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxSearchPanel,
    DxButton,
    DxExport,
    DxHeaderFilter,
    DxStateStoring,
    DxPager,
    DxSelection,
  },
  data() {
    return {
      loaded: false,
      toolsFormated: [],
      pageSizes: [5, 10, 20],
    };
  },
  computed: {
    ...mapGetters('authentication', ['getPermissions']),
  },
  async beforeMount() {
    this.$f7.preloader.show();
    try {
      const xhr = await Api.getSummaryTools();
      const toolsAvailables = JSON.parse(xhr.response);
      for (let i = 0; i < toolsAvailables.length; i += 1) {
        this.toolsFormated.push({
          id: toolsAvailables[i].id,
          code: toolsAvailables[i].code,
          description: toolsAvailables[i].description,
          statusColumn: toolsAvailables[i].status ? this.$t('DxMachineryDatabase_column_active') : this.$t('DxMachineryDatabase_column_inactive'),
          family: toolsAvailables[i].item.family,
          subfamily: toolsAvailables[i].item.subfamily,
          itemId: toolsAvailables[i].item.id,
          fuel: (typeof (toolsAvailables[i].properties.find((x) => x.key === 'fuel')) === 'undefined') ? this.$t('No') : this.$t('Yes'),
        });
      }
    } catch (e) {
      const message = this.$helpers.getFilteredErrorMessage(e);
      if (message.includes('default_') && !message.includes('404')) {
        this.$f7.dialog.alert(message.split('_').pop());
      } else if (!message.includes('404')) {
        this.$f7.dialog.alert(this.$t(message));
      }
    } finally {
      this.$f7.preloader.hide();
      this.loaded = true;
    }
  },
  methods: {
    createTools() {
      this.$f7.views.main.router.navigate('/createTool/', { reloadCurrent: true });
    },
    editTools(rowSelected) {
      this.setActualTools(rowSelected.row.data);
      this.$f7.views.main.router.navigate('/editTool/', { reloadCurrent: true });
    },
    // CREACION BOTON CREAR MAQUINARIA
    onToolbarPreparing(e) {
      if (!this.$helpers.havePermission(this.getPermissions.company_update_master_data)) return;
      e.toolbarOptions.items.unshift({
        location: 'before',
        widget: 'dxButton',
        options: {
          icon: 'add',
          elementAttr: {
            class: 'add-task',
          },
          text: this.$t('createTool_Title'),
          onClick: () => {
            this.createTools();
          },
        },
      });
    },
    // EXPORT EXCEL
    onExporting(e) {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('ToolsDatabase');
      exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
      }).then(() => {
        workbook.xlsx.writeBuffer()
          .then((buffer) => {
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'ToolsDatabase.xlsx');
          });
      });
      e.cancel = true;
    },
    ...mapActions('Tool', ['setActualTools']),
  },
};
</script>
