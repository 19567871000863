var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content-devx content-dx-daily-planning"},[_c('DxForm',{attrs:{"id":"formProduct"}},[_c('DxGroupItem',{attrs:{"col-count":6}},[_c('DxGroupItem',{attrs:{"css-class":"content-combo","col-span":3}},[_c('DxSimpleItem',{attrs:{"name":"status","data-field":_vm.$t('DxFormProduct_status_caption'),"editor-type":"dxCheckBox","editor-options":{
            value: _vm.currentStatus,
            onValueChanged: _vm.changeStatus,
          }}}),_vm._v(" "),_c('DxSimpleItem',{attrs:{"name":"SCSauthorized","data-field":_vm.$t('DxFormProduct_SCS_autorizado'),"editor-type":"dxCheckBox","editor-options":{
            value: _vm.currentSCSauthorized,
            onValueChanged: _vm.onChangeStatusSCS,
          }}}),_vm._v(" "),_c('DxSimpleItem',{attrs:{"name":"EUauthorized","data-field":_vm.$t('DxFormProduct_EU_autorizado'),"editor-type":"dxCheckBox","editor-options":{
            value: _vm.currentEUauthorized,
            onValueChanged: _vm.onChangeStatusEU,
          }}}),_vm._v(" "),_c('DxSimpleItem',{attrs:{"name":"code","data-field":_vm.$t('DxFormProduct_code_caption'),"editor-options":{
            value: _vm.currentCode,
            onValueChanged: _vm.changeCode,
          }}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('DxFormProduct_code_required')}})],1),_vm._v(" "),_c('DxSimpleItem',{attrs:{"name":"family","data-field":_vm.$t('DxFormProduct_family_caption'),"editor-type":"dxSelectBox","editor-options":{
            items: _vm.listFamilies,
            value: _vm.currentFamily,
            displayExpr: 'id',
            valueExpr:'id',
            onValueChanged: _vm.changeFamily,
            searchEnabled: true,
            searchTimeout: _vm.searchTimeoutOption,
            minSearchLength: _vm.minSearchLengthOption,
            showDataBeforeSearch: _vm.showDataBeforeSearchOption,
          }}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('StoreProduct_ValidationFamily')}})],1),_vm._v(" "),_c('DxSimpleItem',{attrs:{"name":"subfamily","data-field":_vm.$t('DxFormProduct_subfamily_caption'),"editor-type":"dxSelectBox","editor-options":{
            items: _vm.filterListSubFamilies,
            value: _vm.currentSubfamily,
            displayExpr: 'id',
            valueExpr:'id',
            onValueChanged: _vm.changeSubfamily,
            searchEnabled: true,
            searchTimeout: _vm.searchTimeoutOption,
            minSearchLength: _vm.minSearchLengthOption,
            showDataBeforeSearch: _vm.showDataBeforeSearchOption,
          }}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('StoreProduct_ValidationSubfamily')}})],1),_vm._v(" "),(!_vm.isDisabled)?_c('DxSimpleItem',{attrs:{"name":"component","data-field":_vm.$t('DxFormProduct_component_caption'),"editor-options":{
            value: _vm.currentComponent,
            onValueChanged: _vm.changeComponent,
          }}}):_vm._e(),_vm._v(" "),_c('DxSimpleItem',{attrs:{"name":"MaxDosisPermitida","data-field":_vm.$t('DxFormProduct_maxdosis'),"editor-type":"dxNumberBox","editor-options":{
            showSpinButtons: true,
            mode: 'number',
            value: _vm.currentMaxDoses,
            showClearButton: true,
            min: '0',
            onValueChanged: _vm.onChangeMaxDose,
          }}})],1),_vm._v(" "),_c('DxGroupItem',{attrs:{"col-span":3,"css-class":"content-combo"}},[_c('DxSimpleItem',{attrs:{"name":"toxicity","data-field":_vm.$t('DxFormProduct_toxicity_caption'),"editor-type":"dxSelectBox","editor-options":{
            items: _vm.listToxicitys,
            value: _vm.currentToxicity,
            onValueChanged: _vm.changeToxicity,
            searchEnabled: true,
            searchTimeout: _vm.searchTimeoutOption,
            minSearchLength: _vm.minSearchLengthOption,
            showDataBeforeSearch: _vm.showDataBeforeSearchOption,
          }}}),_vm._v(" "),_c('DxSimpleItem',{attrs:{"name":"SAPunit","data-field":_vm.$t('DxFormProduct_sapunit_caption'),"editor-type":"dxSelectBox","editor-options":{
            items: _vm.listUnit,
            value: _vm.currentSapunit,
            onValueChanged: _vm.changeSapunit,
            searchEnabled: true,
            searchTimeout: _vm.searchTimeoutOption,
            minSearchLength: _vm.minSearchLengthOption,
            showDataBeforeSearch: _vm.showDataBeforeSearchOption,
          }}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('StoreProduct_ValidationSapunit')}})],1),_vm._v(" "),_c('DxSimpleItem',{attrs:{"name":"description","data-field":_vm.$t('DxFormProduct_description_caption'),"editor-type":"dxTextArea","editor-options":{
            value: _vm.currentDescription,
            onValueChanged: _vm.changeDescription,
            maxLength: 256,
          }}}),_vm._v(" "),_c('DxSimpleItem',{attrs:{"name":"comment","data-field":_vm.$t('DxFormProduct_comment_caption'),"editor-type":"dxTextArea","editor-options":{
            value: _vm.currentComment,
            onValueChanged: _vm.changeComment,
            maxLength: 256,
          }}}),_vm._v(" "),_c('DxSimpleItem',{attrs:{"name":"preHarvest","data-field":_vm.$t('DxFormProduct_preHarvest'),"editor-type":"dxNumberBox","editor-options":{
            showSpinButtons: true,
            mode: 'number',
            showClearButton: true,
            min: '0',
            value: _vm.currentPreharvest,
            onValueChanged: _vm.onChangePreharvest,
          }}}),_vm._v(" "),_c('DxSimpleItem',{attrs:{"name":"IntervalIn","data-field":_vm.$t('DxFormProduct_currentIn'),"editor-type":"dxNumberBox","editor-options":{
            showSpinButtons: true,
            mode: 'number',
            value: _vm.currentIntervalIn,
            showClearButton: true,
            min: '0',
            onValueChanged: _vm.onChangeIntervalIn,
          }}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }