export default {
  namespaced: true,
  state: {
    product: {
      id: '',
      code: '',
      comment: '',
      component: '',
      description: '',
      status: '',
      family: '',
      subfamily: '',
      internalcode: '',
      properties: [],
    },
    listUnit: ['GR', 'KG', 'L', 'M3', 'PU', 'TON'],
  },

  getters: {
    product: (state) => state.product,
    listUnit: (state) => state.listUnit,
  },
  actions: {
    setProduct(context, value) {
      context.commit('setProduct', value);
    },
    setNewProduct(context) {
      context.commit('setNewProduct');
    },
  },
  mutations: {
    setProduct(state, value) {
      state.product = value;
    },
    setNewProduct(state) {
      state.product = {
        id: '',
        code: '',
        comment: '',
        component: '',
        description: '',
        status: 'true',
        family: '',
        subfamily: '',
        internalcode: '',
        properties: [
          {
            key: 'internal_code',
            value: '',
          },
          {
            key: 'SAPunit',
            value: '',
          },
          {
            key: 'maxDoses',
            value: '',
          },
          {
            key: 'intervalIn',
            value: '',
          },
          {
            key: 'SCS_authorized',
            value: 'false',
          },
          {
            key: 'EU_authorized',
            value: 'false',
          },
          {
            key: 'preharvest',
            value: '',
          },
        ],
      };
    },
  },
};
