<template>
  <div
    v-if="loaded"
    class="content-devx content-dx-worker main-dx-machinery"
  >
    <CalendarInitEndDate :key-name="keyName" />
    <DxDataGrid
      id="worker-grid"
      :data-source="territorialUnitFormated"
      :remote-operations="true"
      :column-min-width="90"
      :column-auto-width="true"
      :allow-column-reordering="true"
      :allow-column-resizing="true"
      :show-borders="true"
      :show-column-lines="true"
      :show-row-lines="true"
      :row-alternation-enabled="true"
      @exporting="onExporting"
    >
      <DxSelection
        mode="single"
      />
      <DxStateStoring
        :enabled="true"
        type="localStorage"
        storage-key="DxMachinery"
      />
      <DxExport
        :enabled="true"
      />
      <DxPaging :page-size="10" />
      <DxPager
        :show-page-size-selector="true"
        :allowed-page-sizes="pageSizes"
        :show-info="true"
      />
      <DxHeaderFilter :visible="true" />
      <DxSearchPanel
        :visible="true"
        :width="200"
        :placeholder="$t('Search_placeholder')"
      />
      <DxColumnFixing :enabled="true" />
      <DxColumn
        data-field="area"
        :caption="$t('DxTerritorialUnit_area')"
        :allow-header-filtering="false"
        alignment="left"
      />
      <DxColumn
        data-field="code"
        :caption="$t('DxTerritorialUnit_code')"
        :allow-header-filtering="false"
        alignment="left"
      />
      <DxColumn
        data-field="farm"
        :caption="$t('DxTerritorialUnit_farm')"
        :allow-header-filtering="true"
        alignment="left"
      />
      <DxColumn
        data-field="id"
        :caption="$t('DxTerritorialUnit_id')"
        :allow-header-filtering="false"
        alignment="left"
      />
      <DxColumn
        data-field="name"
        :caption="$t('DxTerritorialUnit_name')"
        :allow-header-filtering="false"
        alignment="left"
      />
      <DxColumn
        data-field="pattern"
        :caption="$t('DxTerritorialUnit_pattern')"
        :allow-header-filtering="false"
        alignment="left"
      />
      <DxColumn
        data-field="planting_year"
        :caption="$t('DxTerritorialUnit_planting_year')"
        :allow-header-filtering="true"
        alignment="left"
      />
      <DxColumn
        data-field="plants"
        :caption="$t('DxTerritorialUnit_plants')"
        :allow-header-filtering="false"
        alignment="left"
      />
      <DxColumn
        data-field="variety_code"
        :caption="$t('DxTerritorialUnit_variety_code')"
        :allow-header-filtering="true"
        alignment="left"
      />
      <DxColumn
        data-field="variety_name"
        :caption="$t('DxTerritorialUnit_variety_name')"
        :allow-header-filtering="true"
        alignment="left"
      />
      <DxScrolling column-rendering-mode="virtual" />
    </DxDataGrid>
  </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex';
import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxSearchPanel,
  DxExport,
  DxScrolling,
  DxColumnFixing,
  DxHeaderFilter,
  DxStateStoring,
  DxPager,
  DxSelection,
} from 'devextreme-vue/data-grid';
import { exportDataGrid } from 'devextreme/excel_exporter';
import ExcelJS from 'exceljs';
import saveAs from 'file-saver';
import Api from '../../services/Api';
import CalendarInitEndDate from '../CalendarInitEndDate.vue';

export default {
  name: 'DxTerritorialUnit',
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxSearchPanel,
    DxExport,
    DxScrolling,
    DxColumnFixing,
    DxHeaderFilter,
    DxStateStoring,
    CalendarInitEndDate,
    DxPager,
    DxSelection,
  },
  data() {
    return {
      loaded: false,
      territorialUnitFormated: [],
      keyName: 'territorialUnit',
      pageSizes: [5, 10, 20],
    };
  },
  computed: {
    ...mapGetters('CalendarInitEndDate', ['actualFilter']),
  },
  async beforeMount() {
    this.$f7.preloader.show();
    try {
      const xhr = await Api.getTerritorialUnit();
      const locationsAvailables = JSON.parse(xhr.response).data;
      for (let i = 0; i < locationsAvailables.length; i += 1) {
        this.territorialUnitFormated.push({
          area: locationsAvailables[i].area,
          code: locationsAvailables[i].code,
          farm: locationsAvailables[i].farm,
          id: locationsAvailables[i].id,
          name: locationsAvailables[i].name,
          pattern: locationsAvailables[i].pattern,
          planting_year: locationsAvailables[i].planting_year,
          plants: locationsAvailables[i].plants,
          variety_code: locationsAvailables[i].variety_code,
          variety_name: locationsAvailables[i].variety_name,
        });
      }
    } catch (e) {
      const message = this.$helpers.getFilteredErrorMessage(e);
      if (message.includes('default_') && !message.includes('404')) {
        this.$f7.dialog.alert(message.split('_').pop());
      } else if (!message.includes('404')) {
        this.$f7.dialog.alert(this.$t(message));
      }
    } finally {
      this.$f7.preloader.hide();
      this.loaded = true;
    }
  },
  methods: {
    onExporting(e) {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('territorialUnit');
      exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
      }).then(() => {
        workbook.xlsx.writeBuffer()
          .then((buffer) => {
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'territorialUnit.xlsx');
          });
      });
      e.cancel = true;
    },
  },
};
</script>
