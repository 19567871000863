<template>
  <div>
    <div
      class="content-devx content-dx-daily-planning no-margin no-shadow"
    >
      <DxPostWorkEmployeeManualHarvesting
        v-if="showManualHarvesting"
        :start-value="0"
      />
      <DxDataGrid
        id="employeeTable"
        ref="employeeTable"
        :data-source="employees"
        :column-min-width="70"
        :allow-column-reordering="true"
        :show-borders="true"
        :show-column-lines="true"
        :show-row-lines="false"
        :column-auto-width="true"
        :row-alternation-enabled="true"
        @row-validating="onRowValidating"
        @row-inserted="onInsertedRow"
        @row-updated="onChangeRow"
        @row-removed="onRemovedRow"
        @init-new-row="setItemFormPerformance"
        @editing-start="setItemFormPerformance"
      >
        <DxStateStoring
          :enabled="true"
          type="localStorage"
          storage-key="DxPostWorkEmployeeTable"
        />
        <DxExport
          :enabled="false"
        />
        <DxPaging :page-size="10" />
        <DxPager
          :show-page-size-selector="true"
          :allowed-page-sizes="pageSizes"
          :show-info="true"
        />
        <DxEditing
          :allow-updating="allowUpdating"
          :allow-deleting="allowDeleting"
          :allow-adding="allowAdding"
          mode="form"
        >
          <DxForm>
            <DxSimpleItem
              data-field="fullname"
              editor-type="dxTagBox"
            />
            <DxSimpleItem
              data-field="init_date"
              :editor-options="{disabledDates:disableSundays}"
            />
            <DxSimpleItem
              data-field="end_date"
              :editor-options="{disabledDates:disableSundays}"
            />
            <DxSimpleItem
              v-if="performanceUnit!==''"
              data-field="performance"
            />
            <DxSimpleItem
              v-if="performanceUnit!==''"
              data-field="unit"
              :editor-options="{disabled:true, showDropDownButton:false,}"
            />
            <DxSimpleItem
              data-field="working_area"
            />
            <DxSimpleItem
              data-field="taskduration"
            />
            <DxSimpleItem
              data-field="comment"
            />
          </DxForm>
        </DxEditing>
        <DxSearchPanel
          :visible="true"
          :width="200"
          :placeholder="$t('Search_placeholder')"
        />
        <DxColumn
          data-field="fullname"
          name="name"
          alignment="left"
          :width="500"
          :caption="$t('DxPostWorkEmployeeTable_Name')"
        >
          <DxLookup
            :data-source="employeesAll"
            display-expr="fullname"
            value-expr="fullname"
          />
          <DxRequiredRule />
        </DxColumn>
        <DxColumn
          data-field="init_date"
          name="init_date"
          :caption="$t('DxPostWorkEmployeeTable_Checkin')"
          data-type="datetime"
          format="yyyy/MM/dd HH:mm"
          alignment="left"
        >
          <DxRequiredRule />
        </DxColumn>
        <DxColumn
          data-field="end_date"
          name="end_date"
          :caption="$t('DxPostWorkEmployeeTable_Checkout')"
          data-type="datetime"
          format="yyyy/MM/dd HH:mm"
          alignment="left"
        >
          <DxRequiredRule />
        </DxColumn>
        <DxColumn
          v-if="performanceUnit!==''"
          data-field="performance"
          name="performance"
          :caption="$t('DxPostWorkEmployeeTable_Productivity')"
          data-type="number"
          alignment="right"
        >
          <DxRequiredRule />
          <DxRangeRule
            min="0"
            :message="$t('DxPostWorkEmployeeTable_rule_range')"
          />
        </DxColumn>
        <DxColumn
          v-if="performanceUnit!==''"
          data-field="unit"
          name="unit"
          alignment="left"
          :caption="$t('DxPostWorkEmployeeTable_Unit')"
        >
          <DxLookup
            :data-source="listProductivityUnit"
            display-expr="name"
            value-expr="id"
          />
          <DxRequiredRule />
        </DxColumn>
        <DxColumn
          data-field="working_area"
          name="workingArea"
          :caption="$t('DxPostWorkEmployeeTable_WorkingArea')"
          data-type="number"
          alignment="right"
        >
          <DxRequiredRule />
          <DxRangeRule
            min="0"
            :message="$t('DxPostWorkEmployeeTable_rule_range')"
          />
        </DxColumn>
        <DxColumn
          data-field="taskduration"
          name="taskduration"
          caption="Duration"
          alignment="right"
          :allow-editing="false"
        />
        <DxColumn
          data-field="comment"
          name="comment"
          :caption="$t('DxPostWorkEmployeeTable_Notes')"
        />
      </DxDataGrid>
    </div>
  </div>
</template>
<script>

import { mapState, mapActions } from 'vuex';
import 'devextreme-vue/tag-box';
import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxEditing,
  DxSearchPanel,
  DxExport,
  DxRequiredRule,
  DxLookup,
  DxForm,
  DxStateStoring,
  DxPager,
} from 'devextreme-vue/data-grid';
import {
  DxSimpleItem,
} from 'devextreme-vue/form';
import * as overlay from 'devextreme/ui/overlay';
import moment from 'moment';
import {
  DxRangeRule,
} from 'devextreme-vue/validator';
import DxPostWorkEmployeeManualHarvesting from './DxPostWorkEmployeeManualHarvesting.vue';

export default {
  name: 'DxEmployeeTable',
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxEditing,
    DxSearchPanel,
    DxExport,
    DxRequiredRule,
    DxLookup,
    DxRangeRule,
    DxForm,
    DxSimpleItem,
    DxStateStoring,
    DxPager,
    DxPostWorkEmployeeManualHarvesting,
  },
  props: {
    employeesArray: { type: Array, default: () => [] },
    allowUpdating: { type: Boolean, default: true },
    allowDeleting: { type: Boolean, default: true },
    allowAdding: { type: Boolean, default: true },
  },
  data() {
    return {
      pageSizes: [5, 10, 20],
      employeesAll: [],
      newEmployeesArray: [],
    };
  },
  computed: {
    showManualHarvesting() {
      if (this.taskSelected && (this.taskSelected.code === '0801' || this.taskSelected.code === '0802' || this.taskSelected.code === '0803')) {
        return true;
      }
      return false;
    },
    ...mapState('PlanningManager', ['employees', 'listProductivityUnit', 'fechaInicio', 'dateSelected', 'workers', 'performanceUnit', 'taskSelected']),
  },
  beforeMount() {
    overlay.baseZIndex(9999);
    this.employeesAll = this.employeesArray;
  },
  destroyed() {
    this.setEmployees([]);
  },
  methods: {
    filteredEmployees(e) {
      try {
        if (e.values[0] !== undefined) {
          const filtered = this.employeesArray.filter((item) => item.fullname === e.values[0]);
          return filtered;
        }
        return this.employeesArray;
      } catch (error) {
        return this.employeesArray;
      }
    },
    // SET DATA SOURCE TO PRODUCTS VUEX
    onChangeRow() {
      let newItems = this.employees;
      newItems = this.getDuration(newItems);
      newItems = this.getIdEmployee(newItems);
      this.setPerformanceTo0(newItems);
      this.setEmployees(newItems);
      this.updateAllTableWorkers();
    },
    onInsertedRow(e) {
      let newItems = this.employees;
      newItems = this.getDuration(newItems);
      newItems = this.getIdEmployee(newItems);
      this.setPerformanceTo0(newItems);
      this.setEmployees(newItems);
      this.updateAllTableWorkers();
    },
    onRowValidating(e) {
      let endDate = e.newData.end_date;
      let initDate = e.newData.init_date;
      if (typeof (endDate) === 'undefined') {
        endDate = e.oldData.end_date;
      }
      if (typeof (initDate) === 'undefined') {
        initDate = e.oldData.init_date;
      }
      if (moment(initDate).format('YYYY-MM-DD') !== this.dateSelected[0]) {
        e.errorText = this.$t('DxPostWorkEmployeeTable_dialogCreate_wrongCheckIn');
        e.isValid = false;
      }
      const ms = moment(endDate, 'YYYY/MM/DD HH:mm:ss').diff(moment(initDate, 'YYYY/MM/DD HH:mm:ss'));

      if (ms < 0) {
        e.errorText = this.$t('DxPostWorkEmployeeTable_dialogCreate_wrongHour');
        e.isValid = false;
      }

      if (e.newData.fullname && e.newData.fullname.filter((element) => Array.isArray(element)).length > 0) {
        e.errorText = this.$t('DxPostWorkEmployeeTable_dialogCreate_wrongWorker');
        e.isValid = false;
      }

      if (e.isValid) {
        const employeeGroup = JSON.parse(JSON.stringify(this.employeesArray[0]));
        employeeGroup.fullname = e.newData.fullname;
        employeeGroup.custom = true;
        this.newEmployeesArray.push(employeeGroup);
        this.employeesAll = this.employeesArray.concat(this.newEmployeesArray);
      }
    },
    getDuration(newItems) {
      for (let i = 0; i < newItems.length; i += 1) {
        const ms = moment(newItems[i].end_date, 'YYYY/MM/DD HH:mm:ss').diff(moment(newItems[i].init_date, 'YYYY/MM/DD HH:mm:ss'));
        const d = moment.duration(ms);
        const s = Math.floor(d.asHours()) + moment.utc(ms).format(':mm:ss');
        newItems[i].taskduration = s;
      }
      return newItems;
    },
    getIdEmployee(newItems) {
      const idsEmployees = [];
      for (let i = 0; i < newItems.length; i += 1) {
        for (let j = 0; j < newItems[i].fullname.length; j += 1) {
          const filtered = this.employeesArray.filter((item) => newItems[i].fullname[j] === item.fullname);
          if (filtered.length > 0) {
            idsEmployees.push({ id: filtered[0].id });
          }
        }
        newItems[i].employees_ids = idsEmployees;
      }
      return newItems;
    },
    onRemovedRow() {
      const newItems = this.employees;
      this.setEmployees(newItems);
      this.updateAllTableWorkers();
    },
    updateValueWorkers(workerType, value) {
      const newWorkers = this.workers;
      let updated = false;
      for (let i = 0; i < this.workers.values.length; i += 1) {
        if (this.workers.values[i].type === workerType) {
          newWorkers.values[i].count = (value) ?? 0;
          updated = true;
          break;
        }
      }
      if (!updated) {
        newWorkers.values.push({ type: workerType, count: value });
      }
      for (let j = 0; j < newWorkers.values.length; j += 1) {
        if (newWorkers.values[j].count === 0) {
          newWorkers.values.splice(j, 1);
        }
      }
      this.setWorkers(newWorkers.values);
      this.actualWorkers = this.workers.values;
    },
    updateAllTableWorkers() {
      let temporary = 0;
      let contratist = 0;
      let permanent = 0;
      for (const employee of this.employees) {
        for (let j = 0; j < employee.employees_ids.length; j += 1) {
          switch (this.getTypeWorkerById(employee.employees_ids[j].id)) {
            case this.$t('TEMPORARY'):
              temporary += 1;
              break;
            case this.$t('CONTRATIST'):
              contratist += 1;
              break;
            case this.$t('PERMANENT'):
              permanent += 1;
              break;
            default:
              break;
          }
        }
      }
      this.updateValueWorkers('TEMPORARY', temporary);
      this.updateValueWorkers('CONTRATIST', contratist);
      this.updateValueWorkers('PERMANENT', permanent);
    },
    getTypeWorkerById(idEmployee) {
      for (const employee of this.employeesArray) {
        if (employee.id === idEmployee) {
          for (const property of employee.properties) {
            if (property.key === 'type') {
              return property.value;
            }
          }
        }
      }
      return '';
    },
    setItemFormPerformance(e) {
      e.data.unit = this.performanceUnit;
    },
    setPerformanceTo0(newItems) {
      for (const row of newItems) {
        if (typeof row.performance === 'undefined') {
          row.performance = 0;
        }
      }
    },
    disableSundays(e) {
      if (e) {
        return [0].indexOf(e.date.getDay()) >= 0;
      }
      return undefined;
    },
    ...mapActions('PlanningManager', ['setEmployees', 'setFechaInicio', 'setWorkers']),
  },
};
</script>
