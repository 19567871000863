<template>
  <DxDataGrid
    id="harvest-report-grid"
    :data-source="reportsFormated"
    :remote-operations="true"
    :column-min-width="90"
    :column-auto-width="true"
    :allow-column-reordering="true"
    :allow-column-resizing="true"
    :show-borders="true"
    :show-column-lines="true"
    :show-row-lines="true"
    :row-alternation-enabled="true"
    @exporting="onExporting"
  >
    <DxStateStoring
      :enabled="true"
      type="localStorage"
      storage-key="DxHarvestActivitiesReport"
    />
    <DxExport
      :enabled="true"
    />
    <DxPaging :page-size="10" />
    <DxPager
      :show-page-size-selector="true"
      :allowed-page-sizes="pageSizes"
      :show-info="true"
    />
    <DxHeaderFilter :visible="true" />
    <DxSearchPanel
      :visible="true"
      :width="200"
      :placeholder="$t('Search_placeholder')"
    />
    <DxColumnFixing :enabled="true" />
    <DxColumn
      data-field="date"
      :caption="$t('date')"
      :allow-header-filtering="false"
      alignment="left"
      :width="100"
    />
    <DxColumn
      data-field="location"
      :caption="$t('team')"
      :allow-header-filtering="false"
      alignment="left"
      width="100"
    />
    <DxColumn
      data-field="farm"
      :caption="$t('farm')"
      :allow-header-filtering="false"
      alignment="left"
      :width="120"
    />
    <DxColumn
      data-field="variety"
      :caption="$t('variety')"
      :allow-header-filtering="true"
      alignment="left"
    />
    <DxColumn
      data-field="performance"
      :caption="$t('kilos')"
      :allow-header-filtering="false"
      alignment="left"
    />
    <DxColumn
      data-field="performanceBags"
      :caption="$t('bags')"
      :allow-header-filtering="false"
      alignment="left"
    />
    <DxColumn
      data-field="task"
      :caption="$t('task')"
      :allow-header-filtering="true"
      alignment="left"
    />
    <DxColumn
      data-field="working_area"
      :caption="$t('harvestReport_working_area')"
      :allow-header-filtering="false"
      alignment="left"
    />
    <DxColumn
      data-field="machinery"
      :caption="$t('machinery')"
      :allow-header-filtering="false"
      alignment="left"
    />
    <DxColumn
      data-field="operator"
      :caption="$t('operator')"
      :allow-header-filtering="false"
      alignment="left"
    />
    <DxColumn
      data-field="numWorkers"
      :caption="$t('numWorkers')"
      :allow-header-filtering="false"
      alignment="left"
    />
    <DxColumn
      data-field="hours"
      :caption="$t('harvestReport_horometer')"
      :allow-header-filtering="false"
      alignment="left"
    />
    <DxColumn
      data-field="comment"
      :caption="$t('observation')"
      :allow-header-filtering="false"
      alignment="left"
    />
    <DxScrolling column-rendering-mode="virtual" />
  </DxDataGrid>
</template>

<script>

import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxSearchPanel,
  DxExport,
  DxScrolling,
  DxColumnFixing,
  DxHeaderFilter,
  DxStateStoring,
  DxPager,
} from 'devextreme-vue/data-grid';
import { exportDataGrid } from 'devextreme/excel_exporter';
import ExcelJS from 'exceljs';
import saveAs from 'file-saver';
import moment from 'moment';

export default {
  name: 'DxHarvestActivitiesReport',
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxSearchPanel,
    DxExport,
    DxScrolling,
    DxColumnFixing,
    DxHeaderFilter,
    DxStateStoring,
    DxPager,
  },
  props: {
    reports: {
      type: Array,
      default: () => [],
    },
    locationTree: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      keyName: 'DxHarvestActivitiesReport',
      pageSizes: [5, 10, 20],
    };
  },
  computed: {
    reportsFormated() {
      const reportsFormated = [];
      for (const report of this.reports) {
        for (const operator of report.workers) {
          const reportFormated = {
            date: report.date,
            location: report.location,
            farm: report.farm,
            variety: report.variety,
            task: report.task,
            working_area: operator.working_area,
            performance: '',
            machinery: '',
            operator: '',
            performanceBags: '',
            numWorkers: report.workers.length,
            hours: operator.hours,
            comment: operator.comment,
          };
          if (report.task_type === 'Mechanic') {
            reportFormated.performance = operator.performance;
            reportFormated.machinery = operator.machinery;
            reportFormated.operator = `${operator.name} ${operator.surname}`;
          } else {
            reportFormated.performanceBags = operator.performance;
          }
          reportsFormated.push(reportFormated);
        }
      }
      return reportsFormated;
    },
  },

  async beforeMount() {
    this.reportFormated = [];
  },
  methods: {
    getTotal(init, end) {
      return end - init;
    },
    onExporting(e) {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('harvestReport');
      exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
      }).then(() => {
        workbook.xlsx.writeBuffer()
          .then((buffer) => {
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `HarvestActivitiesReport${moment().format('YYYYMMDD_HHmmss')}.xlsx`);
          });
      });
      e.cancel = true;
    },
  },
};
</script>
