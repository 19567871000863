<template>
  <f7-page>
    <navbar :text="$t('Stock_Title')" />
    <DxStockTable />
  </f7-page>
</template>

<script>
import DxStockTable from '../../components/warehouse/DxStockTable.vue';
import navbar from '../../components/NavBar.vue';

export default {
  name: 'Stock',
  components: {
    navbar,
    DxStockTable,
  },
  computed: {
  },
  methods: {
  },
};
</script>
