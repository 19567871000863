<template>
  <div class="main-ExportDataToExcel">
    <f7-button
      class="dx-btn-success buttonExport"
      raised
      styling-mode="contained"
      name="btnExportData"
      :disabled="disabled"
      @click="downloadFile"
    >
      {{ $t("ExportDataToExcel_Button") }}
    </f7-button>
  </div>
</template>

<script>
import XLSX from 'xlsx';

export default {
  name: 'ExportDataToExcel',
  components: {
  },
  props: {
    dataToExport: { type: Array, default: () => [] },
    columnTitles: { type: Array, default: () => [] },
    nameFile: { type: String, default: 'file' },
    disabled: { type: Boolean, default: false },
  },
  data() {
    return {
      titles: [],
    };
  },
  computed: {
  },
  beforeMount() {
    this.titles = this.columnTitles;
  },
  methods: {
    downloadFile() {
      const data = XLSX.utils.json_to_sheet(this.dataToExport);
      const range = XLSX.utils.decode_range(data['!ref']);
      for (let C = 0; C <= range.e.c; C += 1) {
        const celda = `${XLSX.utils.encode_col(C)}1`; // <-- first row, column number C
        if (data[celda] && this.titles.length > 0) {
          data[celda].v = this.titles.splice(0, 1).shift(); // Asigna valor de titulo y elimina el titulo del array
        } else {
          break;
        }
      }
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, data, this.nameFile);
      XLSX.writeFile(workbook, `${this.nameFile}.xlsx`);
    },
  },
};
</script>
<style lang="scss">
.main-ExportDataToExcel {
  .buttonExport {
    width: 130px;
    height: 33px;
    font-size: 11px;
  }
}
</style>
