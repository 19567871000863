<template>
  <div
    v-if="loaded"
    class="content-devx main-dx-monthly-component"
  >
    <div id="form-container">
      <f7-row>
        <f7-col
          width="25"
          medium="30"
        >
          <DxTreePlanning
            :locations-tree="locationsTree"
            :planning="actualTaskBadget"
          />
        </f7-col>
        <f7-col
          width="75"
          medium="70"
        >
          <DxMonthlyForm
            :show-tables="showTables"
            :data-form="{activityName: activitiesAvailables, taskName: tasksAvailables, workerTypes, itemTypes, performanceTypes }"
            :edit-form="editForm"
          />
        </f7-col>
      </f7-row>
    </div>
  </div>
</template>
<script>

import { mapActions, mapState } from 'vuex';
import * as overlay from 'devextreme/ui/overlay';
import Api from '../../services/Api';
import DxTreePlanning from '../DxTreePlanning.vue';
import DxMonthlyForm from './DxMonthlyForm.vue';

export default {
  name: 'DxMonthlyComponent',
  components: {
    DxTreePlanning,
    DxMonthlyForm,
  },
  props: {
    showTables: { type: Boolean, default: true },
    editForm: { type: Boolean, default: false },
  },
  data() {
    return {
      loaded: false,
      locationsTree: [],
      activitiesAvailables: [],
      tasksAvailables: [],
      itemTypes: [],
      workerTypes: [],
      performanceTypes: [],
      planning: null,
    };
  },
  computed: {
    ...mapState('PlanningManager', ['showProducts']),
    ...mapState('TaskBadget', ['actualTaskBadget']),
  },
  async beforeMount() {
    this.setShowProducts(false);
    overlay.baseZIndex(9999);
    this.$f7.preloader.show();
    try {
      if (!this.editForm) {
        this.setActualTaskBadget({});
      } else {
        this.planning = this.actualTaskBadget;
      }
      this.locationsTree = await this.loadLocationsTree();
      this.activitiesAvailables = await this.loadActivitiesAvailables();
      this.tasksAvailables = await this.loadTasksAvailables();
      this.ItemsTypes = await this.loadItemsType();
    } catch (error) {
      const message = this.$helpers.getFilteredErrorMessage(error);
      if (message.includes('default_') && !message.includes('404')) {
        this.$f7.dialog.alert(message.split('_').pop());
      } else if (!message.includes('404')) {
        this.$f7.dialog.alert(this.$t(message));
      }
    } finally {
      this.$f7.preloader.hide();
    }
    this.loaded = true;
  },
  methods: {
    async loadLocationsTree() {
      const xhr = await Api.getLocationsTree();
      return JSON.parse(xhr.response);
    },
    async loadActivitiesAvailables() {
      const xhr = await Api.getActivities();
      return JSON.parse(xhr.response);
    },
    async loadTasksAvailables() {
      const xhr = await Api.getTasksDefinition();
      return JSON.parse(xhr.response);
    },
    async loadItemsType() {
      const xhr = await Api.getItemsType();
      const items = JSON.parse(xhr.response);
      for (let i = 0; i < items.length; i += 1) {
        const item = items[i];
        if (item.family === 'ITEM_SUBFAMILY_VEHICLE') {
          this.itemTypes = this.itemTypes.concat(item.values);
        } else if (item.family === 'WORKER_TYPE') {
          this.workerTypes = this.workerTypes.concat(item.values);
        } else if (item.family === 'PERFORMANCE_UNITS') {
          this.performanceTypes = this.performanceTypes.concat(item.values);
        }
      }
    },
    ...mapActions('PlanningManager', ['setShowProducts']),
    ...mapActions('TaskBadget', ['setActualTaskBadget']),
  },
};
</script>

<style scoped>
.long-title h3 {
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-size: 28px;
  text-align: center;
  margin-bottom: 20px;
}
</style>
