<template>
  <div
    v-if="loaded"
    class="content-devx main-annual-budgets"
  >
    <CalendarInitEndDate :key-name="keyName" />
    <DxDataGrid
      id="gridDxAnnualBudgets"
      :data-source="annualBudgetsFormatted"
      :remote-operations="true"
      :column-min-width="90"
      :allow-column-reordering="true"
      :show-borders="true"
      :show-column-lines="true"
      :show-row-lines="false"
      :row-alternation-enabled="true"
      :column-auto-width="true"
      @exporting="onExporting"
      @toolbar-preparing="onToolbarPreparing($event)"
    >
      <DxSelection
        mode="single"
      />
      <DxStateStoring
        :enabled="true"
        type="localStorage"
        storage-key="DxAnnualBudgets"
      />
      <DxHeaderFilter :visible="true" />
      <DxExport
        :enabled="true"
      />
      <DxPaging :page-size="10" />
      <DxPager
        :show-page-size-selector="true"
        :allowed-page-sizes="pageSizes"
        :show-info="true"
      />
      <DxSearchPanel
        :visible="true"
        :width="200"
        :highlight-case-sensitive="true"
        :placeholder="$t('Search_placeholder')"
      />
      <DxColumn
        type="buttons"
        data-field=""
        :width="120"
        alignment="left"
        :allow-header-filtering="false"
        cell-template="buttonsTemplate"
      />
      <template #buttonsTemplate="{ data: annualBudgetsFormatted }">
        <div>
          <!-- BOTON FIND -->
          <button
            class="dx-icon-search boton-search"
            @click="showAnnualBudgetVersion(annualBudgetsFormatted)"
          />
          <!-- BOTON CLOSE -->
          <button
            v-if="setButtonCloseVisible(annualBudgetsFormatted)"
            class="dx-icon-close boton-close"
            @click="closeAnnualBudgetVersion(annualBudgetsFormatted)"
          />
          <!-- BOTON COPY -->
          <button
            class="dx-icon-copy boton-duplicy"
            @click="cloneAnnualBudgetVersion(annualBudgetsFormatted)"
          />
        </div>
      </template>
      <DxColumn
        data-field="year"
        name="year"
        :caption="$t('DxAnnualBudgets_year_caption')"
        :allow-header-filtering="false"
        alignment="left"
      />
      <DxColumn
        data-field="version"
        name="version"
        :caption="$t('DxAnnualBudgets_version_caption')"
        :allow-header-filtering="false"
      />
      <DxColumn
        data-field="status"
        name="status"
        :allow-header-filtering="true"
        :caption="$t('DxAnnualBudgets_status_caption')"
        alignment="center"
      />
    </DxDataGrid>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxSearchPanel,
  DxExport,
  DxHeaderFilter,
  DxStateStoring,
  DxPager,
  DxSelection,
} from 'devextreme-vue/data-grid';
import { exportDataGrid } from 'devextreme/excel_exporter';
import ExcelJS from 'exceljs';
import saveAs from 'file-saver';
import Api from '../../services/Api';
import CalendarInitEndDate from '../CalendarInitEndDate.vue';

export default {
  name: 'DxAnnualBudgets',
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxSearchPanel,
    DxExport,
    DxHeaderFilter,
    DxStateStoring,
    DxPager,
    DxSelection,
    CalendarInitEndDate,
  },
  data() {
    return {
      loaded: false,
      pageSizes: [5, 10, 20],
      keyName: 'annualBudgets',
      annualBudgetsFormatted: [],
    };
  },
  computed: {
    ...mapGetters('CalendarInitEndDate', ['actualFilter']),
  },
  async beforeMount() {
    this.$f7.preloader.show();
    try {
      await this.fetchLocationTree();
      const checkedInitEndDate = Api.checkInitEndDate(this.actualFilter[this.keyName]);
      const xhr = await Api.getMonthlyTasks(checkedInitEndDate.init, checkedInitEndDate.end);
      const annualBudgets = JSON.parse(xhr.response).data;
      for (let i = 0; i < annualBudgets.length; i += 1) {
        for (let j = 0; j < annualBudgets[i].version.length; j += 1) {
          this.annualBudgetsFormatted.push({
            idAnnualBudget: annualBudgets[i].id,
            year: annualBudgets[i].year,
            idAnnualBudgetVersion: annualBudgets[i].version[j].id,
            version: annualBudgets[i].version[j].name,
            status: annualBudgets[i].version[j].closed ? this.$t('Status_Closed') : this.$t('Status_reallyNot_Closed'),
          });
        }
      }
    } catch (e) {
      const message = this.$helpers.getFilteredErrorMessage(e);
      if (message.includes('default_') && !message.includes('404')) {
        this.$f7.dialog.alert(message.split('_').pop());
      } else if (!message.includes('404')) {
        this.$f7.dialog.alert(this.$t(message));
      }
    } finally {
      this.$f7.preloader.hide();
      this.loaded = true;
    }
  },
  methods: {
    // VER VERSIÓN DE UN BUDGET ANUAL
    showAnnualBudgetVersion(e) {
      this.setBadgedId(e.data.idAnnualBudget);
      this.setVersionId(e.data.idAnnualBudgetVersion);
      this.setAnnualBudgetVersion(e.data.version);
      if (e.data.status === this.$t('Status_Closed')) {
        this.setMonthlyPlanningEditable(false);
      } else {
        this.setMonthlyPlanningEditable(true);
      }
      this.$f7.views.main.router.navigate('/monthlyPlanning/', { reloadCurrent: true });
    },
    // CERRAR VERSIÓN DE UN BUDGET ANUAL
    async closeAnnualBudgetVersion(e) {
      this.$f7.preloader.show();
      try {
        await Api.closeBadget(e.data.idAnnualBudget);
      } catch (error) {
        const message = this.$helpers.getFilteredErrorMessage(error);
        if (message.includes('default_') && !message.includes('404')) {
          this.$f7.dialog.alert(message.split('_').pop());
        } else if (!message.includes('404')) {
          this.$f7.dialog.alert(this.$t(message));
        }
      } finally {
        this.$f7.preloader.hide();
        this.$f7.views.main.router.navigate('/annualBudgets/', { reloadCurrent: true });
      }
    },
    // CLONAR VERSIÓN DE UN BUDGET ANUAL
    cloneAnnualBudgetVersion(e) {
      this.setAnnualBudgetId(e.data.idAnnualBudget);
      this.setAnnualBudgetVersionId(e.data.idAnnualBudgetVersion);
      this.$f7.views.main.router.navigate('/cloneAnnualBudget/', { reloadCurrent: true });
    },
    // CREACIÓN DEL BOTÓN -> CREAR NUEVO ANNUAL BUDGET
    onToolbarPreparing(e) {
      e.toolbarOptions.items.unshift({
        location: 'before',
        widget: 'dxButton',
        options: {
          icon: 'add',
          elementAttr: {
            class: 'add-task',
          },
          text: this.$t('DxAnnualBudgets_createAnnualBudget_text'),
          onClick: () => {
            this.$f7.views.main.router.navigate('/createAnnualBudget/', { reloadCurrent: true });
          },
        },
      });
    },
    setButtonCloseVisible(e) {
      if (e.data.status === this.$t('Status_Closed')) {
        return false;
      }
      return true;
    },
    // EXPORTAR EXCEL
    onExporting(e) {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('AnnualBudgets');
      exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
      }).then(() => {
        workbook.xlsx.writeBuffer()
          .then((buffer) => {
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'AnnualBudgets.xlsx');
          });
      });
      e.cancel = true;
    },
    ...mapActions('greenhouse', ['fetchLocationTree']),
    ...mapActions('TaskBadget', ['setBadgedId', 'setVersionId', 'setTaskId', 'setActualTaskBadget']),
    ...mapActions('PlanningManager', ['setAnnualBudgetId', 'setAnnualBudgetVersionId', 'setMonthlyPlanningEditable', 'setAnnualBudgetVersion']),
  },
};
</script>
<style lang="scss">
.templateButtons {
  display: flex;
  justify-content: flex-start;
}
.main-annual-budgets
  .boton-duplicy, .boton-search, .boton-close {
    width: auto;
    padding: 3px 6px;
    font-weight: 500;
    border: 1px solid var(--ha-gray);
    text-transform: uppercase;
    margin-right: 5px;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
    text-decoration: none;
    outline:none;
  }
  .boton-duplicy {
    background: var(--fr-open-tint);
    border: 1px solid var(--fr-open-tint)!important;
    color: var(--fr-pending-tint);
    &:hover {
      background: var(  --fr-pending-shade);
      border: 1px solid var( --f7-searchbar-bg-color);
      color: var(--fr-inprogress-tint);
    }
  }
  .boton-search {
    background: var(--fr-pending-tint);
    border-color: var(  --fr-pending);
    color: var(  --fr-open-tint);
    &:hover {
      background: var( --fr-rejected-tint);
      color:var(--fr-open);
    }
  }
  .boton-close {
    background: #F4F5F7;
    border-color: rgba(47,72,89,0.35);
    color: rgb(47,72,89);
    &:hover {
      background: #ececec;
      border-color: var(--ha-orange);
      color: #263b48;
    }
 }
.total-group-count{
    font-size: 16px;
    font-weight: 700;
    border: 1px solid rgba(133,103,103,0.2);
    margin-right: 10px;
    padding: 8px 12px;
    border-radius: 5px;
    background: #FBF7EF;
    color: #470000;
}
.total-group-count span {
  font-weight: 400;
  font-size: 13px;
}

</style>
