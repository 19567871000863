<template>
  <DxPopup
    :visible.sync="visiblePopupWorkOrder"
    :on-hiding="closePopup"
    :title="$t('DxWorkOrders_modal_title')"
    :show-close-button="false"
  >
    <template>
      <f7-block
        class="margin"
      >
        <f7-block-title v-if="supervisor === ''">
          {{ $t('DxPopupWorkOrder_Title_supervisor') }}:
        </f7-block-title>
        <f7-row
          v-if="supervisor === ''"
        >
          <DxSelectSupervisorPerformance
            :supervisor="actualWorkOrder.responsible"
            :supervisors-availables="supervisorsAvailables"
          />
        </f7-row>
        <f7-block-title v-if="['0801','0802','0803'].includes(actualWorkOrder.taskCode)">
          {{ $t('DxPopupWorkOrder_Title_totalPerformance') }}:
        </f7-block-title>
        <f7-row v-if="['0801','0802','0803'].includes(actualWorkOrder.taskCode)">
          <DxManualPerformance />
        </f7-row>
        <f7-block-title v-if="machineryPopup.length > 0">
          {{ $t('DxPopupWorkOrder_Title_machinery') }}:
        </f7-block-title>
        <f7-row v-if="machineryPopup.length > 0">
          <DxMachineryPerformance
            :machinery-popup="actualWorkOrder.machineryPopup"
          />
        </f7-row>
        <f7-block-title v-if="productPopup.length > 0">
          {{ $t('DxPopupWorkOrder_Title_product') }}:
        </f7-block-title>
        <f7-row v-if="productPopup.length > 0">
          <DxProductPerformance
            :product-popup="actualWorkOrder.productPopup"
          />
        </f7-row>
      </f7-block>
      <f7-block-footer
        class="content-action-botton no-margin-top no-padding-top no-padding-right content-modal"
      >
        <f7-button
          class="dx-btn-cancel margin-right"
          raised
          type="default"
          text-color="grey"
          styling-mode="outlined"
          name="ButtonCancel"
          @click="closePopup"
        >
          {{ $t('EditOrder_ButtonCancel_caption') }}
        </f7-button>
        <f7-button
          class="dx-btn-success"
          raised
          type="success"
          styling-mode="contained"
          name="ButtonSave"
          :disabled="saveButtonPopupWorkOrder"
          @click="savePopup"
        >
          {{ $t("EditOrder_ButtonSave_caption") }}
        </f7-button>
      </f7-block-footer>
    </template>
  </DxPopup>
</template>

<script>

import { mapActions, mapState, mapGetters } from 'vuex';
import { DxPopup } from 'devextreme-vue/popup';
import Api from '../../services/Api';
import DxMachineryPerformance from './DxMachineryPerformance.vue';
import DxProductPerformance from './DxProductPerformance.vue';
import DxSelectSupervisorPerformance from './DxSelectSupervisorPerformance.vue';
import DxManualPerformance from './DxManualPerformance.vue';

export default {
  name: 'DxPopupWorkOrder',
  components: {
    DxPopup,
    DxMachineryPerformance,
    DxProductPerformance,
    DxSelectSupervisorPerformance,
    DxManualPerformance,
  },
  props: {
    machineryPopup: { type: Array, default: () => [] },
    productPopup: { type: Array, default: () => [] },
    back: { type: String, default: '/workOrders/' },
    supervisor: { type: String, default: '' },
    supervisorsAvailables: { type: Array, default: () => [] },
  },
  computed: {
    ...mapState('PlanningManager', ['saveButtonPopupWorkOrder', 'visiblePopupWorkOrder', 'actualWorkOrder']),
    ...mapGetters('PlanningManager', ['manualPerformance']),
  },
  methods: {
    async savePopup() {
      if (this.actualWorkOrder.responsible === '') {
        this.$f7.dialog.alert(this.$t('DxSelectSupervisorPerformance_SelectSupervisor_required'));
      } else {
        this.$f7.preloader.show();

        try {
          const updateOrder = { // TODO: Falta obtener el delta para solo enviar los cambios
            machinery_performance: this.machineryPopup,
            product_performance: this.productPopup,
            responsible: this.actualWorkOrder.responsible,
            performance: this.manualPerformance,
          };
          await Api.updateOrder(this.actualWorkOrder.ID, updateOrder);
          this.$f7.views.main.router.navigate(`/${this.back}/`, { reloadCurrent: true });
        } catch (reason) {
          const message = this.$helpers.getFilteredErrorMessage(reason);
          if (message.includes('default_') && !message.includes('404')) {
            this.$f7.dialog.alert(message.split('_').pop());
          } else if (!message.includes('404')) {
            this.$f7.dialog.alert(this.$t(message));
          }
        } finally {
          this.$f7.preloader.hide();
          this.setSaveButtonPopupWorkOrder(true);
          this.setVisiblePopupWorkOrder(false);
        }
      }
    },
    closePopup() {
      this.setVisiblePopupWorkOrder(false);
      this.setSaveButtonPopupWorkOrder(true);
      this.$f7.views.main.router.navigate(`/${this.back}/`, { reloadCurrent: true });
    },
    ...mapActions('PlanningManager', ['setSaveButtonPopupWorkOrder', 'setVisiblePopupWorkOrder']),
  },

};
</script>

<style scoped>
.popup-supervisor.row {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
}
</style>
