var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.loaded)?_c('DxForm',{attrs:{"id":"form","col-count":1}},_vm._l((_vm.locationsFormated),function(item,index){return _c('DxSimpleItem',{key:index,attrs:{"data-field":((index+1) + " " + (item.name[0])),"editor-options":{
      items: _vm.getSupervisorsAvailablesByLocation(item),
      searchEnabled: true,
      searchTimeout: _vm.searchTimeoutOption,
      minSearchLength: _vm.minSearchLengthOption,
      showDataBeforeSearch: _vm.showDataBeforeSearchOption,
      onValueChanged: _vm.onSupervisorChange,
      value: _vm.supervisorSelected(item),
      readOnly: _vm.readOnly,
      displayExpr: 'fullname',
      valueExpr:'id',
    },"editor-type":"dxSelectBox","caption":_vm.$t('SelectSupervisor_supervisor_caption')}},[_c('DxRequiredRule',{attrs:{"message":"At least one supervisor is needed"}})],1)}),1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }