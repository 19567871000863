export default {
  namespaced: true,

  state: {
    selectedWorker: { name: '', surname: '' },
    event: [],
    upSheet: false,
    type: null,
    code: null,
    searchText: '',
  },

  getters: {
    selectedWorker: (state) => state.selectedWorker,
    event: (state) => state.event,
    upSheet: (state) => state.upSheet,
    type: (state) => state.type,
    code: (state) => state.code,
    searchText: (state) => state.searchText,
  },

  actions: {
    setSelectedWorker(context, value) {
      context.commit('setSelectedWorker', value);
    },
    setEvent(context, value) {
      context.commit('setEvent', value);
    },
    changeUpSheet(context, value) {
      context.commit('changeUpSheet', value);
    },
    setType(context, value) {
      context.commit('setType', value);
    },
    setCode(context, value) {
      context.commit('setCode', value);
    },
    setSearchText(context, value) {
      context.commit('setSearchText', value);
    },
  },

  mutations: {
    setSelectedWorker(state, user) {
      state.selectedWorker = user;
    },
    setEvent(state, event) {
      state.event = event;
    },
    changeUpSheet(state) {
      state.upSheet = !state.upSheet;
    },
    setType(state, value) {
      state.type = value;
    },
    setCode(state, value) {
      state.code = value;
    },
    setSearchText(state, value) {
      state.searchText = value;
    },
  },
};
