var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content-devx main-dx-batch-info-form no-shadow"},[_c('f7-block-title',{staticClass:"no-padding-top"},[_vm._v("\n    "+_vm._s(_vm.editing ? _vm.$t('BatchesForm_edit_batches_info') : _vm.$t('BatchesForm_create_batches_info'))+"\n  ")]),_vm._v(" "),_c('DxForm',[(_vm.editing)?_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('DxBatchesInfoForm_Farm'),"name":"farm","validation-rules":_vm.validationRules.farm,"editor-options":{
        disabled: true,
        items: _vm.locationTree,
        displayExpr:'name',
        valueExpr:'bd_id',
        searchEnabled: true,
        onValueChanged: _vm.changeLocation,
        value: _vm.currentLocation,
      },"editor-type":"dxSelectBox"}}):_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('DxBatchesInfoForm_Farm'),"name":"farm","validation-rules":_vm.validationRules.farm,"editor-options":{
        items: _vm.locationTree,
        displayExpr:'name',
        valueExpr:'bd_id',
        searchEnabled: true,
        onValueChanged: _vm.changeLocation,
        value: _vm.currentLocation,
      },"editor-type":"dxSelectBox"}}),_vm._v(" "),(_vm.editing)?_c('DxSimpleItem',{attrs:{"validation-rules":_vm.validationRules.name,"data-field":_vm.$t('BatchesInfoForm_name'),"editor-options":{
        disabled: true,
        value: _vm.actualNameBatch,
        mode: 'text',
        onValueChanged: _vm.changeNameBatch,
      }}}):_c('DxSimpleItem',{attrs:{"validation-rules":_vm.validationRules.name,"data-field":_vm.$t('BatchesInfoForm_name'),"editor-options":{
        disabled: false,
        value: _vm.actualNameBatch,
        mode: 'text',
        onValueChanged: _vm.changeNameBatch,
      }}}),_vm._v(" "),_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('PlanningComponent_date_caption'),"name":"date"},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('f7-input',{staticClass:"form-calendar",attrs:{"id":"datepicker-multiple","type":"datepicker","placeholder":_vm.$t('PlanningComponent_datepicker_multiple_placeholder'),"disabled":"","calendar-params":{
            openIn: 'customModal',
            header: false,
            footer: true,
            dateFormat: 'yyyy-mm-dd'
          },"value":_vm.currentDate}})]},proxy:true}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }