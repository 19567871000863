<template>
  <div
    class="content-devx content-dx-worker main-dx-machinery"
  >
    <DxDataGrid
      id="worker-grid"
      :data-source="dataSourceFiltered"
      :remote-operations="true"
      :column-min-width="90"
      :column-auto-width="true"
      :allow-column-reordering="true"
      :allow-column-resizing="true"
      :show-borders="true"
      :show-column-lines="true"
      :show-row-lines="true"
      :row-alternation-enabled="true"
      @exporting="onExporting"
    >
      <DxSelection
        mode="single"
      />
      <DxStateStoring
        :enabled="true"
        type="localStorage"
        storage-key="DxWeatherPrecipitationGrid"
      />
      <DxExport
        :enabled="true"
      />
      <DxPaging :page-size="10" />
      <DxPager
        :show-page-size-selector="true"
        :allowed-page-sizes="pageSizes"
        :show-info="true"
      />
      <DxHeaderFilter :visible="true" />
      <DxSearchPanel
        :visible="true"
        :width="200"
        :placeholder="$t('Search_placeholder')"
      />
      <DxColumnFixing :enabled="true" />
      <DxColumn
        data-field="date"
        :caption="$t('DxMachinery_Date_caption')"
        :allow-header-filtering="false"
        alignment="left"
        data-type="datetime"
        format="yyyy-MM-dd"
      />
      <DxColumn
        data-field="date"
        :caption="$t('DxWeatherGrid_date_time')"
        :allow-header-filtering="false"
        alignment="left"
        data-type="datetime"
        format="HH:mm"
      />
      <DxColumn
        data-field="measuredPrecipitation"
        :caption="$t('ClimateGrids_DxWeatherTemperatureGrid_measured_Precipitation')"
        :allow-header-filtering="true"
        alignment="left"
      />
      <DxScrolling column-rendering-mode="virtual" />
    </DxDataGrid>
  </div>
</template>

<script>

import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxSearchPanel,
  DxExport,
  DxScrolling,
  DxColumnFixing,
  DxHeaderFilter,
  DxStateStoring,
  DxPager,
  DxSelection,
} from 'devextreme-vue/data-grid';
import moment from 'moment';
import { mapState } from 'vuex';
import { exportDataGrid } from 'devextreme/excel_exporter';
import ExcelJS from 'exceljs';
import saveAs from 'file-saver';

export default {
  name: 'DxFertilizersDataBase',
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxSearchPanel,
    DxExport,
    DxScrolling,
    DxColumnFixing,
    DxHeaderFilter,
    DxStateStoring,
    DxPager,
    DxSelection,
  },
  props: {
    dataSource: {
      type: Array,
      default: () => [],
    },
    weatherElement: {
      type: String,
      default: 'Precipitation',
    },
  },
  data() {
    return {
      keyName: 'WeatherPrecipitationGrid',
      pageSizes: [5, 10, 20],
    };
  },
  computed: {
    ...mapState('CalendarInitEndDate', ['actualFilter']),
    dataSourceFiltered() {
      return typeof this.actualFilter.climateGrids === 'undefined' || this.actualFilter.climateGrids === '' ? this.dataSource : this.dataSource.filter(
        (dataRow) => moment(dataRow.date).format('YYYY-MM-DD') <= this.actualFilter.climateGrids.end
        && moment(dataRow.date).format('YYYY-MM-DD') >= this.actualFilter.climateGrids.init,
      );
    },
  },
  methods: {
    onExporting(e) {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('WeatherPrecipitationGrid');
      exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
      }).then(() => {
        workbook.xlsx.writeBuffer()
          .then((buffer) => {
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'WeatherPrecipitationGrid.xlsx');
          });
      });
      e.cancel = true;
    },
  },
};
</script>
