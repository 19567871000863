<template>
  <div
    v-if="loaded"
    class="content-devx content-dx-daily-planning tabla-ingredientes"
  >
    <DxDataGrid
      id="id"
      :data-source="prescriptionManagerFormated"
      :remote-operations="true"
      :column-auto-width="true"
      :allow-column-reordering="true"
      :allow-column-resizing="true"
      :show-borders="true"
      :show-column-lines="true"
      :show-row-lines="true"
      :row-alternation-enabled="true"
      @exporting="onExporting"
    >
      <DxSelection
        mode="single"
      />
      <DxStateStoring
        :enabled="true"
        type="localStorage"
        storage-key="DxActiveIngredientsVademecum"
      />
      <DxExport
        :enabled="true"
      />
      <DxPaging :page-size="10" />
      <DxPager
        :show-page-size-selector="true"
        :allowed-page-sizes="pageSizes"
        :show-info="true"
      />
      <DxSearchPanel
        :visible="true"
        :width="200"
        :placeholder="$t('Search_placeholder')"
      />
      <DxColumnFixing :enabled="true" />
      <DxColumn
        v-for="(headerVademecum, index) in headersVademecum"
        :key="index"
        :fixed="true"
        :data-field="headerVademecum"
        :caption="`${$t(headerVademecum)}`"
      />
      <DxScrolling column-rendering-mode="virtual" />
    </DxDataGrid>
  </div>
</template>

<script>

import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxSearchPanel,
  DxExport,
  DxScrolling,
  DxColumnFixing,
  DxHeaderFilter,
  DxStateStoring,
  DxPager,
  DxSelection,
} from 'devextreme-vue/data-grid';
import { mapState } from 'vuex';
import { exportDataGrid } from 'devextreme/excel_exporter';
import ExcelJS from 'exceljs';
import saveAs from 'file-saver';
import Api from '../../services/Api';

export default {
  name: 'DxPrescriptionManager',
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxSearchPanel,
    DxExport,
    DxScrolling,
    DxColumnFixing,
    DxHeaderFilter,
    DxStateStoring,
    DxPager,
    DxSelection,
  },
  data() {
    return {
      loaded: false,
      prescriptionManagerFormated: [],
      pageSizes: [5, 10, 20],
    };
  },
  computed: {
    ...mapState('Prescription', ['headersVademecum']),
  },
  async beforeMount() {
    this.$f7.preloader.show();
    try {
      const xhr2 = await Api.getVademecum();
      const dataArray2 = JSON.parse(xhr2.response);
      const prescriptionManager = dataArray2;
      this.prescriptionManagerFormated.length = 0;
      for (let i = 0; i < prescriptionManager.length; i += 1) {
        let paises = '';
        for (let j = 0; j < prescriptionManager[i].countries.length; j += 1) {
          if (prescriptionManager[i].countries[j].value !== '') {
            if (paises === '') {
              paises = prescriptionManager[i].countries[j].key;
            } else {
              paises = `${paises}, ${prescriptionManager[i].countries[j].key}`;
            }
          }
        }
        this.prescriptionManagerFormated.push({
          activeSustance: prescriptionManager[i].active_substance,
          active_substance_id: prescriptionManager[i].active_substance_id,
          approval_date: prescriptionManager[i].approval_date,
          approval_expiration: prescriptionManager[i].approval_expiration,
          authorisation_in_progress: prescriptionManager[i].authorisation_in_progress,
          authorised: prescriptionManager[i].authorised,
          category: prescriptionManager[i].category,
          organic_approved: this.formattingOrganicApprovedColumn(prescriptionManager[i].organic_approved),
          scs_banned: prescriptionManager[i].scs_banned ? this.$t('Yes') : this.$t('No'),
          remark: prescriptionManager[i].remark,
          status: prescriptionManager[i].status,
          substitution_candidate: prescriptionManager[i].status,
          countries: paises,
        });
      }
    } catch (e) {
      const message = this.$helpers.getFilteredErrorMessage(e);
      if (message.includes('default_') && !message.includes('404')) {
        this.$f7.dialog.alert(message.split('_').pop());
      } else if (!message.includes('404')) {
        this.$f7.dialog.alert(this.$t(message));
      }
    } finally {
      this.$f7.preloader.hide();
      this.loaded = true;
    }
  },
  methods: {
    // UTILITIES
    formattingOrganicApprovedColumn(value) {
      if (value) {
        return this.$t('Yes');
      }
      return this.$t('No');
    },
    // EXPORT EXCEL
    onExporting(e) {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('PrescriptionManager');
      exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
      }).then(() => {
        workbook.xlsx.writeBuffer()
          .then((buffer) => {
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'PrescriptionManager.xlsx');
          });
      });
      e.cancel = true;
    },
  },
};
</script>
