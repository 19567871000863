<template>
  <f7-page
    class="main-formpage"
    @page:beforein="fetchFormData"
    @page:beforeout="resetBareRootsStates"
  >
    <Navbar
      :text="currentTitle"
    />
    <f7-row v-if="loaded">
      <f7-col
        width="100"
      >
        <DxBareRootsInfoForm
          :bare-roots-info="bareRootContent"
          :editing="isEditing"
        />
      </f7-col>
    </f7-row>
    <div class="content-devx main-dx-binsinfoform no-shadow">
      <f7-row>
        <f7-col>
          <f7-block-title
            class="no-padding-top"
          >
            {{ $t('BareRoots_content_title') }}
          </f7-block-title>
        </f7-col>
      </f7-row>

      <f7-row v-if="loaded">
        <f7-col
          width="25"
          medium="30"
        >
          <DxTreePlanning
            :show-grouped="false"
            :show-summary="false"
            :max-height="300"
            :locations-tree="locationTreeByName"
            :location-selected="locationIdSelected"
          />
        </f7-col>
        <f7-col
          width="75"
          medium="70"
        >
          <DxBareRootsContentForm />
        </f7-col>
      </f7-row>
    </div>
    <f7-block-footer
      class="content-action-botton"
    >
      <f7-button
        class="dx-btn-cancel margin-right button button-raised text-color-grey"
        type="cancel"
        styling-mode="contained"
        name="ButtonCancel_caption"
        @click="navigateTo"
      >
        {{ $t('ButtonCancel_caption') }}
      </f7-button>

      <f7-button
        class="dx-btn-success button button-raised"
        type="success"
        styling-mode="contained"
        name="CreatePlanning_ButtonSave_caption"
        :disabled="disableButton"
        @click="saveAction"
      >
        {{ textButtonSave }}
      </f7-button>
    </f7-block-footer>
  </f7-page>
</template>

<script>

import { mapState, mapActions, mapGetters } from 'vuex';
import Navbar from '../../components/NavBar.vue';
import DxTreePlanning from '../../components/DxTreePlanning.vue';
import DxBareRootsInfoForm from '../../components/greenhouse/DxBareRootsInfoForm.vue';
import DxBareRootsContentForm from '../../components/greenhouse/DxBareRootsContentForm.vue';

export default {
  name: 'BareRootsFormPage',
  components: {
    DxTreePlanning,
    DxBareRootsContentForm,
    DxBareRootsInfoForm,
    Navbar,
  },

  data() {
    return {
      defaultLocationsTree: [],
      loaded: false,
      urlOfPreviousRoute: '',
      bareRootContent: {},
      locationIdSelected: [],
    };
  },
  computed: {
    ...mapState('greenhouse',
      [
        'locationTree',
      ]),
    ...mapGetters('greenhouse',
      [
        'getBareRootToEdit',
        'locationTreeByName',
        'isBareRootFormValid',
      ]),
    currentURL() {
      return this.$f7route.url;
    },
    currentTitle() {
      let currentTitle = '';
      switch (this.currentURL) {
        case '/bare-roots/create/':
          currentTitle = this.$t('BareRoots_create_navbar_title');
          break;
        case '/bare-roots/edit/':
          currentTitle = this.$t('BareRoots_edit_navbar_title');
          break;
        default:
          currentTitle = this.$t('BareRoots_create_navbar_title');
          break;
      }
      return currentTitle;
    },
    isEditing() {
      return this.currentURL === '/bare-roots/edit/';
    },
    saveAction() {
      if (this.isEditing) {
        return this.editBin;
      }
      return this.createBareRoot;
    },
    disableButton() {
      if (this.isBareRootFormValid) {
        return false;
      }
      return true;
    },
    textButtonSave() {
      let currentTitle = '';
      switch (this.currentURL) {
        case '/bare-roots/create/':
          currentTitle = this.$t('CreateBareRoot_ButtonSave_caption');
          break;
        case '/bare-roots/edit/':
          currentTitle = this.$t('EditBareRoot_ButtonSave_caption');
          break;
        default:
          currentTitle = this.$t('CreateBareRoot_ButtonSave_caption');
          break;
      }
      return currentTitle;
    },
  },
  methods: {
    ...mapActions('greenhouse',
      [
        'fetchBinsContentFamilies',
        'fetchLocationTree',
        'resetStates',
        'createNewBin',
        'createNewBareRoot',
        'saveEditedBareRoot',
      ]),
    ...mapActions('PlanningManager', ['setActualLocationsSelected']),
    ...mapActions('authentication', ['setActualMenuSelected']),
    showDialogAlertByError(error) {
      const message = this.$helpers.getFilteredErrorMessage(error);
      if (message.includes('default_') && !message.includes('404')) {
        this.$f7.dialog.alert(message.split('_').pop());
      } else if (!message.includes('404')) {
        this.$f7.dialog.alert(this.$t(message));
      }
    },
    async fetchFormData() {
      this.urlOfPreviousRoute = this.$f7router.previousRoute.url;
      this.$f7.preloader.show();
      try {
        await this.fetchBinsContentFamilies();
        await this.fetchLocationTree();
        this.defaultLocationsTree = this.locationTree;
        // Si estamos editando cargamos los datos en los componentes
        if (this.bareRootsId !== '') {
          this.bareRootContent = this.getBareRootToEdit;
          this.locationIdSelected.push(this.getBareRootToEdit.locationId);
          this.setActualLocationsSelected([this.getBareRootToEdit.locationId]);
        }
      } catch (error) {
        this.showDialogAlertByError(error);
      } finally {
        this.$f7.preloader.hide();
        this.loaded = true;
      }
    },
    async createBareRoot() {
      this.$f7.preloader.show();
      try {
        await this.createNewBareRoot();
        this.setActualMenuSelected('/bare-roots/');
        this.$f7.views.main.router.navigate('/bare-roots/', { reloadCurrent: true });
      } catch (error) {
        this.showDialogAlertByError(error);
      } finally {
        this.$f7.preloader.hide();
      }
    },
    resetBareRootsStates() {
      this.resetStates();
    },
    async editBin() {
      this.$f7.preloader.show();
      try {
        await this.saveEditedBareRoot();
        this.setActualMenuSelected('/bare-roots/');
        this.$f7.views.main.router.navigate('/bare-roots/', { reloadCurrent: true });
      } catch (error) {
        const message = this.$helpers.getFilteredErrorMessage(error);
        if (message.includes('default_') && !message.includes('404')) {
          this.$f7.dialog.alert(message.split('_').pop());
        } else if (!message.includes('404')) {
          this.$f7.dialog.alert(this.$t(message));
        }
      } finally {
        this.$f7.preloader.hide();
      }
    },
    navigateTo() {
      if (this.urlOfPreviousRoute === '/bare-roots/') {
        this.setActualMenuSelected('/bare-roots/');
        this.$f7router.navigate('/bare-roots/', { reloadCurrent: true });
      } else {
        this.setActualMenuSelected('/bins/');
        this.$f7router.navigate('/bins/', { reloadCurrent: true });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.ha-card {
    padding: 20px;
    margin-top: 30px;
}
</style>
