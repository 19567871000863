<template>
  <div
    v-if="loaded"
    class="content-devx main-dxwarehouse"
  >
    <CalendarInitEndDate :key-name="keyName" />
    <DxDataGrid
      id="warehouseGrid"
      ref="warehouseGrid"
      :data-source="detailDailyTasks"
      :remote-operations="true"
      :column-min-width="70"
      :allow-column-reordering="true"
      :show-borders="true"
      :show-column-lines="true"
      :show-row-lines="false"
      :column-auto-width="true"
      :row-alternation-enabled="true"
      @exporting="onExporting"
      @row-updated="onChangeRow"
    >
      <DxStateStoring
        :enabled="true"
        type="localStorage"
        storage-key="DxWarehouse"
      />
      <DxEditing
        :allow-updating="true"
        mode="row"
      />
      <DxHeaderFilter :visible="true" />
      <DxExport
        :enabled="true"
      />
      <DxPaging :page-size="10" />
      <DxPager
        :show-page-size-selector="true"
        :allowed-page-sizes="pageSizes"
        :show-info="true"
      />
      <DxSearchPanel
        :visible="true"
        :width="200"
        :highlight-case-sensitive="true"
        :placeholder="$t('Search_placeholder')"
      />
      <DxColumn
        data-field="shortId"
        name="shortId"
        :caption="$t('DxWorkOrder_short_id')"
        :allow-header-filtering="true"
        :allow-editing="false"
      />
      <DxColumn
        data-field="Date"
        name="date"
        :caption="$t('DxDailyPlanning_date_caption')"
        :allow-header-filtering="false"
        :allow-editing="false"
      />
      <DxColumn
        data-field="Activity"
        name="activity"
        :caption="$t('DxDailyPlanning_activity_caption')"
        :allow-header-filtering="true"
        :allow-editing="false"
      />
      <DxColumn
        data-field="activityCode"
        name="activityCode"
        data-type="number"
        :caption="$t('DxWorker_ActivityCode_caption')"
        :allow-header-filtering="true"
        :allow-editing="false"
      />
      <DxColumn
        data-field="Task"
        name="task"
        :caption="$t('DxDailyPlanning_task_caption')"
        :allow-header-filtering="true"
        :allow-editing="false"
      />
      <DxColumn
        data-field="taskCode"
        name="taskCode"
        data-type="number"
        :caption="$t('PlanningComponent_taskCode_caption')"
        :allow-header-filtering="true"
        :allow-editing="false"
        alignment="center"
      />
      <DxColumn
        data-field="item_name"
        :caption="$t('DxWorkOrders_machinery_caption')"
        :allow-header-filtering="true"
        :allow-editing="false"
      />
      <DxColumn
        data-field="internal_code"
        :allow-header-filtering="true"
        :caption="$t('DxMachineryForm_internal_code_sap_caption')"
        :allow-editing="false"
      />
      <DxColumn
        data-field="km_start"
        data-type="number"
        :caption="$t('DxWorkOrders_km_start_caption')"
        :allow-header-filtering="false"
        alignment="center"
      >
        <DxRangeRule
          min="0"
        />
      </DxColumn>
      <DxColumn
        data-field="km_finish"
        data-type="number"
        :caption="$t('DxWorkOrders_km_finish_caption')"
        :allow-header-filtering="false"
        alignment="center"
      >
        <DxRangeRule
          min="0"
        />
      </DxColumn>
      <DxColumn
        :data-field="$t('DxWarehouse_totalKm_caption')"
        data-type="number"
        :allow-header-filtering="false"
        :calculate-cell-value="calculateKmDiff"
        alignment="center"
        :allow-editing="false"
      />
      <DxColumn
        data-field="horometer_start"
        data-type="number"
        :caption="$t('DxWorkOrders_horometer_start_caption')"
        :allow-header-filtering="false"
        alignment="center"
      >
        <DxRangeRule
          min="0"
        />
      </DxColumn>
      <DxColumn
        data-field="horometer_finish"
        data-type="number"
        :caption="$t('DxWorkOrders_horometer_finish_caption')"
        :allow-header-filtering="false"
        alignment="center"
      >
        <DxRangeRule
          min="0"
        />
      </DxColumn>
      <DxColumn
        :data-field="$t('DxWarehouse_totalHorometer_caption')"
        data-type="number"
        :allow-header-filtering="false"
        :calculate-cell-value="calculateHorometerDiff"
        alignment="center"
        :allow-editing="false"
      />
      <DxColumn
        data-field="fuel_consumption"
        data-type="number"
        :caption="$t('DxWorkOrders_fuel_consumption_caption')"
        :allow-header-filtering="false"
        alignment="center"
      >
        <DxRangeRule
          min="0"
        />
      </DxColumn>
      <DxColumn
        data-field="fuel_type"
        alignment="center"
        :caption="$t('DxWorkOrders_fuel_type_caption')"
      >
        <DxLookup
          :data-source="typeCosumptionAvailable"
          :display-expr="dysplayConsumptionType"
        />
      </DxColumn>
    </DxDataGrid>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxLookup,
  DxEditing,
  DxSearchPanel,
  DxExport,
  DxHeaderFilter,
  DxStateStoring,
  DxPager,
} from 'devextreme-vue/data-grid';
import { exportDataGrid } from 'devextreme/excel_exporter';
import ExcelJS from 'exceljs';
import moment from 'moment';
import saveAs from 'file-saver';
import * as overlay from 'devextreme/ui/overlay';
import {
  DxRangeRule,
} from 'devextreme-vue/validator';
import Api from '../services/Api';
import CalendarInitEndDate from './CalendarInitEndDate.vue';

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxLookup,
    DxEditing,
    DxSearchPanel,
    DxExport,
    DxHeaderFilter,
    DxStateStoring,
    CalendarInitEndDate,
    DxPager,
    DxRangeRule,
  },
  data() {
    return {
      loaded: false,
      detailDailyTasks: [],
      machineryPopup: [],
      productPopup: [],
      ocultarBotton: false,
      itemsAvailables: [],
      selectTextOnEditStart: true,
      startEditAction: 'click',
      typeCosumptionAvailable: ['DIESEL', 'GASOLINE'],
      keyName: 'warehouse',
      pageSizes: [5, 10, 20],
    };
  },
  computed: {
    ...mapGetters('CalendarInitEndDate', ['actualFilter']),
  },
  async beforeMount() {
    overlay.baseZIndex(9999);
    this.$f7.preloader.show();
    try {
      this.itemsAvailables = await this.getItemsMachinary('MACHINERY', 'VEHICLE');
      const checkedInitEndDate = Api.checkInitEndDate(this.actualFilter[this.keyName]);
      const xhr = await Api.getFuelRegister(checkedInitEndDate.init, checkedInitEndDate.end);
      const fuelRegister = JSON.parse(xhr.response).data;
      this.detailDailyTasks.length = 0;
      for (let i = 0; i < fuelRegister.length; i += 1) {
        for (let k = 0; k < fuelRegister[i].machinery_performance.length; k += 1) {
          this.detailDailyTasks.push({
            ID: fuelRegister[i].id,
            Date: fuelRegister[i].date,
            Task: fuelRegister[i].task,
            taskCode: fuelRegister[i].task_code,
            Activity: fuelRegister[i].activity,
            activityCode: fuelRegister[i].activity_code,
            item_id: fuelRegister[i].machinery_performance[k].item_id,
            item_name: this.machineryNameFormatted(fuelRegister[i].machinery_performance[k].item_id),
            internal_code: this.machineryCodeFormatted(fuelRegister[i].machinery_performance[k].item_id),
            km_start: fuelRegister[i].machinery_performance[k].km_start,
            km_finish: fuelRegister[i].machinery_performance[k].km_finish,
            horometer_start: fuelRegister[i].machinery_performance[k].horometer_start,
            horometer_finish: fuelRegister[i].machinery_performance[k].horometer_finish,
            working_area: fuelRegister[i].machinery_performance[k].working_area,
            fuel_consumption: fuelRegister[i].machinery_performance[k].fuel_consumption,
            fuel_type: fuelRegister[i].machinery_performance[k].fuel_type,
            comment: fuelRegister[i].machinery_performance[k].comment,
            shortId: fuelRegister[i].short_id,
          });
        }
      }
    } catch (e) {
      const message = this.$helpers.getFilteredErrorMessage(e);
      if (message.includes('default_') && !message.includes('404')) {
        this.$f7.dialog.alert(message.split('_').pop());
      } else if (!message.includes('404')) {
        this.$f7.dialog.alert(this.$t(message));
      }
    } finally {
      this.$f7.preloader.hide();
      this.loaded = true;
    }
  },
  methods: {
    machineryCodeFormatted(e) {
      for (let i = 0; i < this.itemsAvailables.length; i += 1) {
        if (this.itemsAvailables[i].id === e) {
          return this.itemsAvailables[i].item.code;
        }
      }
      return '';
    },
    machineryNameFormatted(e) {
      for (let i = 0; i < this.itemsAvailables.length; i += 1) {
        if (this.itemsAvailables[i].id === e) {
          return this.itemsAvailables[i].item.description;
        }
      }
      return '';
    },
    dysplayConsumptionType(e) {
      if (e === 'DIESEL') {
        return this.$t('DIESEL');
      }
      return this.$t('GASOLINE');
    },
    async getItemsMachinary(type, family) {
      const xhr = await Api.getItems(type, family);
      return JSON.parse(xhr.response);
    },
    getItems(itemsDriver) {
      const items = [];
      for (const itemDriver of itemsDriver) {
        items.push(itemDriver.item);
      }
      return items;
    },
    /*
    Quito la columan working area, compruebo con un console
    que sigue viniendo el dato en el currentRow, si fallase,
    volver a colocar la columna y en vez de eliminarla,
    añadir por ejemplo un visible: false.
    */
    async onChangeRow(e) {
      const currentRow = e.data;
      if (parseInt(currentRow.km_start, 10) > parseInt(currentRow.km_finish, 10)) {
        this.$f7.dialog.alert(this.$t('DxWarehouse_totalKm_validation'));
      } else if (parseInt(currentRow.horometer_start, 10) > parseInt(currentRow.horometer_finish, 10)) {
        this.$f7.dialog.alert(this.$t('DxWarehouse_totalHorometer_validation'));
      } else {
        this.$f7.preloader.show();
        this.machineryPopup.push({
          item_id: currentRow.item_id,
          km_start: currentRow.km_start,
          km_finish: currentRow.km_finish,
          horometer_start: currentRow.horometer_start,
          horometer_finish: currentRow.horometer_finish,
          working_area: currentRow.working_area,
          fuel_consumption: currentRow.fuel_consumption,
          fuel_type: currentRow.fuel_type,
          comment: currentRow.comment,
        });
        try {
          const updateOrder = { // TODO: Falta obtener el delta para solo enviar los cambios
            machinery_performance: this.machineryPopup,
          // product_performance: this.productPopup,
          };
          await Api.updateOrder(currentRow.ID, updateOrder);
        } catch (reason) {
          const message = this.$helpers.getFilteredErrorMessage(reason);
          if (message.includes('default_') && !message.includes('404')) {
            this.$f7.dialog.alert(message.split('_').pop());
          } else if (!message.includes('404')) {
            this.$f7.dialog.alert(this.$t(message));
          }
        } finally {
          this.$f7.preloader.hide();
        }
      }
      this.$f7.views.main.router.navigate('/warehouse/', { reloadCurrent: true });
    },
    calculateHorometerDiff(rowData) {
      if (typeof (rowData.horometer_finish) !== 'undefined' && rowData.horometer_finish !== null && typeof (rowData.horometer_start) !== 'undefined' && rowData.horometer_start !== null) {
        return parseFloat(rowData.horometer_finish - rowData.horometer_start).toFixed(2);
      }
      return 0;
    },
    calculateKmDiff(rowData) {
      if (typeof (rowData.km_finish) !== 'undefined' && rowData.km_finish !== null && typeof (rowData.km_start) !== 'undefined' && rowData.km_start !== null) {
        return parseFloat(rowData.km_finish - rowData.km_start).toFixed(2);
      }
      return 0;
    },
    // EXPORT EXCEL
    onExporting(e) {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('WorkOrders');
      exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
      }).then(() => {
        workbook.xlsx.writeBuffer()
          .then((buffer) => {
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'WorkOrders.xlsx');
          });
      });
      e.cancel = true;
    },
    ...mapActions('PlanningManager', ['setActualWorkOrder', 'setVisiblePopupWorkOrder']),
    ...mapActions('Prescription', ['setProducts', 'setPrescription', 'setNewPrescription']),
  },
};
</script>
<style scoped>
.content-status-cell {
  padding: 0;
}
.open, .in-progress, .completed, .pending, .deleted, .rejected, .work-order-closed, .work-order-not-closed{
  border-radius: 2px;
  font-size: 12px;
  padding: 4px 2px;
  font-weight: 600;
  text-transform: uppercase;
}

.open {
  color: var(--fr-open);
  background: var( --f7-checkbox-inactive-color);
}
.in-progress {
  background: var(--ha-yellow);
  color: var(--fr-inprogress-tint);
}
.completed {
  background: var(--fr-finished);
  color: var(--fr-finished-tint);
}
.pending {
  color: var(--fr-pending);
  background: var(--fr-pending-tint);
}
.deleted {
  color: var(--fr-deleted);;
  background: var(--fr-deleted-tint);
}
.work-order-closed{
  color: var(--ha-dark-green);
  background: var(--ha-light-green);
}
.work-order-not-closed{
  color: #ffffff;
  background: var(--fr-nutella);
}
</style>
