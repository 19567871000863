<template>
  <div
    v-if="loaded"
    class="content-devx content-dx-daily-planning main-dx-products-data-base"
  >
    <DxDataGrid
      id="productDB"
      :data-source="listProductsFormated"
      :remote-operations="true"
      :column-min-width="70"
      :column-auto-width="true"
      :allow-column-reordering="true"
      :allow-column-resizing="true"
      :show-borders="true"
      :show-column-lines="true"
      :show-row-lines="true"
      :row-alternation-enabled="true"
      @exporting="onExporting"
      @toolbar-preparing="onToolbarPreparing($event)"
    >
      <DxSelection
        mode="single"
      />
      <DxStateStoring
        :enabled="true"
        type="localStorage"
        storage-key="DxProductsDataBase"
      />
      <DxHeaderFilter :visible="true" />
      <DxExport
        :enabled="true"
      />
      <DxPaging :page-size="10" />
      <DxPager
        :show-page-size-selector="true"
        :allowed-page-sizes="pageSizes"
        :show-info="true"
      />
      <DxSearchPanel
        :visible="true"
        :width="200"
        :placeholder="$t('Search_placeholder')"
      />
      <DxColumn
        v-if="$helpers.havePermission(getPermissions.company_update_master_data)"
        type="buttons"
        :width="90"
        data-field="Actions"
      >
        <DxButton
          icon="edit"
          :hint="$t('DxProdutsDataBase_edit_hint')"
          :on-click="editProduct"
        />
      </DxColumn>
      <DxColumn
        data-field="status"
        :width="90"
        :caption="$t('DxProductsDataBase_status_caption')"
        alignment="left"
      />
      <DxColumn
        data-field="code"
        :caption="$t('DxProductsDataBase_code_caption')"
        alignment="left"
      />
      <DxColumn
        data-field="description"
        :caption="$t('DxProductsDataBase_description_caption')"
        :allow-header-filtering="false"
        alignment="left"
      />
      <DxColumn
        data-field="component"
        :caption="$t('DxProductsDataBase_component_caption')"
        :allow-header-filtering="false"
        alignment="left"
      />
      <!--  Ahora msimo no vienen todos con la unidad, lo dejo preparado.-->
      <DxColumn
        data-field="SAPunit"
        :caption="$t('DxProductsDataBase_UMSAP_caption')"
        alignment="left"
      />
      <DxColumn
        data-field="family"
        :caption="$t('DxProductsDataBase_family_caption')"
        alignment="left"
      />
      <DxColumn
        data-field="subfamily"
        :caption="$t('DxProductsDataBase_subfamily_caption')"
        alignment="left"
      />
      <DxColumn
        data-field="MaxDoses"
        :caption="$t('DxFormProduct_maxdosis')"
        alignment="left"
      />
      <DxColumn
        data-field="IntervalIn"
        :caption="$t('DxProductsDataBase_currentIN')"
        alignment="left"
      />
      <DxColumn
        data-field="Preharvest"
        :caption="$t('DxProductsDataBase_preHarverst')"
        alignment="left"
      />
      <DxColumn
        data-field="EUauthorized"
        :caption="$t('DxFormProduct_EU_autorizado')"
        alignment="left"
      />
      <DxColumn
        data-field="SCSauthorized"
        :caption="$t('DxFormProduct_SCS_autorizado')"
        alignment="left"
      />
      <DxScrolling column-rendering-mode="virtual" />
    </DxDataGrid>
  </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex';
import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxSearchPanel,
  DxExport,
  DxScrolling,
  DxHeaderFilter,
  DxButton,
  DxStateStoring,
  DxPager,
  DxSelection,
} from 'devextreme-vue/data-grid';
import { exportDataGrid } from 'devextreme/excel_exporter';
import ExcelJS from 'exceljs';
import saveAs from 'file-saver';
import Api from '../../services/Api';

export default {
  name: 'DxProductsDataBase',
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxSearchPanel,
    DxExport,
    DxScrolling,
    DxHeaderFilter,
    DxButton,
    DxStateStoring,
    DxPager,
    DxSelection,
  },
  data() {
    return {
      loaded: false,
      listProductsFormated: [],
      pageSizes: [5, 10, 20],
    };
  },
  computed: {
    ...mapGetters('authentication', ['getPermissions']),
  },
  async beforeMount() {
    this.$f7.preloader.show();
    try {
      const xhr = await Api.getDataSubfamilyByType('product');
      const dataArray = JSON.parse(xhr.response);
      const listPhytosanitary = dataArray.find((x) => x.id === 'PHITOSANITARY').items;
      this.listProductsFormated.length = 0;
      for (let i = 0; i < listPhytosanitary.length; i += 1) {
        for (let j = 0; j < listPhytosanitary[i].items.length; j += 1) {
          for (let k = 0; k < listPhytosanitary[i].items[j].items.length; k += 1) {
            this.listProductsFormated.push({
              status: this.formattingStatusColumn(listPhytosanitary[i].items[j].items[k].status),
              description: listPhytosanitary[i].items[j].items[k].description,
              family: listPhytosanitary[i].items[j].items[k].properties.find((x) => x.key === 'family').value,
              subfamily: listPhytosanitary[i].items[j].items[k].properties.find((x) => x.key === 'subfamily').value,
              toxicity: (typeof (listPhytosanitary[i].items[j].items[k].properties.find((x) => x.key === 'toxicity')) !== 'undefined')
                ? listPhytosanitary[i].items[j].items[k].properties.find((x) => x.key === 'toxicity').value : false,
              code: listPhytosanitary[i].items[j].items[k].code,
              comment: listPhytosanitary[i].items[j].items[k].comment,
              component: listPhytosanitary[i].items[j].items[k].component,
              id: listPhytosanitary[i].items[j].items[k].id,
              properties: listPhytosanitary[i].items[j].items[k].properties,
              item: listPhytosanitary[i].items[j].items[k].item,
              SAPunit: listPhytosanitary[i].items[j].items[k].properties.find((x) => x.key === 'SAPunit').value,
              MaxDoses: this.$helpers.getProperty(listPhytosanitary[i].items[j].items[k].properties, 'maxDoses'),
              IntervalIn: this.$helpers.getProperty(listPhytosanitary[i].items[j].items[k].properties, 'intervalIn'),
              EUauthorized: this.$helpers.getProperty(listPhytosanitary[i].items[j].items[k].properties, 'EU_authorized') === true ? this.$t('Yes') : this.$t('No'),
              SCSauthorized: this.$helpers.getProperty(listPhytosanitary[i].items[j].items[k].properties, 'SCS_authorized') === true ? this.$t('Yes') : this.$t('No'),
              Preharvest: this.$helpers.getProperty(listPhytosanitary[i].items[j].items[k].properties, 'preharvest'),
            });
          }
        }
      }

      const listFertilizers = dataArray.find((x) => x.id === 'FERTILIZERS').items;
      for (let i = 0; i < listFertilizers.length; i += 1) {
        for (let j = 0; j < listFertilizers[i].items.length; j += 1) {
          this.listProductsFormated.push({
            status: this.formattingStatusColumn(listFertilizers[i].items[j].status),
            code: listFertilizers[i].items[j].code,
            description: listFertilizers[i].items[j].description,
            family: listFertilizers[i].items[j].properties.find((x) => x.key === 'family').value,
            subfamily: listFertilizers[i].items[j].properties.find((x) => x.key === 'subfamily').value,
            toxicity: (typeof (listFertilizers[i].items[j].properties.find((x) => x.key === 'toxicity')) !== 'undefined')
              ? listFertilizers[i].items[j].properties.find((x) => x.key === 'toxicity').value : false,
            comment: listFertilizers[i].items[j].comment,
            component: listFertilizers[i].items[j].component,
            id: listFertilizers[i].items[j].id,
            properties: listFertilizers[i].items[j].properties,
            item: listFertilizers[i].items[j].item,
            SAPunit: listFertilizers[i].items[j].properties.find((x) => x.key === 'SAPunit').value,
            MaxDoses: this.$helpers.getProperty(listFertilizers[i].items[j].properties, 'maxDoses'),
            IntervalIn: this.$helpers.getProperty(listFertilizers[i].items[j].properties, 'intervalIn'),
            EUauthorized: this.$helpers.getProperty(listFertilizers[i].items[j].properties, 'EU_authorized') === 'true' ? this.$t('Yes') : this.$t('No'),
            SCSauthorized: this.$helpers.getProperty(listFertilizers[i].items[j].properties, 'SCS_authorized') === 'true' ? this.$t('Yes') : this.$t('No'),
            Preharvest: this.$helpers.getProperty(listFertilizers[i].items[j].properties, 'preharvest'),
          });
        }
      }
      const listSubstrate = dataArray.find((x) => x.id === 'SUBSTRATE').items;
      for (let i = 0; i < listSubstrate.length; i += 1) {
        for (let j = 0; j < listSubstrate[i].items.length; j += 1) {
          this.listProductsFormated.push({
            status: this.formattingStatusColumn(listSubstrate[i].items[j].status),
            code: listSubstrate[i].items[j].code,
            description: listSubstrate[i].items[j].description,
            family: listSubstrate[i].items[j].properties.find((x) => x.key === 'family').value,
            subfamily: listSubstrate[i].items[j].properties.find((x) => x.key === 'subfamily').value,
            toxicity: (typeof (listSubstrate[i].items[j].properties.find((x) => x.key === 'toxicity')) !== 'undefined')
              ? listSubstrate[i].items[j].properties.find((x) => x.key === 'toxicity').value : false,
            comment: listSubstrate[i].items[j].comment,
            component: listSubstrate[i].items[j].component,
            id: listSubstrate[i].items[j].id,
            properties: listSubstrate[i].items[j].properties,
            item: listSubstrate[i].items[j].item,
            SAPunit: listSubstrate[i].items[j].properties.find((x) => x.key === 'SAPunit').value,
            MaxDoses: this.$helpers.getProperty(listSubstrate[i].items[j].properties, 'maxDoses'),
            IntervalIn: this.$helpers.getProperty(listSubstrate[i].items[j].properties, 'intervalIn'),
            EUauthorized: this.$helpers.getProperty(listSubstrate[i].items[j].properties, 'EU_authorized') === 'true' ? this.$t('Yes') : this.$t('No'),
            SCSauthorized: this.$helpers.getProperty(listSubstrate[i].items[j].properties, 'SCS_authorized') === 'true' ? this.$t('Yes') : this.$t('No'),
            Preharvest: this.$helpers.getProperty(listSubstrate[i].items[j].properties, 'preharvest'),
          });
        }
      }
    } catch (e) {
      const message = this.$helpers.getFilteredErrorMessage(e);
      if (message.includes('default_') && !message.includes('404')) {
        this.$f7.dialog.alert(message.split('_').pop());
      } else if (!message.includes('404')) {
        this.$f7.dialog.alert(this.$t(message));
      }
    } finally {
      this.$f7.preloader.hide();
      this.loaded = true;
    }
  },
  methods: {
    formattingStatusColumn(value) {
      if (value) {
        return this.$t('active');
      }
      return this.$t('noActive');
    },
    onToolbarPreparing(e) {
      if (!this.$helpers.havePermission(this.getPermissions.company_update_master_data)) return;
      e.toolbarOptions.items.unshift({
        location: 'before',
        widget: 'dxButton',
        options: {
          icon: 'add',
          elementAttr: {
            class: 'add-task',
          },
          text: this.$t('DxProdutsDataBase_button_text'),
          onClick: () => {
            this.createProduct();
          },
        },
      });
    },
    createProduct() {
      this.setNewProduct();
      this.$f7.views.main.router.navigate('/storeProduct/', { reloadCurrent: true });
    },
    editProduct(rowSelected) {
      this.setProduct(rowSelected.row.data);
      this.$f7.views.main.router.navigate('/storeProduct/', { reloadCurrent: true });
    },
    onExporting(e) {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('ProductsDataBase');
      exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
      }).then(() => {
        workbook.xlsx.writeBuffer()
          .then((buffer) => {
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'PrescriptionManager.xlsx');
          });
      });
      e.cancel = true;
    },
    ...mapActions('Product', ['setProduct', 'setNewProduct']),
  },
};
</script>
