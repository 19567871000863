<template>
  <div
    v-if="loaded"
    class="content-devx main-dx-monitoring-points"
  >
    <DxDataGrid
      id="DxMonitoringPointsGrid"
      :data-source="pointsFormatted"
      :column-min-width="90"
      :column-auto-width="true"
      :allow-column-reordering="true"
      :show-borders="true"
      :show-column-lines="true"
      :show-row-lines="true"
      :row-alternation-enabled="true"
      @exporting="onExporting"
      @toolbar-preparing="onToolbarPreparing($event)"
    >
      <DxSelection
        mode="single"
      />
      <DxStateStoring
        :enabled="true"
        type="localStorage"
        storage-key="DxMonitoringPoints"
      />
      <DxExport
        :enabled="true"
      />
      <DxHeaderFilter :visible="true" />
      <DxPaging :page-size="10" />
      <DxPager
        :show-page-size-selector="true"
        :allowed-page-sizes="pageSizes"
        :show-info="true"
      />
      <DxSearchPanel
        :visible="true"
        :width="200"
        :placeholder="$t('Search_placeholder')"
      />
      <DxColumn
        v-if="$helpers.havePermission(getPermissions.company_update_master_data)"
        type="buttons"
        data-field="Actions"
      >
        <DxButton
          icon="edit"
          :on-click="editPoint"
        />
        <DxButton
          icon="trash"
          :on-click="deletePoint"
        />
      </DxColumn>
      <DxColumn
        data-field="name"
        name="name"
        :caption="$t('DxMonitoringPoints_name')"
        :allow-header-filtering="false"
      />
      <DxColumn
        data-field="locationName"
        name="location"
        :caption="$t('DxMonitoringPoints_location')"
        :allow-header-filtering="true"
      />
      <DxColumn
        data-field="coordinates"
        name="coordinates"
        :caption="$t('DxMonitoringPoints_coordinates')"
        :allow-header-filtering="false"
      />
      <DxColumn
        data-field="numberPlants"
        name="numberPlants"
        :caption="$t('DxMonitoringPoints_numberPlants')"
        :allow-header-filtering="false"
        :width="120"
      />
      <DxColumn
        data-field="variety"
        name="variety"
        :caption="$t('DxMonitoringPoints_variety')"
        :allow-header-filtering="true"
      />
      <DxColumn
        data-field="type"
        name="type"
        :caption="$t('DxMonitoringPoints_type')"
        :allow-header-filtering="true"
      />
      <DxColumn
        data-field="yearPlanting"
        name="yearPlanting"
        :caption="$t('DxMonitoringPoints_yearPlanting')"
        :allow-header-filtering="true"
      />
      <DxColumn
        data-field="statusFormatted"
        name="status"
        :caption="$t('DxMonitoringPoints_statusFormatted')"
        :allow-header-filtering="true"
      />
      <DxColumn
        data-field="note"
        name="note"
        :caption="$t('DxMonitoringPoints_note')"
        :allow-header-filtering="false"
      />
    </DxDataGrid>
  </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex';
import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxSearchPanel,
  DxButton,
  DxExport,
  DxHeaderFilter,
  DxStateStoring,
  DxPager,
  DxSelection,
} from 'devextreme-vue/data-grid';
import { exportDataGrid } from 'devextreme/excel_exporter';
import ExcelJS from 'exceljs';
import saveAs from 'file-saver';
import Api from '../../services/Api';

export default {
  name: 'DxMonitoringPoints',
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxSearchPanel,
    DxButton,
    DxExport,
    DxHeaderFilter,
    DxStateStoring,
    DxPager,
    DxSelection,
  },
  data() {
    return {
      loaded: false,
      pointsFormatted: [],
      locationsTree: [],
      locationsTreeFormatted: [],
      locationsFake: [],
      currentLevel2Name: '',
      level2Data: [],
      areaLevel2: 0,
      pageSizes: [5, 10, 20],
    };
  },
  computed: {
    ...mapGetters('authentication', ['getPermissions']),
  },
  async beforeMount() {
    this.$f7.preloader.show();
    try {
      this.locationsTree = await this.loadLocationsTree();
      const xhr = await Api.getPoints();
      const pointsAvailables = JSON.parse(xhr.response).data;
      for (let i = 0; i < pointsAvailables.length; i += 1) {
        this.locationsFake = [...[pointsAvailables[i].location]];
        this.pointsFormatted.push({
          id: pointsAvailables[i].id,
          name: pointsAvailables[i].name,
          locationId: pointsAvailables[i].location,
          locationName: this.getLocationLevel2(),
          latitude: pointsAvailables[i].coordinates.latitude,
          longitude: pointsAvailables[i].coordinates.longitude,
          coordinates: `${pointsAvailables[i].coordinates.latitude},${pointsAvailables[i].coordinates.longitude}`,
          numberPlants: pointsAvailables[i].number_plants,
          variety: pointsAvailables[i].variety,
          type: pointsAvailables[i].type,
          yearPlanting: pointsAvailables[i].planting_year,
          statusFormatted: pointsAvailables[i].status ? this.$t('active') : this.$t('noActive'),
          status: pointsAvailables[i].status,
          note: pointsAvailables[i].note,
        });
      }
    } catch (e) {
      const message = this.$helpers.getFilteredErrorMessage(e);
      if (message.includes('default_') && !message.includes('404')) {
        this.$f7.dialog.alert(message.split('_').pop());
      } else if (!message.includes('404')) {
        this.$f7.dialog.alert(this.$t(message));
      }
    } finally {
      this.$f7.preloader.hide();
      this.loaded = true;
    }
  },
  methods: {
    createPoint() {
      this.$f7.views.main.router.navigate('/createPoint/', { reloadCurrent: true });
    },
    editPoint(rowSelected) {
      this.setPoint(rowSelected.row.data);
      this.$f7.views.main.router.navigate('/editPoint/', { reloadCurrent: true });
    },
    async deletePoint(rowSelected) {
      const pointId = rowSelected.row.data.id;
      const f7 = this.$f7;
      this.$f7.dialog.create({
        title: this.$t('DxMonitoringPoints_dialogDelete_title'),
        text: this.$t('DxMonitoringPoints_dialogDelete_text'),
        buttons: [
          {
            text: this.$t('Button_cancel_text'),
          },
          {
            text: this.$t('Button_confirm_text'),
            async onClick() {
              try {
                f7.preloader.show();
                await Api.deletePoint(pointId);
                f7.views.main.router.navigate('/monitoringPoints/', { reloadCurrent: true });
              } catch (e) {
                f7.dialog.alert(e);
              } finally {
                f7.preloader.hide();
              }
            },
          },
        ],
      }).open();
    },
    async loadLocationsTree() {
      const xhr = await Api.getLocationsTree();
      return JSON.parse(xhr.response);
    },
    getLocationLevel2() {
      this.currentLevel2Name = '';
      this.level2Data = [];
      this.areaLevel2 = 0;
      for (let i = 0; i < this.locationsTree.length; i += 1) {
        if (this.locationsFake.length === 0) {
          // Si no quedan ids en el array de parcelas de esta tarea
          break;
        }
        this.recursiveGetChildrens(this.locationsTree[i]);
      }
      return this.level2Data;
    },
    recursiveGetChildrens(items) {
      this.currentLevel2Name = items.name;
      // Si el nivel no tiene hijos, comprobamos si su id está en el array
      const check = this.locationsFake.indexOf(items.bd_id);
      if (check !== -1) {
        const alreadyInserted = this.level2Data.indexOf(this.currentLevel2Name);
        if (alreadyInserted === -1) {
          // Está en el array de localizaciones y aun no lo hemos guardado
          this.level2Data.push(this.currentLevel2Name);
        }
        this.areaLevel2 += items.area;
        this.locationsFake.splice(check, 1); // Elimino el id del array
      } else if (this.locationsFake.length !== 0) {
        // El nivel tiene hijos
        for (let j = 0; j < items.children.length; j += 1) {
          this.recursiveGetChildrens(items.children[j]);
        }
      }
    },
    // CREACIÓN BOTÓN CREAR POINT
    onToolbarPreparing(e) {
      if (!this.$helpers.havePermission(this.getPermissions.company_update_master_data)) return;
      e.toolbarOptions.items.unshift({
        location: 'before',
        widget: 'dxButton',
        options: {
          icon: 'add',
          elementAttr: {
            class: 'add-task',
          },
          text: this.$t('DxMonitoringPoints_createPoint_button'),
          onClick: () => {
            this.createPoint();
          },
        },
      });
    },
    // EXPORT EXCEL
    onExporting(e) {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('MonitoringPoints');
      exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
      }).then(() => {
        workbook.xlsx.writeBuffer()
          .then((buffer) => {
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'MonitoringPoints.xlsx');
          });
      });
      e.cancel = true;
    },
    ...mapActions('Scouting', ['setPoint']),
  },
};
</script>
