<template>
  <div
    class="content-devx content-dx-upload-file"
  >
    <f7-block class="profile">
      <input
        id="upload"
        type="file"
        accept="xlsx"
        @change="onFileSelected"
      >
      <DxButton
        :text="$t('UploadFile_button')"
        type="success"
        styling-mode="contained"
        @click="onUpdateFile"
      />
    </f7-block>
  </div>
</template>

<script>
import { DxButton } from 'devextreme-vue/button';
import Api from '../../services/Api';

export default {
  name: 'UploadFile',
  components: {
    DxButton,
  },
  props: {
    urlToSend: { type: String, default: 'crop/file/up' },
  },
  data() {
    return {
      selectedFile: '',
    };
  },
  methods: {
    async onUpdateFile() {
      try {
        if (this.selectedFile === '') {
          this.$f7.dialog.alert(this.$t('UploadFile_empty'));
          return;
        }
        this.$f7.dialog.preloader(this.$t('UploadFile_preloader'), 'primary');
        // Se le da un pequeño tiempo al usuario para que lea el mensaje y no se vaya a otra ventana mientras se sube el fichero
        setTimeout(() => {
          this.$f7.dialog.close();
        }, 3000);
        await Api.uploadFile(this.selectedFile, this.urlToSend);
        this.$f7.dialog.alert(this.$t('UploadFile_success'));
      } catch (error) {
        this.$f7.dialog.close();
        this.$f7.dialog.alert(`${this.$t('UploadFile_error')} ${error.errorCodes[0]}`);
      }
    },
    onFileSelected(event) {
      const reader = new FileReader();
      const newFile = event.target.files[0];

      reader.onload = (e) => {
        this.selectedFile = `${e.target.result}`;
      };
      reader.onerror = (error) => {
        this.$f7.dialog.alert(`${this.$t('UploadFile_error')} ${error}`);
      };
      reader.readAsDataURL(newFile);
    },
  },
};
</script>
