<template>
  <div id="chart-demo">
    <DxChart
      id="chart"
      :data-source="haverstReportFormatted"
      palette="Violet"
      @legend-click="onLegendClick"
    >
      <DxCommonSeriesSettings
        argument-field="date"
      />
      <DxSeries
        value-field="total"
        type="line"
        :name="$t('DxGraphLinePoint_title_graph')"
      />
      <DxMargin :bottom="20" />
      <DxArgumentAxis
        :value-margins-enabled="false"
        discrete-axis-division-mode="crossLabels"
      >
        <DxGrid :visible="true" />
      </DxArgumentAxis>
      <DxLegend
        vertical-alignment="bottom"
        horizontal-alignment="center"
        item-text-position="bottom"
      />
      <DxExport :enabled="true" />
      <DxTitle :text="$t('DxGraphLinePoint_title_graph')" />
      <DxTooltip
        :enabled="true"
        :z-index="999999"
      />
      <DxScrollBar :visible="true" />
      <DxZoomAndPan argument-axis="both" />
    </DxChart>
  </div>
</template>
<script>
import {
  DxChart,
  DxSeries,
  DxArgumentAxis,
  DxCommonSeriesSettings,
  DxExport,
  DxGrid,
  DxMargin,
  DxLegend,
  DxTitle,
  DxTooltip,
  DxZoomAndPan,
  DxScrollBar,
} from 'devextreme-vue/chart';
import { mapState } from 'vuex';

export default {
  name: 'DxGraphLinePoint',
  components: {
    DxChart,
    DxSeries,
    DxArgumentAxis,
    DxCommonSeriesSettings,
    DxExport,
    DxGrid,
    DxMargin,
    DxLegend,
    DxTitle,
    DxTooltip,
    DxZoomAndPan,
    DxScrollBar,
  },
  data() {
    return {
      haverstReportFormatted: [],
    };
  },
  computed: {
    ...mapState('Reporting', ['harvestReport']),
  },
  beforeMount() {
    this.harvestReportFormatted();
  },
  methods: {
    onLegendClick({ target: series }) {
      if (series.isVisible()) {
        series.hide();
      } else {
        series.show();
      }
    },
    harvestReportFormatted() {
      for (const harvestData of this.harvestReport) {
        this.haverstReportFormatted.push({
          date: harvestData.date,
          total: parseInt(harvestData.total, 10),
        });
      }
    },
  },
};
</script>
<style>
.options {
    padding: 20px;
    background-color: rgba(191, 191, 191, 0.15);
    margin-top: 20px;
}

.option {
    margin-top: 10px;
}

.caption {
    font-size: 18px;
    font-weight: 500;
}

.option > span {
    margin-right: 14px;
}

.option > .dx-widget {
    display: inline-block;
    vertical-align: middle;
}
</style>
