var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.loaded)?_c('div',{staticClass:"content-devx content-dx-Tools-form"},[_c('DxForm',{attrs:{"id":"ToolsForm"}},[_c('DxGroupItem',{attrs:{"col-count":6}},[_c('DxGroupItem',{attrs:{"css-class":"content-combo","col-span":3}},[_c('DxSimpleItem',{attrs:{"name":"code","data-field":_vm.$t('DxToolsDatabase_column_code'),"editor-options":{
            value: _vm.actualCode,
            onValueChanged: _vm.onChangeCode,
          }}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('Tools_ValidationCode')}})],1),_vm._v(" "),_c('DxSimpleItem',{attrs:{"name":"description","data-field":_vm.$t('DxToolsDatabase_column_description'),"editor-options":{
            value: _vm.actualDescription,
            onValueChanged: _vm.onChangeDescription,
          }}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('Tools_ValidationDescription')}})],1),_vm._v(" "),((_vm.editForm))?_c('DxSimpleItem',{attrs:{"name":"id","data-field":_vm.$t('DxToolsDatabase_column_id'),"editor-options":{
            value: _vm.actualId,
            onValueChanged: _vm.onChangeId,
            readOnly: true,
          }}}):_vm._e()],1),_vm._v(" "),_c('DxGroupItem',{attrs:{"col-span":3,"css-class":"content-combo"}},[_c('DxSimpleItem',{attrs:{"name":"family","data-field":_vm.$t('DxToolsDatabase_column_family'),"editor-options":{
            // items: familyAvailables,
            value: _vm.actualFamily,
            searchEnabled: true,
            searchTimeout: _vm.searchTimeoutOption,
            minSearchLength: _vm.minSearchLengthOption,
            showDataBeforeSearch: _vm.showDataBeforeSearchOption,
            readOnly: true,
          }}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('Tools_ValidationFamily')}})],1),_vm._v(" "),_c('DxSimpleItem',{attrs:{"name":"subfamily","data-field":_vm.$t('DxToolsDatabase_column_subfamily'),"editor-type":"dxSelectBox","editor-options":{
            items: _vm.subfamilyAvailables,
            value: _vm.actualSubfamily,
            onValueChanged: _vm.onChangeSubfamily,
            searchEnabled: true,
            searchTimeout: _vm.searchTimeoutOption,
            minSearchLength: _vm.minSearchLengthOption,
            showDataBeforeSearch: _vm.showDataBeforeSearchOption,
          }}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('Tools_ValidationSubfamily')}})],1),_vm._v(" "),((_vm.editForm))?_c('DxSimpleItem',{attrs:{"name":"itemId","data-field":_vm.$t('DxToolsDatabase_column_itemId'),"editor-options":{
            value: _vm.actualItemId,
            onValueChanged: _vm.onChangeItemId,
            readOnly: true,
          }}}):_vm._e(),_vm._v(" "),_c('DxSimpleItem',{attrs:{"name":"status","data-field":_vm.$t('DxToolsDatabase_column_status'),"editor-type":"dxCheckBox","editor-options":{
            value: _vm.actualStatus,
            onValueChanged: _vm.onChangeStatus,
          }}}),_vm._v(" "),_c('DxSimpleItem',{attrs:{"name":"fuel","data-field":_vm.$t('DxToolsDatabase_column_fuel'),"editor-type":"dxCheckBox","editor-options":{
            value: _vm.actualFuel,
            onValueChanged: _vm.onChangeFuel,
          }}})],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }