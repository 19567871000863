<template>
  <f7-page>
    <navbar :text="$t('CreateMachinery_Title')" />
    <DxMachineryForm
      class="content-planning"
    />
    <f7-block-footer
      class="content-action-botton"
    >
      <f7-button
        class="dx-btn-cancel margin-right"
        raised
        type="default"
        text-color="grey"
        styling-mode="outlined"
        @click="goToMachineryDatabase"
      >
        {{ $t("CreatePlanning_ButtonCancel_caption") }}
      </f7-button>
      <f7-button
        class="dx-btn-success"
        raised
        type="success"
        styling-mode="contained"
        name="CreatePlanning_ButtonSave_caption"
        @click="createMachinery"
      >
        {{ $t('CreatePlanning_ButtonSave_caption') }}
      </f7-button>
    </f7-block-footer>
  </f7-page>
</template>

<script>
import { mapState } from 'vuex';
import DxMachineryForm from '../../components/machinery/DxMachineryForm.vue';
import navbar from '../../components/NavBar.vue';
import Api from '../../services/Api';

export default {
  name: 'CreateMachinery',
  components: {
    DxMachineryForm,
    navbar,
  },
  computed: {
    ...mapState('Machinery', ['actualMachinery']),
  },
  methods: {
    goToMachineryDatabase() {
      this.$f7.views.main.router.navigate('/machineryDatabase/', { reloadCurrent: true });
    },
    async createMachinery() {
      if (this.actualMachinery.code === '') {
        this.$f7.dialog.alert(this.$t('Machinery_ValidationSAPCode'));
      } else if (this.actualMachinery.brand === '') {
        this.$f7.dialog.alert(this.$t('Machinery_ValidationBrand'));
      } else if (this.actualMachinery.model === '') {
        this.$f7.dialog.alert(this.$t('Machinery_ValidationModel'));
      } else if (this.actualMachinery.serial_number === '') {
        this.$f7.dialog.alert(this.$t('Machinery_ValidationSerialNumber'));
      } else if (this.actualMachinery.plate === '') {
        this.$f7.dialog.alert(this.$t('Machinery_ValidationPlate'));
      } else if (this.actualMachinery.description === '') {
        this.$f7.dialog.alert(this.$t('Machinery_ValidationDescription'));
      } else if (this.actualMachinery.family === '') {
        this.$f7.dialog.alert(this.$t('Machinery_ValidationFamily'));
      } else if (this.actualMachinery.subfamily === '') {
        this.$f7.dialog.alert(this.$t('Machinery_ValidationSubfamily'));
      } else if (this.actualMachinery.internalCode === '') {
        this.$f7.dialog.alert(this.$t('Machinery_ValidationInternalCode'));
      } else {
        this.$f7.preloader.show();
        try {
          const newMachinery = {
            code: this.actualMachinery.code,
            brand: this.actualMachinery.brand,
            model: this.actualMachinery.model,
            serial_number: this.actualMachinery.serialNumber,
            plate: this.actualMachinery.plate,
            description: this.actualMachinery.description,
            attachment: this.actualMachinery.attachment,
            status: this.actualMachinery.status,
            family: this.actualMachinery.family,
            subfamily: this.actualMachinery.subfamily,
            properties: [
              {
                key: 'internal_code',
                value: this.actualMachinery.internalCode,
              },
              {
                key: 'purchase',
                value: this.actualMachinery.purchase,
              },
            ],
          };
          await Api.createMachinery(newMachinery);
          this.goToMachineryDatabase(); // TODO: Habría que mostrar alguna notificación de que se creó correctamente
        } catch (reason) {
          const message = this.$helpers.getFilteredErrorMessage(reason);
          if (message.includes('default_') && !message.includes('404')) {
            this.$f7.dialog.alert(message.split('_').pop());
          } else if (!message.includes('404')) {
            this.$f7.dialog.alert(this.$t(message));
          }
        } finally {
          this.$f7.preloader.hide();
        }
      }
    },
  },
};
</script>
