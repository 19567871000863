<template>
  <f7-block class="container">
    <f7-searchbar
      search-container=".search-list"
      search-item="li"
      search-in=".item-title"
      :placeholder="$t('Search_placeholder')"
    />
    <f7-block>
      <f7-list
        class="search-list searchbar-found"
        virtual-list
        :virtual-list-params="vlParams"
      >
        <f7-list
          v-if="vlData.items.length == 0"
          class="nothingfound"
        >
          <f7-list-item
            :title="$t('ManualList_listItem_title')"
          />
        </f7-list>
        <ul>
          <f7-list-item
            v-for="employee in vlData.items"
            :key="employee.code"
            radio
            name="demo-radio"
            :title="employee.fullname"
            @click="changeStatebtnDisable(employee.object)"
          />
        </ul>
      </f7-list>
      <f7-button
        name="btnContinue"
        class="botonPrimary"
        col
        button
        button-large
        button-raised
        fill
        :disabled="btnDisable"
        @click="saveWorker()"
      >
        {{ $t("Nfc_navbarpresence_btnContinue") }}
      </f7-button>
    </f7-block>
    <SheetNFC :tag="code" />
  </f7-block>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Api from '../../services/Api';
import SheetNFC from '../SheetNFC.vue';

export default {
  components: {
    SheetNFC,
  },
  props: {
    employees: { type: Array, default: () => [] },
    callbackSelected: { type: Function, default: () => {} },
    fullNameFilter: { type: String, default: '' },
    limitFilter: { type: Number, default: 9999 },
    pageFilter: { type: Number, default: 0 },
  },
  data() {
    return {
      code: '',
      vlData: {
        items: [],
      },
      vlParams: {
        items: this.employees,
        searchAll: this.searchAll,
        renderExternal: this.renderExternal,
        // height: this.$theme.ios ? 63 : (this.$theme.md ? 73 : 46),
      },
      worker: null,
      btnDisable: true,
      pathBack: '/nfcManually/',
    };
  },
  computed: {
    ...mapState('TaskManager', ['id']),
  },
  async beforeMount() {
    this.$f7.preloader.show();
    try {
      const xhr = await Api.getEmployees(
        this.limitFilter,
        this.pageFilter,
        this.fullNameFilter,
      );
      const xhrFormat = JSON.parse(xhr.response).data;
      for (let i = 0; i < xhrFormat.length; i += 1) {
        this.employees.push({
          fullname: `${xhrFormat[i].name} ${xhrFormat[i].surname}`,
          code: xhrFormat[i].code,
          object: xhrFormat[i],
        });
      }
      this.vlData.items = this.employees;
    } catch (error) {
      const message = this.$helpers.getFilteredErrorMessage(error);
      if (message.includes('default_') && !message.includes('404')) {
        this.$f7.dialog.alert(message.split('_').pop());
      } else if (!message.includes('404')) {
        this.$f7.dialog.alert(this.$t(message));
      }
    } finally {
      this.$f7.preloader.hide();
    }
  },
  methods: {
    searchAll(query, items) {
      const result = [];
      const lowerQuery = query.toLowerCase();
      items.forEach((item, i) => {
        if (item.fullname.toLowerCase().includes(lowerQuery)) {
          result.push(i);
        }
      });
      return result;
    },
    renderExternal(vl, vlData) {
      this.vlData = vlData;
    },
    saveWorker() {
      this.setSelectedWorker(this.worker);
      this.checkIn('id', this.worker.id);
    },
    changeStatebtnDisable(item) {
      this.btnDisable = false;
      this.worker = item;
    },
    async checkIn(type, code) {
      this.$f7.preloader.show();
      try {
        const xhr = await Api.checkIn(this.id, type, code);
        const response = JSON.parse(xhr.response);
        this.setWorkers(response.workers);
        this.setEvent({ type: 'IN' });
        this.changeUpSheet();
        this.$f7.views.main.router.navigate('/nfc/');
      } catch (error) {
        if (error === 400) {
          this.setType(type);
          this.setCode(code);
          this.$f7.views.main.router.navigate(`/tasks/checkOut${'/nfc/'}`, { reloadCurrent: true });
        } else {
          const event = { type: 'ERROR', status: error, error };
          this.code = code;
          this.setEvent(event);
          this.$f7.sheet.open('.demo-sheet');
        }
      } finally {
        this.$f7.preloader.hide();
      }
    },
    ...mapActions('TaskManager', ['setWorkers']),
    ...mapActions('worker', ['setSelectedWorker', 'setEvent', 'changeUpSheet', 'setType', 'setCode']),
  },
};
</script>
<style scoped>
.list .item-subtitle {
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  color: #999;
  mix-blend-mode: normal;
  opacity: 0.8;
}
.searchbar-found {
  min-height: calc(600px - 100px) !important;
  overflow: scroll;
  background: white;
}
.container {
  background: #f4f5f7;
  padding-top: 10px;
}
.searchbar-input-wrap {
  border: 1px solid #dddddd;
  box-sizing: border-box;
  border-radius: 4px;
}
.searchbar {
  height: 40px;
}
.searchbar-not-found {
  text-align: left;
}
div.item-content{
  text-align: center !important;
}
</style>
